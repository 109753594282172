<template>
  <v-container fluid>
    <v-row class="mt-5 mb-15" justify="center" align="center">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Products</strong></small>
          <v-spacer />
          <v-btn color="#588BAD" rounded small class="pl-10 pr-10" dark
            style="text-transform: none !important; color: white"
            @click="$router.push({ name: '/supplier-setup/create-product' })">
            Create Product
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group>
              <v-list-item two-line v-for="(item, index) in get_supplier_product.filter((x) => !x.archived)"
                :key="index" class="mt-2" @click="click(item)">
                <v-list-item-content>
                  <v-list-item-title>
                    <label style="font-size: 16px;cursor:pointer;">{{ item.name }}</label>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: [],

  destroyed() {
    this.archive_switch = false;
    this.$store.dispatch('supplier/set_archive_switch', false);
  },

  data: () => ({
    amenities: null,
    archive_switch: false,
  }),
  async mounted() {
    await this.$store.dispatch(
      "supplier/fetch_supplier_product",
      this.get_supplier_setup.id
    );
  },
  created() { },
  computed: {
    ...mapGetters({
      get_supplier_setup: "supplier/get_supplier_setup",
      get_supplier_product: "supplier/get_supplier_product",
      get_archived_supplier_product: "supplier/get_archived_supplier_product"
    }),
  },
  methods: {
    click(e) {
      this.$store.dispatch("supplier/set_supplier_product_setup", e);
      this.$router.push({ name: "/supplier-setup/product/setup" });
    },
    async archive_item(e) {
      const toPass = {
        item: e,
        supplier_id: this.get_supplier_setup.id
      }
      this.$store.dispatch('supplier/set_supplier_product_archive', toPass);
    },
    switch_archive() {
      this.archive_switch = !this.archive_switch;

      this.$store.dispatch('supplier/set_archive_switch', this.archive_switch);
    },

    restore_item(e) {
      const toPass = {
        item: e,
        supplier_id: this.get_supplier_setup.id
      };

      this.$store.dispatch('supplier/set_supplier_product_restore', toPass);
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
