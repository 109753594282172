<template>
    <v-row justify="center">
        <v-dialog v-model="show_templates_dialog" max-width="1800" persistent scrollable>
            <v-card>
                <v-card-title>Supplier Email Templates</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="5" class="pr-15">
                            <div class="mb-5">Create new template</div>
                            <v-text-field v-model="new_template.subject" label="Subject" outlined dense></v-text-field>
                            <wysiwyg v-model="new_template.body" outlined dense />
                            <v-btn dark class="custom-buttons mt-5" @click="createEmailTemplate()">Create</v-btn>
                        </v-col>
                        <v-col cols="7" class="pl-15">
                            <div class="mb-5">Edit existing templates</div>
                            <v-select :items="email_templates" v-model="selected_template" outlined dense
                                item-text="subject" return-object placeholder="Select a template"></v-select>
                            <div v-if="selected_template">
                                <v-text-field v-model="selected_template.subject" placeholder="Subject" outlined
                                    dense></v-text-field>
                                <wysiwyg v-model="selected_template.body" outlined dense />
                                <v-btn dark class="custom-buttons mt-5" @click="updateEmailTemplate()">Update</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-buttons" text @click="closeEmailTemplatesDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Settings from '../../../modules/admin/settings/modules/settings.js'
export default {
    components: {
    },
    props: [
        'show_templates_dialog'
    ],
    data: () => ({
        classes: {
            settings: null
        },
        selected_template: null,
        email_templates: [],
        new_template: {
            subject: null,
            body: null,
        }
    }),
    created() {
        this.classes.settings = new Settings()
    },
    async mounted() {
        this.getEmailTemplates()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_user: 'auth/get_user',
        })
    },
    methods: {
        async getEmailTemplates() {
            const result = await this.classes.settings.getEmailTemplates({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.email_templates = result.data
            }
        },
        closeEmailTemplatesDialog() {
            this.$emit('closeEmailTemplatesDialog')
        },
        async createEmailTemplate() {
            let payload = {
                company_id: this.get_selected_company.id,
                subject: this.new_template.subject,
                body: this.new_template.body,
            }
            const result = await this.classes.settings.createEmailTemplate(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.new_template.subject = null
                this.new_template.body = null
                this.getEmailTemplates()
            }
        },
        async updateEmailTemplate() {
            let payload = {
                id: this.selected_template.id,
                subject: this.selected_template.subject,
                body: this.selected_template.body,
            }
            const result = await this.classes.settings.updateEmailTemplate(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.getEmailTemplates()
            }
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}
</style>