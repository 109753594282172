<template>
    <v-container fluid class="mt-1">
        <v-row>
            <v-col cols="12">
                <h3 class="mb-3">Attendance</h3>
                <v-data-table :headers="attendance_headers" :items="combined_builds" disable-pagination
                    hide-default-footer :sort-by.sync="sort_by_attendance" :sort-desc.sync="sort_desc_attendance"
                    style="font-family: Manrope;">
                    <template v-slot:item="{ item }">
                        <tr style="background-color:transparent;cursor:inherit;" :set="tmp_subtotal = 0">
                            <td>{{ item.supplier.supplier_name }} - {{ item.option.name }}</td>
                            <td style="text-align:center;" v-for="(day, index) in days_of_week" :key="index">
                                <div
                                    :set="attendees = event.invitees.filter((x) => x.payments.filter((y) => y.payment_details.filter((z) => z.payment_type_id == 1).length > 0).length > 0).length">
                                    <div v-if="item.type_id == 1">
                                        <div v-for="i in ($date(item.end_date).diff($date(item.start_date), 'day') + 1)"
                                            :key="i">
                                            <div v-if="$date(item.start_date).add(i - 1, 'day').format('dddd') == day"
                                                :set="tmp_subtotal += attendees * item.rate">
                                                {{ attendees }}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="$date(item.start_date).format('dddd') == day"
                                        :set="tmp_subtotal += attendees * item.rate">
                                        {{ attendees }}
                                    </div>
                                </div>
                            </td>
                            <td>{{ get_selected_company.currency }}{{ item.rate }}</td>
                            <td>{{ get_selected_company.currency }}{{ tmp_subtotal.toFixed(2) }}</td>
                        </tr>
                    </template>
                </v-data-table>
                <div class="mt-10 mr-5" style="text-align:right;">
                    <b>Total Payment Due: {{ get_selected_company.currency }}{{ total.toFixed(2) }}</b>
                    <br>
                    <b>Grand Total: {{ get_selected_company.currency }}{{ total.toFixed(2) }}</b>
                </div>
            </v-col>
            <v-col cols="12">
                <div style="display:flex;justify-content:space-between;" class="my-5">
                    <h3 class="mb-3">Supplier Booking</h3>
                    <v-btn class="custom-buttons" dark @click="openUpdateGroupsizeDialog()">Update Groupsizes</v-btn>
                </div>
                <v-expansion-panels v-model="open_panels" multiple class="custom-expansion-panels"
                    style="font-family: Manrope;">
                    <v-expansion-panel v-for="(grouped_builds, index) in builds" :key="index"
                        class="custom-expansion-panel mb-7">
                        <v-expansion-panel-header class="custom-expansion-header" :id="'panel' + index">
                            {{ grouped_builds[0].supplier.supplier_name }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="custom-expansion-panel-content">
                            <div class="mt-5">
                                <div style="display:flex;">
                                    <div style="font-size:20px;">
                                        <b class="mx-5">Booking Status:</b>
                                        <label :class="'label-' + grouped_builds[0].booking_status_id">{{
                                            grouped_builds[0].booking_status.status }}
                                        </label>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn class="custom-buttons mr-2" dark @click="openBookingDialog(grouped_builds)">
                                        Send Booking Confirmation
                                    </v-btn>
                                    <v-btn class="custom-buttons" dark @click="openHistoryDialog(grouped_builds)">
                                        View Email History
                                    </v-btn>
                                </div>
                                <div style="color:black;">
                                    <div>
                                        <v-data-table :headers="build_headers" :items="grouped_builds"
                                            disable-pagination hide-default-footer>
                                            <template v-slot:item="{ item }">
                                                <tr style="background-color:transparent;cursor:inherit;">
                                                    <td>{{ item.option.name }}</td>
                                                    <td>{{ get_selected_company.currency }}{{ item.rate }}</td>
                                                    <td>
                                                        <div v-if="item.type_id == 1">
                                                            {{ $date(item.start_date).format('dddd') }} -
                                                            {{ $date(item.end_date).format('dddd') }}
                                                        </div>
                                                        <div v-else>
                                                            {{ $date(item.start_date).format('dddd') }}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style="display:flex;align-items:center;">
                                                            <v-text-field v-model="item.start_time" placeholder="Time"
                                                                dense class="ml-3"></v-text-field>
                                                            <v-btn text class="custom-buttons"
                                                                @click="updateQuoteBuild(item)">Update</v-btn>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div v-if="item.type_id == 1" :set="tmp_groupsize = []">
                                                            <div v-for="(groupsize, index) in JSON.parse(item.accom_groupsize)"
                                                                :key="index" :set="tmp_groupsize[index] = groupsize"
                                                                style="display:flex;align-items:center;">
                                                                <div class="mr-5">Day {{ index + 1 }}</div>
                                                                <div style="display:flex;align-items:center;">
                                                                    <v-text-field type="number"
                                                                        v-model="tmp_groupsize[index]">
                                                                    </v-text-field>
                                                                    <v-btn text class="custom-buttons"
                                                                        @click="updateQuoteBuildAccom(item, tmp_groupsize)">Update</v-btn>
                                                                    <v-btn text class="custom-buttons"
                                                                        @click="updateQuoteBuildAccomAndSendDialog(item, tmp_groupsize)">Update
                                                                        & Send Email</v-btn>
                                                                </div>
                                                            </div>
                                                            <!-- <v-btn icon
                                                                @click="addAccomGroupsize(item)"><v-icon>mdi-plus</v-icon></v-btn>
                                                            <v-btn icon
                                                                @click="removeAccomGroupsize(item)"><v-icon>mdi-minus</v-icon></v-btn> -->
                                                        </div>
                                                        <div v-else style="display:flex;align-items:center;">
                                                            <v-text-field v-model="item.groupsize"></v-text-field>
                                                            <v-btn text class="custom-buttons"
                                                                @click="updateQuoteBuild(item)">Update</v-btn>
                                                            <v-btn text class="custom-buttons"
                                                                @click="openSendEmailDialog(item)">Update & Send
                                                                Email</v-btn>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </div>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row v-if="selected_supplier">
            <v-dialog v-model="booking_dialog" max-width="600px" persistent>
                <v-card>
                    <v-card-title>
                        Send Booking Confirmation to {{ selected_supplier.supplier_name }}
                    </v-card-title>
                    <v-card-text>
                        <div class="mb-2">TEMPLATE</div>
                        <v-select v-model="booking.template" :items="email_templates" item-text="subject" return-object
                            outlined dense placeholder="Select template" @change="setEmailTemplate()"></v-select>
                        <div class="mb-2">SUBJECT *</div>
                        <v-text-field v-model="booking.subject" outlined dense placeholder="Subject"></v-text-field>
                        <div class="mb-2">EMAIL CONTENT *</div>
                        <wysiwyg v-model="booking.body" outlined dense placeholder="Add text..." />
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeBookingDialog()">Cancel</v-btn>
                        <v-btn dark @click="sendBooking()">Send</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row v-if="selected_supplier">
            <v-dialog v-model="history_dialog" max-width="1000" persistent>
                <v-card>
                    <v-card-title>
                        Email History for {{ selected_supplier.supplier_name }}
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="history_headers" :items="booking_history" :sort-by.sync="sort_by"
                            :sort-desc.sync="sort_desc">
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.subject }}</td>
                                    <td>
                                        <div v-html="item.body" style="max-height:200px;overflow-y:scroll;"></div>
                                    </td>
                                    <td>{{ $date(item.created_at).format('MMMM D, YYYY h:ma') }}</td>
                                    <td>{{ item.sender.name }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeHistoryDialog()">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row v-if="supplier_to_send_to">
            <v-dialog v-model="send_email_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Are you sure you want to send a groupsize update email?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" @click="closeSendEmailDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark
                            @click="updateQuoteBuildAndSend(supplier_to_send_to)">Send</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row v-if="supplier_to_send_to">
            <v-dialog v-model="send_email_dialog_accom" max-width="600" persistent>
                <v-card>
                    <v-card-title>Are you sure you want to send a groupsize update email?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" @click="closeSendEmailAccomDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark
                            @click="updateQuoteBuildAccomAndSend(supplier_to_send_to, groupsize_to_send)">Send</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-dialog v-model="update_groupsize_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Are you sure you want to update final groupsizes?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" @click="closeUpdateGroupsizeDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark @click="updateGroupsizes()">Send</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Events from '../../modules/admin/events/modules/events.js'
import Invitees from '../../modules/admin/invitees/modules/invitees.js'
import Settings from '../../modules/admin/settings/modules/settings.js'
import Quotes from '../../modules/admin/quotes/modules/quotes.js'
import _ from 'lodash'

export default {
    components: {
    },
    data: () => ({
        page_name: "Suppliers",
        classes: {
            events: null,
            invitees: null,
            settings: null,
            quotes: null,
        },
        selected_supplier: null,
        selected_builds: null,
        booking: {
            template: null,
            subject: null,
            body: null,
        },
        booking_dialog: false,
        history_dialog: false,
        booking_history: [],
        history_headers: [
            { text: 'Subject', sortable: false, value: 'subject' },
            { text: 'Body', sortable: false, value: 'body' },
            { text: 'Date Sent', sortable: false, value: 'created_at' },
            { text: 'Sent By', sortable: false, value: 'sent_by' },
        ],
        sort_by: 'created_at',
        sort_desc: true,
        email_templates: null,
        menu: [],
        build_headers: [
            { text: 'Service', sortable: false },
            { text: 'Rate', sortable: false },
            { text: 'Day/s', sortable: false },
            { text: 'Time', sortable: false },
            { text: 'Groupsize', sortable: false },
        ],
        open_panels: [0],
        attendance_headers: [
            { text: 'Supplier', sortable: false },
            { text: 'Sun', sortable: false },
            { text: 'Mon', sortable: false },
            { text: 'Tue', sortable: false },
            { text: 'Wed', sortable: false },
            { text: 'Thu', sortable: false },
            { text: 'Fri', sortable: false },
            { text: 'Sat', sortable: false },
            { text: 'Rate', sortable: false },
            { text: 'Subtotal', sortable: false },
        ],
        sort_by_attendance: 'type_id',
        sort_desc_attendance: false,
        days_of_week: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        send_email_dialog_accom: false,
        send_email_dialog: false,
        supplier_to_send_to: null,
        groupsize_to_send: null,
        update_groupsize_dialog: false,
    }),
    created() {
        this.setPageName(this.page_name)
        this.classes.events = new Events()
        this.classes.invitees = new Invitees()
        this.classes.settings = new Settings()
        this.classes.quotes = new Quotes()
    },
    async mounted() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            event: 'eventsStore/getSelectedEventGetter',
        }),
        combined_builds() {
            let combined_builds = new Array()
            this.event.quotes.forEach(quote => {
                combined_builds = combined_builds.concat(quote.builds)
            })
            return combined_builds
        },
        builds() {
            let builds = []
            let combined_builds = new Array()
            this.event.quotes.forEach(quote => {
                combined_builds = combined_builds.concat(quote.builds)
            })
            builds = _.groupBy(combined_builds, 'supplier_id')
            return builds
        },
        total() {
            let total = 0
            let combined_builds = new Array()
            this.event.quotes.forEach(quote => {
                combined_builds = combined_builds.concat(quote.builds)
            })
            let attendees = this.event.invitees.filter((x) => x.payments.filter((y) => y.payment_details.filter((z) => z.payment_type_id == 1).length > 0).length > 0).length
            combined_builds.forEach(build => {
                if (build.type_id == 1) {
                    total += build.rate * parseInt(attendees) * (this.$date(build.end_date).diff(this.$date(build.start_date), 'day'))
                }
                else {
                    total += build.rate * parseInt(attendees)
                }
            })
            return total
        }
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
        }),
        groupBy(array, value) {
            return _.groupBy(array, value)
        },
        openUpdateGroupsizeDialog() {
            this.update_groupsize_dialog = true
        },
        closeUpdateGroupsizeDialog() {
            this.update_groupsize_dialog = false
        },
        openSendEmailDialog(item) {
            this.send_email_dialog = true
            this.supplier_to_send_to = item
        },
        closeSendEmailDialog() {
            this.send_email_dialog = false
            this.supplier_to_send_to = null
        },
        updateQuoteBuildAccomAndSendDialog(item, tmp_groupsize) {
            this.send_email_dialog_accom = true
            this.supplier_to_send_to = item
            this.groupsize_to_send = tmp_groupsize
        },
        closeSendEmailAccomDialog() {
            this.send_email_dialog_accom = false
            this.supplier_to_send_to = null
            this.groupsize_to_send = null
        },
        async updateGroupsizes() {
            this.event.quotes.forEach(quote => {
                let attendees = this.event.invitees.filter((x) => x.payments.filter((y) => y.payment_details.filter((z) => z.payment_type_id == 1).length > 0).length > 0).length
                quote.builds.forEach(build => {
                    if (build.type_id == 1) {
                        let accom_groupsize = JSON.parse(build.accom_groupsize)
                        for (let i = 0; i < accom_groupsize.length; i++) {
                            accom_groupsize[i] = attendees
                        }
                        build.accom_groupsize = JSON.stringify(accom_groupsize)
                    }
                    else {
                        build.groupsize = attendees
                    }
                })
            })
            const result = await this.classes.quotes.updateGroupsizes(this.builds)
            if (result.response == true) {
                this.closeUpdateGroupsizeDialog()
                this.$toast.success(result.message)
                this.$emit('refresh')
            }
        },
        async updateQuoteBuildAccom(item, groupsize) {
            item.accom_groupsize = JSON.stringify(groupsize)
            const result = await this.classes.quotes.updateQuoteBuildGroupsize(item)
            if (result.response == true) {
                this.$toast.success(result.message)
            }
        },
        async updateQuoteBuildAccomAndSend(item, groupsize) {
            item.accom_groupsize = JSON.stringify(groupsize)
            const result = await this.classes.quotes.updateQuoteBuildGroupsizeAndSend(item)
            if (result.response == true) {
                this.$toast.success(result.message)
            }
        },
        async addAccomGroupsize(item) {
            let accom_groupsize = JSON.parse(item.accom_groupsize)
            if (accom_groupsize.length > 2) {
                this.$toast.error('You cannot add any more days')
                return
            }
            accom_groupsize.push(accom_groupsize[0])
            item.accom_groupsize = JSON.stringify(accom_groupsize)
            const result = await this.classes.quotes.updateQuoteBuildGroupsize(item)
            if (result.response == true) {
                this.$toast.success(result.message)
            }
        },
        async removeAccomGroupsize(item) {
            let accom_groupsize = JSON.parse(item.accom_groupsize)
            if (accom_groupsize.length < 2) {
                this.$toast.error('You cannot remove any more days')
                return
            }
            accom_groupsize = accom_groupsize.slice(0, -1)
            item.accom_groupsize = JSON.stringify(accom_groupsize)
            const result = await this.classes.quotes.updateQuoteBuildGroupsize(item)
            if (result.response == true) {
                this.$toast.success(result.message)
            }
        },
        async updateQuoteBuild(item) {
            const result = await this.classes.quotes.updateQuoteBuildGroupsize(item)
            if (result.response == true) {
                this.$toast.success(result.message)
            }
        },
        async updateQuoteBuildAndSend(item) {
            const result = await this.classes.quotes.updateQuoteBuildGroupsizeAndSend(item)
            if (result.response == true) {
                this.$toast.success(result.message)
            }
        },
        async openHistoryDialog(item) {
            this.history_dialog = true
            this.selected_supplier = item[0].supplier
            let payload = {
                company_id: this.get_selected_company.id,
                event_id: this.event.id,
                supplier_id: this.selected_supplier.id,
            }
            const result = await this.classes.events.getBookingHistory(payload)
            if (result.response == true) {
                this.booking_history = result.data
            }
        },
        closeHistoryDialog() {
            this.history_dialog = false
        },
        async openBookingDialog(item) {
            this.booking_dialog = true
            this.selected_supplier = item[0].supplier
            this.selected_builds = item
            const result = await this.classes.settings.getEmailTemplates({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.email_templates = result.data
            }
        },
        closeBookingDialog() {
            this.booking_dialog = false
        },
        async sendBooking() {
            if (this.booking.subject == null || this.booking.body == null) {
                this.$toast.error('Please fill out all fields')
                return
            }
            let payload = {
                company: this.get_selected_company,
                event: this.event,
                builds: this.selected_builds,
                supplier: this.selected_supplier,
                subject: this.booking.subject,
                body: this.booking.body,
            }
            const result = await this.classes.events.sendBookingConfirmation(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closeBookingDialog()
                this.$emit('refresh')

            }
        },
        setEmailTemplate() {
            this.booking.subject = this.booking.template.subject
            this.booking.body = this.booking.template.body
        }
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    border-radius: 15px !important;

    .v-data-footer {
        background-color: #E9E9E9;
        color: #7C7C7C;
        font-family: 'Work Sans';
    }

    .v-data-footer__select .v-text-field {
        background-color: white;

        .v-select__slot {
            padding-left: 10px;
        }
    }
}

.custom-small {
    color: #7C7C7C;
    font-size: 12px;
    font-weight: 600;
}

:deep(.theme--light.v-data-table tbody td) {
    height: 40px !important;
    border-right: thin solid rgba(0, 0, 0, 0.12) !important;
}

.label-text-color {
    color: #7C7C7C;
}

.text-proper-case {
    text-transform: capitalize;
}

.search-text-field {
    margin-top: 15px;

    .v-input__slot {
        min-height: 0 !important;
        margin-top: 8px;
    }

    .v-input__prepend-inner {
        margin-top: 5px !important;
    }
}

:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.paid_row {
    background-color: #FFF1F1 !important;
}


:deep(.custom-text-field) {
    legend {
        display: none;
    }

    fieldset {
        border-color: #D3D3D3 !important;
    }

    input {
        color: #101010;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}

.label-1 {
    color: #7C7C7C;
    background-color: #F7F7F7;
    font-size: 20px;
    border: solid 1px #7C7C7C;
    border-radius: 4px;
    padding: 3px 5px;
}

.label-2 {
    color: #EE9E25;
    background-color: #FFF3E2;
    font-size: 20px;
    border: solid 1px #EE9E25;
    border-radius: 4px;
    padding: 3px 5px;
}

.label-3 {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 20px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
}

.label-4 {
    color: #AA3939;
    background-color: #FFCFCF;
    font-size: 20px;
    border: solid 1px #AA3939;
    border-radius: 4px;
    padding: 3px 5px;
}

.custom-expansion-panels {
    padding-right: 9px;
    padding-bottom: 50px;
}

.custom-expansion-panel::before {
    box-shadow: none !important;
}

.custom-expansion-panel::after {
    border-top: none !important;
}

.v-expansion-panel--active,
.v-expansion-panel--active+.v-expansion-panel {
    margin-top: 0px !important;
}

.v-expansion-panel-header--active {
    border-radius: 10px 10px 0px 0px !important;
    min-height: 0px !important;
}

:deep(.custom-expansion-header) {
    border-radius: 10px;
    background: var(--Blue-Linear, #3988AA);
    color: var(--icon-color, #FFF);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    .v-icon {
        color: white !important;
    }
}

.custom-expansion-panel-content {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid var(--Border, #E9E9E9);
    background: var(--icon-color, #FFF);
    box-shadow: 5px 5px 13px 0px rgba(240, 240, 240, 0.90), -5px -5px 10px 0px rgba(255, 255, 255, 0.90), 5px -5px 10px 0px rgba(240, 240, 240, 0.20), -5px 5px 10px 0px rgba(240, 240, 240, 0.20);
}
</style>