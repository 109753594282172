import Itinerary from '../Itinerary.vue'
import Initial from '../components/initial/Initial.vue'

export default [
    {
        path: '/itinerary', 
        name: '/itinerary', 
        meta: { layout: 'mystagit' },
        component: Itinerary,
    },
    {
        path: '/itinerary/initial', 
        name: '/itinerary/initial',
        meta: { layout: 'mystagit' },
        component: Initial,
        props: true,
    },
]