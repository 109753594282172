<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-card-title>
                <v-row no-gutters>
                    <!-- <v-col
                        col="2"
                    >
                        <v-menu
                            transition="slide-y-transition"
                            bottom
                            :close-on-content-click="false"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-select
                                    label="Itinerary Displayed Option"
                                    outlined
                                    rounded
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                    style="width: 360px !important;"
                                ></v-select>
                            </template>
                            <v-card
                                style="width: 360px !important;"
                            >
                                <v-card-title>
                                    <small
                                        style="font-size: 12px;"
                                    >More than one option can be selected if no options are selected no pricing will show.</small>
                                </v-card-title>
                                <v-divider/>
                                <v-card-text>
                                    <v-list-item
                                        v-for="(item, itemindex) in items"
                                        :key="itemindex"
                                        class="mb-2"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                            <v-list-item-subtitle
                                                style="white-space: normal; font-size: 11px;"
                                            >{{ item.subtitle }}</v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-row
                                            align="center"
                                            justify="end"
                                        >
                                            <v-checkbox
                                                v-model="item.ischecked"                                            
                                            ></v-checkbox>
                                        </v-row>
                                    </v-list-item>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </v-col> -->
                    <v-col col="12" justify="end" align="end">
                        <v-btn rounded color="primary" class="pt-4 pb-4 mr-2" style="text-transfor: unset !important;"
                            @click="send_payment_notification()">
                            <v-icon class="mr-2">
                                mdi-send-circle-outline
                            </v-icon>
                            send payment notification
                        </v-btn>
                        <v-btn rounded color="primary" class="pt-4 pb-4" style="text-transform: unset !important;"
                            @click="refresh_list()">
                            <v-icon class="mr-2">
                                mdi-refresh
                            </v-icon>
                            Refresh Pricing
                        </v-btn>
                        <v-btn rounded color="primary" class="pt-4 pb-4 ml-2" style="text-transform: unset !important;"
                            @click="download_to_pdf()">
                            <v-icon class="mr-2">
                                mdi-download-circle-outline
                            </v-icon>
                            Export Pricing
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>
        <v-divider />
        <v-data-table :headers="pricing_table_header" :items="get_pricing" class="mt-5" hide-default-footer
            disable-pagination id="element-to-convert" hide-default-header>
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr>
                        <th v-for="(h, i) in headers" :key="i" :class="h.class">
                            <span style="font-weight: 700; font-size: 14px;">{{ h.text.toUpperCase() }}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <v-checkbox v-model="item.is_paid" @click="mark_paid(item)">
                        </v-checkbox>
                    </td>
                    <td>
                        <div style="background: #343642; color: white !important; width: 90px;"
                            class="text-center pt-1 pb-1 pl-1 pr-1">
                            {{ get_range(item.check_in_date, item.check_out_date) === 0 ? 1 :
                                get_range(item.check_in_date, item.check_out_date) }} day(s)
                            <!--{{ new Date(item.check_in_date).toLocaleDateString('en-ie', {weekday: 'short'}) }} - {{ new Date(item.check_out_date).toLocaleDateString('en-ie', {weekday: 'short'}) }}-->
                        </div>
                    </td>
                    <td>
                        <v-list-item
                            :style="item.is_paid == 1 ? 'background-color: #00ff00;' : 'background-color: #f5f2d0;'">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <label>{{ item.supplier_product.name }}</label>
                                    <!-- <label>{{ item.supplier_product_option.name }}</label> -->
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <small>{{ item.supplier_product_option.name }}<br /></small>
                                    <small>{{ item.supplier_info.supplier_name }}</small>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </td>
                    <td>
                        <div>
                            <v-icon color="black">
                                {{ item.supplier_product_type.icon }}
                            </v-icon>
                        </div>
                    </td>
                    <td>
                        <small>
                            {{ item.check_in_date }}
                        </small>
                        <small style="display: block;">
                            {{ item.check_out_date }}
                        </small>
                    </td>
                    <td @click="evtOpenEditNetPriceDialog(item)" style="cursor: pointer;">
                        <v-list-item v-if="item.rates">
                            <v-list-item-content>
                                <v-list-item-title v-if="item.get_supplier.currency != null">
                                    <small><strong v-html="item.get_supplier.currency"></strong> {{
                                        item.rates[0].net * (get_range(item.check_in_date,
                                            item.check_out_date) === 0 ? 1 : get_range(item.check_in_date,
                                                item.check_out_date)) }}</small>
                                </v-list-item-title>
                                <v-list-item-title v-else>
                                    <small>{{ item.rates[0].net * (get_range(item.check_in_date,
                                        item.check_out_date) === 0 ? 1 : get_range(item.check_in_date,
                                            item.check_out_date)) | currency }}</small>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="item.get_supplier.currency != null">
                                    <small>{{ get_range(item.check_in_date, item.check_out_date) === 0 ? 1 :
                                        get_range(item.check_in_date, item.check_out_date) }} day(s) <br /> * {{
        item.count }} count <br /> * <strong
                                            v-html="item.get_supplier.currency"></strong> {{ item.rates[0].net
                                            }}</small>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    <small>{{ get_range(item.check_in_date, item.check_out_date) === 0 ? 1 :
                                        get_range(item.check_in_date, item.check_out_date) }} day(s) <br /> * {{
        item.count }} count <br /> * {{ item.rates[0].net | currency }}</small>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else>
                            <small>No rate found for this period</small>
                        </v-list-item>
                    </td>
                    <td>
                        <v-list-item v-if="item.rates">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <small>{{ item.rates[0].markup }} %</small>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else>
                            <small>No rate found for this period</small>
                        </v-list-item>
                    </td>
                    <td>                        
                        <v-list-item v-if="item.rates">
                            <v-list-item-content>
                                <v-list-item-title v-if="item.get_supplier.currency != null">
                                    <small><strong v-html="item.get_supplier.currency"></strong> {{
                                        item.rates[0].gross * (get_range(item.check_in_date,
                                            item.check_out_date) === 0 ? 1 : get_range(item.check_in_date,
                                                item.check_out_date)) }}</small>
                                </v-list-item-title>
                                <v-list-item-title v-else>
                                    <small>{{ item.rates[0].gross * (get_range(item.check_in_date,
                                        item.check_out_date) === 0 ? 1 : get_range(item.check_in_date,
                                            item.check_out_date)) | currency }}</small>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="item.get_supplier.currency != null">
                                    <small>{{ get_range(item.check_in_date, item.check_out_date) === 0 ? 1 :
                                        get_range(item.check_in_date, item.check_out_date) }} day(s) <br /> * {{
        item.count }} count <br /> * <strong
                                            v-html="item.get_supplier.currency"></strong> {{ item.rates[0].gross
                                            }}</small>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    <small>{{ get_range(item.check_in_date, item.check_out_date) === 0 ? 1 :
                                        get_range(item.check_in_date, item.check_out_date) }} day(s) <br /> * {{
        item.count }} count <br /> * {{ item.rates[0].gross | currency
    }}</small>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else>
                            <small>No rate found for this period</small>
                        </v-list-item>
                    </td>
                    <td>
                        <v-list-item v-if="item.rates">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-menu right bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="editPricing(item.rates[0])">
                                                <v-list-item-title>
                                                    Edit pricing
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </td>
                </tr>
            </template>
            <template slot="body.append">
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><strong>Net Total: {{ get_all_net() | formatNumber }}</strong></td>
                    <td><strong>Markup Total: {{ get_all_markup() | formatNumber }} %</strong></td>
                    <td><strong>Gross Total: {{ get_all_gross() | formatNumber }}</strong></td>
                </tr>
            </template>
        </v-data-table>
        <DialogWarning :value="show_dialog_warning" @close_dialog="close_dialog" />
        <DialogEditRate :value="show_dialog_edit_rate" :rate="to_edit_rate"
            @close_dialog_edit_rate="close_dialog_edit_rate" />
        
        <EditNetPriceDialog
            :edit_net_price_dialog="edit_net_price_dialog"
            :item="to_edit_rate"
            @closeEditNetPriceDialog="evtCloseEditNetPriceDialog"></EditNetPriceDialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import html2pdf from "html2pdf.js";
import DialogWarning from './components/DialogWarning.vue'
import DialogEditRate from './components/DialogEditRate.vue'
import EditNetPriceDialog from './components/EditNetPriceDialog.vue'

export default {
    components: {
        DialogWarning, DialogEditRate,
        EditNetPriceDialog
    },
    props: [
        'id'
    ],
    data() {
        return {
            items: [
                {
                    id: 1,
                    title: 'Total price',
                    subtitle: 'Combines all items to show a single itinerary price',
                    ischecked: true
                },
                {
                    id: 2,
                    title: 'Price per traveller',
                    subtitle: 'Shows the total priuce split evenly between all travellers',
                    ischecked: true
                },
                {
                    id: 3,
                    title: 'Inclusions',
                    subtitle: 'Shows each item individually in a list',
                    ischecked: true
                },
                {
                    id: 4,
                    title: 'Show item prices',
                    subtitle: 'Shows item prices on inclusions list',
                    ischecked: true
                }
            ],
            pricing_table_header: [
                {
                    text: '',
                    value: 'check'
                },
                {
                    text: 'Days',
                    value: 'days'
                },
                {
                    text: 'Item',
                    value: 'item_value'
                },
                {
                    text: 'Type',
                    value: 'type'
                },
                {
                    text: 'Dates',
                    value: 'dates'
                },
                {
                    text: 'Net',
                    value: 'net'
                },
                {
                    text: 'Markup',
                    value: 'markup'
                },
                {
                    text: 'Gross',
                    value: 'gross'
                },
                {
                    text: '',
                    value: 'action'
                }
            ],
            items_menu: [
                {
                    text: 'Lock net',
                    icon: 'mdi-lock',
                    state: 'is_lock_net'
                },
                {
                    text: 'Lock markup',
                    icon: 'mdi-lock',
                    state: 'is_lock_markup'
                },
                {
                    text: 'Lock gross',
                    icon: 'mdi-lock',
                    state: 'is_lock_gross'
                },
                {
                    text: 'Lock all',
                    icon: 'mdi-lock',
                    state: 'is_lock_all'
                }
            ],
            show_dialog_warning: false,
            show_dialog_edit_rate: false,
            to_edit_rate: null,
            edit_net_price_dialog: false,
        }
    },
    async mounted() {
        await this.$store.dispatch('itinerary/fetch_pricing', this.id)
        console.log(this.id)
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_pricing: 'itinerary/get_pricing',
            get_itinerary_build_config: 'itinerary/get_itinerary_build_config',
        })
    },
    methods: {
        async refresh_list() {
            await this.$store.dispatch('itinerary/fetch_pricing', this.id)
        },
        editPricing(item) {
            //console.log(item)
            this.to_edit_rate = item
            this.show_dialog_edit_rate = true
        },
        get_range(start, end) {

            const date1 = new Date(start)
            const date2 = new Date(end)

            const diff_in_time = date2.getTime() - date1.getTime()
            const diff_in_days = diff_in_time / (1000 * 3600 * 24)

            return diff_in_days
        },
        lock_item(item, lockitem) {
            let tp = {
                item: item,
                state: lockitem.state
            }
            this.$store.commit('itinerary/set_is_lock', tp)
        },
        get_all_net() {
            let tp = []
            this.get_pricing.forEach(q => {
                tp.push({
                    net: (q.rates != null) ? q.rates[0].net : 0,
                    multiplier: this.get_range(q.check_in_date, q.check_out_date),
                    count: q.count
                })
            })
            let total = 0
            tp.forEach(q => {
                q.multiplier === 0 ? total += q.net * 1 * q.count : total += q.net * q.multiplier * q.count
            })
            return Math.abs(total)
        },
        get_all_markup() {
            let tp = []
            let i = 0
            this.get_pricing.forEach(q => {
                if (q.rates != null){
                    tp.push({
                        markup: q.rates[0].markup,
                        multiplier: this.get_range(q.check_in_date, q.check_out_date)
                    })
                }
                else {
                    i++
                }
            })
            let total = 0
            tp.forEach(q => {
                q.multiplier === 0 ? total += q.markup * 1 : total += q.markup * q.multiplier
            })
            return Math.abs(total) / (this.get_pricing.length - i)
        },
        get_all_gross() {
            let tp = []
            this.get_pricing.forEach(q => {
                tp.push({
                    gross: (q.rates != null) ? q.rates[0].gross : 0,
                    multiplier: this.get_range(q.check_in_date, q.check_out_date),
                    count: q.count
                })
            })
            let total = 0
            tp.forEach(q => {
                q.multiplier === 0 ? total += q.gross * 1 * q.count : total += q.gross * q.multiplier * q.count
            })
            return Math.abs(total)
        },
        download_to_pdf() {
            html2pdf(document.getElementById("element-to-convert"), {
                margin: 1,
                filename: this.get_itinerary_build_config.itinerary_name + ".pdf",
                image: { type: 'jpeg', quality: 1.0 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
            });
        },
        mark_paid(item) {
            this.$store.dispatch('itinerary/update_payment_status_pricing', item)
        },
        send_payment_notification() {
            let paid_list = []
            this.get_pricing.filter(q => {
                if (q.is_paid) {
                    paid_list.push(q)
                }
            })
            // console.log(paid_list)
            this.show_dialog_warning = true
            this.$store.dispatch('itinerary/set_payment_notification_suppliers', paid_list)
        },
        close_dialog() {
            this.show_dialog_warning = false
        },
        close_dialog_edit_rate() {
            this.to_edit_rate = false
            this.show_dialog_edit_rate = false
            this.refresh_list()
        },
        evtOpenEditNetPriceDialog(item) {
            if ( item.rates ) {
                this.to_edit_rate = item
                this.edit_net_price_dialog = true
            }
        },
        evtCloseEditNetPriceDialog() { 
            this.to_edit_rate = null
            this.edit_net_price_dialog = false
            this.refresh_list()
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>