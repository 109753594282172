import Vue from "vue";

export default class Leads {
    constructor() {
        this.vue = Vue
    }

    getLeads({ company_id }) {
        return this.vue.axios.get(`admin/leads/get_leads/${company_id}`).then((data) => {
            if (data.status == 200) {
                // Process the data
                const result = data.data
                if (result.response == true) {
                    return result.data
                }
            } else {
                return data
            }
        })
    }

    getLead({ lead_id }) {
        return this.vue.axios.get(`admin/leads/get_lead/${lead_id}`).then((data) => {
            if (data.status == 200) {
                // Process the data
                const result = data.data
                if (result.response == true) {
                    return result.data
                }
            } else {
                return data
            }
        })
    }

    createLead({
        first_name, last_name, email, phone, people, duration,
        event_type_id, start_date, location_id,
        message, advisor_id, company_id, password
    }) {
        return this.vue.axios.post(`admin/leads/create_lead`, {
            first_name: first_name, last_name: last_name, email: email,
            phone: phone, people: people, duration: duration, event_type_id: event_type_id,
            start_date: start_date, location_id: location_id, message: message,
            advisor_id: advisor_id, company_id: company_id, password: password
        }).then((data) => {
            if (data.status == 200) {
                return data.data
            } else {
                return {
                    response: false,
                    data: data
                }
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }

    deleteLead({ lead_id, company_id }) {
        return this.vue.axios.delete(`admin/leads/delete_lead/${lead_id}/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return {
                    response: false,
                    data: data
                }
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }

    updateLead({
        id, first_name, last_name, email, phone, people, duration,
        event_type_id, start_date, location_id,
        message, advisor_id, company_id, password
    }) {
        const format_date = new Date(start_date).toISOString().split('T')[0]
        return this.vue.axios.post(`admin/leads/update_lead/${id}`, {
            first_name: first_name, last_name: last_name, email: email,
            phone: phone, people: people, duration: duration, event_type_id: event_type_id,
            start_date: format_date, location_id: location_id, message: message,
            advisor_id: advisor_id, company_id: company_id, password: password
        }).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return {
                    response: false,
                    data: data
                }
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }

    markLeadAsPaid(payload) {
        return this.vue.axios.post(`admin/leads/mark_lead_as_paid/${payload.paid_by}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }

    getAdvisors({ company_id }) {
        return this.vue.axios.get(`admin/users/get_users_by_company/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result.data
                }
            } else {
                return data
            }
        })
    }

    getLocations({ company_id }) {
        return this.vue.axios.get(`admin/locations/get_locations/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result.data
                }
            } else {
                return data
            }
        })
    }
}