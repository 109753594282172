<template>
    <v-row justify="center">
        <v-dialog
            :value="value"
            persistent
            max-width="560"
        >
            <v-card>
                <v-card-title>
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title >{{ get_chosen_service_supplier.supplier_info.supplier_name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-icon
                            @click="close_dialog"
                        >
                            mdi-close
                        </v-icon>
                    </v-list-item>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-card
                        elevation="0"
                    >
                        <v-checkbox
                            v-for="(item, itemindex) in get_chosen_service_supplier.product_options"
                            :key="itemindex"
                            v-model="check"
                            :value="item.id"
                            class="pl-5 pr-5"
                        >
                            <template v-slot:label>
                                <v-row>
                                    <v-col
                                        cols="8"
                                    >
                                        <small>
                                            {{ item.name }}
                                        </small>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-checkbox>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        class="pt-5 pb-5 pl-10 pr-10"
                        rounded
                        @click="add_itinerary_item"
                    >
                        Add
                    </v-btn>
                </v-card-actions>
                <v-card-text
                    v-if="show_check_in_info"
                >
                    <v-divider class="mt-5 mb-5"></v-divider>
                    <v-card
                        v-for="(item, itemindex) in get_to_push_amenities"
                        :key="itemindex"
                        class="mb-5"
                        style="background: #FEFAEB !important;"
                    >
                        <v-card-title>
                            <small
                                style="text-decoration: underline;"
                            >{{ item.amenities }}</small>
                            <v-spacer/>
                            <v-btn
                                icon
                            >
                                <v-icon
                                    small
                                    @click="can_count(item)"
                                >
                                    mdi-minus
                                </v-icon>
                            </v-btn>
                            <small>
                                {{ item.count }}
                            </small>
                            <v-btn
                                icon
                            >
                                <v-icon
                                    small
                                    @click="item.count++"
                                >mdi-plus</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        v-model="menu[itemindex]"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="item.check_in_date"
                                            label="check in date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="item.check_in_date"
                                            :min="get_itinerary.start_date"
                                            :max="get_itinerary.end_date"
                                            label="check in date"
                                            @input="menu[itemindex] = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        :value="item.check_in_time"
                                        v-model="item.check_in_time"
                                        type="time"
                                        label="check in time"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        v-model="menu2[itemindex]"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="item.check_out_date"
                                            label="check out date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="item.check_out_date"
                                            :min="get_itinerary.start_date"
                                            :max="get_itinerary.end_date"
                                            label="check in date"
                                            @input="menu2[itemindex] = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        :value="item.check_out_time"
                                        v-model="item.check_out_time"
                                        type="time"
                                        label="check out time"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <v-text-field
                                        label="check in instructions"
                                        :value="item.instruction"
                                        v-model="item.instruction"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            class="pl-10 pr-10 pt-5 pb-5"
                            color="primary"
                            rounded
                            @click="save_data"
                        >
                            save
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {
  },
  props: [
    'value'
  ],
  data () {
    return {
        check: [],
        show_check_in_info: false,
        menu: [],
        menu2: [],
    }
  },
  async mounted () {
    await this.$store.dispatch('itinerary/fetch_supplier_amenities', this.get_chosen_service_supplier.id)
  },
  async created () {
    
  },
  computed: {
    ...mapGetters({
        get_chosen_service_supplier:        'itinerary/get_chosen_service_supplier',
        get_supplier_amenities:             'itinerary/get_supplier_amenities',
        get_to_push_amenities:              'itinerary/get_to_push_amenities',
        get_itinerary:                      'itinerary/get_itinerary',
    }),
    supplier_amenities(){
        return this.$store.getters['itinerary/get_amenities'](this.check)
    }
  },
  methods: {
    add_itinerary_item(){
        // this.$emit('add_itinerary_item', this.check)
        this.show_check_in_info = true
        this.supplier_amenities
    },
    save_data(){
        this.$emit('save_data')
    },
    close_dialog(){
        this.$emit('close_dialog')
    },
    can_count(item){
        if(item.count > 1){
            item.count--
        }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>