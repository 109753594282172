<template>
    <v-container fluid>
        <v-card style="margin:200px;">
            <v-card-title>Thank you for confirming your booking.</v-card-title>
        </v-card>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [],
    data: () => ({
    }),
    async mounted() {
        this.getData()
    },
    created() {
    },
    computed: {
    },
    methods: {
        async getData() {
            let payload = {
                event_id: this.$route.params.event_id,
                supplier_crypt: this.$route.params.supplier_crypt,
            }
            console.log(payload)
            await this.$axios.post('api/supplier/confirm_booking', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                    }
                })
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss"></style>
