<template>
    <v-container fluid>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <h3 class="mb-3">Agency Settings</h3>
                        <v-text-field v-model="agency_settings.name" outlined dense label="Agency Name"></v-text-field>
                        <v-text-field v-model="agency_settings.password" outlined dense label="Password"></v-text-field>
                        <v-text-field v-model="agency_settings.address" outlined dense label="Address"></v-text-field>
                        <v-text-field v-model="agency_settings.email" outlined dense label="Email"></v-text-field>
                        <v-text-field v-model="agency_settings.number" outlined dense label="Number"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <h3 class="mb-3">New Company Default Settings</h3>
                        <v-text-field v-model="agency_settings.admin_fee" outlined dense label="Admin Fee"
                            type="number"></v-text-field>
                        <v-text-field v-model="agency_settings.cutoff_duration" outlined dense
                            label="Cutoff For Payment"></v-text-field>
                        <div class="mb-3">Late Fee</div>
                        <div style="display:flex;">
                            <v-text-field v-model="agency_settings.late_fee_amount" label="Amount" outlined dense
                                class="mr-2"></v-text-field>
                            <v-text-field v-model="agency_settings.late_fee_days" label="Days" outlined dense
                                class="mr-2"></v-text-field>
                            <v-select v-model="agency_settings.late_fee_date_type" label="Date type"
                                :items="['Event Date', 'Pay By Date']" outlined dense></v-select>

                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark class="custom-buttons" @click="updateAgencySettings()">Update</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        agency_settings: {
            name: null,
            password: null,
            address: null,
            email: null,
            number: null,
            admin_fee: 0,
            cutoff_duration: 0,
            late_fee_amount: 0,
            late_fee_days: 0,
            late_fee_date_type: null,
        }
    }),
    computed: {
        ...mapGetters({
            get_companies: 'auth/get_companies',
            get_user: 'auth/get_user',
        })
    },
    created() {
        this.setPageName("Agency Settings")
    },
    async mounted() {
        this.getAgencySettings()
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction'
        }),
        async getAgencySettings() {
            await this.$axios.get('admin/agency_settings/get_agency_settings')
                .then(({ data }) => {
                    if (data.response) {
                        this.agency_settings = data.data[0]
                    }
                })
        },
        async updateAgencySettings() {
            let payload = {
                id: this.agency_settings.id,
                name: this.agency_settings.name,
                password: this.agency_settings.password,
                address: this.agency_settings.address,
                email: this.agency_settings.email,
                number: this.agency_settings.number,
                admin_fee: this.agency_settings.admin_fee,
                cutoff_duration: this.agency_settings.cutoff_duration,
                late_fee_amount: this.agency_settings.late_fee_amount,
                late_fee_days: this.agency_settings.late_fee_days,
                late_fee_date_type: this.agency_settings.late_fee_date_type,
            }
            await this.$axios.post('admin/agency_settings/update_agency_setting', payload)
                .then(({data}) => {
                    if(data.response){
                        this.$toast.success(data.message)
                        this.getAgencySettings()
                    }
                })
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    display: block;
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}
</style>