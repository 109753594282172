export default {
    namespaced: true,
    state: {
        saved_suppliers: []
    },
    mutations: {
        setSavedSuppliersMutation(state, payload) {
            state.saved_suppliers = [...payload]
        },
    },
    getters: {
        getSavedSuppliers: (state) => state.saved_suppliers
    },
    actions: {
        setSavedSuppliers({ commit }, payload) {
            commit('setSavedSuppliersMutation', payload)
        },
    }
}
