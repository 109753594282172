<template>
    <v-container fluid>
        <v-card max-width="400">
            <v-card-title>
                <v-text-field
                    placeholder="Search Company"
                    class="mx-3"
                    hide-details
                    v-model="search"></v-text-field>
                    <v-btn icon @click="openAddCompanyDialog()" v-if="get_user.user_role_id < 3"><v-icon>mdi-plus</v-icon></v-btn>
            </v-card-title>
            <v-divider class="mx-6"></v-divider>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="get_companies"
                    :search="search"
                    hide-default-footer
                    hide-default-header
                    @click:row="eventSelectCompany"
                    single-select>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        headers: [
            { text: 'Company Name', value: 'company_name', sortable: false }
        ],
        search: null
    }),
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_companies: 'auth/get_companies',
        })
    },
    methods: {
        eventSelectCompany(item) {
            this.$store.dispatch('auth/set_selected_company', item)
            this.$emit('eventCloseCompanySelectionMenu')
        },
        openAddCompanyDialog() {
            this.$emit('openAddCompanyDialog')
        }
    }
}
</script>