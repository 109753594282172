import Vue from 'vue'

export default class Pricing {
    constructor(store) {
        this.store = store
        this.net_price = 0
    }

    async updateNetPrice(item, new_net_price, itinerary_id) {
        
        const net_price = new_net_price / (this.get_range(item.check_in_date, item.check_out_date) == 0 ? 1 : this.get_range(item.check_in_date, item.check_out_date))
        const gross = net_price * ((item.rates[0].markup / 100) + 1);      
        const commission = Number(item.rates[0].markup / gross);

        const props = {
            id: item.rates[0].id,
            sup_opt_rate_id: item.rates[0].sup_opt_rate_id,
            itinerary_id: itinerary_id,
            day: item.rates[0].day,
            net: net_price,
            gross: gross,
            markup: item.rates[0].markup,
            commission: commission,
        }
        /** Compute the price of the per rate based on the net price */        
        await Vue.axios.patch(`api/itinerary/pricing/edit_itinerary_rate`, props).then( (data) => {
            console.log(data)
        })

    }
    
    get_range(start, end) {
        const date1 = new Date(start)
        const date2 = new Date(end)

        const diff_in_time = date2.getTime() - date1.getTime()
        const diff_in_days = diff_in_time / (1000 * 3600 * 24)

        return diff_in_days
    }
}