import Supplier from "../SupplierComponent.vue";
import CreateSupplier from "../CreateSupplier.vue";
import SupplierSetup from "../components/setup/SupplierSetupComponent.vue";
import AddContact from "../components/setup/pages/inputs/AddContactComponent.vue";
import SupplierConfirm from "../SupplierConfirm.vue";
import SupplierDecline from "../SupplierDecline.vue";

export default [
  {
    path: "/supplier",
    name: "/supplier",
    meta: { layout: 'mystagit' },
    component: Supplier,
  },
  {
    path: "/create-supplier",
    name: "/create-supplier",
    meta: { layout: 'mystagit' },
    component: CreateSupplier,
  },
  {
    path: "/supplier-setup",
    name: "/supplier-setup",
    meta: { layout: 'mystagit' },
    component: SupplierSetup,
  },
  {
    path: "/supplier-setup/product/setup",
    name: "/supplier-setup/product/setup",
    meta: { layout: 'mystagit' },
    component: () =>
      import("../components/setup/pages/SupplierProductSetupComponent.vue"),
  },
  {
    path: "/supplier-setup/create-product",
    name: "/supplier-setup/create-product",
    meta: { layout: 'mystagit' },
    component: () =>
      import("../components/setup/pages/SupplierProductAddComponent.vue"),
  },
  {
    path: "/add_contacts_in_supplier",
    name: "/add_contacts_in_supplier",
    component: AddContact,
    meta: { layout: 'mystagit' },
  },
  {
    path: "/supplier_confirm/:event_id/:supplier_crypt",
    name: "/supplier_confirm/:event_id/:supplier_crypt",
    meta: { layout: 'blank' },
    component: SupplierConfirm,
  },
  {
    path: "/supplier_decline/:event_id/:supplier_crypt",
    name: "/supplier_decline/:event_id/:supplier_crypt",
    meta: { layout: 'blank' },
    component: SupplierDecline,
  },
];
