<template>
    <div>
        <h3 class="mb-5 custom-title">
            {{ service_name }}
        </h3>
        <v-card elevation="0" class="custom-title">
            <v-card-actions class="mt-5" style="justify-content:center;">
                <v-btn @click="back()" v-if="build_index > 0" text style="font-weight:800;">BACK</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="save()" style="background-color:green;color:white;font-weight:800;">SAVE AND
                    NEXT</v-btn>
            </v-card-actions>
        </v-card>
        <v-card outlined class="card-format mt-5" v-for="(build, index) in builds" :key="index">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <div class="mb-3 sublabel">ACTIVITY DAY</div>
                        <v-text-field type="number" v-model="build.day" filled outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <div class="mb-3 sublabel">SELECT SUPPLIER</div>
                        <v-select v-model="build.supplier" :items="getSuppliersByType" @change="getSupplier(index)"
                            item-text="supplier_name" return-object filled outlined dense>
                            <template v-slot:selection="{ item }">
                                <b>{{ item.supplier_name }}</b>
                            </template>
                            <template v-slot:item="{ item }">
                                <b>{{ item.supplier_name }}</b>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row v-if="build.supplier">
                    <v-col cols="12" v-if="build.supplier.products == null">
                        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-data-table :items="build.supplier.products.filter((x) => x.archived == 0)"
                            :headers="product_header" hide-default-footer disable-pagination class="custom-table">
                            <template v-slot:item="{ item }">
                                <tr @click="selectOption(item, index)">
                                    <td>
                                        <input
                                            :checked="build.options.findIndex((options) => options.id == item.id) > -1 ? true : false"
                                            type="checkbox" style="cursor:pointer;">
                                    </td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ get_selected_company.currency }}{{ item.customer_price }}</td>
                                </tr>
                                <!-- <tr v-for="option in item.options" :key="option.id" @click="selectOption(option, index)">
                                <td><input
                                        :checked="build.options.findIndex((options) => options.id == option.id) > -1 ? true : false"
                                        type="checkbox" style="cursor:pointer;"></td>
                                <td>{{ option.name }}</td>
                            </tr> -->
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="custom-buttons mb-5">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="removeBuild(index)"><small>Remove</small></v-btn>
            </v-card-actions>
        </v-card>
        <v-card elevation="0">
            <v-card-actions class="mt-5" style="justify-content:center;">
                <v-btn class="custom-buttons" text @click="openAddDialog()">
                    <v-icon>mdi-plus</v-icon>
                    Add {{ service_name }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card elevation="0" class="custom-title">
            <v-card-actions class="mt-5" style="justify-content:center;">
                <v-btn @click="back()" v-if="build_index > 0" text style="font-weight:800;">BACK</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="save()" style="background-color:green;color:white;font-weight:800;">SAVE AND
                    NEXT</v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="add_dialog" max-width="600px" persistent>
            <v-card>
                <v-card-title>Do you want to proceed in adding a new supplier?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-buttons" text @click="closeAddDialog()">Cancel</v-btn>
                    <v-btn class="custom-buttons" dark @click="addBuild()">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    components: {
    },
    props: [
        'lead', 'date_range', 'suppliers', 'service_name', 'build_index', 'edit_build'
    ],
    data: () => ({
        builds: [],
        product_header: [
            { text: '', sortable: false },
            { text: 'ACTIVITY NAME', sortable: false },
            // { text: 'PRICE', sortable: false }
        ],
        add_dialog: false,
    }),
    async mounted() {
        this.getEditData()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        }),
        getSuppliersByType() {
            let supplier_type = 0
            switch (this.build_index + 1) {
                case 1:
                    supplier_type = 2
                    break
                case 2:
                    supplier_type = 6
                    break
                case 3:
                    supplier_type = 5
                    break
                case 4:
                    supplier_type = 4
                    break
                case 5:
                    supplier_type = 3
                    break
                case 6:
                    supplier_type = 7
                    break
            }
            return this.suppliers.filter((item) => {
                return item.service_type_id === supplier_type
            })
        },
    },
    methods: {
        getEditData() {
            if (this.edit_build) {
                this.edit_build.forEach((build, index) => {
                    if (index > 0) {
                        this.addBuild()
                    }
                    this.builds[index].day = build.day
                    this.builds[index].supplier = build.supplier
                    this.builds[index].options = build.options
                    this.getSupplier(index)
                })
            }
        },
        openAddDialog() {
            this.add_dialog = true
        },
        closeAddDialog() {
            this.add_dialog = false
        },
        addBuild() {
            this.builds.push({
                id: null,
                start_date: null,
                end_date: null,
                supplier: null,
                options: [],
                rates: [],
            })
            this.closeAddDialog()
        },
        removeBuild(index) {
            this.builds.splice(index, 1)
        },
        skip() {
            this.$emit('skip')
        },
        back() {
            this.$emit('back')
        },
        save() {
            this.$emit('save', this.builds, this.build_index)
        },
        selectOption(option, index) {
            let optionIndex = this.builds[index].options.findIndex((options) => options.id == option.id)
            if (optionIndex === -1) {
                this.builds[index].options.push(option)
            }
            else {
                this.builds[index].options.splice(optionIndex, 1)
            }
        },
        async getSupplier(index) {
            await this.$axios.get(`admin/suppliers/get_supplier/${this.builds[index].supplier.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.builds[index].supplier = data.data
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

:deep(.v-input__slot) {
    background: white !important;
}

:deep(.v-stepper__header) {
    box-shadow: none !important;
}

.accom-price {
    color: #FAAF3A;
    font-size: 36px;
    font-weight: 600;
}

.custom-title {
    font-family: 'Manrope';
    font-size: 14px !important;
    color: #3988AA;
    font-weight: 600;
}

.sublabel {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description {
    color: #7D7D7D;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.summary {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

:deep(.custom-buttons span) {
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.custom-table {
    font-family: Manrope;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #101010;
}
</style>