<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn class="custom-buttons" dark @click="openAddUserDialog()">
                    <v-icon class="mr-2">mdi-plus</v-icon>Add User
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="users">
                    <template v-slot:item="{ item }">
                        <tr style="cursor:inherit;background-color:transparent;">
                            <td>{{ item.name }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.role.name }}</td>
                            <td>{{ item.created_at }}</td>
                            <td>
                                <div v-if="item.access && item.user_role_id > 1"
                                    style="display:flex;align-items:center;">
                                    <label v-for="(access, index) in item.access" :key="index" class="access-label">
                                        {{ access.company.company_name }}
                                        <v-btn icon small @click="openUpdateAccessDialog(access)"
                                            v-if="item.user_role_id > 2">
                                            <v-icon small>mdi-pen</v-icon>
                                        </v-btn>
                                        <v-btn icon small @click="openDeleteAccessDialog(access)">
                                            <v-icon small>mdi-close</v-icon>
                                        </v-btn>
                                    </label>
                                    <v-btn icon small @click="openCreateAccessDialog(item)"><v-icon
                                            small>mdi-plus</v-icon></v-btn>
                                </div>
                                <div v-else>
                                    <label class="access-label">
                                        All
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div style="display:flex;">
                                    <v-btn icon @click="openUpdateUserDialog(item)"><v-icon
                                            small>mdi-pen</v-icon></v-btn>
                                    <v-btn icon @click="openDeleteUserDialog(item)"><v-icon
                                            small>mdi-delete</v-icon></v-btn>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-row justify="center">
            <v-dialog v-model="add_user_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Add User</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="new_user.name" label="Name *" outlined dense></v-text-field>
                        <v-text-field v-model="new_user.email" label="Email *" outlined dense></v-text-field>
                        <v-text-field v-model="new_user.password" label="Password *" outlined dense></v-text-field>
                        <v-select v-model="new_user.user_role_id" label="Role *" :items="roles" item-text="name"
                            item-value="id" outlined dense></v-select>
                        <v-select v-model="new_user.access" label="Company Access *" item-text="company_name"
                            item-value="id" multiple :items="companies" outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" text @click="closeAddUserDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark @click="addUser()">Add</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="update_user_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Update User Info</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="user_to_update.name" label="Name *" outlined dense></v-text-field>
                        <v-text-field v-model="user_to_update.email" label="Email *" outlined dense></v-text-field>
                        <!-- <v-text-field v-model="user_to_update.password" label="Password *" outlined
                            dense></v-text-field> -->
                        <v-select v-model="user_to_update.user_role_id" label="Role *" :items="roles" item-text="name"
                            item-value="id" outlined dense></v-select>
                        <!-- <v-select v-model="user_to_update.access" label="Company Access *" item-text="company_name"
                            item-value="id" multiple :items="get_companies" outlined dense></v-select> -->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" text @click="closeUpdateUserDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark @click="updateUser()">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="create_access_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Add Access</v-card-title>
                    <v-card-text>
                        <v-select v-model="access_to_create" label="Company Access *" item-text="company_name"
                            item-value="id" multiple :items="allowed_companies" outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" text @click="closeCreateAccessDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark @click="createAccess()">Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="update_access_dialog" max-width="600" persistent>
                <v-card v-if="selected_access">
                    <v-card-title>Update Access to {{ selected_access.company.company_name }}</v-card-title>
                    <v-card-text>
                        <v-select v-model="selected_access.allow_suppliers" label="Allow Access to Suppliers Page"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" outlined dense></v-select>
                        <v-select v-model="selected_access.allow_packages" label="Allow Access to Packages Page"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" outlined dense></v-select>
                        <v-select v-model="selected_access.allow_reports" label="Allow Access to Reports Page"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" text @click="closeUpdateAccessDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark @click="updateAccess()">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="delete_access_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Are you sure you want to remove access to this company?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" text @click="closeDeleteAccessDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark @click="deleteAccess()">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="delete_user_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Are you sure you want to delete this user?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" text @click="closeDeleteUserDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark @click="deleteUser()">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data() {
        return {
            headers: [
                { text: 'Name', width: '20%' },
                { text: 'Email Address', width: '25%' },
                { text: 'Role', width: '10%' },
                { text: 'Created At', width: '10%' },
                { text: 'Company Access', width: '24%' },
                { text: '', width: '1%' }
            ],
            users: [],
            roles: [],
            companies: [],
            add_user_dialog: false,
            new_user: {
                name: null,
                email: null,
                password: null,
                user_role_id: null,
                access: [],
            },
            update_user_dialog: false,
            user_to_update: {
                id: null,
                name: null,
                email: null,
                password: null,
                user_role_id: null,
                access: [],
            },
            create_access_dialog: false,
            allowed_companies: [],
            selected_user: null,
            access_to_create: null,
            delete_access_dialog: false,
            access_to_delete: null,
            delete_user_dialog: false,
            selected_access: null,
            update_access_dialog: false,
        }
    },
    async mounted() {
        this.getUsers()
        this.getRoles()
        this.getCompanies()
    },
    created() {
        this.setPageName('User Management')
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        })
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction'
        }),
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getUsers() {
            this.$axios.get('admin/users/get_all_users')
                .then(({ data }) => {
                    if (data.response) {
                        this.users = data.data
                    }
                })
        },
        async getRoles() {
            this.$axios.get('admin/users/get_roles')
                .then(({ data }) => {
                    if (data.response) {
                        this.roles = data.data
                    }
                })
        },
        async getCompanies() {
            this.$axios.get('admin/companies/get_companies')
                .then(({ data }) => {
                    if (data.response) {
                        this.companies = data.data
                    }
                })
        },
        openUpdateAccessDialog(access) {
            console.log(access)
            this.selected_access = access
            this.update_access_dialog = true
        },
        closeUpdateAccessDialog() {
            this.selected_access = null
            this.update_access_dialog = false
        },
        async updateAccess() {
            let payload = {
                id: this.selected_access.id,
                allow_suppliers: this.selected_access.allow_suppliers,
                allow_packages: this.selected_access.allow_packages,
                allow_reports: this.selected_access.allow_reports,
            }
            await this.$axios.post('admin/users/update_user_access', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateAccessDialog()
                        this.getUsers()
                    }
                })
        },
        openAddUserDialog() {
            this.add_user_dialog = true
        },
        closeAddUserDialog() {
            this.add_user_dialog = false
            this.new_user = {
                name: null,
                email: null,
                password: null,
                user_role_id: null,
                access: [],
            }
        },
        async addUser() {
            let payload = {
                ...this.new_user,
                company_id: this.get_selected_company.id
            }
            await this.$axios.post('admin/users/create_user', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeAddUserDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openUpdateUserDialog(item) {
            this.update_user_dialog = true
            this.user_to_update.id = item.id
            this.user_to_update.name = item.name
            this.user_to_update.email = item.email
            this.user_to_update.password = item.password
            this.user_to_update.user_role_id = item.user_role_id
            this.user_to_update.access = item.access
        },
        closeUpdateUserDialog() {
            this.update_user_dialog = false
            this.user_to_update = {
                id: null,
                name: null,
                email: null,
                password: null,
                user_role_id: null,
                access: [],
            }
        },
        async updateUser() {
            await this.$axios.post(`admin/users/update_user/${this.user_to_update.id}`, this.user_to_update)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateUserDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteUserDialog(item) {
            this.selected_user = item
            this.delete_user_dialog = true
        },
        closeDeleteUserDialog() {
            this.delete_user_dialog = false
        },
        async deleteUser() {
            await this.$axios.delete(`admin/users/delete_user/${this.selected_user.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteUserDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openCreateAccessDialog(item) {
            this.selected_user = item
            this.create_access_dialog = true
            this.allowed_companies = Object.assign([], this.companies)
            if (item.access) {
                item.access.forEach(access => {
                    this.companies.forEach((company, index) => {
                        if (access.company_id == company.id) {
                            this.allowed_companies.splice(index, 1)
                        }
                    })
                })
            }
        },
        closeCreateAccessDialog() {
            this.create_access_dialog = false
            this.selected_user = null
            this.allowed_companies = []
            this.access_to_create = null
        },
        async createAccess() {
            let payload = {
                access: this.access_to_create,
                user_id: this.selected_user.id,
            }
            await this.$axios.post('admin/users/create_user_access', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateAccessDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteAccessDialog(access) {
            this.delete_access_dialog = true
            this.access_to_delete = access
        },
        closeDeleteAccessDialog() {
            this.delete_access_dialog = false
            this.access_to_delete = null
        },
        async deleteAccess() {
            await this.$axios.delete(`admin/users/delete_user_access/${this.access_to_delete.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteAccessDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        }
    },
    watch: {

    }

}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
}
</style>