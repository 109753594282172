import Leads from "../../../../components/leads/Leads.vue"
import EditLeads from "../../../../components/leads/EditLeads.vue"

export default [
    {
        path: '/leads', 
        name: 'Leads', 
        meta: { layout: 'mystagit' },
        component: Leads,
    },
    {
        path: '/leads/:id/editleads',
        name: 'EditLeads', 
        meta: { layout: 'mystagit' },
        component: EditLeads
    }
]