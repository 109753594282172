<template>
    <v-row justify="center">
        <v-dialog v-model="show_reminders_dialog" max-width="1400" persistent scrollable>
            <v-card>
                <v-card-title>Reminders</v-card-title>
                <v-card-text style="padding-bottom:100px;">
                    <v-row>
                        <v-col cols="6" class="pr-15">
                            <div class="mb-5">Create new reminder</div>
                            <v-text-field v-model="new_reminder.name" label="Name *" outlined dense></v-text-field>
                            <v-text-field v-model="new_reminder.subject" label="Subject *" outlined
                                dense></v-text-field>
                            <wysiwyg v-model="new_reminder.body" label="Body *" />
                            <br>
                            <v-text-field v-model="new_reminder.from_email" label="From Email *" outlined
                                dense></v-text-field>
                            <v-text-field v-model="new_reminder.from_name" label="From Name *" outlined
                                dense></v-text-field>
                            <v-text-field v-model="new_reminder.days_before" label="Days Before *" type="number"
                                outlined dense></v-text-field>
                            <v-select v-model="new_reminder.recipient_type" label="Recipient Type *"
                                :items="['lead', 'invitee', 'both']" outlined dense></v-select>
                            <v-select v-model="new_reminder.mail_type" label="Mail Type *" :items="['email', 'sms']"
                                outlined dense></v-select>
                            <v-select v-model="new_reminder.date_type" label="Date Type *" :items="['event', 'payment']"
                                outlined dense></v-select>
                            <v-btn dark class="custom-buttons mt-5" @click="createReminder()">Create</v-btn>
                        </v-col>
                        <v-col cols="6" class="pl-15">
                            <v-data-table :headers="reminder_headers" :items="reminders" disable-pagination
                                hide-default-footer style="height:500px;overflow-y:scroll;">
                                <template v-slot:item="{ item }">
                                    <tr style="cursor:inherit;background-color:transparent;">
                                        <td>{{ item.id }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>
                                            <v-btn class="custom-buttons" icon
                                                @click="openUpdateReminderDialog(item)"><v-icon>mdi-pen</v-icon></v-btn>
                                            <v-btn class="custom-buttons" color="red" icon
                                                @click="deleteReminder(item)"><v-icon>mdi-delete</v-icon></v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-buttons" text @click="closeRemindersDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="update_reminder_dialog" max-width="600" persistent scrollable>
            <v-card v-if="reminder_to_update.id">
                <v-card-title>Update Reminder</v-card-title>
                <v-card-text>
                    <v-text-field v-model="reminder_to_update.name" label="Name *" outlined dense></v-text-field>
                    <v-text-field v-model="reminder_to_update.subject" label="Subject *" outlined dense></v-text-field>
                    <wysiwyg v-model="reminder_to_update.body" label="Body *" />
                    <br>
                    <v-text-field v-model="reminder_to_update.from_email" label="From Email *" outlined
                        dense></v-text-field>
                    <v-text-field v-model="reminder_to_update.from_name" label="From Name *" outlined
                        dense></v-text-field>
                    <v-text-field v-model="reminder_to_update.days_before" label="Days Before *" type="number" outlined
                        dense></v-text-field>
                    <v-select v-model="reminder_to_update.recipient_type" label="Recipient Type *"
                        :items="['lead', 'invitee', 'both']" outlined dense></v-select>
                    <v-select v-model="reminder_to_update.mail_type" label="Mail Type *" :items="['email', 'sms']"
                        outlined dense></v-select>
                    <v-select v-model="reminder_to_update.date_type" label="Date Type *" :items="['event', 'payment']"
                        outlined dense></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-buttons" text @click="closeUpdateReminderDialog()">Close</v-btn>
                    <v-btn class="custom-buttons" text @click="updateReminder(reminder_to_update)">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Settings from '../../../modules/admin/settings/modules/settings.js'
export default {
    components: {
    },
    props: [
        'show_reminders_dialog'
    ],
    data: () => ({
        classes: {
            settings: null
        },
        reminders: [],
        reminder_headers: [
            { text: 'ID', sortable: false },
            { text: 'Name', sortable: false },
            { text: '', sortable: false },
        ],
        new_reminder: {
            name: null,
            subject: null,
            body: null,
            from_email: null,
            from_name: null,
            days_before: null,
            recipient_type: null,
            mail_type: null,
            date_type: null,
        },
        reminder_to_update: {
            id: null,
            name: null,
            subject: null,
            body: null,
            from_email: null,
            from_name: null,
            days_before: null,
            recipient_type: null,
            mail_type: null,
            date_type: null,
        },
        update_reminder_dialog: false,
    }),
    created() {
        this.classes.settings = new Settings()
    },
    async mounted() {
        this.getReminders()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_user: 'auth/get_user',
        })
    },
    methods: {
        async getReminders() {
            const result = await this.classes.settings.getReminders({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.reminders = result.data
            }
        },
        closeRemindersDialog() {
            this.$emit('closeRemindersDialog')
        },
        openUpdateReminderDialog(item) {
            this.reminder_to_update.id = item.id
            this.reminder_to_update.name = item.name
            this.reminder_to_update.subject = item.subject
            this.reminder_to_update.body = item.body
            this.reminder_to_update.from_email = item.from_email
            this.reminder_to_update.from_name = item.from_name
            this.reminder_to_update.days_before = item.days_before
            this.reminder_to_update.recipient_type = item.recipient_type
            this.reminder_to_update.mail_type = item.mail_type
            this.reminder_to_update.date_type = item.date_type
            this.update_reminder_dialog = true
        },
        closeUpdateReminderDialog() {
            this.reminder_to_update = {
                id: null,
                name: null,
                subject: null,
                body: null,
                from_email: null,
                from_name: null,
                days_before: null,
                recipient_type: null,
                mail_type: null,
                date_type: null,
            },
                this.update_reminder_dialog = false
        },
        async createReminder() {
            let payload = {
                company_id: this.get_selected_company.id,
                ...this.new_reminder
            }
            const result = await this.classes.settings.createReminder(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.new_reminder = {
                    name: null,
                    subject: null,
                    body: null,
                    from_email: null,
                    from_name: null,
                    days_before: null,
                    recipient_type: null,
                    mail_type: null,
                    date_type: null,
                }
                this.getReminders()
            }
        },
        async updateReminder(item) {
            const result = await this.classes.settings.updateReminder(item)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closeUpdateReminderDialog()
                this.getReminders()
            }
        },
        async deleteReminder(item) {
            const result = await this.classes.settings.deleteReminder({ reminder_id: item.id })
            if (result.response == true) {
                this.$toast.success(result.message)
                this.getReminders()
            }
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row)) {
    border-bottom: none !important;
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}
</style>