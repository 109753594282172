import Vue from "vue";

export default class Events {
    constructor() {
        this.vue = Vue
    }

    getEvents({ company_id }) {
        return this.vue.axios.get(`admin/events/get_events/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    getEvent({ event_id }) {
        return this.vue.axios.get(`admin/events/get_event/${event_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    createEvent(payload) {
        return this.vue.axios.post('admin/events/create_event', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    updateEvent(payload) {
        return this.vue.axios.post(`admin/events/update_event/${payload.id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    sendOrganiserEmail(payload) {
        return this.vue.axios.post('admin/events/send_organiser_email', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    sendInviteeEmail(payload) {
        return this.vue.axios.post('admin/events/send_invitee_email', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    sendBookingConfirmation(payload) {
        return this.vue.axios.post('admin/events/send_booking_confirmation', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    getBookingHistory(payload) {
        return this.vue.axios.get('admin/events/get_booking_history', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
}