<template>
    <v-container>
        <div class="d-flex justify-center align-center" style="margin-top: 5%;">
            <div>
                <v-img :src="logo" style="width: 70px;"></v-img>
            </div>
            <h1 class="ml-2" style="color:#368CA4;">GoGroupBooking</h1>
        </div>
        <div class="d-flex justify-center">
            <v-card outlined width="450" class="mt-10">
                <v-card-title style="font-size: 16px;font-family: 'Work Sans'; color: '#828282'" class="justify-center">
                    <v-icon style="font-size: 14px;" class="mr-2">mdi-lock</v-icon>
                    <small>Admin Access</small>
                </v-card-title>
                <v-card-text class="px-6">
                    <label style="font-family: 'Manrope';color: black">Email Address</label>
                    <v-text-field outlined dense placeholder="name@company.com" class="mt-2" type="email"
                        v-model="u.email"></v-text-field>

                    <label style="font-family: 'Manrope';color: black">Password</label>
                    <v-text-field outlined dense placeholder="Enter Password" class="mt-2"
                        :type="show_password == false ? 'password' : 'text'"
                        :append-icon="show_password == false ? 'mdi-eye-off' : 'mdi-eye'" v-model="u.password"
                        @click:append="show_password = !show_password"></v-text-field>

                    <label style="font-family: 'Manrope';color: black">Company Password</label>
                    <v-text-field v-model="u.company_password" outlined dense placeholder="Enter Password" class="mt-2"
                        :type="show_company_password == false ? 'password' : 'text'"
                        :append-icon="show_company_password == false ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="show_company_password = !show_company_password"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-list style="width: 100%;padding:0;margin:0">
                        <!-- <v-list-item class="d-flex justify-center">
                            <span @click="ForgotPassword()" class="forgot-password">Forgot Password</span>
                        </v-list-item> -->
                        <v-list-item class="d-flex justify-center">
                            <v-btn outlined dense @click="login()" class="white--text login-btn mb-6">Login</v-btn>
                        </v-list-item>
                    </v-list>
                </v-card-actions>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import Logo from '@/assets/logo.png'

export default {
    data: () => ({
        logo: Logo,
        show_password: false,
        show_company_password: false,
        u: {
            email: null,
            password: null,
            company_password: 'mystagit_go'
        }
    }),
    mounted() {
        const token = this.$store.getters['auth/get_user']
        if (Object.keys(token).length !== 0) {
            this.$router.push({ name: '/dashboard' })
        }
    },
    methods: {
        ForgotPassword() {
            this.$router.push('/forgotpassword')
        },
        async login() {
            let tp = {
                email: this.u.email,
                password: this.u.password,
                company_password: this.u.company_password,
            }
            await this.$axios.post('api/un_auth/login', tp)
                .then(({ data }) => {
                    if (data.response) {
                        if (data.message) {
                            this.$toast.success(data.message)
                        }
                        this.$router.push({ name: '/dashboard' })
                        this.$store.dispatch('auth/set_user', data.user)
                        this.$store.dispatch('auth/set_token', data.token)
                        return
                    }
                    this.$toast.info(data.message)
                    return
                })
        },
        register() {
            this.$router.push({ name: '/register' })
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Work+Sans|Manrope');

.forgot-password {
    font-family: 'Manrope';
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    color: #085DAC;
}

.login-btn {
    background-color: #085DAC;
    font-family: 'Manrope';
    padding-left: 150px !important;
    padding-right: 150px !important;
}
</style>