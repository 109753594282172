<template>
    <v-dialog :value="value" persistent max-width="560">
        <v-card>
            <v-card-title>
                Edit Rate
            </v-card-title>
            <v-card-text v-if="rate">
                <v-text-field v-model="edit_rate_model.net" label="Net" :hint="`Original Net: ${rate.net}`" outlined dense
                    type="number" @keyup="computeeditrates(edit_rate_model)">
                </v-text-field>
                <v-text-field v-model="edit_rate_model.markup" label="Markup %" :hint="`Original Markup: ${rate.markup}`"
                    outlined dense type="number" @keyup="computeeditrates(edit_rate_model)">
                </v-text-field>
                <v-text-field v-model="edit_rate_model.gross" label="Gross" :hint="`Original Gross ${rate.gross}`" outlined
                    dense readonly type="number">
                </v-text-field>
                <v-text-field v-model="edit_rate_model.commission" label="Commission"
                    :hint="`Original Commission ${rate.commission}%`" outlined dense readonly type="number">
                </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="close_dialog()">
                    Cancel
                </v-btn>
                <v-btn color="green darken-1" text @click="save_rate()">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    components: {
    },
    props: [
        'value', 'rate'
    ],
    data() {
        return {
            edit_rate_model: {
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0
            }
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_itinerary_action: "itinerary/get_itinerary_action",
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        computeeditrates(item) {
            const a = item.net * (item.markup / 100);
            const gross = Number(item.net) + Number(a);
            this.edit_rate_model.gross = Number(gross);
            this.edit_rate_model.commission = Number(item.markup / gross);
        },
        close_dialog() {
            this.$emit('close_dialog_edit_rate')
            this.edit_rate_model.net = 0
            this.edit_rate_model.gross = 0
            this.edit_rate_model.markup = 0
            this.edit_rate_model.commission = 0
        },
        async save_rate() {
            let payload = {
                id: this.rate.id,
                sup_opt_rate_id: this.rate.sup_opt_rate_id,
                itinerary_id: this.get_itinerary_action.id,
                day: this.rate.day,
                net: this.edit_rate_model.net,
                gross: this.edit_rate_model.gross,
                markup: this.edit_rate_model.markup,
                commission: this.edit_rate_model.commission,
            }
            
            if (this.rate.itinerary_id != null) {
                await this.$axios.patch('api/itinerary/pricing/edit_itinerary_rate', payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.close_dialog()
                        }
                    })
            }
            else {
                await this.$axios.post('api/itinerary/pricing/add_itinerary_rate', payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.close_dialog()
                        }
                    })
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>