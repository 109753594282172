<template>
    <v-container fluid>
        <v-card
            elevation="0"
        >
            <v-card-title>
                <v-spacer/>
                <v-btn
                    class="pl-5 pr-5 ml-2 mr-2"
                    rounded
                    color="primary"
                    style="text-transform: unset !important;"
                    @click="download_to_pdf()"
                    :disabled="get_selected"
                >
                    Create {{ selected.length }} voucher(s)
                </v-btn>
                <!-- <v-btn
                    :color="get_selected ? 'red' : 'primary'"
                    style="text-transform: unset !important;"
                    rounded
                    :disabled="get_selected"
                    @click="send_booking_request()"
                >
                    Send booking request
                </v-btn> -->
                <v-menu
                    :disabled="get_selected"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :color="get_selected ? 'red' : 'primary'"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="ml-2"
                            rounded
                            style="text-transform: unset !important;"
                        >
                            Send {{ selected.length }} booking <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list
                        class="pr-10"
                    >
                        <v-list-item
                            v-for="(dd, dditem) in dd_booking"
                            :key="dditem"
                            style="cursor: pointer;"
                            @click="request_event(dd.action)"
                        >
                            <v-list-item-title>
                                <small
                                    style="font-weight: 600;"
                                >
                                    <v-icon
                                        class="mr-1"
                                    >
                                        mdi-send-circle-outline
                                    </v-icon>
                                    {{ dd.text }}
                                </small>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <v-data-table
                    :headers="booking_table_header"
                    :items="get_booking"
                    class="mt-5"
                    hide-default-footer
                    disable-pagination
                    show-select
                    item-key="id"
                    v-model="selected"
                    hide-default-header>
                    <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr>
                                <th v-for="(h, i) in headers" 
                                    :key="i"
                                    :class="h.class">
                                    <span
                                        style="font-weight: 700; font-size: 14px;">
                                        {{h.text.toUpperCase()}}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item.days="props">
                        <div
                            style="background: #343642; color: white !important; width: 90px;"
                            class="text-center pt-1 pb-1 pl-1 pr-1"
                        >
                            {{ get_range(props.item.check_in_date, props.item.check_out_date) === 0 ? 1 : get_range(props.item.check_in_date, props.item.check_out_date) }} day(s)
                            <!--{{ new Date(props.item.check_in_date).toLocaleDateString('en-ie', {weekday: 'short'}) }} - {{ new Date(props.item.check_out_date).toLocaleDateString('en-ie', {weekday: 'short'}) }}-->
                        </div>
                    </template>
                    <template v-slot:item.item_value="props">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <label
                                        style="font-weight: 300;"
                                    >
                                        {{ props.item.supplier_product.name }}
                                    </label>
                                    <small>( {{ props.item.count }} * {{ props.item.supplier_product_option.name }} )</small>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <small>{{ props.item.supplier_product_type.name }}<br/></small>
                                    <small>{{ props.item.supplier_info.supplier_name }}</small>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-slot:item.type="props">
                        <div>
                            <v-icon
                                color="black"
                            >
                                {{ props.item.supplier_product_type.icon }}
                            </v-icon>
                        </div>
                    </template>
                    <template v-slot:item.dates="props">
                        <small>
                            {{ props.item.check_in_date }}
                        </small>
                        <small
                            style="display: block;"
                        >
                            {{ props.item.check_out_date }}
                        </small>
                    </template>
                    <template v-slot:item.time="props">
                        <small>
                            {{ props.item.check_in_time }}
                        </small>
                        <small
                            style="display: block;"
                        >
                            {{ props.item.check_out_time }}
                        </small>
                    </template>
                    <template v-slot:item.status="props">
                        <v-menu                           
                            rounded
                            offset-y>
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                    v-bind="attrs" v-on="on"
                                    small
                                    rounded
                                    :color="evtGetBookingStatusColor(props.item.get_booking_status)"
                                    dark>
                                    {{ props.item.get_booking_status != null ? props.item.get_booking_status.status : 'Not Yet Requested' }}
                                    <v-icon>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(status) in getBookingStatus"
                                    :key="status.id"
                                    link
                                    @click="evtChangeBookingStatus(props.item.id, status)">
                                    <v-list-item-title v-text="status.status"></v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:item.booking_reference_number="props">
                        <label>
                            BKID - {{ props.item.booking_reference_number }} 
                            <v-icon 
                                small 
                                style="cursor: pointer;"
                                @click="update_booking_id(props.item)">
                                mdi-pen
                            </v-icon>
                        </label>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <div
            id="element-to-convert"
        >
            <v-card
                v-for="(item, itemindex) in selected"
                :key="itemindex"
            >
                <v-card-title>
                    <v-img
                        eager
                        class="mx-auto my-5"
                        v-if="get_selected_company.company_logo"
                        :src="get_selected_company.company_logo"
                    ></v-img>
                </v-card-title>
                <v-card-title>
                    <!-- <small>Supplier Name: {{ item.supplier_info.supplier_name }}</small> -->
                    <v-list three-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title><v-icon class="mr-1">mdi-domain</v-icon>{{ item.supplier_info.supplier_name }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <label>
                                        <v-icon class="mr-1">mdi-map-marker</v-icon>{{ item.supplier_info.address }}<br/>
                                        <v-icon class="mr-1">mdi-phone</v-icon>{{ item.supplier_info.phone }}
                                        <v-icon class="mr-1">mdi-web-check</v-icon>{{ item.supplier_info.website }}
                                    </label>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer/>
                    <small>Voucher number: {{ new Date().getMilliseconds() }}</small>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                <v-card>
                    <v-card-title>
                        <label
                            style="font-size: 16px; font-weight: 800;"
                        >Voucher Booking Details</label>
                    </v-card-title>
                    <v-card-subtitle>
                        <label><strong>Booking REF #: </strong>{{ item.booking_reference_number }}</label>
                        <label
                            style="display: block;"
                        ><strong>Itinerary #: </strong>{{ get_reference_number }}</label>
                    </v-card-subtitle>
                    <v-card-title>
                        <label
                            style="font-size: 16px; font-weight: 800;"
                        >
                            Notes:
                        </label>
                    </v-card-title>
                    <v-card-subtitle>
                        <label>{{ item.instructions }}</label>
                    </v-card-subtitle>
                    <v-card-text>
                    <v-row>
                        <v-col
                            cols="2"
                        >
                            <strong>Item</strong>
                        </v-col>
                        <v-col
                            cols="10"
                        >
                            <label>{{ item.supplier_product_type.name }} ( {{ item.supplier_product_option.name }} ) * {{ get_itinerary.traveller_info.length }}</label>
                        </v-col>
                        <v-col
                            cols="2"
                        >
                            <strong>Dates</strong>
                        </v-col>
                        <v-col
                            cols="10"
                        >
                            <label>{{ item.check_in_date }} - {{ item.check_out_date }}</label>
                        </v-col>
                        <v-col
                            cols="2"
                        >
                            <strong>Start</strong>
                        </v-col>
                        <v-col
                            cols="10"
                        >
                            <label>
                                {{ item.check_in_date }} {{ item.check_in_time }}
                            </label>
                        </v-col>
                        <v-col
                            cols="2"
                        >
                            <strong>End</strong>
                        </v-col>
                        <v-col
                            cols="10"
                        >
                            <label>
                                {{ item.check_out_date }} {{ item.check_out_time }}
                            </label>
                        </v-col>
                        <v-col
                            cols="2"
                            v-if="get_itinerary.traveller_info[0].contact != null"
                        >
                            <strong>Traveller names</strong>
                        </v-col>
                        <v-col
                            cols="10"
                            v-if="get_itinerary.traveller_info[0].contact != null"
                        >
                            <label
                                v-for="(namelist, namesindex) in get_itinerary.traveller_info"
                                :key="namesindex"
                            >
                                {{ namelist.contact.first_name }}, {{ namelist.contact.last_name }}<br/>
                            </label>
                        </v-col>
                        <v-col
                            cols="2"
                        >
                            <strong>Itinerary Name</strong>
                        </v-col>
                        <v-col
                            cols="10"
                        >
                            <label>{{ get_itinerary.itinerary_name }}</label>
                        </v-col>
                    </v-row>
                    </v-card-text>
                    <v-card-title>
                        <label
                            style="font-size: 16px; font-weight: 800;"
                        >
                            Issued By:
                        </label>
                        <v-spacer/>
                        <label
                            style="font-size: 16px; font-weight: 800;"
                        >
                            Issued Date: {{ $date(Date.now()).format('DD MMMM, YYYY') }}
                        </label>
                    </v-card-title>
                    <v-card-subtitle>
                        <label>{{ get_created_by_info.name }}</label>
                    </v-card-subtitle>
                </v-card>
                </v-card-text>
            </v-card>
        </div>
        <v-dialog
            v-model="bk_id_model"
            persistent
            max-width="770px"
            overlay-color="#f4f4f5"
            overlay-opacity="1"
        >
            <v-btn fab @click="bk_id_model = false" class="dialog-close">
                <v-icon dark> mdi-arrow-left </v-icon>
            </v-btn>
            <v-card>
                <v-card-title
                    style="font-weight:lighter; font-size: 16px"
                >
                    <label>Add booking reference ID</label>
                </v-card-title>
                <v-divider/>
                <v-card-text
                    class="mt-5"
                >
                    <v-text-field
                        v-model="i.booking_id"
                        label="Booking ID"
                        hint="Booking ID"
                        dense
                        outlined
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        outlined
                        class="pl-3 pr-3"
                        rounded
                        @click="close_bk_dialog()"
                    >
                        <v-icon
                            class="mr-2"
                        >
                            mdi-close
                        </v-icon>
                        cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pl-8 pr-8"
                        rounded
                        @click="save_bk_dialog()"
                    >
                        <v-icon
                            class="mr-2"
                        >
                            mdi-content-save
                        </v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <AvailabilityRequest 
            :data="selected" 
            :dialog_value="availability_request_model"
            :data_length="selected.length"
            :title="title"
            @close_dialog="close_dialog"
        />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import html2pdf from "html2pdf.js"
import AvailabilityRequest from './components/AvailabilityRequest.vue'

import Booking from './modules/Booking.js'

export default {
  components: {
    AvailabilityRequest
  },
  props: [
    'id'
  ],
  data () {
    return {
        timer: null,
        booking_table_header: [
            {
                text:   'Days',
                value:  'days'
            },
            {
                text:   'Item',
                value:  'item_value'
            },
            {
                text:   'Type',
                value:  'type'
            },
            {
                text:   'Dates',
                value:  'dates'
            },
            {
                text:   'Time',
                value:  'time'
            },
            {
                text:   'Status',
                value:  'status'
            },
            {
                text:   'Booking Reference',
                value:  'booking_reference_number'
            }
        ],
        selected: [],
        dd_booking: [
            // {
            //     text:   'Availability request',
            //     action: 'availability_request'
            // },
            {
                text:   'Booking request',
                action: 'booking_request'
            },
            // {
            //     text:   'Amendment request',
            //     action: 'amendment_request'
            // },
            {
                text:   'Cancellation request',
                action: 'cancellation_request'
            },
            // {
            //     text:   'Courtesy reconfirmation',
            //     action: 'courtesy_reconfirmation'
            // }
        ],
        availability_request_model: false,
        bk_id: null,
        bk_id_model: false,
        i: {
            booking_id: null
        },
        to_add_bk_id: {},
        title: null,        
        booking: {}
    }
  },
  async mounted () {
    await this.$store.dispatch(
        'itinerary/fetch_booking',
        this.id
    )

    this.booking = new Booking(this.$store)
    this.booking.getBookingStatus()
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_booking:                        'itinerary/get_booking',
        get_itinerary:                      'itinerary/get_itinerary',
        get_group_name:                     'itinerary/get_group_name',
        get_itinerary_number:               'itinerary/get_itinerary_number',
        get_created_by_info:                'itinerary/get_created_by_info',
        get_reference_number:               'itinerary/get_reference_number',
        get_selected_company:               'auth/get_selected_company',
        getBookingStatus:                   'booking/getBookingStatusGetters'
    }),
    get_selected(){
        return this.selected.length === 0 ? true : false
    }
  },
  methods: {
    download_to_pdf(){
        html2pdf(document.getElementById("element-to-convert"), {
            margin:       1,
            filename:     this.get_group_name + "voucher.pdf",
            image:        { type: 'jpeg', quality: 1.0 },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'landscape' }
        });
        // document.getElementById("element-to-convert").classList.add("hidden");
        this.timer = setInterval(() => {
            // document.getElementById("element-to-convert").classList.add("hidden");
            this.selected = []
            clearInterval(this.timer)
        }, 1500)
    },
    get_range(start, end){

        const date1 = new Date(start)
        const date2 = new Date(end)

        const diff_in_time = date2.getTime() - date1.getTime()
        const diff_in_days = diff_in_time / (1000 * 3600 * 24)

        return diff_in_days
    },
    request_event(item){
        switch(item){
            case 'availability_request': {
                this.availability_request_model = true
                break
            }
            case 'booking_request': {
                this.availability_request_model = true
                this.title = "Send booking request"
                break
            }
            case 'amendment_request': {
                alert('amendment_request')
                break
            }
            case 'cancellation_request': {
                this.availability_request_model = true
                this.title = "Cancel booking request"
                break
            }
            case 'courtesy_reconfirmation': {
                alert('courtesy_reconfirmation')
                break
            }
        }
    },
    close_dialog(){
        this.availability_request_model = false
    },
    update_booking_id(data){
        this.to_add_bk_id = data
        this.bk_id_model = true
    },
    close_bk_dialog(){
        this.to_add_bk_id = {}
        this.bk_id_model = false
    },
    async save_bk_dialog(){
        let tp = {
            id:                         this.to_add_bk_id.id,
            booking_reference_number:   this.i.booking_id
        }
        this.$store.dispatch('itinerary/update_booking_id', tp)
        await this.$store.dispatch(
            'itinerary/fetch_booking',
            this.id
        )
        this.close_bk_dialog()
    },
    async send_booking_request(){
        // console.log(this.selected)
        this.selected[0].reference_number = this.get_reference_number
        this.$store.dispatch('itinerary/send_booking_request', this.selected)
    },
    evtChangeBookingStatus(supplier_id, status) {
        this.booking.updateBookingStatusByBookingId(supplier_id, status)
    },
    evtGetBookingStatusColor(booking_status) {
        if ( booking_status != null ) {
            switch ( booking_status.id ) {
                case 1: 
                    return 'primary'
                case 2:
                    return 'warning'
                case 3:
                    return 'success'
                case 4:
                    return 'error'
                case 5:
                    return 'error'
            }
        } else {    
            return 'primary'
        }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}
.card .card-header-items {
  text-align: right;
}
.card .card-header-items .header-items {
  margin: 0px 10px;
}
.dialog-close {
  margin-left: -75px;
  position: absolute;
}
</style>