<template>
    <v-container
        fluid
    >
        <h3
            style="font-weight: 400;"
        >Outline</h3>
        <v-row
            v-for="(item, itemindex) in data"
            :key="itemindex"
            class="mt-5"
        >
            <v-col
                cols="3"
            >
                <label
                    style="font-weight: 600; font-size: larger;"
                >
                    Day {{ itemindex + 1 }}
                </label>
                <label
                    style="display: block; font-weight: 100;"
                >
                    {{ $date(item.date).format('dddd MMM DD, YYYY') }}
                </label>
            </v-col>
            <v-col
                cols="7"
            >
                <v-row
                    v-for="(iitem, iitemindex) in item.get_child"
                    :key="iitemindex"
                >
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon>{{ iitem.supplier_product_type.icon }}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ iitem.check_in_time }} | {{ iitem.info.supplier_name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ iitem.info.address }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
    'data'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>