import CustomQuote from "../../../../components/quotes/CustomQuote.vue"
import QuickQuote from "../../../../components/quotes/QuickQuote.vue"

export default [
    {
        path: '/custom_quote/create/:lead_id',
        name: 'CreateCustomQuote',
        meta: { layout: 'mystagit' },
        component: CustomQuote,
    },
    {
        path: '/quote/edit/:lead_id/:quote_id',
        name: 'EditCustomQuote',
        meta: { layout: 'mystagit' },
        component: CustomQuote,
    },
    {
        path: '/quick_quote/create/:lead_id',
        name: 'CreateQuickQuote',
        meta: { layout: 'mystagit' },
        component: QuickQuote,
    }
]