<template>
  <div
    v-if="get_supplier_pricing_information == null || Object.keys(get_supplier_pricing_information).length == 0 || is_edit"
  >
    <v-container fluid style="background-color: #f4f4f5 !important">
      <v-row class="mt-5" justify="center" align="center">
        <v-card width="820" elevation="2">
          <v-card-title class="text-small">
            <small><strong>Pricing</strong></small>
            <!-- {{ get_supplier_pricing_information }} -->
          </v-card-title>
          <v-card-subtitle>
            Enter default margins for this supplier
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-btn-toggle
                  v-model="c.selection"
                  tile
                  color="deep-purple accent-3"
                  group
                  dense
                >
                  <v-btn value="net" class="pl-8 pr-8"> Net </v-btn>
                  <v-btn value="gross" class="pl-8 pr-8"> Gross </v-btn>
                  <v-btn value="both" class="pl-8 pr-8"> Both </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="Default markup"
                  hint="Enter a value greater than or equal to 0"
                  type="number"
                  v-model="c.markup"
                >
                  <template v-slot:append>
                    <v-icon small style="position: relative; top: 4px">
                      mdi-percent-outline
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card width="820" elevation="2">
            <v-card-title class="text-small">
              <small><strong>Currency</strong></small>
            </v-card-title>
            <v-card-subtitle>
              Enter the currency paid to the supplier
            </v-card-subtitle>
            <v-card-text>
              <v-alert
                elevation="2"
                colored-border
                icon="mdi-information"
                border="left"
                color="#5996C5"
                prominent
                style="fontsize: 14px"
              >
                <small
                  >Changing the currency of this supplier will change the currency
                  for new and existing rates. Any itineraries using these rates
                  will need to refresh their prices in order to use the new
                  currency.</small
                >
              </v-alert>
              <div class="mt-8"></div>
              <v-autocomplete
                :items="get_currencies"
                item-value="code"
                item-text="code"
                outlined
                label="Currency"
                dense
                v-model="c.currency"
              >
                <template slot="item" slot-scope="{ item }">
                  <label class="mb-3">
                    {{ item.code }}
                    <small style="display: block">
                      {{ item.description }}
                    </small>
                  </label>
                </template>
              </v-autocomplete>
            </v-card-text>
            <v-card width="820" elevation="2">
              <v-card-title class="text-small">
                <small><strong>Payment Terms</strong></small>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      :items="get_payment_term"
                      item-value="name"
                      item-text="name"
                      outlined
                      dense
                      label="Generate due date from"
                      v-model="c.payment_terms"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field 
                      dense 
                      outlined 
                      label="Days" 
                      v-model="c.days"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Payment details"
                      v-model="c.payment_details"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  class="pl-10 pr-10"
                  color="primary"
                  rounded
                  @click="!is_edit ? save_pricing() : save_edited_pricing()"
                >
                  <v-icon
                    class="mr-2"
                  >
                    mdi-content-save
                  </v-icon>
                  save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </v-card>
      </v-row>
    </v-container>
  </div>
  <div v-else>
    <v-container fluid style="background-color: #f4f4f5 !important">
      <v-row class="mt-5" justify="center" align="center">
        <v-card width="820" elevation="2">
          <v-card-title class="text-small">
            <small><strong>Pricing</strong></small>
            <!-- {{ get_supplier_pricing_information }} -->
          </v-card-title>
          <v-card-subtitle>
            Enter default margins for this supplier
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-btn
                  rounded
                  elevation="0"
                  color="primary"
                >
                  {{ get_supplier_pricing_information.selection }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="Default markup"
                  hint="Enter a value greater than or equal to 0"
                  type="number"
                  v-model="get_supplier_pricing_information.markup"
                >
                  <template v-slot:append>
                    <v-icon small style="position: relative; top: 4px">
                      mdi-percent-outline
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card width="820" elevation="2">
            <v-card-title class="text-small">
              <small><strong>Currency</strong></small>
            </v-card-title>
            <v-card-subtitle>
              Enter the currency paid to the supplier
            </v-card-subtitle>
            <v-card-text>
              <v-alert
                elevation="2"
                colored-border
                icon="mdi-information"
                border="left"
                color="#5996C5"
                prominent
                style="fontsize: 14px"
              >
                <small
                  >Changing the currency of this supplier will change the currency
                  for new and existing rates. Any itineraries using these rates
                  will need to refresh their prices in order to use the new
                  currency.</small
                >
              </v-alert>
              <div class="mt-8"></div>
              <v-text-field
                outlined
                readonly
                v-model="get_supplier_pricing_information.currency"
                dense
              >
              </v-text-field>
            </v-card-text>
            <v-card width="820" elevation="2">
              <v-card-title class="text-small">
                <small><strong>Payment Terms</strong></small>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      readonly
                      v-model="get_supplier_pricing_information.payment_terms"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field 
                      dense 
                      outlined 
                      label="Days" 
                      v-model="get_supplier_pricing_information.days"
                      type="number"
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Payment details"
                      v-model="get_supplier_pricing_information.payment_details"
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  class="pl-10 pr-10"
                  color="primary"
                  rounded
                  @click="edit_pricing()"
                >
                  <v-icon
                    class="mr-2"
                  >
                    mdi-pen
                  </v-icon>
                  edit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: [],
  data: () => ({
    c: {
      currency: null,
      selection: null,
      payment_terms: null,
      days: null,
      payment_details: null,
      markup: null
    },
    is_edit: false
  }),
  async mounted() {
    await this.$store.dispatch("supplier/fetch_currencies");
    await this.$store.dispatch("supplier/fetch_payment_term");
    await this.$store.dispatch('supplier_pricing/fetch_pricing_data', this.get_supplier_setup)
  },
  created() {},
  computed: {
    ...mapGetters({
      get_currencies:                     "supplier/get_currencies",
      get_payment_term:                   "supplier/get_payment_term",
      get_supplier_setup:                 'supplier/get_supplier_setup',
      get_user:                           'auth/get_user',
      get_supplier_pricing_information:   'supplier_pricing/get_supplier_pricing_information'
    }),
  },
  methods: {
    async save_pricing(){
      this.c.supplier_id = this.get_supplier_setup.id
      // if(this.get_supplier_pricing_information !== null || Object.keys(this.get_supplier_pricing_information).length > 0){
      //   // patch call
      //   this.c.id = this.get_supplier_pricing_information.id
      //   this.c.updated_by = this.get_user.id
      //   await this.$store.dispatch('supplier_pricing/update_pricing_data', this.c)
      //   await this.$store.dispatch('supplier_pricing/fetch_pricing_data', this.get_supplier_setup)
      //   this.is_edit = false
      //   this.c = {}
      //   return
      // }
      this.c.created_by = this.get_user.id
      await this.$store.dispatch('supplier/post_pricing_details', this.c)
      this.c = {}
      await this.$store.dispatch('supplier_pricing/fetch_pricing_data', this.get_supplier_setup)
      return
    },
    edit_pricing(){
      // console.log('edit pricing')
      this.is_edit = true
    },
    async save_edited_pricing(){
      this.c.supplier_id = this.get_supplier_setup.id
      // patch call
      this.c.id = this.get_supplier_pricing_information.id
      this.c.updated_by = this.get_user.id
      await this.$store.dispatch('supplier_pricing/update_pricing_data', this.c)
      await this.$store.dispatch('supplier_pricing/fetch_pricing_data', this.get_supplier_setup)
      this.is_edit = false
      this.c = {}
      return
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
