<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col
                cols="12"
                v-for="(item, itemindex) in data"
                :key="itemindex"
            >
                <v-card
                    v-for="(iitem, iitemindex) in item.get_child"
                    :key="iitemindex"
                >
                    <v-card-title>
                        <label>{{ iitem.check_in_date }}</label>
                    </v-card-title>
                    <v-card-title>
                        <v-list two-line>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>{{ iitem.supplier_product_type.icon }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ iitem.amenities.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ iitem.supplier_product_option.name }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="8"
                            >
                                <v-img :src="iitem.supplier_product_option.base64"/>
                            </v-col>
                            <v-col
                                cols="4"
                            >
                                <label
                                    v-if="iitem.info.description == null"
                                    v-html="iitem.supplier_product.description"
                                >
                                </label>
                                <label
                                    v-else
                                    v-html="iitem.info.description"
                                >
                                </label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
    'data'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>