<template>
    <div class="card">
        <v-row no-gutters>
            <v-col cols="12" md="6">
                <div class="card-header">
                    Resources
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <div class="card-header-items">
                    <v-btn
                        rounded
                        color="#525a68"
                        dark
                        class="header-items"
                        >
                        <v-icon left>
                            mdi-cloud-upload
                        </v-icon>
                        Import resources
                    </v-btn>
                    <v-btn
                        rounded
                        color="#525a68"
                        dark
                        class="header-items"
                        @click="CreateResources()"
                        >
                        Create resource
                    </v-btn>
                </div>
            </v-col>
            <v-col cols="12">
                <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="6">
                <div class="filtertabs-wrapper">
                    <v-tabs
                        grow
                        class="filtertabs"
                        show-arrows
                    >
                        <v-tab
                            @click="ResourcesFilter(0)"
                        >
                            All
                        </v-tab>
                        <v-tab
                            v-for="val in get_resources_type"
                            :key="val.id"
                            @click="ResourcesFilter(val.id)"
                        >
                            {{ val.name }}
                        </v-tab>
                    </v-tabs>
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Search"
                            prepend-icon="mdi-magnify"
                            class="mt-5 pr-2"
                            v-model="resources_search"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-switch
                            v-model="show_archived"
                            label="Show archived"
                            class="mt-9 pr-2"
                            @change="ShowArchived()"
                        ></v-switch>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-divider></v-divider>
                <v-data-table
                    :headers="headers"
                    :items="get_resources_info"
                    @click:row="EditResources"
                >
                    <template v-slot:item.image="props">
                        <div class="mx-auto text-center">
                            <v-avatar>
                                <v-img
                                    :lazy-src="SetPrimaryImage(props)"
                                    max-width="50"
                                    :src="SetPrimaryImage(props)"
                                />
                            </v-avatar>
                        </div>
                    </template>
                    <template v-slot:item.name="props">
                        {{ props.item.title }}
                    </template>
                    <template v-slot:item.type="props">
                        {{ props.item.resources_type_info.name }}
                    </template>
                    <template v-slot:item.action="props">
                        <v-menu
                            right
                            bottom
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list
                                v-if="props.item.deleted_at == null"
                            >
                                <v-list-item
                                    @click="Duplicate(props.item.id)"
                                >
                                    <v-list-item-title>
                                        Duplicate
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="Archive(props.item.id)"
                                >
                                    <v-list-item-title>
                                        Archive
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                            <v-list
                                v-else
                            >
                                <v-list-item
                                    @click="Restore(props.item.id)"
                                >
                                    <v-list-item-title>
                                        Restore
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="570px"
            overlay-color="#f4f4f5"
            overlay-opacity="1"
        >
            <v-btn
                fab
                @click="dialog = false"
                class="dialog-close"
            >
                <v-icon dark>
                    mdi-arrow-left
                </v-icon>
            </v-btn>
            <!-- 8=========================================================================================================================================D -->
            <v-card v-if="!new_resource_media">
                <v-card-title>
                    <span class="text-h5">{{ dialog_title }}</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-text-field
                            label="Title *"
                            v-model="payload.title"
                            @keyup="ValTitle()"
                            dense
                            outlined
                        ></v-text-field>
                        <ErrMessage :message="validation.title.message" :show="validation.title.show" :success="validation.title.success"/>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-autocomplete
                            v-model="payload.resources_type_id"
                            :items="get_resources_type"
                            item-value="id"
                            item-text="name"
                            dense
                            outlined
                        ></v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <v-textarea
                            v-model="payload.description"
                            auto-grow
                            label="Description *"
                            rows="1"
                            @keyup="ValDescription()"
                            dense
                            outlined
                        ></v-textarea>
                        <ErrMessage :message="validation.description.message" :show="validation.description.show" :success="validation.description.success"/>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Location"
                            required
                            v-model="payload.location"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <div class="media-instruction">
                            <h1>Media</h1>
                            <p>Add images here to use them in your itinerary</p>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <!-- <div class="media-button">
                            <input type="file" ref="files" class="input-media" accept="image/png, image/gif, image/jpeg" v-on:change="LoadImage($event)" multiple>
                            <v-btn
                                rounded
                                color="#525a68"
                                dark
                                @click="$refs.files.click()"
                            >
                                Add media
                            </v-btn>
                        </div> -->
                        <div class="media-button">
                            <v-btn
                                rounded
                                color="#525a68"
                                dark
                                @click="new_resource_media = true"
                            >
                                Add media
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <v-alert
                            elevation="2"
                            colored-border
                            icon="mdi-information"
                            style="font-size: 12px;"
                        >
                            For the best results, we recommend an image size of 1440 x 700 pixels.
                        </v-alert>
                    </v-col>
                    <v-col
                        cols="4"
                        v-for="(img, index) in media_list" 
                        :key="img.id"
                    >
                        <div class="preview-image-container">
                            <span><strong v-if="img.primary">Primary</strong><strong v-else>&nbsp;</strong></span>
                            <v-img
                                :src="img.url"
                                :lazy-src="img.url"
                                aspect-ratio="1"
                                class="grey lighten-2 preview-image"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <div class="preview-image-actions">
                                <v-btn
                                    color="#808080"
                                    icon
                                    @click="PrimaryImage(img.id)"
                                >
                                    <v-icon style="font-size: 38px;" dark>
                                        mdi-numeric-1
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    color="#808080"
                                    icon
                                    @click="RemoveImage(index)"
                                >
                                    <v-icon dark>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                    <div class="action-button">
                        <v-btn
                            color="success"
                            fab
                            @click="AddResources()"
                            v-if="dialog_title == 'Create resource'"
                        >
                            <v-icon dark>
                                mdi-check
                            </v-icon>
                        </v-btn>
                        <v-btn
                            color="success"
                            fab
                            @click="UpdateResources()"
                            v-else
                        >
                            <v-icon dark>
                                mdi-check
                            </v-icon>
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
            <!-- 8=========================================================================================================================================D -->
            <!-- 8=========================================================================================================================================D -->
            <v-card v-else>
                <v-card-title>
                    <v-row>
                        <v-col cols="6">
                            <span class="text-h5">Media Library</span>
                        </v-col>
                        <v-col cols="6">
                            <div class="media-library-button">
                                <input type="file" ref="files" class="input-media" accept="image/png, image/gif, image/jpeg" v-on:change="LoadImage($event)" multiple>
                                <v-btn
                                    rounded
                                    color="#525a68"
                                    dark
                                    @click="$refs.files.click()"
                                >
                                    Upload Media
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row>

                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                v-model="media_search"
                                label="Search"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col
                            v-for="(media) in get_media_info"
                            :key="media.id"
                            class="d-flex child-flex"
                            cols="4"
                        >
                        <v-img
                            :src="media.url"
                            :lazy-src="media.url"
                            aspect-ratio="1"
                            class="grey lighten-2 media-image"
                            @click="SelectImage(media)"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        </v-col>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                    <div class="action-button">
                        <v-btn
                            fab
                            @click="new_resource_media = false"
                            class="mr-5"
                        >
                            <v-icon dark>
                                mdi-arrow-left
                            </v-icon>
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
            <!-- 8=========================================================================================================================================D -->
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ErrMessage from '../../errmessage/ErrMessage.vue';
import Validation from '../../../common/validation';

export default {
    components: {
        ErrMessage
    },
    data: () => ({
        tab: null,
        show_archived: false,
        payload: {
            company_id: 0,
            title: '',
            resources_type_id: 1,
            description: '',
            location: ''
        },
        dialog: false,
        headers: [
            {
                text: '',
                value: 'image',
            },
            { text: 'Name', value: 'name' },
            { text: 'Type', value: 'type' },
            { text: 'Location', value: 'location' },
            { text: 'Created', value: 'created_at' },
            { text: '', value: 'action' }
        ],
        media_list: [],
        validation: {
            title: {
                message: '',
                show: false,
                success: false
            },
            description: {
                message: '',
                show: false,
                success: false
            }
        },
        resources_filter: 0,
        resources_search: '',
        new_resource_media: false,
        media_search: '',
        counter: 0,
        set_primary: false,
        dialog_title: '',
        selected_id: 0,
        remove_media_list: []
    }),
    mounted () {
    },
    created () {
    },
    computed: {
        ...mapGetters({
            get_selected_company:     'auth/get_selected_company',
            get_resources_type:       'resources/get_resources_type',
        }),
        get_resources_info(){
            return this.$store.getters['resources/get_resources_info'](this.resources_filter, this.resources_search)
        },
        get_media_info(){
            return this.$store.getters['resources/get_media_info'](this.media_search)
        }
    },
    methods: {
        SetPrimaryImage(props){
            let obj = props.item.media_info.find(element => element.primary == true);
            if(obj){
                return obj.media.url;
            }
        },
        LoadImage(event){
            // this.media_list = [];
            for(let img of event.target.files){
                if(this.media_list.length==0){
                    this.media_list.push({
                        id: this.counter+=1,
                        primary: true,
                        url: URL.createObjectURL(img),
                        file: img,
                        new: true
                    });
                }
                else{
                    this.media_list.push({
                        id: this.counter+=1,
                        primary: false,
                        url: URL.createObjectURL(img),
                        file: img,
                        new: true
                    });
                }
            }
            this.new_resource_media = false;
            console.log(this.media_list);
        },
        RemoveImage(index){
            this.counter = 0;
            this.set_primary = false;
            let removeObj = this.media_list.splice(index, 1);
            for(let img of this.media_list){
                if(removeObj[0].primary && !this.set_primary){
                    img.primary = true
                    this.set_primary = true;
                }
                img.id = this.counter++;
            }
            if(this.dialog_title=='Edit resource'){
                this.remove_media_list.push(removeObj[0]);
            }
            console.log(this.media_list);
            console.log(this.remove_media_list);
        },
        PrimaryImage(index){
            for(let img of this.media_list){
                if(img.id==index){
                    img.primary = true
                }
                else{
                    img.primary = false
                }
            }
            console.log(this.media_list);
        },
        ValTitle(){
            if(Validation.valBlank(this.payload.title)){
                this.validation.title.message = 'Please input your title.';
                this.validation.title.show = true;
                this.validation.title.success = false;
                return true;
            }
            else{
                this.validation.title.show = false;
                return false;
            }
        },
        ValDescription(){
            if(Validation.valBlank(this.payload.description)){
                this.validation.description.message = 'Please input your description.';
                this.validation.description.show = true;
                this.validation.description.success = false;
                return true;
            }
            else{
                this.validation.description.show = false;
                return false;
            }
        },
        FormValidation(){
            this.err_counter = 0;
            if(this.ValTitle()){
                this.err_counter += 1;
            }
            if(this.ValDescription()){
                this.err_counter += 1;
            }
            return this.err_counter;
        },
        async AddResources(){
            this.payload.company_id = this.get_selected_company.id;
            if(this.FormValidation()==0){
                // this.dialog = false;
                let count = 0;
                let formdata = new FormData();
                formdata.append('company_id', this.payload.company_id);
                formdata.append('title', this.payload.title);
                formdata.append('resources_type_id', this.payload.resources_type_id);
                formdata.append('description', this.payload.description);
                formdata.append('location', this.payload.location);
                this.media_list.forEach((value) => {
                    formdata.append('media' + count, value.file);
                    if(value.primary){
                        formdata.append('media_primary', count);
                    }
                    count++;
                });
                formdata.append('counter', count);
                await this.$axios.post('api/resources/add_resources', formdata).then(({data}) => {
                    if(data.response){
                        this.$store.dispatch('resources/set_resources', data.data);
                        this.$store.dispatch('resources/fetch_media_info', this.get_selected_company.id);
                        this.dialog = false;
                    }
                    else{
                        console.log('Something went wrong!');
                    }
                });
            }
        },
        ResourcesFilter(id){
            this.resources_filter = id;
        },
        ShowArchived(){
            if(this.show_archived){
                this.$store.dispatch('resources/fetch_archived_resources_info', this.get_selected_company.id);
            }
            else{
                this.$store.dispatch('resources/fetch_resources_info', this.get_selected_company.id);
            }
        },
        async Duplicate(id){
            let payload = {
                id: id
            };
            await this.$axios.post('api/resources/duplicate_resources_info', payload).then(({data}) => {
                if(data.response){
                    this.$store.dispatch('resources/set_resources', data.data);
                }
                else{
                    console.log('Something went wrong!');
                }
            });
        },
        async Archive(id){
            let payload = {
                id: id
            };
            await this.$axios.post('api/resources/archived_resources_info', payload).then(({data}) => {
                if(data.response){
                    this.$store.dispatch('resources/fetch_resources_info', this.get_selected_company.id);
                }
                else{
                    console.log('Something went wrong!');
                }
            });
        },
        async Restore(id){
            let payload = {
                id: id
            };
            await this.$axios.post('api/resources/restore_resources_info', payload).then(({data}) => {
                if(data.response){
                    this.$store.dispatch('resources/fetch_archived_resources_info', this.get_selected_company.id);
                }
                else{
                    console.log('Something went wrong!');
                }
            });
        },
        CreateResources(){
            this.emptyPayload();
            this.media_list = [];
            this.dialog_title = 'Create resource';
            this.dialog = true;
            this.new_resource_media = false;
        },
        SelectImage(media){
            console.log(media);
            if(this.media_list.length==0){
                this.media_list.push({
                    id: this.counter+=1,
                    primary: true,
                    url: media.url,
                    file: media.id,
                    new: false
                });
            }
            else{
                this.media_list.push({
                    id: this.counter+=1,
                    primary: false,
                    url: media.url,
                    file: media.id,
                    new: false
                });
            }
            this.new_resource_media = false;
        },
        async EditResources(item){
            if(!this.show_archived){
                this.counter = 0;
                this.media_list = [];
                this.remove_media_list = [];
                await this.$axios.post(`api/resources/fetch_resources/${item.id}`, {}).then(({data}) => {
                    if(data.response){
                        this.selected_id = data.data.id;
                        this.payload.company_id = this.get_selected_company.id;
                        this.payload.title = data.data.title;
                        this.payload.description = data.data.description;
                        this.payload.location = data.data.location;
                        this.payload.resources_type_id = data.data.resources_type_id;
                        data.data.media_info.forEach((item) => {
                            // console.log(item);
                            this.media_list.push({
                                id: this.counter+=1,
                                primary: item.primary,
                                url: item.media.url,
                                file: item.media.id,
                                new: false
                            }); 
                        });
                        this.dialog_title = 'Edit resource';
                        this.dialog  = true;
                        this.new_resource_media = false;
                    }
                    else{
                        console.log('Something went wrong!');
                    }
                });
            }
        },
        async UpdateResources(){
            if(this.FormValidation()==0){
                let count = 0;
                let remove_count = 0;
                let formdata = new FormData();
                formdata.append('id', this.selected_id);
                formdata.append('company_id', this.payload.company_id);
                formdata.append('title', this.payload.title);
                formdata.append('resources_type_id', this.payload.resources_type_id);
                formdata.append('description', this.payload.description);
                formdata.append('location', this.payload.location);
                this.media_list.forEach((value) => {
                    formdata.append('media' + count, value.file);
                    if(value.primary){
                        formdata.append('media_primary', count);
                    }
                    count++;
                });
                this.remove_media_list.forEach((value) => {
                    console.log(value);
                    formdata.append('remove_media' + remove_count, value.file);
                    remove_count++;
                });
                formdata.append('counter', count);
                formdata.append('remove_counter', remove_count);
                await this.$axios.post('api/resources/update_resources', formdata).then(({data}) => {
                    if(data.response){
                        this.$store.dispatch('resources/fetch_resources_info', this.get_selected_company.id);
                        this.$store.dispatch('resources/fetch_media_info', this.get_selected_company.id);
                        this.dialog = false;
                    }
                    else{
                        console.log('Something went wrong!');
                    }
                });
            }
        },
        emptyPayload(){
            this.payload.title = '';
            this.payload.description = '';
            this.payload.location = '';
            this.payload.resources_type_id = 1;
        }
    },
    watch: {
    }
}
</script>

<style scoped>
.card {
    max-width: 100%;
    background-color: white;
    margin: 25px 15px;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.card .card-header {
    font-size: 24px;
    color: #343642;
    margin-bottom: 15px;
}
.card .card-header-items{
    text-align: center;
}
.card .card-header-items .header-items{
    margin: 0px 10px;
}
.media-instruction h1 {
    margin-bottom: 10px;
}
.media-instruction p {
    font-size: 11px;
}
.media-button {
    text-align: center;
}
.media-library-button {
    text-align: right;
}
.media-button .input-media {
    /* color: rgb(82, 90, 104); */
    display: none;
}
.media-library-button .input-media {
    /* color: rgb(82, 90, 104); */
    display: none;
}
.action-button {
    margin: 0px 17px 10px 17px;
}
.preview-image-container {
    position: relative;
}
.preview-image-container .preview-image {
    max-width: 240px;
}
.preview-image-container .preview-image-actions {
    display: flex;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    gap: 5px;
}
.preview-image-container:hover .preview-image {
  opacity: 0.3;
}
.preview-image-container:hover .preview-image-actions {
  opacity: 1;
}
.filtertabs-wrapper {
    max-width: 100%;
    margin-top: 18px;
}
.media-image:hover {
    cursor: pointer;
    box-shadow: 0px 1px 5px 5px rgba(169, 169, 169, .6);
}
.dialog-close {
    margin-left: -75px; 
    position: absolute;
}
@media only screen and (max-width: 959px) {
    .card .card-header {
        text-align: center;
    }
    .card .card-header-items .header-items{
        margin: 5px 5px 10px 5px;
    }
}
</style>