<template>
    <v-container>
        <v-row v-for="build in builds" :key="build.id">
            <v-col cols="12" v-if="build && build.supplier && build.options.length > 0">
                <v-row v-for="(option, index) in build.options" :key="index">
                    <v-col cols="4" v-if="option">
                        <v-img v-if="!option.use_product_type_image && option.image != null"
                            :src="api_url + 'media/suppliers/services/' + option.image"></v-img>
                        <v-img v-else-if="option.product_type != null"
                            :src="api_url + 'media/suppliers/product_types/' + option.product_type.image"></v-img>
                        <v-img v-else :src="api_url + 'media/suppliers/images/' + build.supplier.image"></v-img>
                    </v-col>
                    <v-col cols="8" v-if="build.options">
                        <div class="supplier-title">
                            {{ build.supplier.supplier_name + ' — ' + option.name }}
                            <div style="display:flex;">
                                <div class="sublabel" v-if="build.day">DAY — {{ build.day }}</div>
                                <div class="supplier-dates" v-else>
                                    <i>No date selected</i>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn class="details" color="#3988AA" text @click="changeStep(step)">SEE MORE
                            DETAILS</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [
        'accommodation', 'builds', 'step'
    ],
    data: () => ({
        api_url: null,
    }),
    async mounted() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    created() {
    },
    computed: {
    },
    methods: {
        changeStep(step) {
            this.$emit('changeStep', step)
        }
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

.v-input__slot {
    background: white !important;
}

.v-stepper__header {
    box-shadow: none !important;
}

.accom-price {
    color: #FAAF3A;
    font-size: 36px;
    font-weight: 600;
}

.custom-title {
    font-family: Manrope;
    font-size: 14px !important;
    color: #3988AA;
    font-weight: 600;
}

.supplier-title {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.supplier-dates {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #000000;
}

.sublabel {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.details {
    font-family: Manrope;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px !important;
}

.description {
    color: #7D7D7D;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.summary {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

.back-to-leads {
    color: var(--Blue-Linear, #3988AA);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
}

:deep(.custom-text-field) {
    label {
        top: 10px !important;
    }

    .v-text-field__slot {
        top: 6px;
    }

    .v-label--active {
        transform: translateY(-10px) scale(0.75) !important;
    }

    legend {
        display: none;
    }

    fieldset {
        border-color: #D3D3D3 !important;
    }

    input {
        color: #101010;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
}

:deep(.custom-stepper-header) {
    display: contents;
    flex-wrap: inherit;

    .v-stepper__step--inactive span {
        background: #AFCFDC !important;
    }
}

.custom-step-active {
    color: var(--Blue-Linear, #3988AA);
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-shadow: none !important;
}

.custom-step-inactive {
    color: #AFCFDC;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-shadow: none !important;
}

.custom-expansion-panels {
    max-height: 500px;
    overflow-y: scroll;
    padding-right: 9px;
    padding-bottom: 300px;
}

.custom-expansion-panel::before {
    box-shadow: none !important;
}

.custom-expansion-panel::after {
    border-top: none !important;
}

.v-expansion-panel--active,
.v-expansion-panel--active+.v-expansion-panel {
    margin-top: 0px !important;
}

.v-expansion-panel-header--active {
    border-radius: 10px 10px 0px 0px !important;
    min-height: 0px !important;
}

:deep(.custom-expansion-panel-header) {
    border-radius: 10px;
    background: var(--Blue-Linear, #3988AA);
    color: var(--icon-color, #FFF);
    font-family: Manrope;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    .v-icon {
        color: white !important;
    }
}

.custom-expansion-panel-content {
    font-family: Manrope;
    padding: 30px;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid var(--Border, #E9E9E9);
    background: var(--icon-color, #FFF);
    box-shadow: 5px 5px 13px 0px rgba(240, 240, 240, 0.90), -5px -5px 10px 0px rgba(255, 255, 255, 0.90), 5px -5px 10px 0px rgba(240, 240, 240, 0.20), -5px 5px 10px 0px rgba(240, 240, 240, 0.20);
}

.v-expansion-panel-content__wrap {
    padding: 0px !important;
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 226px;
}
</style>