import Email from "../EmailComponent.vue";

export default [
  {
    path: "/accept/:token",
    name: "/accept",
    meta: { layout: 'blank' },
    component: Email,
  },
  {
    path: '/cancel/:token',
    name: '/cancel',
    meta: { layout: 'blank' },
    component: Email
  }
];
