import Contact from "../ContactComponent.vue";
import CreateContact from "../CreateContact.vue";

export default [
  {
    path: "/contact",
    name: "/contact",
    meta: { layout: 'mystagit' },
    component: Contact,
  },
  {
    path: "/create-contact",
    name: "/create-contact",
    meta: { layout: 'mystagit' },
    component: CreateContact,
  },
  {
    path: "/contact/setup",
    name: "/contact/setup",
    meta: { layout: 'mystagit' },
    component: () => import("../SetupContact.vue"),
  },
];
