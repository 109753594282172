<template>
    <v-container fluid>
        <v-app-bar clipped-right flat class="main-app-bar">
            <v-app-bar-nav-icon @click.stop="drawer = true"
                v-if="this.$vuetify.breakpoint.mdAndDown"></v-app-bar-nav-icon>
            <v-app-bar-title>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="main-app-bar-title">{{ getPageName }}</v-list-item-title>
                        <v-list-item-subtitle class="main-app-bar-subtitle">Last Update: {{ new Date().toString()
                            }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-app-bar-title>
            <v-spacer></v-spacer>
            <v-btn text fab small color="default">
                <v-icon>mdi-bell-badge-outline</v-icon>
            </v-btn>
            <v-menu v-model="profile_menu" :close-on-content-click="false" :nudge-width="100"
                transition="slide-x-transition" bottom right rounded>
                <template v-slot:activator="{on, attrs}">
                    <v-btn text fab small color="default" v-bind="attrs" v-on="on">
                        <v-icon>mdi-account-circle-outline</v-icon>
                    </v-btn>
                </template>
                <ProfileMenu></ProfileMenu>
            </v-menu>
        </v-app-bar>
        <v-divider class="ml-4"></v-divider>
        <Sidebar :drawer="drawer" @closeDrawer="closeDrawer()"></Sidebar>
        <main class="main">
            <slot></slot>
        </main>
    </v-container>
</template>

<script>
import Sidebar from './Sidebar.vue'
import ProfileMenu from './ProfileMenu.vue';
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        profile_menu: false,
        drawer: false,
    }),
    components: {
        Sidebar,
        ProfileMenu
    },
    computed: {
        ...mapGetters({
            getPageName:    'pageNameStore/getPageName'
        })
    },
    methods: {
        closeDrawer() {
            this.drawer = false
        }
    }
}
</script>

<style lang="scss">

.v-app-bar-title__content {
    width: 50% !important;
}

.main-app-bar {
    background-color: #FFF !important;
}

.main-app-bar-title {
    font-family: 'Manrope';
    font-size: 24px;
    font-weight: bolder !important;
}

.main-app-bar-subtitle {
    font-size: 10px;
}

.v-menu__content {
    box-shadow: none;
}
</style>