<template>
    <v-container fluid>
        <v-card>
            <v-card-text>
                <v-btn class="custom-buttons mx-3 my-3" style="height:100px;width:200px;"
                    v-for="(button, index) in buttons" :key="index" @click="openSetting(button.action)">
                    <div><v-icon size="50">{{ button.icon }}</v-icon></div>
                    <br>
                    <div>{{ button.name }}</div>
                </v-btn>
            </v-card-text>
        </v-card>
        <CompanySetting :show_company_dialog="show_company_dialog" @closeCompanyDialog="show_company_dialog = false" />
        <Locations :show_locations_dialog="show_locations_dialog"
            @closeLocationsDialog="show_locations_dialog = false" />
        <EventTypes :show_event_types_dialog="show_event_types_dialog"
            @closeEventTypesDialog="show_event_types_dialog = false" />
        <ProfitMargins :show_profit_margins_dialog="show_profit_margins_dialog"
            @closeProfitMarginsDialog="show_profit_margins_dialog = false" />
        <EmailTemplates :show_templates_dialog="show_templates_dialog"
            @closeEmailTemplatesDialog="show_templates_dialog = false" />
        <Reminders :show_reminders_dialog="show_reminders_dialog"
            @closeRemindersDialog="show_reminders_dialog = false" />
        <PaymentSettings :show_payment_settings_dialog="show_payment_settings_dialog"
            @closePaymentSettingsDialog="show_payment_settings_dialog = false"></PaymentSettings>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CompanySetting from './components/CompanySetting.vue'
import Locations from './components/Locations.vue'
import EventTypes from './components/EventTypes.vue'
import ProfitMargins from './components/ProfitMargins.vue'
import EmailTemplates from './components/EmailTemplates.vue'
import Reminders from './components/Reminders.vue'
import PaymentSettings from './components/PaymentSettings.vue'

export default {
    components: {
        CompanySetting, Locations, EventTypes, ProfitMargins, EmailTemplates, Reminders,
        PaymentSettings
    },
    props: [
    ],
    data: () => ({
        buttons: [
            { name: 'Company Settings', icon: 'mdi-cog', action: 'show_company_dialog' },
            { name: 'Locations', icon: 'mdi-earth', action: 'show_locations_dialog' },
            { name: 'Event Types', icon: 'mdi-ticket-confirmation-outline', action: 'show_event_types_dialog' },
            { name: 'Profit Margins', icon: 'mdi-chart-box-outline', action: 'show_profit_margins_dialog' },
            { name: 'Supplier Email Templates', icon: 'mdi-email-outline', action: 'show_templates_dialog' },
            { name: 'Reminders', icon: 'mdi-calendar', action: 'show_reminders_dialog' },
            { name: 'Payment Settings', icon: 'mdi-account-credit-card', action: 'show_payment_settings_dialog'}
        ],
        show_company_dialog: false,
        show_locations_dialog: false,
        show_event_types_dialog: false,
        show_profit_margins_dialog: false,
        show_templates_dialog: false,
        show_reminders_dialog: false,
        show_payment_settings_dialog: false,
    }),
    created() {
        this.setPageName("Company Settings")
    },
    mounted() {
    },
    computed: {
        ...mapGetters({
            get_companies: 'auth/get_companies',
            get_user: 'auth/get_user',
        })
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction'
        }),
        openSetting(item) {
            switch (item) {
                case 'show_company_dialog': {
                    this.show_company_dialog = true
                    break;
                }
                case 'show_locations_dialog': {
                    this.show_locations_dialog = true
                    break;
                }
                case 'show_event_types_dialog': {
                    this.show_event_types_dialog = true
                    break;
                }
                case 'show_profit_margins_dialog': {
                    this.show_profit_margins_dialog = true
                    break;
                }
                case 'show_templates_dialog': {
                    this.show_templates_dialog = true
                    break;
                }
                case 'show_reminders_dialog': {
                    this.show_reminders_dialog = true
                    break;
                }
                case 'show_payment_settings_dialog': {
                    this.show_payment_settings_dialog = true
                    break;
                }
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    display: block;
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}
</style>