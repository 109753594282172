<template>
    <v-card outlined>
        <v-list-item>
            <v-list-item-avatar color="primary">
                <v-icon color="white">mdi-account-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ get_user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ get_user.email }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list flat>
            <v-list-item link>
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Test Button</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn outlined block color="error" @click="logout_fn()">
                <v-icon class="mr-2">mdi-logout</v-icon>Logout
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data: () => ({

    }),
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        })
    },
    methods: {
        async logout_fn() {
            await this.$axios.post('/logout')
            this.$store.commit('auth/set_empty_data')
            this.$router.push({ name: '/' })
        }
    }
}
</script>