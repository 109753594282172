<template>
    <v-card outlined class="card-format">
        <v-card-title class="mb-5 custom-title">
            REVIEW QUOTE
        </v-card-title>
        <v-card-text>
            <div v-for="(builds, index) in all_builds[0]" :key="index">
                <v-row v-if="builds && builds.supplier">
                    <v-col cols="4" class="review-quote-left-text">
                        ACCOMMODATION
                    </v-col>
                    <v-col cols="6" class="review-quote-right-text">
                        {{ builds.supplier.supplier_name }} - {{ $date(builds.end_date).diff(builds.start_date, 'day')
                        }} night(s)
                    </v-col>
                    <v-col cols="2" class="review-quote-right-text text-right">
                        <div v-if="builds.options">
                            {{ get_selected_company.currency }}{{ builds.options[0].customer_price *
                            $date(builds.end_date).diff(builds.start_date, 'day') }}
                        </div>
                    </v-col>
                </v-row>
                <hr class="custom-hr" v-if="builds && builds.supplier">
            </div>

            <div v-for="(quote, quote_index) in quotes" :key="'quote' + quote_index">
                <div v-for="(builds, index) in all_builds[quote_index + 1]" :key="index">
                    <v-row v-if="builds && builds.supplier && builds.options.length > 0">
                        <v-col cols="4" class="review-quote-left-text">
                            {{ quote }}
                        </v-col>
                        <v-col cols="8">
                            <v-row v-for="(option, option_index) in builds.options" :key="option_index">
                                <v-col cols="8" class="review-quote-right-text">
                                    {{ builds.supplier.supplier_name + ' — ' + option.name }}
                                </v-col>
                                <v-col cols="4" class="review-quote-right-text text-right">
                                    <div v-if="option">
                                        {{ get_selected_company.currency }}{{ option.customer_price }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <hr class="custom-hr" v-if="builds && builds.supplier && builds.options.length > 0">
                </div>
            </div>

            <v-row class="mt-10 mb-5">
                <v-col cols="12" class="text-right">
                    <div class="d-flex" style="justify-content:end;">
                        <div class="quote-total mr-15">SUBTOTAL:</div>
                        <div class="accom-price">{{ get_selected_company.currency }}{{ details.subtotal = getSubTotal() }}</div>
                    </div>
                </v-col>
            </v-row>

            <v-row class="mt-10 mb-5 quote-total" v-if="details.subtotal">
                <v-col cols="4"></v-col>
                <v-col cols="8">
                    <div class="mb-2">Discount</div>
                    <v-row>
                        <v-col cols="6">
                            <v-select v-model="details.discount_type" label="Type" :items="['amount', 'percent']" filled
                                outlined dense>
                                <template v-slot:selection="{ item }">
                                    <b>{{ item }}</b>
                                </template>
                                <template v-slot:item="{ item }">
                                    <b>{{ item }}</b>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="details.discount" type="number" min="0" label="Amount" filled
                                outlined dense></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="8">
                    <div class="mb-2">Surcharge</div>
                    <v-row>
                        <v-col cols="6">
                            <v-select v-model="details.surcharge_type" label="Type" :items="['amount', 'percent']"
                                filled outlined dense>
                                <template v-slot:selection="{ item }">
                                    <b>{{ item }}</b>
                                </template>
                                <template v-slot:item="{ item }">
                                    <b>{{ item }}</b>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="details.surcharge" type="number" min="0" label="Amount" filled
                                outlined dense></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="4">
                    Profit Margin for {{ lead.location.name }} {{ lead.event_type.name }}:
                </v-col>
                <v-col cols="4" v-if="selected_profit_margin[0]">
                    <div v-if="all_builds[0]">
                        {{ get_selected_company.currency }}{{ selected_profit_margin[0].accommodation_margin }}
                    </div>
                    <div v-else>
                        {{ get_selected_company.currency }}{{ selected_profit_margin[0].activity_only_margin }}
                    </div>
                </v-col>
            </v-row>

            <v-row class="mt-10 mb-5" v-if="details.subtotal">
                <v-col cols="12" class="text-right">
                    <div class="d-flex" style="justify-content:end;">
                        <div class="quote-total mr-15">TOTAL:</div>
                        <div class="accom-price">{{ get_selected_company.currency }}{{ details.total = getTotal() }}</div>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="per-person">PER PERSON</div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="custom-buttons">
            <v-btn color="#F7F7F7" @click="back()">BACK</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="#252525" dark @click="update()" v-if="details.subtotal && this.edit_quote">FINISH</v-btn>
            <v-btn color="#252525" dark @click="finish()" v-else-if="details.subtotal">FINISH</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Settings from '@/modules/admin/settings/modules/settings.js'
export default {
    components: {
    },
    props: [
        'all_builds', 'edit_quote', 'lead'
    ],
    data: () => ({
        details: {
            type: 'tailormade',
            subtotal: 0,
            discount_type: 'amount',
            discount: 0,
            surcharge_type: 'amount',
            surcharge: 0,
            total: 0,
        },
        quotes: [
            'DAY ACTIVITIES', 'NIGHT ACTIVITIES', 'DINNER', 'FINGERFOOD', 'TRANSPORT', 'ADDONS'
        ],
        classes: {
            settings: null,
        },
        selected_profit_margin: [],
    }),
    async mounted() {
        this.getEditData()
        this.classes.settings = new Settings()
        this.getProfitMargins()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        }),
    },
    methods: {
        getEditData() {
            if (this.edit_quote) {
                this.details.type = this.edit_quote.type
                this.details.subtotal = this.edit_quote.subtotal
                this.details.discount_type = this.edit_quote.discount_type
                this.details.discount = this.edit_quote.discount
                this.details.surcharge_type = this.edit_quote.surcharge_type
                this.details.surcharge = this.edit_quote.surcharge
                this.details.total = this.edit_quote.total
            }
        },
        async getProfitMargins() {
            const result = await this.classes.settings.getProfitMargins({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.selected_profit_margin = result.data.filter((item) => {
                    return item.event_type_id == this.lead.event_type_id && item.location_id == this.lead.location_id
                })
            }
        },
        back() {
            this.$emit('back')
        },
        finish() {
            this.$emit('finish', this.details)
        },
        update() {
            this.$emit('update', this.details)
        },
        getSubTotal() {
            if (this.all_builds) {
                let subtotal = 0
                this.all_builds.forEach(builds => {
                    builds.forEach(build => {
                        let rate = (build && build.options) ? build.options.reduce((a, b) => a + b.customer_price, 0) : 0
                        if (build.supplier.service_type_id == 1) {
                            rate *= this.$date(build.end_date).diff(build.start_date, 'day')
                        }
                        subtotal += rate
                    })
                })
                return subtotal.toFixed(2)
            }
            else {
                return 0
            }
        },
        getTotal() {
            let discount = 0
            let surcharge = 0
            let margin = 0
            if (this.details.discount_type == 'amount' && this.details.discount != '' && this.details.discount != null) {
                discount = this.details.discount
            }
            else if (this.details.discount_type == 'percent' && this.details.discount != '' && this.details.discount != null) {
                discount = (this.details.discount / 100) * this.details.subtotal
            }
            if (this.details.surcharge_type == 'amount' && this.details.surcharge != '' && this.details.surcharge != null) {
                surcharge = this.details.surcharge
            }
            else if (this.details.surcharge_type == 'percent' && this.details.surcharge != '' && this.details.surcharge != null) {
                surcharge = (this.details.surcharge / 100) * this.details.subtotal
            }

            if (this.selected_profit_margin[0]) {
                if (this.all_builds[0]) {
                    margin = this.selected_profit_margin[0].accommodation_margin
                }
                else {
                    margin = this.selected_profit_margin[0].activity_only_margin
                }
            }

            let grand_total = this.getSubTotal() - parseFloat(discount) + parseFloat(surcharge) + parseFloat(margin)
            return grand_total.toFixed(2)
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

:deep(.v-input__slot) {
    background: white !important;
}

:deep(.v-stepper__header) {
    box-shadow: none !important;
}

.accom-price {
    color: #FAAF3A;
    font-size: 36px;
    font-weight: 600;
}

.custom-title {
    font-family: 'Manrope';
    font-size: 14px !important;
    color: #3988AA;
    font-weight: 600;
}

.sublabel {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description {
    color: #7D7D7D;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.summary {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

.review-quote-left-text {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #7C7C7C;
}

:deep(.custom-buttons span) {
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
    width: 58px;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.review-quote-right-text {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #101010;
}

.custom-hr {
    border: 0.5px solid #D3D3D3;
    height: 0.5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.quote-total {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    color: #101010;
}

.per-person {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #7C7C7C;
}
</style>