import Vue from "vue";
// import dayjs from 'dayjs';

export default {
  namespaced: true,
  state: {
    rates: [],
    switch_rate: true,
    edit_rates_obj: {}
  },
  mutations: {
    set_rates(state, payload){
        state.rates = [...payload]
    },
    set_edit_rates_obj(state, payload){
        state.edit_rates_obj = payload
    },
    TOGGLE_EXPIRE_RATE(state, payload) {
      state.switch_rate = payload;
    }
  },
  getters: {
    get_rates : (state) => {
      // const current_date = dayjs();
      function recursiveFunc(arr) {
        for(const item of arr) {
          if(Array.isArray(item)) {
            recursiveFunc(item);
          } else if (typeof item === 'object' && Object.keys(item).length > 0) {
            const endDate = new Date(item.end_date);
            const today = new Date();

            if(endDate > today) {
              item.expired = false;
            } else {
              item.expired = true;
            }
          }
        }
      }
      const result = state.rates.map((a) => a.supplieroption.map((b) => b.supplieroptionrates));
      recursiveFunc(result);

      return state.rates;
      
    },
    get_edit_rates_obj : (state) => state.edit_rates_obj
  },
  actions: {
    async fetch_rates({commit}, payload){
        await Vue.axios.get(`api/suppliers/rate/get_supplier_rate/${payload}`)
        .then(({data}) => {
            commit('set_rates', data.data)
        })
    },
    assign_edit_rates_obj({commit}, payload){
        commit('set_edit_rates_obj', payload)
    },
    async edit_rate_api_call(_, payload){
        await Vue.axios.patch('api/suppliers/products/edit_rates', payload)
    },
    toggle_expire_rate({ commit }, payload) {
      commit('TOGGLE_EXPIRE_RATE', payload);
    }
  }
}