<template>
    <v-container
        fluid
    >
        <v-card
            elevation="0"
            class="mb-15"
        >
            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                grow
            >
                <v-tab
                    v-for="(item, index) in items"
                    :key="index"
                    :href="`#tab-` + index"
                >
                    {{ item }}
                </v-tab>
            </v-tabs>
            <v-tabs-items
                v-model="tab"
            >
                <v-tab-item
                    value="tab-0"
                >
                <v-skeleton-loader type="table" v-if="get_itinerary_preview[0].id != id"></v-skeleton-loader>
                    <v-card v-else>
                        <v-card-title>
                            <v-spacer/>
                            <v-btn
                                rounded
                                color="primary"
                                class="pt-4 pb-4 ml-2"
                                style="text-transform: unset !important;"
                                @click="refresh_list()"
                            >
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-refresh
                                </v-icon>
                                Refresh Pricing
                            </v-btn>
                            <v-btn
                                rounded
                                color="primary"
                                class="pt-4 pb-4 ml-2"
                                style="text-transform: unset !important;"
                                @click="download_to_pdf()"
                            >
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-download
                                </v-icon>
                                Download to pdf
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div id="preview-to-pdf">
                                <v-card
                                    elevation="1"
                                    class="mb-15"
                                >
                                    <v-card-title>
                                        <v-img
                                            :src="`${get_itinerary_preview[0].company_logo_base64}`"
                                            v-if="get_itinerary_preview[0].company_logo_base64"
                                            aspect-ratio="3"
                                        />
                                    </v-card-title>
                                    <v-card-title>
                                        <h1
                                            style="font-weight: 700; font-size:20px;"
                                        >
                                            {{ get_itinerary_preview[0].itinerary_name }}
                                        </h1>
                                        <v-spacer/>
                                        <label
                                            style="font-weight: 500; font-size: 14px;"
                                        >
                                            {{ $date(get_itinerary_preview[0].start_date).format('DD MMM') }} - {{ get_itinerary_preview[0].end_date }}
                                        </label>
                                    </v-card-title>
                                </v-card>
                                <v-card
                                    elevation="1"
                                    class="mb-15"
                                >
                                    <Outline
                                        :data="get_itinerary_preview[0].get_builds"
                                    />
                                </v-card>
                                <v-card
                                    elevation="1"
                                    class="mb-15"
                                >
                                    <Iitinerary
                                        :data="get_itinerary_preview[0].get_builds"
                                    />
                                </v-card>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item
                    value="tab-1"
                >
                    <v-skeleton-loader type="table" v-if="get_itinerary_preview[0].id != id"></v-skeleton-loader>
                    <v-card v-else>
                        <v-card-title>
                            <v-spacer/>
                            <v-btn
                                rounded
                                color="primary"
                                class="pt-4 pb-4 ml-2"
                                style="text-transform: unset !important;"
                                @click="refresh_list()"
                            >
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-refresh
                                </v-icon>
                                Refresh Pricing
                            </v-btn>
                            <v-btn
                                rounded
                                color="primary"
                                class="pt-4 pb-4 ml-2"
                                style="text-transform: unset !important;"
                                @click="download_to_pdf_summary()"
                            >
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-download
                                </v-icon>
                                Download summary to pdf
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div id="preview-to-pdf-summary">
                                <Summary
                                    :data="get_itinerary_preview[0].get_builds"
                                    :company_logo="get_itinerary_preview[0].company_logo_base64"
                                />
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

import Outline from './components/Outline.vue'
import Iitinerary from './components/itinerary.vue'
/**
 * summary
 */
import Summary from './components/Summary.vue'
import html2pdf from "html2pdf.js";
export default {
  components: {
    Outline,
    Iitinerary,
    Summary
  },
  props: [
    'id'
  ],
  data () {
    return {
        items: [
            'Outline',
            'Summary'
        ],
        tab: null,
    }
  },
  async mounted () {
    await this.$store.dispatch(
      "itinerary/fetch_itinerary_preview",
      this.id
    );
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_itinerary_preview:              'itinerary/get_itinerary_preview',
        get_itinerary_build_config:         'itinerary/get_itinerary_build_config',
        get_group_name:                     'itinerary/get_group_name'
    })
  },
  methods: {
    async refresh_list(){
        await this.$store.dispatch(
            "itinerary/fetch_itinerary_preview",
            this.id
        );
    },
    download_to_pdf(){
        const opt = {
            margin:       1,
            filename:     this.get_group_name + ".pdf",
            image:        { type: 'jpeg', quality: 1.0 },
            html2canvas:  { scale: 2, useCORS: true },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'landscape' }
        }
        html2pdf().set(opt).from(document.getElementById("preview-to-pdf")).save();
    },
    download_to_pdf_summary(){
        const opt = {
            margin:       1,
            filename:     this.get_group_name + ".pdf",
            image:        { type: 'jpeg', quality: 1.0 },
            html2canvas:  { scale: 2, useCORS: true },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'landscape' }
        }
        html2pdf().set(opt).from(document.getElementById("preview-to-pdf-summary")).save();
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>