<template>
    <v-card outlined class="card-format">
        <v-card-title class="mb-5 custom-title">
            ACCOMMODATION
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-data-table :items="getSuppliersAccommodation" :headers="accommodation_headers" hide-default-footer
                        disable-pagination fixed-header height="500">
                        <template v-slot:item="{ item }">
                            <tr @click="selected_supplier = item">
                                <td><input type="radio" :checked="selected_supplier == item"></td>
                                <td>{{ item.supplier_name }}</td>
                                <td>{{ item.nights }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="custom-buttons">
            <v-btn color="#F7F7F7" @click="back()">BACK</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="#252525" :dark="getSuppliersAccommodation.length > 0 && selected_supplier != null"
                    :disabled="(getSuppliersAccommodation.length > 0 && selected_supplier != null) ? false : true" @click="save()">SAVE</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    components: {
    },
    props: [
        'lead', 'date_range', 'suppliers', 'edit_build'
    ],
    data: () => ({
        accommodation_headers: [
            { text: '', sortable: false },
            { text: 'SUPPLIER', value: 'supplier_name', width: '80%' },
            { text: 'TYPE', value: 'type', width: '20%' },
        ],
        selected_supplier: null,
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
        getSuppliersAccommodation() {
            return this.suppliers.filter((item) => {
                return item.service_type_id === 1
            })
        },
    },
    methods: {
        back() {
            this.$emit('back')
        },
        save() {
            this.$emit('save', this.selected_supplier, 1)
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

:deep(.v-input__slot) {
    background: white !important;
}

:deep(.v-stepper__header) {
    box-shadow: none !important;
}

.accom-price {
    color: #FAAF3A;
    font-size: 36px;
    font-weight: 600;
}

.custom-title {
    font-family: 'Manrope';
    font-size: 14px !important;
    color: #3988AA;
    font-weight: 600;
}

.sublabel {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description {
    color: #7D7D7D;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.summary {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

:deep(.custom-buttons span) {
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
    width: 58px;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}
</style>