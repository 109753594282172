<template>
    <v-container fluid>
        <div class="header mt-12">
            <div class="mx-4">
                <v-tabs v-model="tab" fixed-tabs>
                <v-tab href="#tab-1"> Setup </v-tab>
                <v-tab href="#tab-2" :disabled="get_itinerary_action.is_disabled">
                    Tasks
                </v-tab>
                <v-tab href="#tab-3" :disabled="get_itinerary_action.is_disabled">
                    Build
                </v-tab>
                <v-tab href="#tab-4" :disabled="get_itinerary_action.is_disabled">
                    Pricing
                </v-tab>
                <v-tab href="#tab-5" :disabled="get_itinerary_action.is_disabled">
                    Bookings
                </v-tab>
                <v-tab href="#tab-6" :disabled="get_itinerary_action.is_disabled">
                    Payments
                </v-tab>
                <v-tab href="#tab-7" :disabled="get_itinerary_action.is_disabled">
                    Preview
                </v-tab>
                </v-tabs>
            </div>
        </div>
        <div>
        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
            <v-card flat>
                <Setup
                :action="get_itinerary_action.action"
                :id="get_itinerary_action.id"
                />
            </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-2">
            <v-card flat> <Task :id="get_itinerary_action.id"></Task></v-card>
            </v-tab-item>
            <v-tab-item value="tab-3">
            <v-card flat> <Build :id="get_itinerary_action.id" /></v-card>
            </v-tab-item>
            <v-tab-item value="tab-4">
            <v-card flat> <Pricing :id="get_itinerary_action.id"/></v-card>
            </v-tab-item>
            <v-tab-item value="tab-5">
            <v-card flat> <Booking :id="get_itinerary_action.id"/></v-card>
            </v-tab-item>
            <v-tab-item value="tab-6">
            <v-card flat> <Payments :id="get_itinerary_action.id"/></v-card>
            </v-tab-item>
            <v-tab-item value="tab-7">
            <v-card flat> <Preview2 :id="get_itinerary_action.id"/></v-card>
            </v-tab-item>
        </v-tabs-items>
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Setup from "../setup/Setup.vue";
import Task from "../tasks/Tasks.vue";
import Build from "../build_two/BuildTwo.vue"
import Pricing from '../pricing/Pricing.vue'
import Booking from '../booking/Booking.vue'
import Payments from '../payments/Payments.vue'
// import Preview from '../preview/Preview.vue'
import Preview2 from '../preview/Preview2.vue'
// import ErrMessage from '../errmessage/ErrMessage.vue';
// import Validation from '../../common/validation';

export default {
  name: "Initial",
  components: {
    // ErrMessage
    Setup,
    Task,
    Build,
    // Preview,
    Preview2,
    Pricing,
    Booking,
    Payments,
  },
  data: () => ({
    show_archived: false,
    menu: false,
    tab: null,
    headers: [
      { text: "", value: "initial" },
      { text: "Title", value: "title" },
      { text: "Dates", value: "dates" },
      { text: "Confirm", value: "confirm" },
      { text: "Status", value: "status" },
      { text: "Template", value: "template" },
      { text: "Created", value: "created_at" },
      { text: "", value: "alert" },
      { text: "", value: "action" },
    ],
  }),
  async mounted() {
    // await this.$store.dispatch('task/fetch_task_info', this.get_selected_company.id);
    // await this.$store.dispatch('task/fetch_task_type');
    // await this.$store.dispatch('task/fetch_priority_type');
    // await this.$store.dispatch('task/fetch_user_info');
  },
  created() {},
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
      get_itinerary_action: "itinerary/get_itinerary_action",
    }),
    // get_user_info(){
    //     return this.$store.getters['task/get_user_info'](this.selected_assigness)
    // },
    // get_task_info(){
    //     return this.$store.getters['task/get_task_info'](this.filter, this.filter_payload, this.filter_search)
    // }
  },
  methods: {},
  watch: {},
};
</script>

<style scoped>
.header {
  position: sticky;
  z-index: 3;
  top: 0;
}
.header .header-title {
  line-height: 50px;
  background-color: #79a2bd;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
  position: relative;
  z-index: 1;
}
.header .header-title h3 {
  color: white;
  text-align: center;
}
@media only screen and (max-width: 959px) {
}
</style>
