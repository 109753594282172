<template>
    <v-container fluid class="mt-6">
        <v-row>
            <v-col>
                <v-btn text @click="backToLeads()">
                    <div class="back-to-leads d-flex">
                        <v-img src="../../assets/back_arrow.svg" width="20" height="20" class="mr-2"></v-img>
                        Back to Lead
                    </div>
                </v-btn>
                <v-btn text color="red" @click="deleteQuoteDialog()" v-if="edit_quote_id">
                    Delete Quote
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-5" style="padding-left:24px;padding-right:24px;" v-if="lead && loaded">
            <v-col>
                <v-text-field class="custom-text-field-active" v-model="quote_name" outlined round
                    label="QUOTE NAME *"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field class="custom-text-field" v-model="start_date" round label="ARRIVAL"
                    readonly></v-text-field>
            </v-col>
            <v-col>
                <v-text-field class="custom-text-field" v-model="end_date" round label="DEPARTURE"
                    readonly></v-text-field>
            </v-col>
            <v-col>
                <v-text-field class="custom-text-field" v-model="lead.location.name" round label="LOCATION"
                    readonly></v-text-field>
            </v-col>
            <v-col>
                <v-text-field class="custom-text-field" v-model="people" round label="NO. OF PERSON"
                    readonly></v-text-field>
            </v-col>
        </v-row>
        <v-skeleton-loader type="table" v-if="!loaded"></v-skeleton-loader>
        <v-row v-if="loaded">
            <v-col cols="12">
                <v-stepper v-model="selected_step" alt-labels elevation="0">
                    <v-stepper-header>
                        <div v-for="(step, index) in steps" :key="index" class="custom-stepper-header">
                            <v-stepper-step :complete="selected_step > index + 1" :step="index + 1"
                                @click="changeStep(index)" style="cursor:pointer;"
                                :color="(selected_step == index + 1) ? '#3988AA' : '#AFCFDC'">
                                <div
                                    :class="(selected_step == index + 1) ? 'custom-step-active' : 'custom-step-inactive'">
                                    {{ step }}
                                </div>
                            </v-stepper-step>
                            <v-divider v-if="index < steps.length - 1" color="#AFCFDC"></v-divider>
                        </div>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-row>
                            <v-col cols="12" class="pr-9">
                                <PackageList v-if="selected_step == 1" :lead="lead" :date_range="date_range" :package_list="package_list"
                                    :suppliers="all_suppliers" @back="back()" @save="save" />

                                <QuickAccommodation v-if="selected_step == 2" :lead="lead" :date_range="date_range"
                                    :suppliers="all_suppliers" @skip="skip()" @back="back()" @save="save" />

                                <Complete v-if="selected_step == 3" :lead="lead" :quote="created_quote"
                                    @emailQuote="emailQuote()" />
                            </v-col>
                        </v-row>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isBetween from 'dayjs/plugin/isBetween'

import Quotes from '@/modules/admin/quotes/modules/quotes.js'
import Leads from '@/modules/admin/leads/modules/leads.js'
import Packages from '@/modules/admin/packages/modules/packages.js'

import PackageList from './modules/PackageList.vue'
import QuickAccommodation from './modules/QuickAccommodation.vue'
import Complete from './modules/Complete.vue'
export default {
    components: {
        PackageList, QuickAccommodation, Complete
    },
    props: [
    ],
    data: () => ({
        loaded: false,
        edit_quote_id: null,
        quote_to_edit: null,
        delete_quote_dialog: false,
        quote_name: null,
        package_list: [],
        classes: {
            quotes: null,
            leads: null,
            packages: null,
        },
        steps: [
            'Package List', 'Accommodation'
        ],
        selected_step: 1,
        lead: {
            id: null,
            advisor_id: 0,
            duration: 1,
            activity_type: null,
            start_date: null,
            location_id: null,
            location: {
                name: null,
            },
            first_name: null,
            last_name: null,
            phone: null,
            email: null,
            password: null,
            people: 1,
            company_id: null
        },
        date_range: [],
        all_suppliers: [],
        open_panels: [0],
        builds: [],
        selected_package: null,
        selected_accommodation: null,
        created_quote: null,
    }),
    async mounted() {
        this.classes.quotes = new Quotes()
        this.classes.leads = new Leads()
        this.classes.packages = new Packages()
        this.getLeadData()
        this.getEditData()
    },
    created() {
        this.$date.extend(advancedFormat)
        this.$date.extend(isBetween)
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            getLeadsById: 'leadsStore/getLeadsByIdGetter',
        }),
        start_date() {
            return this.$date(this.lead.start_date).format('dddd, MMMM D, YYYY')
        },
        end_date() {
            return this.$date(this.lead.end_date).format('dddd, MMMM D, YYYY')
        },
        people() {
            return this.lead.people + ' persons'
        }
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
        }),
        async emailQuote() {
            const result = await this.classes.quotes.sendEmailQuote(this.created_quote).then((data) => data)
            if (result.response == true) {
                this.$toast.success(result.message, { timeout: 2000, icon: true })
            }
            else {
                this.$toast.error(`${result.status}: ${result.data.message}`)
            }
        },
        async getLeadData() {
            this.lead.id = this.$route.params.lead_id
            const lead_data = await this.classes.leads.getLead({ lead_id: this.lead.id })
            if (lead_data != null) {
                this.lead = lead_data
                this.date_range = this.getDateRange()
                this.getPackages()
                this.getSuppliers()
            }
        },
        async getPackages() {
            let payload = {
                company_id: this.get_selected_company.id,
                location_id: this.lead.location_id,
                type: this.lead.event_type.name == 'Stag Party' ? 'stag' : 'hen',
                nights: this.lead.duration,
            }
            const result = await this.classes.packages.getPackagesByLocation(payload).then((data) => data)
            this.package_list = result.data
            this.loaded = true
        },
        getEditData() {
            this.edit_quote_id = this.$route.params.quote_id
            if (this.edit_quote_id) {
                this.open_panels = [0, 1, 2, 3, 4, 5, 6]
                this.setPageName('Edit Quick Quote')
                this.classes.quotes.getCustomQuote({ quote_id: this.edit_quote_id }).then((data) => {
                    this.quote_to_edit = data
                    this.quote_name = this.quote_to_edit.name

                    this.quote_to_edit.builds.forEach(build => {
                        for (let i = 0; i < 7; i++) {
                            if (build.type_id == i + 1) {
                                if (this.builds[i] == null) {
                                    let edit_build = {
                                        start_date: build.start_date,
                                        end_date: build.end_date,
                                        supplier: build.supplier,
                                        options: [],
                                        rates: [],
                                    }
                                    this.builds[i] = edit_build
                                }
                                this.builds[i].options.push(build.option)
                                this.builds[i].rates.push(build.rate)
                            }
                        }
                    })
                })
            }
            else {
                this.setPageName('Create Quick Quote')
            }
        },
        getDateRange() {
            let number_of_days = -1
            return [...Array(parseInt(this.lead.duration + 1))].map(() => {
                const event_date = new Date(this.lead.start_date)
                number_of_days += 1
                event_date.setDate(event_date.getDate() + number_of_days)
                return event_date.toISOString().substr(0, 10)
            })
        },
        backToLeads() {
            this.$router.push({ name: 'EditLeads', params: { id: this.lead.id } })
        },
        changeStep(index) {
            this.selected_step = index + 1
        },
        skip() {
            this.changeStep(this.selected_step)
        },
        back() {
            this.changeStep(this.selected_step - 2)
        },
        save(data, step) {
            if (step == 0) {
                this.selected_package = data
                this.quote_name = this.selected_package.name
                this.changeStep(this.selected_step)
            }
            if (step == 1) {
                this.selected_accommodation = data
                this.finish()
            }
        },
        async finish() {
            if (this.quote_name == null || this.quote_name == '') {
                this.$toast.error('Please enter a quote name')
                return
            }
            let payload = {
                company_id: this.get_selected_company.id,
                lead_id: this.lead.id,
                name: this.quote_name,
                package: this.selected_package,
                accommodation: this.selected_accommodation,
                date_range: this.date_range,
                type: 'premade',
                groupsize: this.lead.people,
            }
            const result = await this.classes.quotes.createQuickQuote(payload).then((data) => data)
            if (result.response == true) {
                this.$toast.success('Please wait...', { timeout: 2000, icon: true })
                // this.changeStep(this.selected_step)
                setTimeout(() => {
                    this.$router.push({ name: 'EditCustomQuote', params: { lead_id: this.lead.id, quote_id: result.data.id } })
                }, 2000)
            }
            else {
                this.$toast.error(`${result.status}: ${result.data.message}`)
            }
        },
        async update(data) {
            let payload = {
                id: this.edit_quote_id,
                company_id: this.get_selected_company.id,
                lead_id: this.lead.id,
                name: this.quote_name,
                builds: this.builds,
                date_range: this.date_range,
                type: data.type,
                subtotal: data.subtotal,
                discount_type: data.discount_type,
                discount: data.discount,
                surcharge_type: data.surcharge_type,
                surcharge: data.surcharge,
                total: data.total
            }
            const result = await this.classes.quotes.updateCustomQuote(payload).then((data) => data)
            if (result.response == true) {
                this.$toast.success(result.message, { timeout: 2000, icon: true })
                this.changeStep(this.selected_step)
            }
            else {
                this.$toast.error(`${result.status}: ${result.data.message}`)
            }
        },
        deleteQuoteDialog() {
            this.delete_quote_dialog = true
        },
        closeDeleteQuoteDialog() {
            this.delete_quote_dialog = false
        },
        async deleteQuote() {
            const result = await this.classes.quotes.deleteCustomQuote({ quote_id: this.edit_quote_id }).then((data) => data)
            if (result.response == true) {
                this.$toast.success(result.message, { timeout: 2000, icon: true })
                this.backToLeads()
            }
            else {
                this.$toast.error(`${result.data.status}: ${result.data.data.message}`)
            }
        },
        async getSuppliers() {
            let payload = {
                company_id: this.get_selected_company.id,
                location_id: this.lead.location_id
            }
            const result = await this.classes.quotes.getSuppliersByLocation(payload)
            if (result.response == true) {
                this.all_suppliers = result.data
            }
            else {
                this.$toast.error(result.data.message)
            }
        },
        getSuppliersByType(index) {
            return this.all_suppliers.filter((item) => {
                return item.service_type_id === index
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

.v-input__slot {
    background: white !important;
}

.v-stepper__header {
    box-shadow: none !important;
}

.accom-price {
    color: #FAAF3A;
    font-size: 36px;
    font-weight: 600;
}

.custom-title {
    font-family: Manrope;
    font-size: 14px !important;
    color: #3988AA;
    font-weight: 600;
}

.supplier-title {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.supplier-dates {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.sublabel {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
}

.details {
    font-family: Manrope;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px !important;
}

.description {
    color: #7D7D7D;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.summary {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

.back-to-leads {
    color: var(--Blue-Linear, #3988AA);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
}

:deep(.custom-text-field) {
    label {
        top: 10px !important;
    }

    .v-text-field__slot {
        top: 6px;
    }

    .v-label--active {
        transform: translateY(-20px) scale(0.75) !important;
    }

    legend {
        display: none;
    }

    fieldset {
        border-color: #D3D3D3 !important;
    }

    input {
        color: #727272;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
}

:deep(.custom-text-field-active) {
    input {
        color: #101010;
    }
}

:deep(.custom-stepper-header) {
    display: contents;
    flex-wrap: inherit;

    .v-stepper__step--inactive span {
        background: #AFCFDC !important;
    }
}

.custom-step-active {
    color: var(--Blue-Linear, #3988AA);
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-shadow: none !important;
}

.custom-step-inactive {
    color: #AFCFDC;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-shadow: none !important;
}

.custom-expansion-panel::before {
    box-shadow: none !important;
}

.custom-expansion-panel::after {
    border-top: none !important;
}

.v-expansion-panel--active,
.v-expansion-panel--active+.v-expansion-panel {
    margin-top: 0px !important;
}

.v-expansion-panel-header--active {
    border-radius: 10px 10px 0px 0px !important;
    min-height: 0px !important;
}

:deep(.custom-expansion-header) {
    border-radius: 10px;
    background: var(--Blue-Linear, #3988AA);
    color: var(--icon-color, #FFF);
    font-family: Manrope;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    .v-icon {
        color: white !important;
    }
}

.custom-expansion-panel-content {
    font-family: Manrope;
    padding: 30px;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid var(--Border, #E9E9E9);
    background: var(--icon-color, #FFF);
    box-shadow: 5px 5px 13px 0px rgba(240, 240, 240, 0.90), -5px -5px 10px 0px rgba(255, 255, 255, 0.90), 5px -5px 10px 0px rgba(240, 240, 240, 0.20), -5px 5px 10px 0px rgba(240, 240, 240, 0.20);
}

.v-expansion-panel-content__wrap {
    padding: 0px !important;
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 226px;
}
</style>