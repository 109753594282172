<template>
    <v-container fluid>
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-row v-if="!loading">
            <v-col cols="6" height="50">
                <small class="custom-small">EVENT</small>
                <div style="font-size:20px;font-weight:600;">{{ event.name + ' — ' + event.lead.event_type.name }}</div>
                <br>
            </v-col>
            <v-col cols="6" class="custom-buttons" style="text-align:right;">
            </v-col>
        </v-row>
        <v-row class="mt-5" v-if="!loading">
            <v-col cols="12">
                <div style="display:flex;border-bottom:solid 2px #F0F0F0;margin-bottom:-2px;">
                    <div style="position:relative;display:flex;" class="mr-15 mt-1">
                        <v-btn @click="openLead()" text class="custom-buttons">Lead</v-btn>
                    </div>
                    <!-- <div style="position:relative;" class="mr-15">
                        <v-btn @click="openLead()" text class="custom-buttons">Quotes</v-btn>
                    </div> -->
                    <v-tabs v-model="tabs" style="border-bottom:solid 2px #F0F0F0;margin-bottom:-2px;">
                        <v-tab class="custom-tab" href="#tab-settings" @click="setPageName('Settings')">Settings</v-tab>
                        <v-tab class="custom-tab" href="#tab-quotes" @click="setPageName('Quotes')">Quotes</v-tab>
                        <v-tab class="custom-tab" href="#tab-suppliers"
                            @click="setPageName('Suppliers')">Suppliers</v-tab>
                        <v-tab class="custom-tab" href="#tab-itinerary"
                            @click="setPageName('Itinerary')">Itinerary</v-tab>
                        <v-tab class="custom-tab" href="#tab-invitees" @click="setPageName('Invitees')">Invitees</v-tab>
                    </v-tabs>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-tabs-items v-model="tabs">
                    <v-tab-item value="tab-settings">
                        <ManageSettings @refresh="getEventData" />
                    </v-tab-item>
                    <v-tab-item value="tab-quotes">
                        <ManageQuotes @refresh="getEventData" />
                    </v-tab-item>
                    <v-tab-item value="tab-suppliers">
                        <ManageSuppliers @refresh="getEventData" />
                    </v-tab-item>
                    <v-tab-item value="tab-itinerary">
                        <ManageItinerary />
                    </v-tab-item>
                    <v-tab-item value="tab-invitees">
                        <ManageInvitees @refresh="getEventData" />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Events from '../../modules/admin/events/modules/events.js'
import Invitees from '../../modules/admin/invitees/modules/invitees.js'

import ManageSettings from "./ManageSettings.vue"
import ManageQuotes from "./ManageQuotes.vue"
import ManageSuppliers from "./ManageSuppliers.vue"
import ManageItinerary from "./ManageItinerary.vue"
import ManageInvitees from "./ManageInvitees.vue"

export default {
    components: {
        ManageSettings, ManageQuotes, ManageSuppliers, ManageItinerary, ManageInvitees
    },
    data: () => ({
        page_name: "Event",
        loading: true,
        tabs: null,
        classes: {
            events: null,
            invitees: null,
        },
        quote_dialog: false,
    }),
    created() {
        this.setPageName(this.page_name)
        this.classes.events = new Events()
        this.classes.invitees = new Invitees()
    },
    async mounted() {
        this.getEventData()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            event: 'eventsStore/getSelectedEventGetter',
        })
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
            setEvent: 'eventsStore/setSelectedEventAction',
        }),
        async getEventData() {
            const result = await this.classes.events.getEvent({ event_id: this.$route.params.event_id })
            if (result.response == true) {
                this.setEvent(result.data)
                this.loading = false
            }
        },
        openLead() {
            this.$router.push({ name: 'EditLeads', params: { id: this.event.lead.id } })
        },
        openQuoteDialog() {
            this.quote_dialog = true
        },
        closeQuoteDialog() {
            this.quote_dialog = false
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    border-radius: 15px !important;

    .v-data-footer {
        background-color: #E9E9E9;
        color: #7C7C7C;
        font-family: 'Work Sans';
    }

    .v-data-footer__select .v-text-field {
        background-color: white;

        .v-select__slot {
            padding-left: 10px;
        }
    }
}

.custom-small {
    color: #7C7C7C;
    font-size: 12px;
    font-weight: 600;
}

:deep(.theme--light.v-data-table tbody td) {
    height: 80px;
    border: none;
}

.label-text-color {
    color: #7C7C7C;
}

.text-proper-case {
    text-transform: capitalize;
}

.search-text-field {
    margin-top: 15px;

    .v-input__slot {
        min-height: 0 !important;
        margin-top: 8px;
    }

    .v-input__prepend-inner {
        margin-top: 5px !important;
    }
}

:deep(.custom-buttons span),
:deep(.custom-tab) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-tab) {
    color: black;
}

:deep(.custom-buttons button),
:deep(.custom-tab) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.paid_row {
    background-color: #FFF1F1 !important;
}


:deep(.custom-text-field) {
    legend {
        display: none;
    }

    fieldset {
        border-color: #D3D3D3 !important;
    }

    input {
        color: #101010;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
</style>