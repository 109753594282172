import Vue from "vue";
import axioss from "axios";

export default {
  namespaced: true,
  state: {
    suppliers: [],
    supplier_type: [],
    archived_suppliers: [],
    supplier_setup: {},
    media_files: [],
    selected_images: {},
    currencies: [],
    payment_term: [],
    supplier_product: [],
    archived_supplier_product: [],
    supplier_product_type: [],
    supplier_product_setup: [],
    supplier_product_location: [],
    supplier_product_time: [],
    supplier_product_inclusion: [],
    supplier_product_option: [],
    supplier_product_amenity: [],
    supplier_product_amenity_type: [],
    supplier_product_media: [],
    supplier_rates: [],
    /**
     * Author: Edgar Garcia
     * supplier product options edit
     */
    supplier_product_options_edit: {},
    /**
     * Author: Edgar Garcia
     * module: rates view / edit
     */
    rates_view: [],
    selected_service: {},
    edit_rates: {},
    response: {},
    add_option_children_parent_data: {},
    child_data: {},
    child_item_data: [],
    // rate period add child
    rate_period_child: [],

    // author: Bruce James Dela Cruz
    // Aug 14, 2023
    archive_switch: false
  },
  mutations: {
    set_supplier_type(state, payload) {
      state.supplier_type = [...payload];
    },
    set_suppliers(state, payload) {
      state.suppliers = [...payload];
    },
    set_archived_suppliers(state, payload) {
      state.archived_suppliers = [...payload];
    },
    set_supplier_setup(state, payload) {
      state.supplier_setup = payload;
    },
    set_supplier_setup_company(state, payload) {
      state.supplier_setup.contacts = [...payload];
    },
    set_supplier_setup_supplier_type(state, payload) {
      state.supplier_setup.get_supplier_type = payload;
    },
    set_supplier_setup_name(state, payload) {
      state.supplier_setup.supplier_name = payload;
    },
    set_supplier_setup_industry_ratings(state, payload) {
      state.supplier_setup.industry_rating = payload;
    },
    set_media_files(state, payload) {
      state.media_files = [...payload];
    },
    set_selected_images(state, payload) {
      state.selected_images = payload;
    },
    set_currencies(state, payload) {
      state.currencies = [...payload];
    },
    set_payment_term(state, payload) {
      state.payment_term = [...payload];
    },
    set_supplier_product(state, payload) {
      state.supplier_product = [...payload];
    },
    set_supplier_product_setup(state, payload) {
      state.supplier_product_setup = payload;
    },
    set_archived_supplier_product(state, payload) {
      state.archived_supplier_product = [...payload];
    },
    set_supplier_product_type(state, payload) {
      state.supplier_product_type = payload;
    },
    set_supplier_product_location(state, payload) {
      state.supplier_product_location = [...payload];
    },
    set_supplier_product_time(state, payload) {
      state.supplier_product_time = [...payload];
    },
    set_supplier_product_inclusion(state, payload) {
      state.supplier_product_inclusion = [...payload];
    },
    set_supplier_product_option(state, payload) {
      state.supplier_product_option = [...payload];
    },
    set_supplier_product_amenity(state, payload) {
      state.supplier_product_amenity = [...payload];
    },
    set_supplier_product_amenity_type(state, payload) {
      state.supplier_product_amenity_type = [...payload];
    },
    set_supplier_product_media(state, payload) {
      state.supplier_product_media = [...payload];
    },
    set_supplier_rates(state, payload) {
      state.supplier_rates = [...payload];
    },
    set_supplier_product_options_edit(state, payload){
      state.supplier_product_options_edit = payload
    },
    set_empty_supplier_product_options_edit(state, payload){
      state.supplier_product_options_edit = payload
    },
    set_rates_view(state, payload){
      state.rates_view = [...payload];
    },
    set_selected_service(state, payload){
      state.selected_service = payload
    },
    set_edit_rates(state, payload){
      state.edit_rates = payload
    },
    set_response(state, payload){
      state.response = payload
    },
    set_add_option_children_parent_data(state, payload){
      state.add_option_children_parent_data = payload
    },
    set_child_data(state, payload){
      state.child_data = payload
    },
    set_child_item_data(state, payload){
      state.child_item_data = [...payload]
    },
    set_rate_period_child(state, payload){
      state.rate_period_child = payload
    },
    SET_ARCHIVE_SWITCH(state, payload) {
      state.archive_switch = payload;
    }
  },
  getters: {
    get_supplier_type: (state) => state.supplier_type,
    get_suppliers: (state) => (value, archived) => {
      if (archived) {
        return state.archived_suppliers;
      }
      if (value === null) {
        return state.suppliers;
      }
      return state.suppliers.filter((q) => {
        return q.supplier_name.toLowerCase().includes(value);
      });
    },
    get_suppliers_via_value: (state) => (number, value, archived) => {
      if (archived) {
        return state.archived_suppliers.filter((q) => {
          return q.service_type_id == number;
        });
      }
      if (value === null || value === "") {
        return state.suppliers.filter((q) => {
          return q.service_type_id == number;
        });
      }
      return state.suppliers.filter((q) => {
        return (
          q.supplier_name.toLowerCase().includes(value) &&
          q.service_type_id == number
        );
      });
    },
    get_supplier_via_search: (state) => (value) => {
      if (value !== null) {
        return state.suppliers.filter((q) => {
          return q.supplier_name.toLowerCase().includes(value);
        });
      }
    },
    get_supplier_setup: (state) => state.supplier_setup,
    get_media_files: (state) => state.media_files,
    get_selected_images: (state) => state.selected_images,
    get_amenities: (state) => state.supplier_setup.amenities,
    get_supplier_amenities: (state) => state.supplier_setup.real_amenities,
    get_currencies: (state) => state.currencies,
    get_payment_term: (state) => state.payment_term,
    get_supplier_product: (state) => {
      if(!state.archive_switch) {
        return state.supplier_product.filter((a) => a.deleted_at == null);
      } else {
        return state.supplier_product;
      }
      
    },
    get_supplier_product_setup: (state) => state.supplier_product_setup,
    get_archived_supplier_product: (state) => state.archived_supplier_product,
    get_supplier_product_type: (state) => state.supplier_product_type,
    get_supplier_product_location: (state) => state.supplier_product_location,
    get_supplier_product_time: (state) => state.supplier_product_time,
    get_supplier_product_inclusion: (state) => state.supplier_product_inclusion,
    get_supplier_product_option: (state) => state.supplier_product_option,
    get_supplier_product_amenity: (state) => state.supplier_product_amenity,
    get_supplier_product_amenity_type: (state) =>
      state.supplier_product_amenity_type,
    get_supplier_product_media: (state) => state.supplier_product_media,
    get_supplier_rates: (state) => state.supplier_rates,
    get_supplier_product_options_edit: (state) => state.supplier_product_options_edit,
    get_rates_view : (state) => state.rates_view,
    get_selected_service : (state) => state.selected_service,
    get_edit_rates : (state) => state.edit_rates,
    get_response : (state) => state.response,
    get_add_option_children_parent_data : (state) => state.add_option_children_parent_data,
    get_child_data : (state) => state.child_data,
    get_child_item_data : (state) => (value) => {
      if(value === null || value === ""){
        return state.child_item_data
      }
      return state.child_item_data.filter(q => {
        return q.name.toLowerCase().includes(value)
      })
    },
    get_rate_period_child : (state) => state.rate_period_child
  },
  actions: {
    async fetch_supplier_type({ commit }) {
      await Vue.axios.get("api/suppliers/get_supplier_type").then(({ data }) => {
        commit("set_supplier_type", data.data);
      });
    },
    async fetch_supplier({ commit }, payload) {
      await Vue.axios
        .get(`api/suppliers/get_suppliers/${payload}`)
        .then(({ data }) => {
          if (data.response) {
            commit("set_suppliers", data.data);
            commit("set_archived_suppliers", data.archived);
          }
        });
    },
    set_suppliers({ commit }, payload) {
      commit("set_suppliers", payload);
    },
    set_supplier_setup({ commit }, payload) {
      commit("set_supplier_setup", payload);
    },
    set_supplier_setup_company({ commit }, payload) {
      commit("set_supplier_setup_company", payload);
    },
    set_supplier_setup_supplier_type({ commit }, payload) {
      commit("set_supplier_setup_supplier_type", payload);
    },
    set_supplier_setup_name({ commit }, payload) {
      commit("set_supplier_setup_name", payload);
    },
    set_supplier_setup_industry_ratings({ commit }, payload) {
      commit("set_supplier_setup_industry_ratings", payload);
    },
    async fetch_media_files({ commit }, payload) {
      Vue.axios
        .get(`api/resources/fetch_media_info/${payload}`)
        .then(({ data }) => {
          commit("set_media_files", data.data);
        });
    },
    set_selected_images({ commit }, payload) {
      commit("set_selected_images", payload);
    },
    async fetch_currencies({ commit }) {
      await axioss
        .get("https://api.exchangerate.host/symbols")
        .then(({ data }) => {
          const arrayOfObj = Object.keys(data.symbols).map((key) => {
            return data.symbols[key];
          });
          commit("set_currencies", arrayOfObj);
        });
    },
    async fetch_payment_term({ commit }) {
      await Vue.axios.get("api/suppliers/payment_terms").then(({ data }) => {
        commit("set_payment_term", data.data);
      });
    },
    async fetch_supplier_product({ commit }, id) {
      await Vue.axios
        .get(`api/suppliers/products/get_products/${id}`)
        .then(({ data }) => {
          if (data.response) {
            const items = [
              ...data.data.supplier_product,
              ...data.data.supplier_product_archived
            ];

            commit("set_supplier_product", items);

            // commit(
            //   "set_archived_supplier_product",
            //   data.data.supplier_product_archived
            // );
          }
        });
    },
    set_supplier_product({ commit }, payload) {
      commit("set_supplier_product", payload);
    },
    set_supplier_product_setup({ commit }, payload) {
      commit("set_supplier_product_setup", payload);
    },
    async fetch_supplier_product_type({ commit }) {
      await Vue.axios
        .get(`api/suppliers/products/get_product_type`)
        .then(({ data }) => {
          if (data.response) {
            commit("set_supplier_product_type", data.data);
          }
        });
    },
    async fetch_supplier_product_location({ commit }, id) {
      await Vue.axios
        .get(`api/suppliers/products/get_products_location/${id}`)
        .then(({ data }) => {
          if (data.response) {
            commit("set_supplier_product_location", data.data);
          }
        });
    },

    set_supplier_product_location({ commit }, payload) {
      commit("set_supplier_product_location", payload);
    },

    async fetch_supplier_product_time({ commit }, id) {
      await Vue.axios
        .get(`api/suppliers/products/get_products_time/${id}`)
        .then(({ data }) => {
          if (data.response) {
            commit("set_supplier_product_time", data.data);
          }
        });
    },
    set_supplier_product_time({ commit }, payload) {
      commit("set_supplier_product_time", payload);
    },

    async fetch_supplier_product_inclusion({ commit }, id) {
      await Vue.axios
        .get(`api/suppliers/products/get_products_inclusion/${id}`)
        .then(({ data }) => {
          if (data.response) {
            commit("set_supplier_product_inclusion", data.data);
          }
        });
    },
    set_supplier_product_inclusion({ commit }, payload) {
      commit("set_supplier_product_inclusion", payload);
    },

    async fetch_supplier_product_option({ commit }, id) {
      await Vue.axios
        .get(`api/suppliers/products/get_products_option/${id}`)
        .then(({ data }) => {
          if (data.response) {
            commit("set_supplier_product_option", data.data);
          }
        });
    },
    set_supplier_product_option({ commit }, payload) {
      commit("set_supplier_product_option", payload);
    },

    async fetch_supplier_product_amenity({ commit }, id) {
      await Vue.axios
        .get(`api/suppliers/products/get_products_amenity/${id}`)
        .then(({ data }) => {
          if (data.response) {
            commit("set_supplier_product_amenity", data.data.amenity);
            commit("set_supplier_product_amenity_type", data.data.amenitytype);
          }
        });
    },
    set_supplier_product_amenity({ commit }, payload) {
      commit("set_supplier_product_amenity", payload.amenity);
      commit("set_supplier_product_amenity_type", payload.amenitytype);
    },

    set_supplier_product_media({ commit }, payload) {
      commit("set_supplier_product_media", payload);
    },

    async fetch_supplier_product_media({ commit }, id) {
      await Vue.axios
        .get(`api/suppliers/products/get_product_media/${id}`)
        .then(({ data }) => {
          if (data.respset_supplier_ratesonse) {
            commit("set_supplier_product_media", data.data);
          }
        });
    },

    set_supplier_rates({ commit }, payload) {
      commit("set_supplier_rates", payload);
    },

    async fetch_supplier_rates({ commit }, id) {
      await Vue.axios
        .get(`api/suppliers/products/get_product_option_rates/${id}`)
        .then(({ data }) => {
          if (data.response) {
            commit("set_supplier_rates", data.data);
          }
        });
    },

    async duplicate_product_options({commit}, payload){
      await Vue.axios.post('api/suppliers/products/duplicate_product_option', {payload})
      .then(({data}) => {
        console.log(commit, data)
      })
    },
    async update_image_product_options({commit, state}, payload){
      let formData = new FormData()
      formData.append('image', payload)
      formData.append('id', state.supplier_product_options_edit.id)
      await Vue.axios.post('api/suppliers/products/update_image_product_option', formData)
      .then(({data}) => {
        console.log(commit, data)
      })
    },
    set_supplier_product_options_edit({commit}, payload){
      commit('set_supplier_product_options_edit', payload)
    },
    set_empty_supplier_product_options_edit({commit}, payload){
      Vue.axios.delete(`api/suppliers/products/remove_image_product_option/${payload.id}`)
      commit('set_empty_supplier_product_options_edit', payload)
    },
    async fetch_rates_view({commit}, payload){
      await Vue.axios.get('api/suppliers/products/get_rates_for_view', payload)
      .then(({data}) => {
        commit('set_rates_view', data.data)
        commit('set_selected_service', payload)
      })
    },
    set_edit_rates({commit}, payload){
      commit('set_edit_rates', payload)
    },
    async update_rates({commit}, payload){
      await Vue.axios.patch('api/suppliers/products/edit_rates', payload)
      .then(({data}) => {
        if(!data.response){
          commit('set_response', data)
          return
        }
        commit('set_response', data)
        return
      })
    },
    set_add_option_children_parent_data({commit}, payload){
      commit('set_add_option_children_parent_data', payload)
    },
    post_product_option_children({commit}, payload){
      Vue.axios.post('api/suppliers/options/add_child_product_option', payload)
      .then(({data}) => {
        commit('set_response', data)
      })
    },
    set_child_data({commit}, payload){
      commit('set_child_data', payload)
      commit('set_child_item_data', payload.option_child)
    },
    set_rate_period_child({commit}, payload){
      commit('set_rate_period_child', payload)
    },
    async add_option_children_rate(_, payload){
      await Vue.axios.post('api/suppliers/options/children/add_option_child_rate', payload)
    },
    async post_pricing_details(_, payload){
      await Vue.axios.post('api/suppliers/pricing/add_pricing', payload)
      .then(({data}) => {
        console.log(data)
      })
    },

    set_supplier_product_archive({ dispatch }, payload) {
      Vue.axios.patch(`api/suppliers/products/archive_product/${payload.item.id}`)
        .then(({data}) => {
          if(data.response) {
            dispatch('fetch_supplier_product', payload.supplier_id);
          }
        }).catch(err => console.error(err));
    },

    set_archive_switch({ commit }, payload) {
      commit('SET_ARCHIVE_SWITCH', payload);
    },

    set_supplier_product_restore({ dispatch }, payload) {
      Vue.axios.patch(`api/suppliers/products/restore_product/${payload.item.id}`)
        .then(({data}) => {
          if(data.response) {
            dispatch('fetch_supplier_product', payload.supplier_id);
          }
        }).catch(err => console.error(err));
    }
  },
};
