import Vue from "vue";

export default class Quotes {
    constructor() {
        this.vue = Vue
    }
    getSuppliers({ company_id }) {
        return this.vue.axios.get(`admin/suppliers/get_suppliers/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result.data
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    getSuppliersByLocation({ company_id, location_id }) {
        return this.vue.axios.get(`admin/suppliers/get_suppliers_by_location/${company_id}/${location_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    getQuotes(payload) {
        return this.vue.axios.get('admin/quotes/get_quotes', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    getCustomQuote({ quote_id }) {
        return this.vue.axios.get(`admin/quotes/get_quote/${quote_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result.data
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    createCustomQuote(payload) {
        return this.vue.axios.post('admin/quotes/create_custom_quote', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    updateQuote(payload) {
        return this.vue.axios.post(`admin/quotes/update_quote/${payload.id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    updateCustomQuote(payload) {
        return this.vue.axios.post(`admin/quotes/update_custom_quote/${payload.id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    updateQuoteBuilds(payload) {
        return this.vue.axios.post('admin/quotes/update_quote_builds', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    updateQuoteBuild(payload) {
        return this.vue.axios.post('admin/quotes/update_quote_build', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    updateQuoteBuildGroupsize(payload) {
        return this.vue.axios.post('admin/quotes/update_quote_build_groupsize', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    updateQuoteBuildGroupsizeAndSend(payload) {
        return this.vue.axios.post('admin/quotes/update_quote_build_groupsize_and_send', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    updateGroupsizes(payload) {
        return this.vue.axios.post('admin/quotes/update_groupsizes', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    copyQuote(payload) {
        return this.vue.axios.post('admin/quotes/copy_quote', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    sendEmailQuote(payload) {
        return this.vue.axios.post('admin/quotes/send_email_quote', payload).then((data) => {
            console.log(data.data)
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    deleteCustomQuote({ quote_id, company_id, lead_id }) {
        return this.vue.axios.delete(`admin/quotes/delete_quote_with_builds/${quote_id}/${company_id}/${lead_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    createQuickQuote(payload) {
        console.log(payload)
        return this.vue.axios.post('admin/quotes/create_quick_quote', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
}