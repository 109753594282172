import Vue from "vue";

export default {
  namespaced: true,
  state: {
    supplier_pricing_information: {}
  },
  mutations: {
    set_supplier_pricing_information(state, payload){
        state.supplier_pricing_information = payload
    }
  },
  getters: {
    get_supplier_pricing_information : (state) => state.supplier_pricing_information
  },
  actions: {
    async fetch_pricing_data({commit}, payload){
        await Vue.axios.get(`api/suppliers/pricing/fetch_pricing_info/${payload.id}`)
        .then(({data}) => {
            commit('set_supplier_pricing_information', data.data)
        })
    },
    async update_pricing_data(_, payload){
        await Vue.axios.patch('api/suppliers/pricing/update_pricing_info', payload)
    }
  }
}