<template>
    <v-container fluid>
        <div class="card mt-9">
        <!-- {{ get_task_info }} -->
        <v-row no-gutters>
            <v-col cols="12" md="6">
            <div class="card-header"></div>
            </v-col>
            <v-col cols="12" md="6">
            <div class="card-header-items">
                <v-btn
                rounded
                color="#525a68"
                dark
                class="header-items"
                @click="NewTask()"
                >
                Create task
                </v-btn>
            </div>
            </v-col>
            <v-col cols="12">
            <v-divider class="mt-3"></v-divider>
            </v-col>
            <v-col cols="12" sm="8">
            <div class="filtertabs-wrapper">
                <v-tabs grow class="filtertabs" show-arrows>
                <v-tab @click="Filter('All', null)"> All tasks </v-tab>
                <v-tab @click="Filter('My Task', { user_id: get_user_login.id })">
                    My tasks
                </v-tab>
                <v-tab @click="Filter('Done', null)"> Completed tasks </v-tab>
                <v-tab @click="Filter('To do today', null)"> To do today </v-tab>
                <v-tab @click="Filter('To do this week', null)">
                    To do this week
                </v-tab>
                <v-tab @click="Filter('Comming up', null)"> Comming up </v-tab>
                </v-tabs>
            </div>
            </v-col>
            <v-col cols="12" sm="4">
            <v-text-field
                v-model="filter_search"
                label="Search"
                prepend-icon="mdi-magnify"
                class="mt-5 pr-2"
            ></v-text-field>
            </v-col>
            <v-col cols="12">
            <v-divider></v-divider>
            <v-data-table
                :headers="headers"
                :items="get_task_info"
                @click:row="EditTaskInformation"
            >
                <template v-slot:item.itinerary="props">
                {{ select_itenerary(props.item.itinerary_id) }}
                </template>
                <template v-slot:item.status="props">
                <div
                    :class="
                    props.item.status == 'To do'
                        ? 'status-todo'
                        : props.item.status == 'In progress'
                        ? 'status-inprogress'
                        : 'status-done'
                    "
                >
                    <h4>{{ props.item.status }}</h4>
                </div>
                </template>
                <template v-slot:item.priority="props">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        :style="{ color: props.item.priority_info.color }"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ props.item.priority_info.symbol }}
                    </v-icon>
                    </template>
                    <span>{{ props.item.priority_info.name }}</span>
                </v-tooltip>
                </template>
                <template v-slot:item.assignees="props">
                <v-tooltip
                    bottom
                    v-for="(value, index) in props.item.assignee_info"
                    :key="index + 'chips'"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-chip class="data-chip" v-bind="attrs" v-on="on">{{
                        value.user_info.name.charAt(0).toUpperCase()
                    }}</v-chip>
                    </template>
                    <span>{{ value.user_info.name }}</span>
                </v-tooltip>
                </template>
                <template v-slot:item.action="props">
                <v-menu right bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>

                    <v-list>
                    <v-list-item v-if="props.item.itinerary_id!=0" @click="GotoItinerary(props.item.itinerary_id)">
                        <v-list-item-title> Go to itinerary </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="Duplicate(props.item.id)">
                        <v-list-item-title> Duplicate </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="RemoveTaskInformation(props.item.id)">
                        <v-list-item-title> Delete </v-list-item-title>
                    </v-list-item>
                    <v-subheader class="list-header">
                        <v-list-item-title> Change Status </v-list-item-title>
                    </v-subheader>
                    <v-list-item
                        v-for="(value, index) in status"
                        :key="index + 'status'"
                        @click="UpdateTaskStatus(props.item.id, value.name)"
                    >
                        <v-list-item-title>
                        {{ value.name }}
                        </v-list-item-title>
                        <v-list-item-action v-if="props.item.status == value.name">
                        <v-btn icon>
                            <v-icon style="color: #5996c5">mdi-check</v-icon>
                        </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    </v-list>
                </v-menu>
                </template>
            </v-data-table>
            </v-col>
        </v-row>
        </div>

        <v-dialog
        v-model="dialog"
        persistent
        max-width="770px"
        overlay-color="#f4f4f5"
        overlay-opacity="1"
        >
        <v-btn fab @click="dialog = false" class="dialog-close">
            <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
        <v-card>
            <v-card-title>
            {{ task_title }}
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-row>
                <v-col cols="12">
                    <v-text-field
                    v-model="payload.task"
                    label="Task name *"
                    dense
                    outlined
                    @keyup="ValTaskName()"
                    ></v-text-field>
                    <ErrMessage
                    :message="validation.task.message"
                    :show="validation.task.show"
                    :success="validation.task.success"
                    />
                </v-col>
                <v-col cols="12">
                    <v-autocomplete
                    v-model="payload.task_type_id"
                    :items="get_task_type"
                    item-value="id"
                    item-text="name"
                    dense
                    outlined
                    label="Task type"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                    <v-autocomplete
                    v-model="payload.priority_type_id"
                    :items="get_priority_type"
                    item-value="id"
                    item-text="name"
                    dense
                    outlined
                    label="Priority"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                    <v-autocomplete
                    v-model="payload.status"
                    :items="status"
                    item-value="name"
                    item-text="name"
                    dense
                    outlined
                    label="Status"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    nudge-bottom="39"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="payload.due_date"
                        label="Due date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="payload.due_date"
                        @input="menu = false"
                    ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12">
                    <v-textarea
                    v-model="payload.description"
                    auto-grow
                    label="Description"
                    rows="1"
                    dense
                    outlined
                    ></v-textarea>
                </v-col>
                <v-col cols="12">
                    <div class="form-group">
                    <h3>Itinerary</h3>
                    <v-autocomplete
                        v-model="payload.itinerary_id"
                        label="Search itineraries"
                        class="form-control"
                        :items="get_itinerary_info"
                        item-value="id"
                        item-text="itinerary_name"
                        outlined
                        dense
                    >
                    </v-autocomplete>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="form-group">
                    <h3>Assignees</h3>
                    <v-autocomplete
                        v-model="assigness"
                        class="form-control"
                        :items="get_user_info"
                        dense
                        outlined
                        chips
                        label="Select"
                        item-text="name"
                        item-value="id"
                        @change="AddAssignees()"
                    >
                        <template v-slot:item="data">
                        <template>
                            <v-list-item-avatar>
                            <div class="initial-avatar-wrapper">
                                <h5 class="initial">
                                {{ data.item.name.charAt(0).toUpperCase() }}
                                </h5>
                            </div>
                            </v-list-item-avatar>
                            <v-list-item-content class="initial-content">
                            <div class="initial-content">
                                <p>{{ data.item.name }}</p>
                                <span>{{ data.item.email }}</span>
                            </div>
                            <!-- <v-list-item-title v-html="data.item.name"></v-list-item-title> -->
                            <!-- <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle> -->
                            </v-list-item-content>
                        </template>
                        </template>
                    </v-autocomplete>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="assignees-wrapper">
                    <div
                        class="assignees"
                        v-for="(item, index) in payload.assigness"
                        :key="item.id"
                    >
                        <v-avatar class="assignees-avatar" size="56">
                        {{ item.name.charAt(0).toUpperCase() }}</v-avatar
                        >
                        <div class="assignees-initial">
                        <p>{{ item.name }}</p>
                        <span>{{ item.email }}</span>
                        </div>
                        <div class="assignees-action">
                        <v-btn icon fab @click="RemoveAssignee(index)">
                            <v-icon dark> mdi-delete </v-icon>
                        </v-btn>
                        </div>
                    </div>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="action-button">
                    <v-btn
                        color="success"
                        fab
                        @click="SaveTaskInformation()"
                        v-if="task_title == 'Create task'"
                    >
                        <v-icon dark> mdi-check </v-icon>
                    </v-btn>
                    <v-btn
                        color="success"
                        fab
                        v-else
                        @click="UpdateTaskInformation()"
                    >
                        <v-icon dark> mdi-check </v-icon>
                    </v-btn>
                    </div>
                </v-col>
                </v-row>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ErrMessage from "../errmessage/ErrMessage.vue";
import Validation from "../../common/validation";

export default {
  name: "Task",
  components: {
    ErrMessage,
  },
  props: [],
  data: () => ({
    menu: false,
    dialog: false,
    task_title: "",
    validation: {
      task: {
        message: "",
        show: false,
        success: false,
      },
    },
    status: [
      {
        id: 1,
        name: "To do",
      },
      {
        id: 2,
        name: "In progress",
      },
      {
        id: 3,
        name: "Done",
      },
    ],
    payload: {
      company_id: 0,
      id: 0,
      task: "",
      task_type_id: 7,
      priority_type_id: 2,
      status: "To do",
      due_date: "",
      description: "",
      itinerary_id: 0,
      assigness: [],
    },
    headers: [
      { text: "Task Name", value: "task" },
      { text: "Itinerary Name", value: "itinerary" },
      { text: "Due Date", value: "due_date" },
      { text: "Status", value: "status" },
      { text: "Priority", value: "priority" },
      { text: "Assignees", value: "assignees" },
      { text: "", value: "action" },
    ],
    assigness: [],
    selected_assigness: [],
    remove_assignees: [],
    filter: "All",
    filter_payload: null,
    filter_search: "",
  }),
  async mounted() {
    await this.$store.dispatch(
      "task/fetch_task_info",
      {
        company_id: this.get_selected_company.id,
        itinerary_id: null
      }
    );
    await this.$store.dispatch("task/fetch_task_type");
    await this.$store.dispatch("task/fetch_priority_type");
    await this.$store.dispatch("task/fetch_user_info");
  },
    created() {
            this.setPageName("Tasks")
    },
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
      get_task_type: "task/get_task_type",
      get_priority_type: "task/get_priority_type",
      get_user_login: "auth/get_user",
      get_itinerary_info: "itinerary/get_itinerary_info",
    }),
    get_user_info() {
      return this.$store.getters["task/get_user_info"](this.selected_assigness);
    },
    get_task_info() {
      return this.$store.getters["task/get_task_info"](
        this.filter,
        this.filter_payload,
        this.filter_search
      );
    },
  },
  methods: {
    ...mapActions({
        setPageName:    'pageNameStore/setPageNameAction'
    }),
    NewTask() {
      this.selected_assigness = [];
      this.ClearPayload();
      this.task_title = "Create task";
      this.dialog = true;
      this.payload.company_id = this.get_selected_company.id;
    },
    ValTaskName() {
      if (Validation.valBlank(this.payload.task)) {
        this.validation.task.message = "Please input your task.";
        this.validation.task.show = true;
        this.validation.task.success = false;
        return true;
      } else {
        this.validation.task.show = false;
        return false;
      }
    },
    FormValidation() {
      this.err_counter = 0;
      if (this.ValTaskName()) {
        this.err_counter += 1;
      }
      return this.err_counter;
    },
    AddAssignees() {
      this.payload.assigness.push(
        this.get_user_info.find((el) => {
          if (el.id == this.assigness) return el;
        })
      );
      this.selected_assigness.push(this.assigness);
      console.log(this.payload.assigness);
      console.log(this.selected_assigness);
      this.assigness = [];
    },
    RemoveAssignee(index) {
      this.payload.assigness.splice(index, 1);
      this.selected_assigness.splice(index, 1);
    },
    async SaveTaskInformation() {
      if (this.FormValidation() == 0) {
        await this.$axios
          .post("api/task/add_task", this.payload)
          .then(({ data }) => {
            if (data.response) {
              this.$store.dispatch("task/set_task", data.data);
              this.dialog = false;
            } else {
              console.log("Something went wrong!");
            }
          });
      }
    },
    UpdateTaskStatus(id, status) {
      let payload = {
        id: id,
        status: status,
      };
      let info = this.get_task_info.find((el) => {
        if (el.id == id) return el;
      });
      info.status = status;
      this.$axios.post("api/task/update_task_status", payload);
    },
    RemoveTaskInformation(id) {
      let payload = {
        id: id,
      };
      this.get_task_info.find((el, index) => {
        if (el.id == id) return this.get_task_info.splice(index, 1);
      });
      this.$axios.post("api/task/remove_task_info", payload);
    },
    async Duplicate(id) {
      let payload = {
        id: id,
      };
      await this.$axios
        .post("api/task/duplicate_task_info", payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch("task/set_task", data.data);
          } else {
            console.log("Something went wrong!");
          }
        });
    },
    ClearPayload() {
      this.payload.task = "";
      this.payload.task_type_id = 7;
      this.payload.priority_type_id = 2;
      this.payload.status = "To do";
      this.payload.due_date = "";
      this.payload.description = "";
      this.payload.itinerary_id = 0;
      this.payload.assigness = [];
    },
    Filter(value, payload) {
      this.filter = value;
      this.filter_payload = payload;
    },
    async EditTaskInformation(item) {
      this.ClearPayload();
      this.selected_assigness = [];
      this.remove_assignees = [];
      await this.$axios.get(`api/task/fetch_task/${item.id}`).then(({ data }) => {
        if (data.response) {
          this.task_title = "Edit task";
          this.payload.id = data.data.id;
          this.payload.task = data.data.task;
          this.payload.task_type_id = data.data.task_type_id;
          this.payload.priority_type_id = data.data.priority_type_id;
          this.payload.description = data.data.description;
          this.payload.due_date = data.data.due_date;
          this.payload.status = data.data.status;
          this.payload.itinerary_id = data.data.itinerary_id;
          data.data.assignee_info.forEach((value) => {
            this.payload.assigness.push(value.user_info);
            this.selected_assigness.push(value.user_info.id);
          });
          this.dialog = true;
        } else {
          console.log("Something went wrong!");
        }
      });
    },
    UpdateTaskInformation() {
      this.$axios
        .post("api/task/update_task_info/", this.payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch(
              "task/fetch_task_info",
              {
                company_id: this.get_selected_company.id,
                itinerary_id: null
              }
            );
            this.dialog = false;
          } else {
            console.log("Something went wrong!");
          }
        });
    },
    select_itenerary(id) {
      const selectif = this.get_itinerary_info.filter((item) => {
        return item.id === id;
      });
      return selectif[0] ? selectif[0]["itinerary_name"] : null;
    },
    GotoItinerary(itinerary_id){
      this.$store.dispatch('itinerary/set_itinerary_action', { id: itinerary_id, action: 'edit', is_disabled: false });
      this.$router.push({
        name: "/itinerary/initial"
      });
    }
  },
  watch: {},
};
</script>

<style scoped>
.header {
  max-width: 100%;
  background-color: #79a2bd;
  height: 50px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 50px;
}
.header h3 {
  color: white;
}
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}
.card .card-header-items {
  text-align: right;
}
.card .card-header-items .header-items {
  margin: 0px 10px;
}
.filtertabs-wrapper {
  max-width: 100%;
  margin-top: 18px;
}
.dialog-close {
  margin-left: -75px;
  position: absolute;
}
.action-button {
  text-align: right;
}
.form-group {
  margin-top: -15px;
}
.form-group h3 {
  padding-bottom: 10px;
}
.form-group .form-control {
  margin-top: 5px;
}
.initial-avatar-wrapper {
  width: 100%;
  background-color: #9a3856;
  height: 100%;
}
.initial-avatar-wrapper .initial {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin: 3px 10px;
}
.initial-content p {
  margin: 0;
  font-size: 18px;
}
.initial-content p::first-letter {
  font-weight: bolder;
}
.initial-content span {
  font-size: 12px;
}
.assignees-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.assignees-wrapper .assignees {
  max-width: 340px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
  padding: 12px;
  display: flex;
}
.assignees-wrapper .assignees .assignees-avatar {
  background-color: #343642;
  color: white;
  font-size: 25px;
}
.assignees-wrapper .assignees .assignees-initial {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 10px;
  margin-right: 12px;
}
.assignees-wrapper .assignees .assignees-initial p {
  margin: 0px;
  font-size: 18px;
}
.assignees-wrapper .assignees .assignees-initial p::first-letter {
  font-weight: bolder;
}
.assignees-wrapper .assignees .assignees-initial span {
  font-size: 10px;
}
.assignees-wrapper .assignees .assignees-action {
  margin: 0 auto;
}
.status-todo {
  width: 90px;
  background-color: #e46711;
  border-radius: 10px;
  text-align: center;
}
.status-todo h4 {
  color: white;
  padding: 5px;
}
.status-inprogress {
  width: 90px;
  background-color: #17559b;
  border-radius: 10px;
  text-align: center;
}
.status-inprogress h4 {
  color: white;
  padding: 5px;
}
.status-done {
  width: 90px;
  background-color: #68a74f;
  border-radius: 10px;
  text-align: center;
}
.status-done h4 {
  color: white;
  padding: 5px;
}
.data-chip {
  background-color: rgb(56, 154, 116) !important;
  font-weight: bolder;
  font-size: 14px;
  color: white;
  margin-right: 5px;
}
.list-header {
  color: black;
  font-size: 15px;
  font-weight: bold;
}
@media only screen and (max-width: 959px) {
  .card .card-header {
    text-align: center;
  }
  .card .card-header-items .header-items {
    margin: 5px 5px 10px 5px;
  }
}
</style>
