<template>
    <v-container fluid style="background-color: #F4F4F5 !important;">
        <v-dialog :value="value" persistent max-width="670">
            <v-card>
                <v-card-title class="text-small">
                    <small><strong>Media library</strong></small>
                    <v-spacer />
                    <!-- <v-btn
                        dark
                        rounded
                        color="#588BAD"
                        small
                        style="textTransform: none !important; color: white;"
                    >
                        Upload media
                    </v-btn> -->
                    <v-btn icon @click="close_dialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field label="Search" prepend-inner-icon="mdi-magnify" v-model="search_image">
                    </v-text-field>
                    <v-row>
                        <v-col cols="4" v-for="(media, mediaindex) in get_media_files" :key="mediaindex"
                            @click="select_media(media)">
                            <v-img :src="media.url" :lazy-src="media.url" aspect-ratio="1" style="cursor: pointer;"
                                class="media_images" />
                        </v-col>
                    </v-row>
                    <v-btn dark rounded color="#588BAD" small style="text-transform: none !important; color: white;"
                        block class="mt-5">
                        Load more
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'value'
    ],
    data: () => ({
        search_image: null
    }),
    async mounted() {
        await this.$store.dispatch('supplier/fetch_media_files', this.get_selected_company.id)
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_supplier_setup: 'supplier/get_supplier_setup',
            get_media_files: 'supplier/get_media_files',
            get_selected_company: 'auth/get_selected_company'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async select_media(data) {
            this.$store.dispatch('supplier/set_selected_images', data)
            await this.$axios.patch('api/suppliers/update_media', {
                media: data,
                supplier_id: this.get_supplier_setup.id,
                company_id: this.get_selected_company.id
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.close_dialog()
                        this.$store.dispatch('supplier/fetch_supplier', this.get_selected_company.id)
                        this.$store.dispatch('supplier/set_supplier_setup', data.supplier_setup)
                        return
                    }
                    this.showSnackBar(data.message)
                })

        },
        close_dialog() {
            this.$emit('close_dialog')
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.media_images:hover {
    border: 3px solid grey;
}
</style>