<template>
    <v-container fluid>
        <v-row class="mt-5 mb-15" justify="center" align="center">
            <v-card width="820" elevation="2">
                <v-card-title class="text-small">
                    <small><strong>Create Supplier</strong></small>
                </v-card-title>
                <v-card-text>
                    <v-row class="mt-1">
                        <v-col cols="12">
                            <v-text-field v-model="new_supplier.supplier_name" label="Supplier Name *" outlined dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete v-model="new_supplier.service_type_id" label="Supplier Type *"
                                prepend-inner-icon="mdi-tooltip-text-outline" :items="get_supplier_type" item-value="id"
                                item-text="name" outlined dense>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined prepend-inner-icon="mdi-at" label="Booking email *"
                                v-model="new_supplier.email">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete :items="getLocation" multiple item-text="name" item-value="id" label="Location *" dense
                                outlined v-model="supplier_locations"></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined label="Address" v-model="new_supplier.address">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="new_supplier.phone" dense label="Phone" outlined
                                prepend-inner-icon="mdi-phone-outline">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="new_supplier.mobile" dense label="Mobile" outlined
                                prepend-inner-icon="mdi-cellphone">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="new_supplier.free_phone" dense label="Freephone"
                                outlined prepend-inner-icon="mdi-phone-outline">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="new_supplier.others" dense label="Other" outlined
                                prepend-inner-icon="mdi-phone-outline">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined v-model="new_supplier.website" label="Website"
                                prepend-inner-icon="mdi-web">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined v-model="new_supplier.notes" label="Notes"
                                prepend-inner-icon="mdi-tooltip-text-outline">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <label>Description</label>
                            <wysiwyg dense outlined v-model="new_supplier.description" label="Description" />
                        </v-col>
                        <v-col cols="6">
                            <label>Admin Notes</label>
                            <wysiwyg dense outlined v-model="new_supplier.admin_notes" label="Admin Notes" />
                        </v-col>
                        <v-col cols="6">
                            <v-textarea dense outlined v-model="new_supplier.drink_policy" label="Drinking Policy">
                            </v-textarea>
                        </v-col>
                        <v-col cols="6">
                            <v-textarea dense outlined v-model="new_supplier.directions" label="Directions">
                            </v-textarea>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined v-model="new_supplier.time_to_center" label="Time To Center">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined v-model="new_supplier.time_to_arrive" label="Time To Be There">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="mb-10 mr-5">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded class="px-5" @click="createSupplier()">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        new_supplier: {
            supplier_name: null,
            service_type_id: null,
            email: null,
            location_id: null,
            address: null,
            phone: null,
            mobile: null,
            free_phone: null,
            others: null,
            website: null,
            notes: null,
            description: null,
            admin_notes: null,
            drink_policy: null,
            directions: null,
            time_to_center: null,
            time_to_arrive: null,
        },
        supplier_locations: [],
    }),
    mounted() {
        this.$store.dispatch('supplier/fetch_supplier_type')
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_supplier_type: 'supplier/get_supplier_type',
            getLocation: 'locationStore/getLocationsGetter',
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async createSupplier() {
            let payload = {
                company_id: this.get_selected_company.id,
                supplier_locations: this.supplier_locations,
                ...this.new_supplier,
            }
            await this.$axios.post('admin/suppliers/create_supplier', payload)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.$router.push({ name: '/supplier' })
                    }
                })
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>