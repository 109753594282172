<template>
    <v-row>
        <v-col cols="12">
            <v-card elevation="0">
                <v-card-title class="justify-center">
                    <div class="mr-4"><v-img src="../../../assets/check_circle_green.png" height="40"
                            width="40"></v-img>
                    </div>
                    <div class="payment-confirmed" v-if="lead && quote">
                        Quote Created Successfully
                    </div>
                    <div class="payment-confirmed" v-else>
                        Quote Updated Successfully
                    </div>
                </v-card-title>
            </v-card>
        </v-col>
        <v-col cols="12">
            <div class="thank-you mb-10" v-if="lead && quote">Your quote has been successfully created.</div>
            <div class="thank-you mb-10" v-else>Your quote has been successfully updated.</div>
            <div class="quote-summary" v-if="lead && quote">QUOTE SUMMARY</div>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="8" v-if="lead && quote">
            <v-card outlined class="card-format px-15 py-8">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="6" class="summary-text-left">Quote Name:</v-col>
                                <v-col cols="6" class="summary-text-right">{{ quote.name }}</v-col>
                                <v-col cols="6" class="summary-text-left">Location:</v-col>
                                <v-col cols="6" class="summary-text-right">{{ lead.location.name }}</v-col>
                                <v-col cols="6" class="summary-text-left">Email:</v-col>
                                <v-col cols="6" class="summary-text-right">{{ lead.email }}</v-col>
                                <v-col cols="6" class="summary-text-left">Quote Price:</v-col>
                                <v-col cols="6" class="summary-text-right">{{ get_selected_company.currency }}{{ quote.total }}</v-col>
                            </v-row>
                        </v-col>
                        <!-- <v-col cols="4">
                            <v-row class="text-right">
                                <v-col cols="12">
                                    <v-btn class="summary-button" color="#FAFAFA">
                                        <div>
                                            <v-img src="../../../assets/copy_quote.png" height="20" width="20"
                                                class="mr-2"></v-img>
                                        </div>
                                        COPY QUOTE
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn class="summary-button" color="#FAFAFA" @click="emailQuote()">
                                        <div>
                                            <v-img src="../../../assets/email_quote.png" height="20" width="20"
                                                class="mr-2"></v-img>
                                        </div>
                                        EMAIL QUOTE
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn class="summary-button" color="#FAFAFA">
                                        <div>
                                            <v-img src="../../../assets/add_message.png" height="20" width="20"
                                                class="mr-2"></v-img>
                                        </div>
                                        ADD MESSAGE
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col> -->
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="2"></v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'lead', 'quote'
    ],
    data: () => ({
        accommodation: {
            start_date: null,
            end_date: null,
            supplier: null,
            product: {
                options: null,
            },
            option: null,
        },
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        }),
    },
    methods: {
        emailQuote() {
            this.$emit('emailQuote')
        }
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

.v-input__slot {
    background: white !important;
}

.v-stepper__header {
    box-shadow: none !important;
}

.payment-confirmed {
    color: #679F54;
    font-family: Manrope;
    font-size: 32px;
    font-weight: 800;
}

.thank-you {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    color: #7C7C7C;
}

.quote-summary {
    font-family: Manrope;
    font-size: 20px !important;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #7C7C7C;
}

.summary-text-left {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
    color: #7C7C7C;

}

.summary-text-right {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #101010;
}

.summary-button {
    width: 220px;
    height: 38px;
    border-radius: 5px;
    border: 0.5px;
    font-family: Manrope;
    font-size: 16px !important;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000 !important;
    box-shadow: none;
    border: 0.5px solid #D3D3D3 !important;
}
</style>