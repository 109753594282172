<template>
    <v-container fluid>
        <div class="card mt-9">
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <div class="card-header">
                        Agencies
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="card-header-items">
                        <v-btn
                            rounded
                            color="#525a68"
                            dark
                            class="header-items"
                            @click="NewAgency()"
                        >
                            Create agency
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" sm="10">
                    <v-text-field
                        v-model="filter_search"
                        label="Search"
                        prepend-icon="mdi-magnify"
                        class="mt-5 pr-2"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-switch
                        v-model="show_archived"
                        label="Show archived"
                        class="mt-9 ml-12"
                        @change="ShowArchived()"
                    ></v-switch>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                    <v-data-table
                        :headers="headers"
                        :items="get_agencies_info"
                        @click:row="EditAgency"
                    >
                        <template v-slot:item.sufix="props">
                            <v-chip
                                class="data-chip"
                            >{{ props.item.business_name.charAt(0).toUpperCase() }}</v-chip>
                        </template>
                        <template v-slot:item.action="props">
                            <v-menu
                                right
                                bottom
                                v-if="props.item.deleted_at==null"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list
                                >
                                    <v-list-item
                                        @click="Archive(props.item.id)"
                                    >
                                        <v-list-item-title>
                                            Archive
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-menu
                                right
                                bottom
                                v-else
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list
                                >
                                    <v-list-item
                                        @click="Restore(props.item.id)"
                                    >
                                        <v-list-item-title>
                                            Restore
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </div>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="770px"
            overlay-color="#f4f4f5"
            overlay-opacity="1"
        >
            <v-btn
                fab
                @click="dialog = false; is_edit = false; tab = 'tab-1';"
                class="dialog-close"                    
            >
                <v-icon dark>
                    mdi-arrow-left
                </v-icon>
            </v-btn>
            <v-card>
                <div class="header" v-if="is_edit">
                    <h3>{{ payload.business_name }}</h3>
                </div>
                <v-tabs
                    dark
                    background-color="#588BAD"
                    v-model="tab"
                    fixed-tabs
                    v-if="is_edit"
                >
                    <v-tab
                        href="#tab-1"
                    >
                        Setup
                    </v-tab>
                    <v-tab
                        href="#tab-2"
                    >
                        Connections
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        value="tab-1"
                    >
                        <v-card v-if="tab=='tab-1'">
                            <v-card-title>
                                {{ agency_title }}
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col
                                            cols="6"
                                        >
                                            <v-text-field
                                                v-model="payload.business_name"
                                                label="Business name *"
                                                dense
                                                outlined
                                                @keyup="ValBusinessName()"
                                            ></v-text-field>
                                            <ErrMessage :message="validation.business_name.message" :show="validation.business_name.show" :success="validation.business_name.success"/>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                        >
                                            <v-text-field
                                                v-model="payload.email"
                                                label="Primary email"
                                                dense
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="4"
                                        >
                                            <v-text-field
                                                v-model="payload.mobile"
                                                label="Mobile"
                                                dense
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="4"
                                        >
                                            <v-text-field
                                                v-model="payload.phone"
                                                label="Phone"
                                                dense
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="4"
                                        >
                                            <v-text-field
                                                v-model="payload.other"
                                                label="Other"
                                                dense
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-text-field
                                                v-model="payload.business_address"
                                                label="Business address"
                                                dense
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-text-field
                                                v-model="payload.website"
                                                label="Website"
                                                dense
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                        >
                                            <div class="form-group">
                                                <h3>Default commission setup</h3>
                                                <v-autocomplete
                                                    v-model="payload.commission_type_id"
                                                    :items="get_commission_type"
                                                    class="form-control"
                                                    item-value="id"
                                                    item-text="name"
                                                    dense
                                                    outlined
                                                    label="Commission type"
                                                ></v-autocomplete>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                        >
                                            <div class="form-group">
                                                <h3>&nbsp;</h3>
                                                <v-text-field
                                                    v-model="payload.percentage"
                                                    class="form-control"
                                                    label="Percentage"
                                                    dense
                                                    outlined
                                                    v-if="this.get_selected_commission_type!=0"
                                                ></v-text-field>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                        >
                                            <div class="form-group">
                                                <h3>Tags</h3>
                                                <v-text-field
                                                    v-model="tag_name"
                                                    label="Add a tag"
                                                    dense
                                                    outlined
                                                    v-on:keyup.enter="AddTag()"
                                                ></v-text-field>
                                                <ErrMessage :message="validation.tags.message" :show="validation.tags.show" :success="validation.tags.success"/>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <div class="tags-wrapper">
                                                <div class="tags" v-for="(tag, index) in this.payload.tags" :key="index">
                                                    <div class="tag-name">
                                                        {{ tag }}
                                                    </div>
                                                    <div class="tag-action">
                                                        <v-btn
                                                            color="error"
                                                            icon
                                                            @click="RemoveTag(index)"
                                                        >
                                                            <v-icon dark>
                                                                mdi-delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                        >
                                            <div class="form-group">
                                                <h3>Notes</h3>
                                                <v-textarea
                                                    v-model="payload.notes"
                                                    auto-grow
                                                    label="Add an agency note"
                                                    rows="1"
                                                    dense
                                                    class="form-control"
                                                    outlined
                                                ></v-textarea>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <div class="action-button">
                                                <v-btn
                                                    color="success"
                                                    fab
                                                    @click="SaveAgenciesInformation()"
                                                    v-if="agency_title=='Create agency'"
                                                >
                                                    <v-icon dark>
                                                        mdi-check
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    color="success"
                                                    fab
                                                    v-else
                                                    @click="UpdateAgencyInformation()"
                                                >
                                                    <v-icon dark>
                                                        mdi-check
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        value="tab-2"
                    >
                        <v-card v-if="tab=='tab-2'">
                            <v-card-title>
                                <v-row>
                                    <v-col cols="6">
                                        Agents
                                    </v-col>
                                    <v-col cols="6">
                                        <div class="text-center">
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        color="#525a68"
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        Add agent
                                                        <v-icon
                                                            dark
                                                            right
                                                        >
                                                            mdi-menu-down
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item
                                                        @click="dialog_existing_contact = true"
                                                    >
                                                        <v-list-item-title>Add existing contact</v-list-item-title>
                                                    </v-list-item>

                                                    <v-list-item
                                                        @click="CreateNewAgent()"
                                                    >
                                                        <v-list-item-title>Create new</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-data-table
                                        :headers="headers_contact"
                                        :items="payload.contacts"
                                        hide-default-header
                                        hide-default-footer
                                    >
                                        <template v-slot:item.contact="props">
                                            <div class="contact-info">
                                                <h3>{{ props.item.firstname }} {{ props.item.lastname }}</h3>
                                                <span>{{ props.item.email }}</span>
                                            </div>
                                        </template>
                                        <template v-slot:item.action="props">
                                            <v-btn
                                                icon
                                            >
                                                <v-icon>mdi-file-account</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                @click="RemoveAgencyContact(props.item.agency_id)"
                                            >
                                                {{ props.item.index }}
                                                {{ props.item.agency_id }}
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_add_contact"
            max-width="500px"
        >
        <v-card>
          <v-card-title>
            <span>Add new agent</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="contact_payload.firstname"
                            label="Firstname *"
                            dense
                            outlined
                            @keyup="ValFirstname()"
                        ></v-text-field>
                        <ErrMessage :message="validation.firstname.message" :show="validation.firstname.show" :success="validation.firstname.success"/>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="contact_payload.lastname"
                            label="Lastname *"
                            dense
                            outlined
                            @keyup="ValLastname()"
                        ></v-text-field>
                        <ErrMessage :message="validation.lastname.message" :show="validation.lastname.show" :success="validation.lastname.success"/>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="contact_payload.email"
                            label="Email"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
          <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog_add_contact = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="AddAgentContactConcent()"
                >
                    Add agent
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
            v-model="dialog_existing_contact"
            max-width="500px"
        >
        <v-card>
          <v-card-title>
            <span>Add existing contact</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
                v-model="existing_contact"
                class="form-control"    
                :items="get_contact_info"
                dense
                outlined
                label="Search *"
                item-text="first_name"
                item-value="id"
            >
                <template v-slot:item="data">
                    <template>
                        <v-list-item-avatar>
                            <div class="initial-avatar-wrapper">
                                <h5 class="initial">{{ data.item.first_name.charAt(0).toUpperCase() }}</h5>
                            </div>
                        </v-list-item-avatar>
                        <v-list-item-content class="initial-content">
                            <div class="initial-content">
                                <p>{{ data.item.first_name }} {{ data.item.last_name }}</p>
                                <span>{{ data.item.email }}</span>
                            </div>
                            <!-- <v-list-item-title v-html="data.item.name"></v-list-item-title> -->
                            <!-- <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle> -->
                        </v-list-item-content>
                    </template>
                </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog_existing_contact = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="AddExisitingAgentContact()"
                >
                    Add contact
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
            v-model="dialog_add_contact_concent"
            max-width="600px"
        >
        <v-card>
          <v-card-title>
            <span>You're about to save personal data</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            In order to save you must confirm that you have a legitimate interest, contractual basis or consent to store this personal data.
            <v-checkbox
                v-model="contact_payload.concent"
                label="I have a legitimate interest and/or consent to store this information"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog_add_contact_concent = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    :disabled="!contact_payload.concent"
                    @click="AddAgentContact()"
                >
                    Accept
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
</template>
  
<script>
    import { mapGetters, mapActions } from 'vuex';
    import ErrMessage from '../errmessage/ErrMessage.vue';
    import Validation from '../../common/validation';

    export default {
        name: 'Agencies',
        components: {
            ErrMessage
        },
        props: [
        ],
        data: () => ({
            tab: 'tab-1',
            menu: false,
            dialog: false,
            show_archived: false,
            agency_title: '',
            validation: {
                business_name: {
                    message: '',
                    show: false,
                    success: false
                },
                tags: {
                    message: 'Tags make it easier to find and categorise images',
                    show: true,
                    success: true
                },
                firstname: {
                    message: '',
                    show: false,
                    success: false
                },
                lastname: {
                    message: '',
                    show: false,
                    success: false
                }
            },  
            payload: {
                id: 0,
                company_id: 0,
                business_name: '',
                email: '',
                mobile: '',
                phone: '',
                other: '',
                business_address: '',
                website: '',
                commission_type_id: 1,
                percentage: '0',
                tags: [],
                notes: '',
                contacts: []
            },
            contact_payload: {
                company_id: 0,
                agency_id: 0,
                firstname: '',
                lastname: '',
                email: '',
                concent: false
            },
            headers: [
                { text: '', value: 'sufix' },
                { text: 'Business name', value: 'business_name' },
                { text: 'Website', value: 'website' },
                { text: 'Phone', value: 'phone' },
                { text: 'Created', value: 'created_at' },
                { text: '', value: 'action' }
            ],
            headers_contact: [
                { text: '', value: 'contact', width: '80%' },
                { text: '', value: 'action', width: '20%' },
            ],
            filter_search: '',
            tag_name: '',
            is_edit: false,
            items: [
                { title: 'Add existing contact' },
                { title: 'Create new' },
            ],
            dialog_add_contact: false,
            dialog_existing_contact: false,
            dialog_add_contact_concent: false,
            existing_contact: 0,
        }),
        async mounted () {
            await this.$store.dispatch('agencies/fetch_commission_type');
            await this.$store.dispatch('agencies/fetch_agencies_info', this.get_selected_company.id);
            await this.$store.dispatch('agencies/fetch_contact_info', this.get_selected_company.id);
        },
        created () {
            this.setPageName('Agencies')
        },
        computed: {
            ...mapGetters({
                get_selected_company:       'auth/get_selected_company',
                get_commission_type:        'agencies/get_commission_type',
            }),
            get_selected_commission_type(){
                return this.$store.getters['agencies/get_selected_commission_type'](this.payload.commission_type_id)
            },
            get_agencies_info(){
                return this.$store.getters['agencies/get_agencies_info'](this.filter_search)
            },
            get_contact_info(){
                return this.$store.getters['agencies/get_contact_info'](this.payload.contacts)
            }
        },  
        methods: {
            ...mapActions({
                setPageName:    'pageNameStore/setPageNameAction'
            }),
            NewAgency(){
                this.ClearPayload();
                this.dialog = true;
                this.agency_title = 'Create agency';
                this.payload.company_id = this.get_selected_company.id;
            },
            RemoveTag(index){
                this.payload.tags.splice(index, 1);
            },
            AddTag(){
                this.ValidateTags(this.payload.tags, this.tag_name).then((response) => {
                    if(!response){
                        this.payload.tags.push(this.tag_name);
                        this.tag_name = '';
                    }
                });
            },
            ValidateTags(tags, tag_name){
                const promise = new Promise(function (resolve) {
                    tags.forEach(element => {
                        if(element.toUpperCase() == tag_name.toUpperCase()){
                            resolve(true);
                        }
                    });
                    resolve(false);
                });
                return promise;
            },
            ValBusinessName(){
                if(Validation.valBlank(this.payload.business_name)){
                    this.validation.business_name.message = 'Please input your business name.';
                    this.validation.business_name.show = true;
                    this.validation.business_name.success = false;
                    return true;
                }
                else{
                    this.validation.business_name.show = false;
                    return false;
                }
            },
            ValFirstname(){
                if(Validation.valBlank(this.contact_payload.firstname)){
                    this.validation.firstname.message = 'Please input the agent firstname.';
                    this.validation.firstname.show = true;
                    this.validation.firstname.success = false;
                    return true;
                }
                else{
                    this.validation.firstname.show = false;
                    return false;
                }
            },
            ValLastname(){
                if(Validation.valBlank(this.contact_payload.lastname)){
                    this.validation.lastname.message = 'Please input the agent lastname.';
                    this.validation.lastname.show = true;
                    this.validation.lastname.success = false;
                    return true;
                }
                else{
                    this.validation.lastname.show = false;
                    return false;
                }
            },
            FormValidation(){
                this.err_counter = 0;
                if(this.ValBusinessName()){
                    this.err_counter += 1;
                }
                return this.err_counter;
            },
            FormValidationContact(){
                this.err_counter = 0;
                if(this.ValFirstname()){
                    this.err_counter += 1;
                }
                if(this.ValLastname()){
                    this.err_counter += 1;
                }
                return this.err_counter;
            },
            async SaveAgenciesInformation(){
                if(this.FormValidation()==0){
                    await this.$axios.post('api/agencies/create', this.payload).then(({data}) => {
                        if(data.response){
                            this.$store.dispatch('agencies/set_agency', data.data);
                            this.dialog = false;
                        }
                        else{
                            console.log('Something went wrong!');
                        }
                    });
                }
            },
            ShowArchived(){
                if(this.show_archived){
                    this.$store.dispatch('agencies/fetch_archived_agencies_info', this.get_selected_company.id);
                }
                else{
                    this.$store.dispatch('agencies/fetch_agencies_info', this.get_selected_company.id);
                }
            },
            async Archive(id){
                await this.$axios.post('api/agencies/archived_agency', { id: id }).then(({data}) => {
                    if(data.response){
                        this.$store.dispatch('agencies/fetch_agencies_info', this.get_selected_company.id);
                    }
                    else{
                        console.log('Something went wrong!');
                    }
                });
            },
            async Restore(id){
                await this.$axios.post('api/agencies/restore_archived_agency', { id: id }).then(({data}) => {
                    if(data.response){
                        this.$store.dispatch('agencies/fetch_archived_agencies_info', this.get_selected_company.id);
                    }
                    else{
                        console.log('Something went wrong!');
                    }
                });
            },
            ClearPayload(){
                this.payload.id = 0;
                this.payload.company_id = 0;
                this.payload.business_name = '';
                this.payload.email = '';
                this.payload.mobile = '';
                this.payload.phone = '';
                this.payload.other = '';
                this.payload.business_address = '';
                this.payload.website = '';
                this.payload.commission_type_id = 1;
                this.payload.percentage = 0;
                this.payload.tags = [];
                this.payload.notes = '';
                this.payload.contacts = [];
            },
            ClearContactPayload(){
                this.contact_payload.firstname = '';
                this.contact_payload.lastname = '';
                this.contact_payload.email = '';
                this.contact_payload.concent = false;
            },
            EditAgency(item){
                this.$axios.post(`agencies/fetch_agency/${item.id}`).then(({data}) => {
                    if(data.response){
                        this.dialog = true;
                        this.agency_title = 'Edit agency';
                        this.is_edit = true;
                        this.payload.id = data.data.id;
                        this.payload.business_name = data.data.business_name;
                        this.payload.email = data.data.email;
                        this.payload.mobile = data.data.mobile;
                        this.payload.phone = data.data.phone;
                        this.payload.other = data.data.other;
                        this.payload.business_address = data.data.business_address;
                        this.payload.website = data.data.website;
                        this.payload.commission_type_id = data.data.commission_type_id;
                        this.payload.percentage = data.data.percentage;
                        this.payload.tags = data.data.tags;
                        this.payload.notes = data.data.notes;
                        this.payload.contacts = [];
                        data.data.agency_contact.forEach((value, index) => {
                            this.payload.contacts.push({
                                index: index,
                                agency_id: value.id,
                                contact_id: value.contact_info.id,
                                firstname: value.contact_info.first_name,
                                lastname: value.contact_info.last_name,
                                email: value.contact_info.email,
                            });
                        });
                    }
                    else{
                        console.log('Something went wrong!');
                    }
                });
            },
            async UpdateAgencyInformation(){
                if(this.FormValidation()==0){
                    await this.$axios.post('api/agencies/update', this.payload).then(({data}) => {
                        if(data.response){
                            this.$store.dispatch('agencies/fetch_agencies_info', this.get_selected_company.id);
                            this.dialog = false;
                        }
                        else{
                            console.log('Something went wrong!');
                        }
                    });
                }
            },
            AddAgentContactConcent(){
                if(this.FormValidationContact()==0){
                    this.dialog_add_contact_concent = true;
                }
            },
            async AddAgentContact(){
                await this.$axios.post('api/agencies/add_agency_contact', this.contact_payload).then(({data}) => {
                    if(data.response){
                        this.payload.contacts = [];
                        data.data.agency_contact.forEach((value, index) => {
                            this.payload.contacts.push({
                                index: index,
                                agency_id: value.id,
                                contact_id: value.contact_info.id,
                                firstname: value.contact_info.first_name,
                                lastname: value.contact_info.last_name,
                                email: value.contact_info.email,
                            });
                        });
                        this.dialog_add_contact_concent = false;
                        this.dialog_add_contact = false;
                    }
                    else{
                        console.log('Something went wrong!');
                    }
                });
            },
            CreateNewAgent(){
                this.ClearContactPayload();
                this.dialog_add_contact = true;
                this.contact_payload.agency_id = this.payload.id;
                this.contact_payload.company_id = this.get_selected_company.id;
            },
            RemoveAgencyContact(id){
                this.payload.contacts = this.payload.contacts.filter(element => element.agency_id != id);
                this.$axios.post('api/agencies/remove_agency_contact', { id: id });
            },
            AddExisitingAgentContact(){
                let payload = {
                    agency_id: this.payload.id,
                    contact_id: this.existing_contact
                };
                this.$axios.post('api/agencies/add_agency_contact_exisiting', payload).then(({data}) => {
                    if(data.response){
                        this.payload.contacts = [];
                        data.data.agency_contact.forEach((value, index) => {
                            this.payload.contacts.push({
                                index: index,
                                agency_id: value.id,
                                contact_id: value.contact_info.id,
                                firstname: value.contact_info.first_name,
                                lastname: value.contact_info.last_name,
                                email: value.contact_info.email,
                            });
                        });
                        this.dialog_existing_contact = false;
                    }
                    else{
                        console.log('Something went wrong!');
                    }
                });
            }
        },
        watch: {
        }
    }
</script>

<style scoped>
.header {
    max-width: 100%;
    background-color: #79A2BD;
    height: 50px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
    text-align: center;
    position: relative;
    z-index: 1;
    line-height: 50px;
}

.dialog-header {
    max-width: 100%;
    background-color: #79A2BD;
    height: 50px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
    text-align: center;
    position: relative;
    z-index: 201;
    line-height: 50px;
}

.header h3 {
    color: white;
}
.card {
    max-width: 100%;
    background-color: white;
    margin: 25px 15px;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.card .card-header {
    font-size: 24px;
    color: #343642;
    margin-bottom: 15px;
}
.card .card-header-items{
    text-align: right;
}
.card .card-header-items .header-items{
    margin: 0px 10px;
}
.dialog-close {
    margin-left: -75px; 
    position: absolute;
}
.action-button {
    text-align: right;
}
.form-group {
    margin-top: -15px;
}
.form-group h3 {
    padding-bottom: 10px;
}
.form-group .form-control {
    margin-top: 5px;
}
.tags-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.tags-wrapper .tags {
    max-height: 50px;
    display: flex;
    background-color: #DCDEE0;
}
.tags-wrapper .tags .tag-name{
    margin: auto 0;
    padding: 12px;
    color: #343642;
    font-weight: bold;
    font-size: 16px;
    background: rgb(237, 238, 239);
}
.tags-wrapper .tags .tag-action{
    margin: auto 0;
}
.data-chip{
    background-color: rgb(56, 154, 116) !important;
    font-weight: bolder;
    font-size: 14px;
    color: white;
    margin-right: 5px;
}
.contact-info h3 {
    font-size: 16px;
}
.contact-info span {
    font-size: 12px;
}
.initial-avatar-wrapper {
    width: 100%;
    background-color: #9a3856;
    height: 100%;
}
.initial-avatar-wrapper .initial {
    color: white;
    font-size: 30px;
    font-weight: bold;
    margin: 3px 10px;
}
.initial-content p {
    margin: 0;
    font-size: 18px;
}
.initial-content p::first-letter {
    font-weight: bolder;
}
.initial-content span {
    font-size: 12px;
}
@media only screen and (max-width: 959px) {
    .card .card-header {
        text-align: center;
    }
    .card .card-header-items .header-items{
        margin: 5px 5px 10px 5px;
    }
}
</style>