<template>
  <v-container fluid>
    <v-tabs v-model="tab" fixed-tabs class="mt-9">
      <v-tab href="#tab-1"> Library </v-tab>
      <v-tab href="#tab-2"> Media </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <Library v-if="tab == 'tab-1'" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-2">
        <v-card flat>
          <Media v-if="tab == 'tab-2'" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Media from "./components/Media.vue";
import Library from "./components/Library.vue";

export default {
  name: "Resources",
  components: {
    Media,
    Library,
  },
  props: [],
  data: () => ({
    tab: null,
  }),
  async mounted() {
    await this.$store.dispatch("resources/fetch_resources_type");
    await this.$store.dispatch(
      "resources/fetch_resources_info",
      this.get_selected_company.id
    );
    await this.$store.dispatch(
      "resources/fetch_media_info",
      this.get_selected_company.id
    );
  },
    created() {
        this.setPageName("Resources")
    },
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
    }),
  },
    methods: {
        ...mapActions({
            setPageName:    'pageNameStore/setPageNameAction'
        })
    },
  watch: {},
};
</script>

<style scoped>
.header {
  max-width: 100%;
  background-color: #79a2bd;
  height: 50px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 50px;
}
.header h3 {
  color: white;
}
@media only screen and (max-width: 599px) {
}
</style>
