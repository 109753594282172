import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../components/login/router/index.js'
import Dashboard from '../components/dashboard/router/index.js'
import Register from '../components/register/router/index.js'
import ForgotPassword from '../components/forgotpassword/router/index.js'
import CompaniesRoute from '../components/companies/router/index.js'
import SupplierRoute from '../components/suppliers/router/index.js'
import ResourcesRoute from '../components/resources/router/index.js'
import ContactRoute from '../components/contacts/router/index.js'
import TaskRoute from '../components/task/router/index.js'
import AgenciesRoute from '../components/agencies/router/index.js'
import ItineraryRoute from '../components/itinerary/router/index.js'
import EmailRoute from '../components/emails/router/index.js'
import UsersRoute from '../components/users/router/index.js'
import UserActivityRoute from '../components/useractivity/router/index.js'

// My StagI Routes
import LeadRoute from "../modules/admin/leads/route"
import QuoteRoute from "../modules/admin/quotes/route"
import PackageRoute from "../modules/admin/packages/route"
import EventRoute from "../modules/admin/events/route"
import SettingsRoute from "../modules/admin/settings/route"
import ReportsRoute from "../modules/admin/reports/route"
import AgencySettingsRoute from "../modules/agency/settings/route"

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        ...Login,
        ...Dashboard,
        ...Register,
        ...ForgotPassword,
        ...CompaniesRoute,
        ...SupplierRoute,
        ...ResourcesRoute,
        ...ContactRoute,
        ...TaskRoute,
        ...AgenciesRoute,
        ...ItineraryRoute,
        ...EmailRoute,
        ...UsersRoute,
        ...UserActivityRoute,
        ...LeadRoute,
        ...QuoteRoute,
        ...PackageRoute,
        ...EventRoute,
        ...SettingsRoute,
        ...ReportsRoute,
        ...AgencySettingsRoute,
    ]
});