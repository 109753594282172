<template>
    <v-container fluid class="mt-9">
        <v-row class="ml-3">
            <v-col cols="12">
                <a class="back-link" @click="eventBackToLeads()">
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    <label>Back to Leads</label>
                </a>
            </v-col>
        </v-row>
        <v-skeleton-loader v-if="lead.email == null" type="table"></v-skeleton-loader>
        <v-card v-else class="ml-4 mt-5 pl-3 pb-3" style="border-radius: 20px;">
            <v-card-title>Edit Lead</v-card-title>
            <v-card-subtitle><small>Fill out the required information</small></v-card-subtitle>
            <v-card-text>
                <v-form ref="lead_form_data">
                    <v-row>
                        <v-col cols="6">
                            <div>
                                <label class="form-label">Advisor <span class="required-field">*</span></label>
                                <v-autocomplete v-model="lead.advisor_id" outlined dense placeholder="Select Advisor"
                                    item-text="name" item-value="id" :items="getAdvisors" autofocus requried
                                    :rules="[v => !!v || 'You are required to select an Advisor for this lead.']"></v-autocomplete>
                            </div>
                            <div>
                                <label class="form-label">Nights <span class="required-field">*</span></label>
                                <v-text-field v-model="lead.duration" outlined dense placeholder="Enter No. of Nights"
                                    type="number" required :rules="[
                    v => !!v || 'You are required to enter the number of nights for this lead.',
                    v => (v > 0) || 'The number of nights must be at least one (1) day for this lead.'
                ]"></v-text-field>
                            </div>
                            <div>
                                <label class="form-label">Group Size <span class="required-field">*</span></label>
                                <v-text-field v-model="lead.people" outlined dense placeholder="Enter Group Size"
                                    type="number" required :rules="[
                    v => !!v || 'You are required to enter the number of poeople for this lead.',
                    v => (v > 0) || 'The number of poeople must be at least one (1) for this lead.'
                ]"></v-text-field>
                            </div>
                            <div>
                                <label class="form-label">Event Type <span class="required-field">*</span></label>
                                <v-select v-model="lead.event_type_id" :items="event_types" outlined dense
                                    item-text="name" item-value="id" placeholder="Select Event Type" required :rules="[
                    v => !!v || 'You are required to select an event type for this lead.'
                ]">
                                </v-select>
                            </div>
                            <div>
                                <label class="form-label">Party Location <span class="required-field">*</span></label>
                                <v-autocomplete v-model="lead.location_id" :items="getLocation" item-text="name"
                                    item-value="id" outlined dense placeholder="Select Party Location" required :rules="[
                    v => !!v || 'You are required to select a party location for this lead.'
                ]"></v-autocomplete>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div>
                                <label class="form-label">First Name <span class="required-field">*</span></label>
                                <v-text-field v-model="lead.first_name" outlined dense placeholder="Enter First Name"
                                    type="text" required :rules="[
                    v => !!v || 'You are required to enter the first name of the organizer for this lead.'
                ]"></v-text-field>
                            </div>
                            <div>
                                <label class="form-label">Last Name <span class="required-field">*</span></label>
                                <v-text-field v-model="lead.last_name" outlined dense placeholder="Enter Last Name"
                                    type="text" required :rules="[
                    v => !!v || 'You are required to enter the last name of the organizer for this lead.'
                ]"></v-text-field>
                            </div>
                            <div>
                                <label class="form-label">Mobile Number</label>
                                <v-text-field v-model="lead.phone" outlined dense placeholder="Enter Mobile Number"
                                    type="text"></v-text-field>
                            </div>
                            <div>
                                <label class="form-label">Email <span class="required-field">*</span></label>
                                <v-text-field v-model="lead.email" outlined dense placeholder="Enter Email" type="text"
                                    required :rules="[
                    v => !!v || 'You are required to enter the email address of the organizer for this lead.'
                ]"></v-text-field>
                            </div>
                            <div>
                                <label class="form-label">Party Date <span class="required-field">*</span></label>
                                <v-menu ref="menu" v-model="party_date.menu" :close-on-content-click="false"
                                    :return-value.sync="party_date.date" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="party_date.formatted_date" outlined dense
                                            placeholder="Select a Party Date to start." type="text" v-bind="attrs"
                                            v-on="on" required :rules="[
                    v => !!v || 'You are required to select a party date for this lead.'
                ]">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="party_date.date" @change="eventSave"
                                        :events="addPartyActivities" event-color="green"></v-date-picker>
                                </v-menu>
                            </div>
                            <!-- <div>
                                <label class="form-label">Password <span class="required-field">*</span></label>
                                <v-text-field v-model="lead.password" outlined dense placeholder="Enter Password"
                                    :type="show_password ? 'text' : 'password'"
                                    :append-icon="show_password ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click:append="show_password = !show_password" autocomplete="new-password" required
                                    :rules="[
                                        v => !!v || 'You are required to enter the password of the organizer for this lead.'
                                    ]"></v-text-field>
                            </div>
                            <div>
                                <label class="form-label">Confirm Password</label>
                                <v-text-field v-model="confirm_password" outlined dense placeholder="Enter Confirm Password"
                                    :type="show_confirm_password ? 'text' : 'password'"
                                    :append-icon="show_confirm_password ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click:append="show_confirm_password = !show_confirm_password" required :rules="[
                                        v => (v === this.lead.password) || 'The password you enter does not match with the confirmed password.'
                                    ]"></v-text-field>
                            </div> -->
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="ml-2">
                <v-btn color="black" class="white--text" @click="eventSaveLeadChanges()">Save Changes</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="lead.email != null" class="ml-4 mt-5 pl-3 pb-3" style="border-radius: 20px;">
            <v-card-title>
                View Quotes
                <v-spacer></v-spacer>
                <v-btn class="mr-2 white--text" color="black" @click="createCustomQuote()">Create Custom Quote</v-btn>
                <v-btn color="primary" @click="createQuickQuote()">Quick Quote</v-btn>
            </v-card-title>
            <v-card-subtitle>
                <v-chip color="success" outlined style="border: none;"><v-icon color="success"
                        small>mdi-star-outline</v-icon> =
                    Booked Quotes</v-chip>
            </v-card-subtitle>
            <v-card-text>
                <v-data-table :headers="quote_headers" :items="lead.quotes" :sort-by.sync="sort_by"
                    :sort-desc.sync="sort_desc">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td @click="viewCustomQuote(item)">
                                <label><v-icon color="success" small
                                        v-if="item.is_booked == 1">mdi-star-outline</v-icon></label>
                            </td>
                            <td @click="viewCustomQuote(item)">{{ item.id }}</td>
                            <td @click="viewCustomQuote(item)">{{ item.name }}</td>
                            <td @click="viewCustomQuote(item)">{{ item.type }}</td>
                            <td @click="viewCustomQuote(item)">
                                <label v-if="item.total">{{ get_selected_company.currency }}{{ item.total.toFixed(2) }}</label>
                            </td>
                            <td @click="viewCustomQuote(item)">
                                <label v-if="item.groupsize">{{ item.groupsize }}</label>
                            </td>
                            <td @click="viewCustomQuote(item)">
                                <v-chip color="success" v-if="item.date_sent">
                                    {{ $date(item.date_sent).format('DD-MMM-YYYY') }}
                                </v-chip>
                                <v-chip color="error" v-else>Never</v-chip>
                            </td>
                            <td @click="viewCustomQuote(item)">
                                <label>{{ $date(item.created_at).format('MMM DD YYYY') }}</label>
                                <br>
                                <label style="color:#3886A7;">{{ $date(item.created_at).format('h:m A') }}</label>
                            </td>
                            <td>
                                <div style="display:flex;align-items:center;">
                                    <v-btn class="mr-2" color="black" dark @click="openCreateEventDialog(item)"
                                        v-if="!item.is_booked">Book Quote</v-btn>
                                    <v-btn class="mr-2" color="black" dark @click="unbookQuote(item)"
                                        v-else>Unbook
                                        Quote</v-btn>
                                    <v-menu right bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on">
                                                <v-icon color="black">mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item style="cursor:pointer;" @click="viewCustomQuote(item)">
                                                <v-list-item-title>
                                                    <v-icon>mdi-magnify</v-icon>View/Update Quote
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item style="cursor:pointer;" @click="sendQuote(item)">
                                                <v-list-item-title>
                                                    <v-icon>mdi-navigation-variant-outline</v-icon>Send Quote
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item style="cursor:pointer;" @click="copyQuote(item)">
                                                <v-list-item-title>
                                                    <v-icon>mdi-content-copy</v-icon>Copy Quote
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item style="cursor:pointer;" @click="deleteQuoteDialog(item)">
                                                <v-list-item-title>
                                                    <v-icon>mdi-delete</v-icon>Delete Quote
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>

            </v-card-actions>
        </v-card>
        <ConfirmDeleteQuote :delete_quote_dialog="delete_quote_dialog" :quote_id="delete_quote_id"
            @closeDeleteQuoteDialog="closeDeleteQuoteDialog()" @deleteQuote="deleteQuote()"></ConfirmDeleteQuote>
        <CreateNewEvent :create_event_dialog="create_event_dialog" :quote="quote_for_event" :lead="lead"
            @closeCreateEventDialog="closeCreateEventDialog()" @createEvent="createEvent" @bookQuote="bookQuote">
        </CreateNewEvent>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Leads from '@/modules/admin/leads/modules/leads.js'
import Quotes from '@/modules/admin/quotes/modules/quotes.js'
import Events from '@/modules/admin/events/modules/events.js'
import Settings from '@/modules/admin/settings/modules/settings.js'
import ConfirmDeleteQuote from './components/ConfirmDeleteQuote.vue'
import CreateNewEvent from '../events/components/CreateNewEvent.vue'

export default {
    components: {
        ConfirmDeleteQuote, CreateNewEvent
    },
    data: () => ({
        quote_headers: [
            { text: '', value: 'is_booked', sortable: false, align: 'center' },
            { text: 'ID', value: 'id', width: '5%', sortable: false, align: 'center' },
            { text: 'Quote Name', value: 'name' },
            // { text: 'Location', value: 'location' },
            // { text: 'Advisor', value: 'advisor' },
            { text: 'Type', value: 'type' },
            { text: 'Total', value: 'total' },
            { text: 'Groupsize', value: 'groupsize' },
            { text: 'Date Sent', value: 'date_sent' },
            { text: 'Created', value: 'created_at' },
            { text: 'Action', value: 'action', width: '100', align: 'center' },
        ],
        lead: {
            id: null,
            advisor_id: 0,
            duration: 1,
            event_type_id: null,
            start_date: null,
            location_id: null,
            first_name: null,
            last_name: null,
            phone: null,
            email: null,
            password: null,
            people: 1,
            company_id: null
        },
        event_types: [],
        party_date: {
            menu: false,
            date: new Date().toISOString().split("T")[0],
            formatted_date: new Date().toLocaleDateString(),
        },
        show_password: false,
        show_confirm_password: false,
        confirm_password: null,
        classes: {
            leads: null,
            quotes: null,
            events: null,
            settings: null,
        },
        sort_by: 'id',
        sort_desc: true,
        delete_quote_id: null,
        delete_quote_dialog: false,
        create_event_dialog: false,
        quote_for_event: null,
    }),
    computed: {
        ...mapGetters({
            getAdvisors: 'leadsStore/getAdvisorsGetter',
            get_selected_company: 'auth/get_selected_company',
            getLocation: 'locationStore/getLocationsGetter',
            getLeadsById: 'leadsStore/getLeadsByIdGetter'
        }),
        addPartyActivities() {
            let number_of_days = 0;
            return [...Array(parseInt(this.lead.duration))].map(() => {
                const event_date = new Date(this.party_date.date)
                number_of_days = number_of_days + 1
                event_date.setDate(event_date.getDate() + number_of_days)
                return event_date.toISOString().substr(0, 10)
            })
        }
    },
    async mounted() {
        this.setPageName("Edit Lead")

        this.classes.leads = new Leads()
        this.classes.quotes = new Quotes()
        this.classes.events = new Events()
        this.classes.settings = new Settings()

        this.lead.id = this.$route.params.id
        const lead_data = await this.classes.leads.getLead({ lead_id: this.lead.id })
        this.lead = lead_data
        this.party_date.date = new Date(this.lead.start_date).toISOString().split("T")[0]
        this.party_date.formatted_date = new Date(this.lead.start_date).toLocaleDateString()
        this.confirm_password = lead_data.password

        this.getEventTypes()
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
            setLeads: 'leadsStore/setLeadsAction',
        }),
        eventBackToLeads() {
            this.$router.push({ name: 'Leads' })
        },
        async getEventTypes() {
            const result = await this.classes.settings.getEventTypes({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.event_types = result.data
            }
        },
        async fetchLead() {
            this.lead = await this.classes.leads.getLead({ lead_id: this.lead.id })
        },
        eventSave(party_date) {
            this.$refs.menu.save(party_date)
            this.party_date.formatted_date = new Date(party_date).toLocaleDateString()
        },
        async eventSaveLeadChanges() {
            const valid = this.$refs.lead_form_data.validate()
            if (valid) {
                this.lead.start_date = this.party_date.date
                const result = await this.classes.leads.updateLead(this.lead).then((data) => data)
                if (result.response == true) {
                    this.fetchLead()
                    this.$toast.success(result.message, { timeout: 2000, icon: true })
                } else {
                    this.$toast.error(`${result.data.status}: ${result.data.statusText}`)
                }
            }
        },
        createCustomQuote() {
            this.$router.push({ name: 'CreateCustomQuote', params: { lead_id: this.lead.id } })
        },
        createQuickQuote() {
            this.$router.push({ name: 'CreateQuickQuote', params: { lead_id: this.lead.id } })
        },
        viewCustomQuote(item) {
            this.$router.push({ name: 'EditCustomQuote', params: { lead_id: this.lead.id, quote_id: item.id } })
        },
        async copyQuote(item) {
            let payload = {
                company_id: this.get_selected_company.id,
                ...item
            }
            const result = await this.classes.quotes.copyQuote(payload).then((data) => data)
            if (result.response == true) {
                this.lead = result.data
                this.$toast.success(result.message, { timeout: 2000, icon: true })
            }
            else {
                this.$toast.error(`${result.status}: ${result.data.message}`)
            }
        },
        async sendQuote(item) {
            if (item.total == null) {
                this.$toast.error('Please edit this quote and make sure the total price is saved')
                return
            }
            let payload = {
                company_id: this.get_selected_company.id,
                lead: this.lead,
                quote: item
            }
            const result = await this.classes.quotes.sendEmailQuote(payload).then((data) => data)
            if (result.response == true) {
                this.lead = result.data
                this.$toast.success(result.message, { timeout: 2000, icon: true })
            }
            else {
                this.$toast.error(`${result.status}: ${result.data.message}`)
            }
        },
        openCreateEventDialog(item) {
            this.create_event_dialog = true
            this.quote_for_event = item
        },
        closeCreateEventDialog() {
            this.create_event_dialog = false
        },
        async createEvent(event_data, quote_data) {
            let payload = {
                company_id: this.get_selected_company.id,
                company: this.get_selected_company,
                lead_id: this.lead.id,
                name: event_data.name,
                deposit_amount: parseFloat(event_data.deposit_amount),
                quote_id: quote_data.id,
            }
            const result = await this.classes.events.createEvent(payload).then((data) => data)
            if (result.response == true) {
                let accom_groupsize = [this.lead.people]
                let quote_payload = {
                    event_id: result.data.id,
                    builds: quote_data.builds,
                    groupsize: this.lead.people,
                    accom_groupsize: accom_groupsize,
                }
                this.classes.quotes.updateQuoteBuilds(quote_payload)

                this.$toast.success(result.message, { timeout: 2000, icon: true })
                this.fetchLead()
                this.closeCreateEventDialog()
            }
        },
        async bookQuote(quote_data) {
            let payload = {
                company_id: this.get_selected_company.id,
                id: quote_data.id,
                event_id: this.lead.event.id,
                is_booked: 1,
            }
            const result = await this.classes.quotes.updateQuote(payload)
            if (result.response == true) {
                let accom_groupsize = [this.lead.people]
                let quote_payload = {
                    event_id: this.lead.event.id,
                    builds: quote_data.builds,
                    groupsize: this.lead.people,
                    accom_groupsize: accom_groupsize,
                }
                this.classes.quotes.updateQuoteBuilds(quote_payload)

                this.$toast.success(result.message, { timeout: 2000, icon: true })
                this.fetchLead()
                this.closeCreateEventDialog()
            }
        },
        async unbookQuote(item) {
            let payload = {
                company_id: this.get_selected_company.id,
                id: item.id,
                event_id: null,
                is_booked: 0,
            }
            const result = await this.classes.quotes.updateQuote(payload)
            if (result.response == true) {
                this.$toast.success(result.message, { timeout: 2000, icon: true })
                this.fetchLead()
            }
        },
        deleteQuoteDialog(item) {
            this.delete_quote_dialog = true
            this.delete_quote_id = item.id
        },
        closeDeleteQuoteDialog() {
            this.delete_quote_dialog = false
        },
        async deleteQuote() {
            const result = await this.classes.quotes.deleteCustomQuote({ quote_id: this.delete_quote_id, company_id: this.get_selected_company.id, lead_id: this.lead.id }).then((data) => data)
            if (result.response == true) {
                this.$toast.success(result.message, { timeout: 2000, icon: true })
                this.fetchLead()
                this.closeDeleteQuoteDialog()
            }
            else {
                this.$toast.error(`${result.data.status}: ${result.data.data.message}`)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.back-link {
    font-family: 'Manrope';
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #3988AA;

    .v-icon {
        color: #3988AA;
    }

    label {
        cursor: pointer;
    }
}

.v-card__title {
    font-family: 'Manrope';
    font-size: 20px !important;
    font-weight: 600 !important;
    color: black;
}

.v-btn {
    font-family: 'Manrope';
    font-weight: 600 !important;
    font-size: 13px !important;
}

.form-label {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 600;
    color: #7C7C7C;
}

.required-field {
    color: red
}

.v-chip__content {
    font-family: 'Manrope';
}
</style>