export default {
    namespaced: true,
    state: {
        packages: []
    },
    mutations: {
        setPackagesMutation(state, payload) {
            state.packages = [...payload]
        },
    },
    getters: {
        getPackages: (state) => state.packages
    },
    actions: {
        setPackagesAction({ commit }, payload) {
            commit('setPackagesMutation', payload)
        },
    }
}