
export default {
    namespaced: true,
    state: {
        locations: []
    },
    mutations: {
        setLocationMutation(state, payload) {
            state.locations = [...payload]
        }
    },
    getters: {
        getLocationsGetter: (state) => state.locations,
    },
    actions: {
        setLocationAction({commit}, payload) {
            commit('setLocationMutation', payload)
        }
    }
}