<template>
  <v-container fluid>
    <v-row class="text-center" v-if="get_selected_company.company_name">
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <v-card class="card-format">
              <v-card-title>Income</v-card-title>
              <v-card-text>
                <apexchart type="area" :options="options" :series="options.series" width="90%"></apexchart>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="card-format">
              <v-card-title>Daily Payments</v-card-title>
              <v-card-text>
                <apexchart type="area" :options="options" :series="options.series" width="90%"></apexchart>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-card class="card-format">
          <v-card-title>Upcoming Events</v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="events" :sort-by.sync="sort_by" :sort-desc.sync="sort_desc"
              hide-default-footer>
              <template v-slot:item="{ item }">
                <tr style="cursor:initial;text-align:left;">
                  <td><b>{{ item.name }}</b></td>
                  <td>{{ item.lead.first_name }} {{ item.lead.last_name }}</td>
                  <td>{{ $date(item.lead.start_date).format('MMM DD, YYYY') }}</td>
                  <td>{{ item.reference_code }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Events from '../../modules/admin/events/modules/events.js'

export default {
  name: 'Dashboard',
  data: () => ({
    headers: [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Organiser', value: 'lead.first_name', sortable: false },
      { text: 'Event Date', value: 'lead.start_date', sortable: false },
      { text: 'Code', sortable: false },
    ],
    events: [],
    classes: {
      events: null,
    },
    sort_by: 'lead.start_date',
    sort_desc: false,
    options: {
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      series: [
        {
          name: "Amount",
          data: [45, 52, 48, 45, 49, 43, 50],
        }
      ],
      stroke: {
        colors: ['#3988AA'],
        width: 1,
      },
      fill: {
        colors: ['#6ED4F4'],
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        }
      },
      xaxis: {
        categories: [
          "01 Jan",
          "02 Jan",
          "03 Jan",
          "04 Jan",
          "05 Jan",
          "06 Jan",
          "07 Jan"
        ]
      }
    },
  }),
  computed: {
    ...mapGetters({
      get_selected_company: 'auth/get_selected_company'
    })
  },
  created() {
    this.setPageName('Dashboard')
    this.classes.events = new Events()
  },
  async mounted() {
    this.getEvents()
  },
  methods: {
    ...mapActions({
      setPageName: 'pageNameStore/setPageNameAction'
    }),
    async getEvents() {
      if (this.get_selected_company.id) {
        const result = await this.classes.events.getEvents({ company_id: this.get_selected_company.id })
        if (result.response == true) {
          this.events = result.data
          this.orig_events = result.data
          this.loading = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card-format {
  border-radius: 15px !important;

  .v-data-footer {
    background-color: #E9E9E9;
    color: #7C7C7C;
    font-family: 'Work Sans';
  }

  .v-data-footer__select .v-text-field {
    background-color: white;

    .v-select__slot {
      padding-left: 10px;
    }
  }
}
</style>