<template>
    <v-dialog
        :value="value"
        persistent
        max-width="560"
    >
      <v-card>
        <v-card-title>
            {{ get_payment_notification_suppliers.length }} Item in list
        </v-card-title>
        <v-card-subtitle>
            You are about to send a payment notification
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="close_dialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="send_notification()"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: [
    'value'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_payment_notification_suppliers:         'itinerary/get_payment_notification_suppliers',
        get_group_name:                             'itinerary/get_group_name',
    })
  },
  methods: {
    showSnackBar(message){
        this.$store.commit("auth/setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    close_dialog(){
        this.$emit('close_dialog')
    },
    async send_notification(){
        this.showSnackBar('Please wait...')
        await this.$axios.post('api/itinerary/pricing/send_payment_notification', {data: this.get_payment_notification_suppliers, group_name: this.get_group_name})
        this.close_dialog()
        this.showSnackBar('Email sent')
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>