<template>
    <div>
        <v-dialog
            :value="dialog_value"
            persistent
            max-width="770px"
            overlay-color="#f4f4f5"
            overlay-opacity="1"
        >
            <v-btn fab @click="close_dialog()" class="dialog-close">
                <v-icon dark> mdi-arrow-left </v-icon>
            </v-btn>
            <v-card-title>
                {{ title }}
            </v-card-title>
            <v-card-title>
                <v-row>
                    <v-col
                        cols="6"
                    >
                        <v-select
                            v-model="value"
                            :items="items"
                            multiple
                            chips
                            hint="included details"
                            persistent-hint
                            dense
                            outlined
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                </v-chip>
                                <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                >
                                    (+{{ value.length - 1 }} others)
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col
                        cols="6"
                    >
                        <v-select
                            v-model="value_email"
                            :items="items_email"
                            multiple
                            chips
                            hint="Email settings"
                            persistent-hint
                            dense
                            outlined
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                </v-chip>
                                <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                >
                                    (+{{ value_email.length - 1 }} others)
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-card
                    v-for="(item, itemindex) in data"
                    :key="itemindex"
                >
                    <v-list
                        class="mb-5"
                        dense
                        two-line
                    >
                        <v-list-group>
                            <template v-slot:activator>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-icon>
                                            {{ item.supplier_product_type.icon }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <label
                                                style="font-weight: 600; font-size: medium;"
                                            >
                                                {{ item.amenities.name }}
                                            </label>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <label>
                                                {{ item.supplier_info.supplier_name }}
                                            </label>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn
                                            icon
                                            v-if="item.supplier_info.email === null"
                                        >
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >mdi-information</v-icon>
                                                </template>
                                                <span>This supplier doesn't have an email set</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-row>
                                            <label
                                                style="font-weight: 400; font-size: small;"
                                            >
                                                {{ item.supplier_info.email }}
                                            </label>
                                            <v-btn
                                                class="ml-2"
                                                small
                                                icon
                                                @click.prevent="view_email_outline(item)"
                                                @mousedown.stop 
                                                @touchstart.native.stop
                                            >
                                                <v-icon
                                                    style="position: relative; top: -2px;"
                                                >
                                                    mdi-eye-outline
                                                </v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-textarea
                                        label="note to supplier"
                                        outlined
                                        dense
                                        v-model="note[itemindex]"
                                    >
                                    </v-textarea>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    color="primary"
                    class="pl-5 pr-5"
                    rounded
                    style="text-transform: unset !important;"
                    @click="send_request()"
                >
                    send {{ data.length }} request
                </v-btn>
            </v-card-actions>
        </v-dialog>
        <v-row justify="center">
            <v-dialog
                v-model="email_layout"
                persistent
                max-width="720"
            >
                <v-card
                    v-if="Object.keys(data_preview).length > 0"
                >
                    <v-card-title class="text-h5">
                        <label
                            v-if="title.toLowerCase().includes('send booking request')"
                            style="font-weight: 300; font-size: smaller;"
                        >
                            Preview of booking request email
                        </label>
                        <label
                            v-else
                            style="font-weight: 300; font-size: smaller;"
                        >
                            Preview of cancellation request email
                        </label>
                    </v-card-title>
                    <v-card-text>
                        <label
                            style="font-weight: 500; font-size: 16px;"
                        >
                            Dear {{ data_preview.supplier_info.supplier_name }}
                        </label>
                    </v-card-text>
                    <v-card-text>
                        <label
                            v-if="title.toLowerCase().includes('send booking request')"
                            style="font-weight: 300; font-size: 16px;"
                        >
                            Please reserve the following booking
                        </label>
                        <label
                            v-else
                            style="font-weight: 300; font-size: 16px;"
                        >
                            Please cancel the following booking(s)
                        </label>
                    </v-card-text>
                    <v-card-text>
                        <label
                            style="font-weight: 700; font-size: 20px;"
                        >
                            Booking 1 of 1
                        </label>
                        <li
                            style="font-size: 15px;"
                        >
                            <strong>Supplier Name: </strong> {{ data_preview.supplier_info.supplier_name }}
                        </li>
                        <li
                            style="font-size: 15px;"
                        >
                            <strong>Itinerary Name: </strong> {{ this.get_itinerary.itinerary_name }}
                        </li>
                    </v-card-text>
                    <v-card-text>
                        <label
                            style="font-weight: 700; font-size: 20px;"
                        >
                            Item 1
                        </label>
                        <li
                            v-if="value.includes('Product Inclusion')"
                            style="font-size: 15px;"
                        >
                            <strong>Product name: </strong> {{ data_preview.supplier_product.name }}
                        </li>
                        <li
                            v-if="value.includes('Product Inclusion')"
                            style="font-size: 15px;"
                        >
                            <strong>Option name: </strong> {{ data_preview.supplier_product_option.name }}
                        </li>
                        <li
                            v-if="value.includes('Dates')"
                            style="font-size: 15px;"
                        >
                            <strong>Check-in date: </strong> {{ data_preview.check_in_date }}
                        </li>
                        <li
                            v-if="value.includes('Dates')"
                            style="font-size: 15px;"
                        >
                            <strong>Check-out date: </strong> {{ data_preview.check_out_date }}
                        </li>
                        <li
                            v-if="value.includes('Times')"
                            style="font-size: 15px;"
                        >
                            <strong>Check-in time: </strong> {{ data_preview.check_in_time }}
                        </li>
                        <li
                            v-if="value.includes('Times')"
                            style="font-size: 15px;"
                        >
                            <strong>Check-out time: </strong> {{ data_preview.check_out_time }}
                        </li>
                        <li
                            v-if="value.includes('Quantities')"
                            style="font-size: 15px;"
                        >
                            <strong>Item quantity: </strong> {{ data_preview.count }}
                        </li>
                        <li
                            v-if="value.includes('Item type')"
                            style="font-size: 15px;"
                        >
                            <strong>Item type: </strong> {{ data_preview.supplier_product_type.name }}
                        </li>
                    </v-card-text>
                    <v-card-text
                        v-if="data_preview.children.length > 0"
                    >
                        <div
                            v-for="(item, i) in data_preview.children"
                            :key="i"
                            class="mb-4"
                        >
                            <label
                                style="font-weight: 700; font-size: 20px;"
                            >
                                Item {{ i + 2 }}
                            </label>
                            <li
                                v-if="value.includes('Product Inclusion')"
                                style="font-size: 15px;"
                            >
                                <strong>Product name: </strong> {{ item.supplier_product.name }}
                            </li>
                            <li
                                v-if="value.includes('Product Inclusion')"
                                style="font-size: 15px;"
                            >
                                <strong>Option name: </strong> {{ item.supplier_product_option.name }}
                            </li>
                            <li
                                v-if="value.includes('Dates')"
                                style="font-size: 15px;"
                            >
                                <strong>Check-in date: </strong> {{ item.check_in_date }}
                            </li>
                            <li
                                v-if="value.includes('Dates')"
                                style="font-size: 15px;"
                            >
                                <strong>Check-out date: </strong> {{ item.check_out_date }}
                            </li>
                            <li
                                v-if="value.includes('Times')"
                                style="font-size: 15px;"
                            >
                                <strong>Check-in time: </strong> {{ item.check_in_time }}
                            </li>
                            <li
                                v-if="value.includes('Times')"
                                style="font-size: 15px;"
                            >
                                <strong>Check-out time: </strong> {{ item.check_out_time }}
                            </li>
                            <li
                                v-if="value.includes('Quantities')"
                                style="font-size: 15px;"
                            >
                                <strong>Item quantity: </strong> {{ item.count }}
                            </li>
                            <li
                                v-if="value.includes('Item type')"
                                style="font-size: 15px;"
                            >
                                <strong>Item type: </strong> {{ item.supplier_product_type.name }}
                            </li>
                        </div>
                    </v-card-text>
                    <v-card-text
                        v-if="value_email.includes('Enable supplier confirmation')"
                    >
                        <label
                            v-if="title.toLowerCase().includes('send booking request')"
                            style="font-weight: 300; font-size: medium;"
                        >
                            If you have availability and all details including price are correct, please click confirm 
                        </label>
                        <label
                            v-if="title.toLowerCase().includes('cancel booking request')"
                            style="font-weight: 300; font-size: medium;"
                        >
                            To respond to this cancellation request, click the button below.
                        </label>
                        <br/><br/>
                        <v-btn
                            rounded
                            color="primary"
                            class="pa-5"
                        >
                            confirm
                        </v-btn>
                        <label
                            v-if="title.toLowerCase().includes('send booking request')"
                            style="font-weight: 300; font-size: medium; display: block;"
                            class="mt-5"
                        >
                            If you do have availability but with different rate or no availability at all and no alternatives, please send us a reply email<br/><br/>
                            I look forward to receiving your response.
                        </label>
                        <label
                            v-else
                            style="font-weight: 300; font-size: medium; display: block;"
                            class="mt-5"
                        >
                            I look forward to receiving your response.
                        </label>
                    </v-card-text>
                    <v-card-text>
                        Regards
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined
                            @click="email_layout = false"
                            rounded
                            class="pl-10 pr-10"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
    'dialog_value',
    'data',
    'data_length',
    'title'
  ],
  data () {
    return {
        note: [],
        email: null,
        supplier_count: 0,
        items: [
            'Product Inclusion',
            'Dates',
            'Times',
            'Quantities',
            'Traveller names',
            'Number of travellers',
            'Booking reference',
            'Item type',
            'Net price',
            'Gross price',
            'Commission',
            'Note to supplier',
        ],
        value: [
            'Product Inclusion',
            'Dates',
            'Times',
            'Quantities',
            'Traveller names',
            'Number of travellers',
            'Booking reference',
            'Item type',
            'Net price',
            'Gross price',
            'Commission',
            'Note to supplier',
        ],
        items_email: [
            'Bcc to sender',
            'Enable supplier confirmation',
            'Group by recipient'
        ],
        value_email: [
            'Bcc to sender',
            'Enable supplier confirmation',
            'Group by recipient'
        ],
        email_layout: false,
        data_preview: {}
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_itinerary:                 'itinerary/get_itinerary'
    })
  },
  methods: {
    showSnackBar(message){
        this.$store.commit("auth/setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    close_dialog(){
        this.$emit('close_dialog')
    },
    send_request(){
        let to_send = []
        if(this.note.length === 0){
            for(let i = 0; i < this.data.length; i++){
                to_send.push({
                    supplier_info:              this.data[i],
                    note:                       "",
                    included_value:             this.value,
                    included_email_value:       this.value_email,
                    request:                    this.title,
                    name:                       this.get_itinerary.itinerary_name
                })
            }
        }else{
            this.note.forEach((q, i) => {
                to_send.push({
                    supplier_info:              this.data[i],
                    note:                       q,
                    included_value:             this.value,
                    included_email_value:       this.value_email,
                    request:                    this.title,
                    name:                       this.get_itinerary.itinerary_name
                })
            })
        }

        this.$store.dispatch('booking/send_request', {
            service: 'BookingRequestService',
            action: this.title.toLowerCase() == 'send booking request' ? 'sendBookingRequest' : 'cancelBookingRequest',
            data: to_send
        })
        this.close_dialog()
        this.showSnackBar('Email has been sent')
    },
    view_email_outline(data){
        this.data_preview = data
        this.email_layout = true
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.dialog-close {
  margin-left: -75px;
  position: absolute;
}
</style>