<template>
  <v-container fluid>
    <v-row class="mt-5 mb-15" justify="center" align="center">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Update Supplier</strong></small>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-1">
            <v-col cols="6">
              <v-text-field v-model="selected_supplier.supplier_name" label="Supplier Name" outlined dense>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-file-input v-model="file" @change="uploadImage()" label="Upload Supplier Image" outlined dense>
              </v-file-input>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="selected_supplier.service_type_id" label="Supplier Type"
                prepend-inner-icon="mdi-tooltip-text-outline" :items="get_supplier_type" item-value="id"
                item-text="name" outlined dense>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined prepend-inner-icon="mdi-at" label="Booking email"
                v-model="selected_supplier.email">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete :items="getLocations" multiple item-text="name" item-value="id" return-object
                label="Location" dense outlined v-model="supplier_locations"></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined label="Address" v-model="selected_supplier.address">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="number" v-model="selected_supplier.phone" dense label="Phone" outlined
                prepend-inner-icon="mdi-phone-outline">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="number" v-model="selected_supplier.mobile" dense label="Mobile" outlined
                prepend-inner-icon="mdi-cellphone">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="number" v-model="selected_supplier.free_phone" dense label="Freephone" outlined
                prepend-inner-icon="mdi-phone-outline">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="number" v-model="selected_supplier.others" dense label="Other" outlined
                prepend-inner-icon="mdi-phone-outline">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined v-model="selected_supplier.website" label="Website"
                prepend-inner-icon="mdi-web">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined v-model="selected_supplier.notes" label="Notes"
                prepend-inner-icon="mdi-tooltip-text-outline">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <label style="font-weight:700;">Description</label>
              <wysiwyg dense outlined v-model="selected_supplier.description" label="Description" />
            </v-col>
            <v-col cols="6">
              <label style="font-weight:700;">Admin Notes</label>
              <wysiwyg dense outlined v-model="selected_supplier.admin_notes" label="Admin Notes" />
            </v-col>
            <v-col cols="6">
              <label style="font-weight:700;">Drinking Policy</label>
              <wysiwyg dense outlined v-model="selected_supplier.drink_policy" label="Drinking Policy" />
            </v-col>
            <v-col cols="6">
              <label style="font-weight:700;">Directions</label>
              <wysiwyg dense outlined v-model="selected_supplier.directions" label="Directions" />
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined v-model="selected_supplier.time_to_center" label="Time To Center">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined v-model="selected_supplier.time_to_arrive" label="Time To Be There">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-10 mr-5">
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded class="px-5" @click="updateSupplier()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [],
  data: () => ({
    file: null,
    for_tag: null,
    show_warning_dialog_delete: false,
    dialog_delete: {
      title: "You're about to remove a supplier contact",
      body: {
        label: 'Are you sure you want to remove',
      },
    },
    change_location_dialog: false,
    e: {
      address: null,
      city: null,
      postcode: null,
      country: null,
    },
    tags: null,
    supplier_locations: [],
    updated_supplier_locations: [],
  }),
  async mounted() {
    await this.$store.dispatch('supplier/fetch_supplier_type')
    this.getSupplierLocations()
  },
  created() { },
  computed: {
    ...mapGetters({
      selected_supplier: 'supplier/get_supplier_setup',
      get_supplier_type: 'supplier/get_supplier_type',
      get_contact_to_archive: 'contact/get_contact_to_archive',
      get_selected_company: 'auth/get_selected_company',
      getLocations: 'locationStore/getLocationsGetter',
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit('auth/setMessage',
        { show: true, message: message },
        { root: 1 })
    },
    getSupplierLocations() {
      this.selected_supplier.supplier_locations.forEach(supplier_location => {
        this.supplier_locations.push(supplier_location.location)
      })
    },
    async uploadImage() {
      if (this.file != null) {
        const formData = new FormData()
        formData.append('supplier_image', this.file)
        await this.$axios.post(`admin/suppliers/upload_supplier_image/${this.selected_supplier.id}`, formData)
          .then(({ data }) => {
            if (data.response) {
              this.$toast.success(data.message)
              this.file = null
            }
          })
      }
    },
    async updateSupplier() {
      this.supplier_locations.forEach(supplier_location => {
        this.updated_supplier_locations.push(supplier_location.id)
      })
      let payload = {
        ...this.selected_supplier,
        updated_supplier_locations: this.updated_supplier_locations,
      }
      console.log(payload)
      await this.$axios.post(`admin/suppliers/update_supplier/${this.selected_supplier.id}`, payload)
        .then(({ data }) => {
          console.log(data)
          if (data.response) {
            this.showSnackBar(data.message)
          }
        })
    },
  },
};
</script>

<style scoped lang="scss"></style>
