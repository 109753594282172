
export default class Sidebar {
    constructor() {

    }

    getSidebars() {
        return [
            { id: 1, icon: "mdi-view-dashboard", label: "Dashboard", route: "/dashboard" },
            { id: 2, icon: "mdi-account-network", label: "Leads", route: "/leads" },
            { id: 3, icon: "mdi-ticket-confirmation-outline", label: "Events", route: "/events" },
            // { id: 4, icon: 'mdi-account-box-multiple-outline', label: 'Suppliers', route: '/supplier' },
            // { id: 5, icon: "mdi-list-box-outline", label: "Packages", route: "/packages" },
            // { id: 6, icon: "mdi-chart-box-multiple-outline", label: "Reports", route: "/reports" },
            // { id: 2, icon: 'mdi-clipboard-list-outline', label: 'Events', route: '/itinerary' },
            // { id: 6, icon: 'mdi-folder-multiple-outline', label: 'Resources', route: '/resources' },
            // { id: 5, icon: 'mdi-account-group-outline', label: 'Contacts', route: '/contact' },
            // { id: 6, icon: 'mdi-domain', label: 'Agencies', route: '/agencies' },
            // { id: 7, icon: 'mdi-file-tree-outline', label: 'Tasks', route: '/task' },
            // { id: 7, icon: 'mdi-account-group-outline', label: 'Team', route: '/teams' },
        ];
    }
}