<template>
    <v-dialog
        persistent
        :value="value"
        width="50%"
    >
        <v-card v-if="get_itinerary_edit_item[0]">
            <v-card-title>
                <v-icon
                    class="mr-2"
                >
                    mdi-alert
                </v-icon>
                {{ get_itinerary_edit_item[0].supplier_product.name }}
                <v-spacer/>
                <v-icon
                    @click="close_dialog_delete()"
                >mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle>
                You are about to delete an item!
                <v-spacer/>
            </v-card-subtitle>
            <v-divider/>
            <v-card-text
                v-for="item in get_itinerary_edit_item" :key="item.id"
                class="mt-5"
            >
            
                <h3>{{ item.supplier_product_option.name }}</h3>
                <br>
                Head count: {{ item.count }}
                <br>
                <strong>Booking information</strong>
                <v-row>
                    <v-col
                        cols="6"
                        class="mt-2"
                    >
                        <v-text-field
                            label="Check in date"
                            :value="`${item.check_in_date}`"
                            readonly
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="6"
                        class="mt-2"
                    >
                        <v-text-field
                            label="Check in time"
                            :value="`${item.check_in_time}`"
                            readonly
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="6"
                    >
                        <v-text-field
                            label="Check out date"
                            :value="`${item.check_out_date}`"
                            readonly
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="6"
                    >
                        <v-text-field
                            label="Check out time"
                            :value="`${item.check_out_time}`"
                            readonly
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    outlined
                    rounded
                    class="pl-8 pr-8"
                    @click="close_dialog_delete()"
                >
                    <v-icon
                        class="mr-2"
                    >
                        mdi-close
                    </v-icon>
                    close
                </v-btn>
                <v-btn
                    color="red"
                    rounded
                    class="pl-10 pr-10"
                    @click="proceed_delete()"
                >
                    <v-icon
                        class="mr-2"
                    >
                        mdi-delete
                    </v-icon>
                    Delete
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
    'value'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
      get_itinerary_edit_item:        'itinerary/get_itinerary_edit_item'
    })
  },
  methods: {
    close_dialog_delete(){
        this.$emit('close_dialog_delete')
    },
    proceed_delete(){
        this.get_itinerary_edit_item.forEach(item => {
            this.$store.dispatch('itinerary/delete_itinerary_item', item.id)
        })
        this.close_dialog_delete()
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>