import Vue from 'vue'

export default class Booking
{
    constructor(store) 
    {
        this.store = store
    }

    getBookingStatus() {
        Vue.axios.get(`api/itinerary/booking/get_booking_status`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    this.store.dispatch('booking/setBookingStatusAction', result.data)
                }
            }
        })
    }

    updateBookingStatusByBookingId(supplier_id, status) {        
        Vue.axios.patch(`api/itinerary/booking/update_booking_status/${supplier_id}`, {status_id: status}).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    this.store.dispatch('itinerary/updateBookingStatusBySupplierIdAction', {
                        supplier_id: supplier_id,
                        data: status
                    })
                }
            }
        })
    }
}