<template>
    <v-container fluid class="mt-6">
        <div class="card">
            <v-row no-gutters>
                <v-col cols="12" md="6">
                <div class="card-header"></div></v-col>
                <v-col cols="12" md="6">
                <div class="card-header-items">
                    <v-btn
                    rounded
                    color="#525a68"
                    dark
                    class="header-items"
                    @click="NewItinerary()">
                    Create Event
                    </v-btn>
                </div>
                </v-col>
                <v-col cols="12">
                <v-divider class="mt-3"></v-divider>
                </v-col>
                <v-col cols="12" sm="4">
                <div class="filtertabs-wrapper">
                    <v-tabs grow class="filtertabs" show-arrows>
                    <v-tab> All </v-tab>
                    <v-tab> Alerts </v-tab>
                    </v-tabs>
                </div>
                </v-col>
                <v-col cols="12" sm="6">
                <v-text-field
                    label="Search"
                    prepend-icon="mdi-magnify"
                    class="mt-5 pr-2"
                    v-model="search"
                ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                <v-switch
                    v-model="show_archived"
                    label="Show archived"
                    class="mt-9 pr-2"
                ></v-switch>
                </v-col>
                <v-col cols="12">
                <v-divider></v-divider>
                <v-skeleton-loader type="table" v-if="!loaded"></v-skeleton-loader>
                <v-data-table
                    v-if="loaded"
                    :headers="headers"
                    :items="get_itinerary_info"
                    disable-pagination
                    hide-default-footer
                    item-key="is_confirm"
                    :sort-by.sync="sort_by"
                    :sort-desc.sync="sort_desc"
                    :search="search">
                    <template v-slot:item="{item}">
                    <tr style="cursor:pointer;" @click="EditItinerary(item)">
                        <td>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                            <v-chip class="data-chip" v-bind="attrs" v-on="on">{{
                                item.created_info.name.charAt(0).toUpperCase()
                            }}</v-chip>
                            </template>
                            <span>{{ item.created_info.name }}</span>
                        </v-tooltip>
                        </td>
                        <td>
                        {{ item.itinerary_name }}
                        </td>
                        <td>
                        {{ item.itinerary_number }}
                        </td>
                        <td>
                        {{ $date(item.start_date).format('DD MMM') }} > {{ item.end_date }}
                        </td>
                        <td>
                        <div class="text-center">
                            <v-checkbox
                            v-model="item.is_confirm"
                            @click.stop="
                                UpdateItinerary(
                                'is_confirm',
                                item.is_confirm,
                                item.id
                                )
                                "
                            ></v-checkbox>
                        </div>
                        </td>
                        <td>
                        <div v-if="item.status_info">{{ item.status_info.name }}</div>
                        </td>
                        <td>
                        <div v-if="item.template_info">{{ item.template_info.name }}</div>
                        </td>
                        <td>
                        {{ $date(item.created_at).format('DD MMM, YYYY') }}
                        </td>
                        <td>
                        <v-icon v-if="ConvertDate(item.created_at)">mdi-alert</v-icon>
                        </td>
                        <td>
                        <v-menu right bottom>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                                <v-list-item-title> Duplicate </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title> Archive </v-list-item-title>
                            </v-list-item>
                            <v-subheader class="list-header">
                                <v-list-item-title> Change Status </v-list-item-title>
                            </v-subheader>
                            <v-list-item
                                v-for="(value, index) in get_itinerary_status"
                                :key="index"
                                @click="
                                UpdateItinerary(
                                    'itinerary_status_id',
                                    value.id,
                                    item.id,
                                    'status'
                                )
                                "
                            >
                                <v-list-item-title>
                                {{ value.name }}
                                </v-list-item-title>
                                <v-list-item-action
                                v-if="item.itinerary_status_id == value.id"
                                >
                                <v-btn icon>
                                    <v-icon style="color: #5996c5">mdi-check</v-icon>
                                </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-subheader class="list-header">
                                <v-list-item-title> Change Template </v-list-item-title>
                            </v-subheader>
                            <v-list-item
                                v-for="(value, index) in get_itinerary_template"
                                :key="index"
                                @click="
                                UpdateItinerary(
                                    'itinerary_template_id',
                                    value.id,
                                    item.id,
                                    'template'
                                )
                                "
                            >
                                <v-list-item-title>
                                {{ value.name }}
                                </v-list-item-title>
                                <v-list-item-action
                                v-if="item.itinerary_template_id == value.id"
                                >
                                <v-btn icon>
                                    <v-icon style="color: #5996c5">mdi-check</v-icon>
                                </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            </v-list>
                        </v-menu>
                        </td>
                    </tr>
                    </template>
                </v-data-table>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import ErrMessage from '../errmessage/ErrMessage.vue';
// import Validation from '../../common/validation';

export default {
  name: "Task",
  components: {
    // ErrMessage
  },
  props: [],
  data: () => ({
    show_archived: false,
    menu: false,
    loaded: false,
    headers: [
      { text: "", value: "initial" },
      { text: "Title", value: "itinerary_name" },
      { text: 'IT #', value: 'itinerary_number'},
      { text: "Dates", value: "dates" },
      { text: "Confirm", value: "confirm" },
      { text: "Status", value: "status" },
      { text: "Template", value: "template" },
      { text: "Created", value: "created_at" },
      { text: "", value: "alert" },
      { text: "", value: "action" },
    ],
    confirm_duration: 0,
    sort_by: 'created_at',
    sort_desc: true,
    search: ''
  }),
  async mounted() {
    await this.$store.dispatch("itinerary/fetch_itinerary_status");
    await this.$store.dispatch("itinerary/fetch_itinerary_template");
    await this.$store.dispatch("itinerary/fetch_itinerary_info", this.get_selected_company.id)
      .then(() => {
        this.loaded = true
      })
  },
  created() {
    this.confirm_duration = process.env.VUE_APP_CONFIRM_DURATION;
    this.setPageName('Event')
  },
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
      get_itinerary_info: "itinerary/get_itinerary_info",
      get_itinerary_status: "itinerary/get_itinerary_status",
      get_itinerary_template: "itinerary/get_itinerary_template",
      get_user: "auth/get_user",
    }),
    // get_user_info(){
    //     return this.$store.getters['task/get_user_info'](this.selected_assigness)
    // },
    // get_task_info(){
    //     return this.$store.getters['task/get_task_info'](this.filter, this.filter_payload, this.filter_search)
    // }
  },
  methods: {
    ...mapActions({
        setPageName:    'pageNameStore/setPageNameAction'
    }),
    NewItinerary() {
      this.$store.dispatch("itinerary/set_itinerary_action", {
        id: 0,
        action: "new",
        is_disabled: true,
      });
      this.$router.push({
        name: "/itinerary/initial",
      });
    },
    EditItinerary(item) {
      this.$store.dispatch("itinerary/set_itinerary_action", {
        id: item.id,
        action: "edit",
        is_disabled: false,
      });
      this.$router.push({
        name: "/itinerary/initial",
      });
    },
    UpdateItinerary(field, value, id, action = null) {
      let info = {
        id: id,
        field: field,
        value: value,
      };
      this.$axios.post("api/itinerary/update_itinerary", info).then(({ data }) => {
        if (data.response) {
          if (action == "status") {
            let itinerary = this.get_itinerary_info.find(
              (element) => element.id == id
            );
            let status = this.get_itinerary_status.find(
              (element) => element.id == value
            );
            itinerary.itinerary_status_id = value;
            itinerary.status_info = status;
          }
          if (action == "template") {
            let itinerary = this.get_itinerary_info.find(
              (element) => element.id == id
            );
            let template = this.get_itinerary_template.find(
              (element) => element.id == value
            );
            itinerary.itinerary_template_id = value;
            itinerary.template_info = template;
          }
        }
      });
    },
    ConvertDate(datetime){
      let created_date = new Date(datetime);
      let now_date = new Date();
      let diff_days = created_date.getTime() - now_date.getTime();
      let total_days = Math.abs(Math.ceil(diff_days / (1000 * 3600 * 24)));
      if(parseInt(total_days) >= parseInt(this.confirm_duration)) return true;
      return false;
    }
  },
  watch: {},
};
</script>

<style scoped>
.header {
  max-width: 100%;
  background-color: #79a2bd;
  height: 50px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 50px;
}
.header h3 {
  color: white;
}
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}
.card .card-header-items {
  text-align: right;
}
.card .card-header-items .header-items {
  margin: 0px 10px;
}
.filtertabs-wrapper {
  max-width: 100%;
  margin-top: 18px;
}
.data-chip {
  background-color: rgb(56, 154, 116) !important;
  font-weight: bolder;
  font-size: 14px;
  color: white;
  margin-right: 5px;
}
.text-center {
  text-align: center;
}
@media only screen and (max-width: 959px) {
  .card .card-header {
    text-align: center;
  }
  .card .card-header-items .header-items {
    margin: 5px 5px 10px 5px;
  }
}
</style>
