<template>
    <div>
        <v-card
            width="550"
        >
            <v-card-title
                style="fontSize: 20px;"
            >
                <v-icon
                    v-for="(icons, iconindex) in passed_data.buttons"
                    :key="iconindex"
                    x-large
                    @click="$router.push(icons.action)"
                    class="mr-3"
                >
                    {{icons.icon}}
                </v-icon>
                <label>{{passed_data.title}}</label>
            </v-card-title>
            <v-card-text>
                <v-row
                    class="mt-5"
                >
                    <v-col
                        v-for="(fields, fieldindex) in passed_data.fields"
                        :key="fieldindex"
                        :cols="fields.col"                
                    >
                        <v-text-field
                            v-if="fields.ttype === 'text-field'"
                            outlined
                            dense
                            :label="fields.label"
                            :prepend-inner-icon="fields.icon"
                            v-model="c.field[[fieldindex]]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-for="(button, buttonindex) in passed_data.buttons_action"
                    :key="buttonindex"
                    :class="button.class"
                    :style="button.style"
                    :color="button.color"
                    rounded
                    @click="button.action === 'save' ? save() : ''"
                >
                    <v-icon
                        class="mr-3"
                    >{{button.icon}}</v-icon>
                    {{button.label}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    passed_data: {},
    c: {
        field: []
    }
  }),
  mounted () {
    console.log(this.$router.currentRoute.query)
    this.passed_data = this.$router.currentRoute.query
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_selected_company:           'auth/get_selected_company',
        get_supplier_setup:             'supplier/get_supplier_setup'
    })
  },
  methods: {
    showSnackBar(message){
        this.$store.commit("auth/setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    async save(){
        await this.$axios.post('api/contacts/create', {
            supplier_id:        this.get_supplier_setup.id,
            company_id:         this.get_selected_company.id,
            first_name:         this.c.field[0],
            last_name:          this.c.field[1],
            email:              this.c.field[2]
        })
        .then(({data}) => {
            if(data.response){
                this.$router.push(this.passed_data.buttons[0].action)
                this.c.field = []
                this.$store.dispatch('supplier/fetch_supplier', this.get_selected_company.id)
                this.$store.dispatch('supplier/set_supplier_setup_company', data.data)
                return
            }else{
                this.showSnackBar(data.message)
                return
            }
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>