import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        roles: [],
        response: {},
        members: [],
        to_delete_user: {},
        to_edit_user: {}
    },
    mutations: {
        set_roles(state, payload){
            state.roles = [...payload]
        },
        set_response(state, payload){
            state.response = payload
        },
        set_members(state, payload){
            state.members = [...payload]
        },
        set_to_delete_user(state, payload){
            state.to_delete_user = payload
        },
        set_to_edit_user(state, payload){
            state.to_edit_user = payload
        }
    },
    getters: {
        get_roles : state => state.roles,
        get_response : state => state.response,
        get_members : state => state.members,
        get_to_delete_user : state => state.to_delete_user,
        get_to_edit_user : state => state.to_edit_user
    },
    actions: {
        async get_user_roles({commit}){
            await Vue.axios.get('api/roles/g_roles')
            .then(({data}) => {
                commit('set_roles', data.data)
            })
        },
        async register_team_member({commit}, payload){
            await Vue.axios.post('api/users/register_team_member', payload)
            .then(({data}) => {
                commit('set_response', data)
            })
        },
        async get_org_members({commit}, payload){
            await Vue.axios.get(`api/roles/org_members/${payload}`)
            .then(({data}) => {
                commit('set_members', data.data)
            })
        },
        set_to_delete_user({commit}, payload){
            commit('set_to_delete_user', payload)
        },
        async archive_user(_, payload){
            await Vue.axios.delete(`api/users/remove_user/${payload}`)
        },
        set_to_edit_user({commit}, payload){
            commit('set_to_edit_user', payload)
        },
        async save_user_information({commit}, payload){
            await Vue.axios.patch('api/users/edit_user_information', payload)
            .then(({data}) => {
                commit('set_response', data)
            })
        },
    }
}