import Vue from 'vue'

export default class PaymentSettings
{
    constructor()
    {
        this.api = Vue;
    }

    public_methods = ( () => {

        const getCurrencies = () => {
            return this.api.axios.get(`admin/company_settings/get_currencies`).then( (data) => {
                const result = data.data
                return result
            }).catch( (err) => {
                return err.response
            })
        }
        
        const getPaymentSettings = (company_id) => {
            return this.api.axios.get(`admin/company_settings/get_payment_settings/${company_id}`).then( (data) => {
                const result = data.data
                return result
            }).catch( (err) => {
                return err.response
            })
        }

        /** Save and Update Payment Settings */
        const savePaymentSettings = ({
            id, payload
        }) => {
            if ( id == null ) {
                // Save new Payment Settings
                return this.api.axios.post(`admin/company_settings/save_payment_settings`, payload).then( (data) => {
                    const result = data.data
                    return result
                }).catch( (err) => {
                    return err.response
                })
            } else {
                // Update Existing Payment Settings
                return this.api.axios.post(`admin/company_settings/update_payment_settings/${id}`, payload).then( (data) => {
                    const result = data.data
                    return result
                }).catch( (err) => {
                    return err.response
                })
            }
        }

        return { getCurrencies, getPaymentSettings, savePaymentSettings }
    })()

}