<template>
    <v-container fluid class="mt-1">
        <div style="text-align:right;">
            <v-btn class="custom-buttons" dark @click="printItinerary()">Print</v-btn>
        </div>
        <br>
        <div id="itinerary" class="px-5">
            <v-row>
                <v-col cols="12">
                    <v-img eager :src="event.lead.event_type.itinerary_header"></v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div style="font-family:'Arial';font-size:70px;font-weight:600;color:#3988AA;text-align:center;">
                        IT'S GONNA BE FUN
                    </div>
                    <div class="itinerary-title">{{ event.lead.first_name }}'s {{ event.lead.activity_type }} Itinerary
                    </div>
                    <div class="itinerary-subtitle">{{ $date(event.lead.start_date).format('MMMM D, YYYY') }} TO {{
                        $date(event.lead.end_date).format('MMMM D, YYYY') }}</div>
                </v-col>
            </v-row>
            <br>
            <v-row style="font-family: Manrope;text-align:center;" class="my-8">
                <v-col cols="3">
                    <small>GROUP LEADER</small><br>
                    <b style="text-transform: uppercase;color:black;">{{ event.lead.first_name }} {{
                        event.lead.last_name }}</b>
                </v-col>
                <v-col cols="3">
                    <small>CONTACT</small><br>
                    <b style="text-transform: uppercase;color:black;">{{ event.lead.phone }}</b>
                </v-col>
                <v-col cols="3">
                    <small>ARRIVAL</small><br>
                    <b style="text-transform: uppercase;color:black;">
                        {{ $date(event.lead.start_date).format('MMMM D, YYYY') }}
                    </b>
                </v-col>
                <v-col cols="3">
                    <small>DEPARTURE</small><br>
                    <b style="text-transform: uppercase;color:black;">
                        {{ $date(event.lead.end_date).format('MMMM D, YYYY') }}
                    </b>
                </v-col>
            </v-row>
            <br>
            <v-row class="px-10" style="font-family:sans-serif;">
                <v-col cols="12">
                    <p style="font-size:20px;font-weight:600;">Dear
                        {{ event.lead.first_name }} {{ event.lead.last_name }},</p>
                    <br>
                    <p>
                        We would like to take this opportunity to thank you for choosing us as your party organiser.
                        Please find below the details that we have organised for your group to make it an enjoyable
                        experience.
                        <br><br>
                        As your group leader, you will be the chief organiser. So all correspondence with the suppliers
                        will have your name for all bookings. Please state your name at the accommodation front desk,
                        activity centre. nightclub doors, etc.
                        <br><br>
                        Your accommodation provider may ask for a security deposit upon arrival. This is a good
                        behaviour bond between you and the supplier. Please see your accommodation details below for
                        more info.
                        <br><br>
                        All the info you should need is on this itinerary.
                        <br><br>
                        What’s <b style="color:#AA3939;">NOT INCLUDED</b> in your itinerary:
                        <br><br>
                    <ul>
                        <li>NO BUS ON THIS PACKAGE</li>
                        <li>NO MISCELLANEOUS ON THIS PACKAGE</li>
                    </ul>
                    <br>
                    <i style="color:#3988AA;">*Please advise the accommodation or activity supplier if the
                        accommodation or activity are not satisfactory to the relevant standards required.</i>
                    </p>
                </v-col>
            </v-row>
            <br class="html2pdf__page-break">
            <br>
            <v-row class="px-5">
                <v-col cols="12" v-for="(build, index) in builds" :key="index">
                    <div>
                        <v-img v-if="build.supplier.service_type_id == 1 || build.option.image == null"
                            :src="build.supplier.image" style="max-height:280px;" eager></v-img>
                        <v-img v-else-if="!build.option.use_product_type_image" :src="build.option.image"
                            style="max-height:280px;" eager></v-img>
                        <v-img v-else-if="build.option.product_type != null" :src="build.option.product_type.image"
                            style="max-height:280px;" eager></v-img>
                    </div>
                    <div>
                        <div
                            style="font-family:'Arial';font-size:70px;font-weight:600;color:#3988AA;text-align:center;height:110px;text-transform:uppercase;">
                            {{ build.supplier.type.name }}
                        </div>
                        <div style="font-family:Manrope;text-transform:uppercase;text-align:center;color:black;"
                            v-if="build.type_id == 1">
                            {{ $date(build.start_date).format('dddd, MMMM D, YYYY') }} -
                            {{ $date(build.end_date).format('dddd, MMMM D, YYYY') }}
                        </div>
                        <div style="font-family:Manrope;text-transform:uppercase;text-align:center;color:black;" v-else>
                            {{ $date(build.start_date).format('dddd, MMMM D, YYYY') }}
                        </div>
                        <br><br>
                        <v-row v-if="build.type_id == 1">
                            <v-col cols="4" class="itinerary-left"><b>Accommodation:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.supplier.supplier_name }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Accommodation Type:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>Self Catering Accommodation</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Address:</b></v-col>
                            <v-col cols="8" class="itinerary-right">
                                <b>{{ build.supplier.address ?? build.supplier.location.name }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Room Type:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.option.name }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Contact Number:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.supplier.phone }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Security Deposit:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.supplier.deposit_info ?? 'None'
                                    }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Breakfast Included:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>
                                    {{ build.supplier.breakfast_included ? 'Yes' : 'No' }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Bar Facilities:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.supplier.has_bar ? 'Yes' : 'No'
                                    }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Group Size:</b></v-col>
                            <v-col cols="8" class="itinerary-right">
                                <b>
                                    <div style="display:flex;">
                                        <div v-for="(groupsize, index) in JSON.parse(build.accom_groupsize)"
                                            :key="index" style="margin-right:10px;">
                                            Day {{ index + 1 }} - {{ groupsize }}
                                            <label v-if="index < JSON.parse(build.accom_groupsize).length - 1">,</label>
                                        </div>
                                    </div>
                                </b>
                            </v-col>
                            <v-col cols="4" class="itinerary-left"><b>Supplier Notes:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>None</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Special Notes/Comments:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>None</b></v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="4" class="itinerary-left"><b>Activity:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.supplier.supplier_name }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Activity Type:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.option.name }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Day:</b></v-col>
                            <v-col cols="8" class="itinerary-right">
                                <b>{{ $date(build.start_date).format('dddd') }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Group Size:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.groupsize }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Time:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.start_time }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Time to Activity Center:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.supplier.time_to_center ?? 'None'
                                    }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Time to be there:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.supplier.time_to_arrive ?? 'None'
                                    }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Activity Contact:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.supplier.phone }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Address:</b></v-col>
                            <v-col cols="8" class="itinerary-right">
                                <b>{{ build.supplier.address ?? build.supplier.location.name }}</b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Details:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b v-html="build.supplier.description"></b></v-col>
                            <v-col cols="4" class="itinerary-left"><b>Special Notes/Comments:</b></v-col>
                            <v-col cols="8" class="itinerary-right"><b>{{ build.supplier.special_notes ?? 'None'
                                    }}</b></v-col>
                        </v-row>
                    </div>
                    <br class="html2pdf__page-break">
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Events from '../../modules/admin/events/modules/events.js'
import Invitees from '../../modules/admin/invitees/modules/invitees.js'
import html2pdf from 'html2pdf.js'
import _ from 'lodash'

export default {
    components: {
    },
    data: () => ({
        page_name: "Itinerary",
        classes: {
            events: null,
            invitees: null,
        },
        build_headers: [
            { text: 'Service', sortable: false, width: '25%' },
            { text: 'Rate', sortable: false, width: '10%' },
            { text: 'Day/s', sortable: false, width: '15%' },
            { text: 'Time', sortable: false, width: '25%' },
            { text: 'Groupsize', sortable: false, width: '25%' },
        ],
        open_panels: [0],
        api_url: null,
    }),
    created() {
        this.setPageName(this.page_name)
        this.classes.events = new Events()
        this.classes.invitees = new Invitees()
    },
    async mounted() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            event: 'eventsStore/getSelectedEventGetter',
        }),
        builds() {
            let combined_builds = new Array()
            this.event.quotes.forEach(quote => {
                combined_builds = combined_builds.concat(quote.builds)
            })
            return _.orderBy(combined_builds, ['start_date', 'type_id'], ['asc', 'asc'])
        },
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
        }),
        printItinerary() {
            const opt = {
                margin: 0.1,
                filename: this.event.name + '.pdf',
                image: { type: 'jpeg', quality: 0.7 },
                html2canvas: { useCORS: true, scale: 2, allowTaint: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            }
            html2pdf().set(opt).from(document.getElementById('itinerary')).save();
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    border-radius: 15px !important;

    .v-data-footer {
        background-color: #E9E9E9;
        color: #7C7C7C;
        font-family: 'Work Sans';
    }

    .v-data-footer__select .v-text-field {
        background-color: white;

        .v-select__slot {
            padding-left: 10px;
        }
    }
}

.custom-small {
    color: #7C7C7C;
    font-size: 12px;
    font-weight: 600;
}

:deep(.theme--light.v-data-table tbody td) {
    height: 80px;
    border: none;
}

.label-text-color {
    color: #7C7C7C;
}

.text-proper-case {
    text-transform: capitalize;
}

.search-text-field {
    margin-top: 15px;

    .v-input__slot {
        min-height: 0 !important;
        margin-top: 8px;
    }

    .v-input__prepend-inner {
        margin-top: 5px !important;
    }
}

:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.paid_row {
    background-color: #FFF1F1 !important;
}


:deep(.custom-text-field) {
    legend {
        display: none;
    }

    fieldset {
        border-color: #D3D3D3 !important;
    }

    input {
        color: #101010;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}

.itinerary-title {
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    color: #FAAF3A;
    text-align: center;
    font-family: sans-serif;
}

.itinerary-subtitle {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    font-family: Manrope;
}

.itinerary-left {
    font-family: Montserrat;
    color: #3988AA;
    border-bottom: solid 1px #D3D3D3;
}

.itinerary-right {
    font-family: Manrope;
    color: black;
    border-bottom: solid 1px #D3D3D3;
}
</style>