import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import store from './data/vuex'
import axios from './plugins/axios.js'
import { VueEditor } from "vue2-editor"
import Vue2Filters from 'vue2-filters'
import numeral from 'numeral'
import VueDayjs from 'vue-dayjs-plugin'

import Default from './layouts/Default.vue'
import Blank from './layouts/Blank.vue'
import Input from './layouts/Input.vue'
import MyStagIt from './layouts/mystagit/Default.vue'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import wysiwyg from "vue-wysiwyg"
import "vue-wysiwyg/dist/vueWysiwyg.css"

import './assets/css/style.css'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.component('default-layout', Default)
Vue.component('blank-layout', Blank)
Vue.component('input-layout', Input)
Vue.component('mystagit-layout', MyStagIt)
Vue.config.productionTip = false

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0.00")
})

Vue.use(axios)
Vue.use(VueEditor)
Vue.use(Vue2Filters)
Vue.use(VueDayjs)
Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
})

let wysiwyg_config = {
  hideModules: { "image": true, "removeFormat": true, "table": true, "orderedList": true, "unorderedList": true, "code": true }
}
Vue.use(wysiwyg, wysiwyg_config)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
