
export default {
    namespaced: true,
    state: {
        leads: [],
        advisors: []
    },
    mutations: {
        setLeadsMutation(state, payload) {
            state.leads = [...payload]
        },
        setAdvisorsMutation(state, payload) {
            state.advisors = [...payload]
        }
    },
    getters: {
        getLeadsGetter: (state) => state.leads,
        getAdvisorsGetter: (state) => state.advisors,
        getLeadsByIdGetter: (state) => (lead_id) => {
            return state.leads.find( (lead) => lead.id == lead_id )
        },
        getAdvisorByIdGetter: (state) => (advisor_id) => {
            return state.advisors.find( (advisor) => advisor.id == advisor_id )
        }
    },
    actions: {
        setLeadsAction( {commit}, payload ) {
            commit('setLeadsMutation', payload)
        },
        setAdvisorsAction( {commit}, payload ) {
            commit('setAdvisorsMutation', payload)
        }
    }
}