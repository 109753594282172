<template>
    <v-container fluid class="mt-12">
        <div style="display:flex;">
            <v-file-input dense outlined style="max-width:200px;" class="mb-10" v-model="packages_file"
                @change="importPackages()" label="Packages">
            </v-file-input>
            <v-file-input dense outlined style="max-width:200px;" class="mb-10" v-model="package_builds_file"
                @change="importPackageBuilds()" label="Package Builds">
            </v-file-input>
        </div>
        <v-row>
            <v-col cols="3">
                <v-select v-model="search_location" :items="get_locations" item-text="name" item-value="id" dense
                    outlined label="Location" @change="filterPackages()"></v-select>
            </v-col>
            <!-- <v-col cols="3">
                {{ search_type }}
                <v-select v-model="search_type" :items="[{ text: 'Stag', value: 1 }, { text: 'Hen', value: 2 }]"
                    item-text="text" item-value="value" dense outlined label="Party Type"
                    @change="filterPackages()"></v-select>
            </v-col> -->
            <v-col cols="3">
                <v-text-field v-model="search_name" dense outlined label="Package Name"
                    v-on:keyup.enter="filterPackages()"></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-btn @click="resetPackages()" dark>Reset Search</v-btn>
            </v-col>
            <v-col cols="3">
                <v-spacer></v-spacer>
                <v-btn-toggle class="d-flex justify-end pr-1">
                    <v-btn dense large color="black" style="color: white" @click="createPackage()">
                        <v-icon color="white" class="mr-2">mdi-plus</v-icon>
                        <label class="text-proper-case" style="cursor: pointer;">Create Package</label>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row class="mt-2">
            <v-col cols="12" class="pt-0">
                <v-card outlined class="card-format">
                    <v-card-text v-if="searching" class="text-center">
                        <strong>Searching...</strong>
                        <br />
                        <v-progress-circular :size="120" :width="10" color="primary"
                            indeterminate></v-progress-circular>
                    </v-card-text>
                    <v-card-text v-else>
                        <v-data-table :headers="headers" :items="packages" :sort-by.sync="sort_by"
                            :sort-desc.sync="sort_desc">
                            <template v-slot:item="{ item }">
                                <tr @click="viewPackage(item)">
                                    <td>{{ item.id }}</td>
                                    <td>
                                        {{ item.name }}
                                        <br>
                                        <small>Type: {{ item.type }}</small> | <small>Nights: {{ item.nights }}</small>
                                    </td>
                                    <td>{{ item.location ? item.location.name : null }}</td>
                                    <td>
                                        <div v-for="(build, index) in item.builds.filter((x) => x.type_id == 2)"
                                            :key="index">
                                            <small style="color:blue;">Night {{ build.day }}</small>
                                            -
                                            <small style="color:red;">
                                                {{ build.option ? build.option.name : build.supplier.supplier_name }}
                                            </small>
                                            <br>
                                            <!-- <small style="color:blue;">
                                            P:
                                        </small>
                                        <small style="color:red;">
                                            {{ get_selected_company.currency }}
                                            {{ build.option ? build.option.customer_price.toFixed(2) : 0 }}
                                        </small>
                                        <br> -->
                                        </div>
                                    </td>
                                    <td>
                                        <div v-for="(build, index) in item.builds.filter((x) => x.type_id == 5)"
                                            :key="index">
                                            <small style="color:blue;">Night {{ build.day }}</small>
                                            -
                                            <small style="color:red;">
                                                {{ build.option ? build.option.name : build.supplier.supplier_name }}
                                            </small>
                                            <br>
                                            <!-- <small style="color:blue;">
                                            P:
                                        </small>
                                        <small style="color:red;">
                                            {{ get_selected_company.currency }}
                                            {{ build.option ? build.option.customer_price.toFixed(2) : 0 }}
                                        </small>
                                        <br> -->
                                        </div>
                                    </td>
                                    <td>
                                        <div v-for="(build, index) in item.builds.filter((x) => x.type_id == 6) "
                                            :key="index">
                                            <small style="color:blue;">Night {{ build.day }}</small>
                                            -
                                            <small style="color:red;">
                                                {{ build.supplier.supplier_name }}
                                            </small>
                                            <br>
                                            <!-- <small style="color:blue;">
                                            P:
                                        </small>
                                        <small style="color:red;">
                                            {{ get_selected_company.currency }}
                                            {{ build.option ? build.option.customer_price.toFixed(2) : 0 }}
                                        </small>
                                        <br> -->
                                        </div>
                                    </td>
                                    <td>
                                        <div v-for="(build, index) in item.builds.filter((x) => x.type_id == 4) "
                                            :key="index">
                                            <small style="color:blue;">Night {{ build.day }}</small>
                                            -
                                            <small style="color:red;">
                                                {{ build.supplier.supplier_name }}
                                            </small>
                                            <br>
                                            <!-- <small style="color:blue;">
                                            P:
                                        </small>
                                        <small style="color:red;">
                                            {{ get_selected_company.currency }}
                                            {{ build.option ? build.option.customer_price.toFixed(2) : 0 }}
                                        </small>
                                        <br> -->
                                        </div>
                                    </td>
                                    <td>
                                        <v-tooltip bottom color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" v-ripple small class="mr-1"
                                                    color="primary" @click="viewPackage(item)">mdi-magnify</v-icon>
                                            </template>
                                            <span>View/Update Package</span>
                                        </v-tooltip>
                                        <v-tooltip bottom color="error">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn small dense icon v-bind="attrs" v-on="on"
                                                    @click="deletePackageDialog(item)">
                                                    <v-icon small color="error">mdi-delete</v-icon></v-btn>
                                            </template>
                                            <span>Delete Package</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <ConfirmDeletePackage :delete_package_dialog="delete_package_dialog"
            @closeDeletePackageDialog="closeDeletePackageDialog()" @deletePackage="deletePackage()">
        </ConfirmDeletePackage>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Packages from '../../modules/admin/packages/modules/packages.js'
import ConfirmDeletePackage from './components/ConfirmDeletePackage.vue'
export default {
    data: () => ({
        classes: {
            package: null,
        },
        searching: false,
        original_packages: [],
        packages: [],
        headers: [
            { text: 'ID', value: 'id', width: '1%' },
            { text: 'Name', value: 'name', width: '20%' },
            { text: 'Location', value: 'location.name', width: '10%' },
            { text: 'Day Activity', width: '15%' },
            { text: 'Dinner', width: '15%' },
            { text: 'Night Club', width: '15%' },
            { text: 'Fingerfood', width: '15%' },
            { text: 'Action', value: 'action', width: '9%' },
        ],
        delete_package_dialog: false,
        delete_package_id: null,
        sort_by: 'name',
        sort_desc: false,
        packages_file: null,
        package_builds_file: null,
        search_name: null,
        search_location: null,
        search_type: null,
    }),
    components: {
        ConfirmDeletePackage
    },
    created() {
        this.setPageName("Packages")
        this.classes.package = new Packages()
    },
    async mounted() {
        this.fetchPackages()
        this.fetchLocations()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_locations: 'locationStore/getLocationsGetter'
        })
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
            setLocation: 'locationStore/setLocationAction',
        }),
        filterPackages() {
            this.packages = this.original_packages
            if (this.search_location != null) {
                let filtered_packages = this.packages.filter((x) => x.location_id == this.search_location)
                this.packages = filtered_packages
            }
            if (this.search_name != null) {
                let filtered_packages = this.packages.filter((x) => x.name.toLowerCase().includes(this.search_name.toLowerCase()))
                this.packages = filtered_packages
            }
        },
        resetPackages() {
            this.packages = this.original_packages
            this.search_location = null
            this.search_name = null
        },
        async importPackages() {
            if (this.packages_file) {
                const formData = new FormData()
                formData.append('file', this.packages_file)
                formData.append('company_id', this.get_selected_company.id)
                await this.$axios.post('admin/packages/import_packages', formData)
                    .then(({ data }) => {
                        console.log(data)
                        this.packages_file = null
                    })
            }
        },
        async importPackageBuilds() {
            if (this.package_builds_file) {
                const formData = new FormData()
                formData.append('file', this.package_builds_file)
                formData.append('company_id', this.get_selected_company.id)
                await this.$axios.post('admin/packages/import_package_builds', formData)
                    .then(({ data }) => {
                        console.log(data)
                        this.package_builds_file = null
                    })
            }
        },
        viewPackage(item) {
            this.$router.push({ name: 'EditPackage', params: { package_id: item.id } })
        },
        deletePackageDialog(item) {
            this.delete_package_dialog = true
            this.delete_package_id = item.id
        },
        closeDeletePackageDialog() {
            this.delete_package_dialog = false
        },
        async deletePackage() {
            const result = await this.classes.package.deletePackage({ package_id: this.delete_package_id })
            if (result.response == true) {
                this.$toast.success(result.message, { timeout: 2000, icon: true })
                this.closeDeletePackageDialog()
                this.fetchPackages()
            }
            else {
                this.$toast.error(`${result.data.status}: ${result.data.data.message}`)
            }
        },
        async fetchPackages() {
            this.searching = true
            const result = await this.classes.package.getPackages({ company_id: this.get_selected_company.id })
            this.packages = result.data
            this.original_packages = result.data
            if (this.packages.length > 0) {
                this.searching = false
            }
        },
        async fetchLocations() {
            const location_data = await this.classes.package.getLocations({ company_id: this.get_selected_company.id })
            this.setLocation(typeof location_data.status == "undefined" ? location_data : [])
        },
        createPackage() {
            this.$router.push({ name: 'CreatePackage' })
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    border-radius: 15px !important;

    .v-data-footer {
        background-color: #E9E9E9;
        color: #7C7C7C;
        font-family: 'Work Sans';
    }

    .v-data-footer__select .v-text-field {
        background-color: white;

        .v-select__slot {
            padding-left: 10px;
        }
    }
}

.label-text-color {
    color: #7C7C7C;
}

.text-proper-case {
    text-transform: capitalize;
}

.search-text-field {
    margin-top: 15px;

    .v-input__slot {
        min-height: 0 !important;
        margin-top: 8px;
    }

    .v-input__prepend-inner {
        margin-top: 5px !important;
    }
}
</style>