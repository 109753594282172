<template>
    <v-card outlined class="card-format">
        <v-card-title class="mb-5 custom-title">
            REVIEW QUOTE
        </v-card-title>
        <v-card-text>
            <div v-for="(quote, quote_index) in quotes" :key="'quote' + quote_index">
                <div v-for="(builds, index) in all_builds[quote_index]" :key="index">
                    <v-row v-if="builds && builds.supplier">
                        <v-col cols="4" class="review-quote-left-text">
                            {{ quote }}
                        </v-col>
                        <v-col cols="8">
                            <v-row v-for="(option, option_index) in builds.options" :key="option_index">
                                <v-col cols="12" class="review-quote-right-text">
                                    {{ builds.supplier.supplier_name + ' — ' + option.name }}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <hr class="custom-hr" v-if="builds && builds.supplier">
                </div>
            </div>
        </v-card-text>
        <v-card-actions class="custom-buttons">
            <v-btn color="#F7F7F7" @click="back()">BACK</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="#252525" dark @click="update()" v-if="edit_package">UPDATE</v-btn>
            <v-btn color="#252525" dark @click="finish()" v-else>FINISH</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    components: {
    },
    props: [
        'all_builds', 'edit_package'
    ],
    data: () => ({
        quotes: [
            'DAY ACTIVITIES', 'NIGHT ACTIVITIES', 'DINNER', 'FINGERFOOD', 'TRANSPORT', 'ADDONS'
        ],
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        back() {
            this.$emit('back')
        },
        finish() {
            this.$emit('finish')
        },
        update() {
            this.$emit('update')
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

:deep(.v-input__slot) {
    background: white !important;
}

:deep(.v-stepper__header) {
    box-shadow: none !important;
}

.accom-price {
    color: #FAAF3A;
    font-size: 36px;
    font-weight: 600;
}

.custom-title {
    font-family: 'Manrope';
    font-size: 14px !important;
    color: #3988AA;
    font-weight: 600;
}

.sublabel {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description {
    color: #7D7D7D;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.summary {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

.review-quote-left-text {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #7C7C7C;
}

:deep(.custom-buttons span) {
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
    width: 58px;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.review-quote-right-text {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #101010;
}

.custom-hr {
    border: 0.5px solid #D3D3D3;
    height: 0.5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.quote-total {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    color: #101010;
}

.per-person {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #7C7C7C;
}
</style>