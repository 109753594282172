<template>
    <v-row justify="center">
        <v-dialog v-model="show_company_dialog" max-width="1800" persistent scrollable>
            <v-card>
                <v-card-title>Company</v-card-title>
                <v-card-text class="pt-3">
                    <v-row>
                        <v-col cols="4" class="pr-5">
                            <v-text-field v-model="company.company_name" label="Name" outlined dense></v-text-field>
                            <v-file-input v-model="file" label="Upload New Logo" outlined dense></v-file-input>
                            <v-img contain :src="api_url + company.company_logo"></v-img>
                            <v-divider></v-divider>
                            <v-textarea v-model="company_details.description" label="Description" outlined dense
                                class="mt-3"></v-textarea>
                            <v-text-field v-model="company_details.sub_description" label="Sub Description" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="4" class="px-5">
                            <v-text-field v-model="company.email" label="Email" outlined dense></v-text-field>
                            <v-text-field v-model="company.number" label="Number" outlined dense></v-text-field>
                            <v-text-field v-model="company.address" label="Address" outlined dense></v-text-field>
                            <v-text-field v-model="company.website" label="Website" outlined dense></v-text-field>
                            <v-text-field v-model="company.password" label="Company Password" outlined
                                dense></v-text-field>
                            <v-text-field v-model="company.cutoff_duration"
                                label="Cutoff For Payment (how many days before event date)" outlined
                                dense></v-text-field>
                            <v-text-field v-model="company.admin_fee" label="Admin Fee" type="number" outlined
                                dense></v-text-field>
                            <div class="mb-3">Late Fee</div>
                            <div style="display:flex;">
                                <v-text-field v-model="company.late_fee_amount" label="Amount" outlined dense
                                    class="mr-2"></v-text-field>
                                <v-text-field v-model="company.late_fee_days" label="Days" outlined dense
                                    class="mr-2"></v-text-field>
                                <v-select v-model="company.late_fee_date_type" label="Date type"
                                    :items="['Event Date', 'Pay By Date']" outlined dense></v-select>
                            </div>
                        </v-col>
                        <v-col cols="2" class="px-5">
                            <v-text-field v-model="company.sagepay_vendor" label="Sagepay Vendor Name" outlined
                                dense></v-text-field>
                            <v-text-field v-model="company.sagepay_key_test" label="Sagepay Test Key" outlined
                                dense></v-text-field>
                            <v-text-field v-model="company.sagepay_key_live" label="Sagepay Live Key" outlined
                                dense></v-text-field>
                            <v-select v-model="company.sagepay_is_live"
                                :items="[{ text: 'Live', value: 1 }, { text: 'Test', value: 0 }]" :item-text="'text'"
                                :item-value="'value'" label="Sagepay Mode" outlined dense></v-select>
                        </v-col>
                        <v-col cols="2" class="pl-5">
                            <v-text-field v-model="company.created_at" label="Created At" outlined dense
                                disabled></v-text-field>
                            <v-text-field v-model="company.created_by" label="Created By" outlined dense
                                disabled></v-text-field>
                            <v-text-field v-model="company.currency" label="Currency" outlined dense
                                disabled></v-text-field>
                            <v-text-field v-model="company.timezone" label="Timezone" outlined dense
                                disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-buttons" text @click="closeCompanyDialog()">Close</v-btn>
                    <v-btn dark class="custom-buttons" @click="updateCompany()">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Settings from '../../../modules/admin/settings/modules/settings.js'
import CompanySettings from '../../../modules/admin/settings/modules/company_setting.class.js'

export default {
    components: {
    },
    props: [
        'show_company_dialog'
    ],
    data: () => ({
        classes: {
            settings: null,
            company_setting: null
        },
        selected_template: null,
        company: {
            id: null,
            company_name: null,
            company_logo: null,
            email: null,
            number: null,
            address: null,
            website: null,
            password: null,
            currency: null,
            timezone: null,
            sagepay_vendor: null,
            sagepay_key_test: null,
            sagepay_key_live: null,
            sagepay_is_live: null,
            cutoff_duration: null,
            admin_fee: 0,
            late_fee_amount: 0,
            late_fee_days: 0,
            late_fee_date_type: null,
            created_at: null,
            created_by: null,
        },
        file: null,
        api_url: null,
        company_details:{
            company_id: null,
            id: null,
            description: null,
            sub_description: null
        }
    }),
    created() {
        this.classes.settings = new Settings()
        this.classes.company_setting = new CompanySettings()
    },
    async mounted() {
        this.setCompany()
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_user: 'auth/get_user',
        })
    },
    methods: {
        setCompany() {
            this.company.id = this.get_selected_company.id
            this.company.company_name = this.get_selected_company.company_name
            this.company.company_logo = this.get_selected_company.company_logo
            this.company.email = this.get_selected_company.email
            this.company.number = this.get_selected_company.number
            this.company.address = this.get_selected_company.address
            this.company.website = this.get_selected_company.website
            this.company.password = this.get_selected_company.password
            this.company.currency = this.get_selected_company.currency
            this.company.timezone = this.get_selected_company.timezone
            this.company.sagepay_vendor = this.get_selected_company.sagepay_vendor
            this.company.sagepay_key_test = this.get_selected_company.sagepay_key_test
            this.company.sagepay_key_live = this.get_selected_company.sagepay_key_live
            this.company.sagepay_is_live = this.get_selected_company.sagepay_is_live
            this.company.cutoff_duration = this.get_selected_company.cutoff_duration
            this.company.admin_fee = this.get_selected_company.admin_fee
            this.company.late_fee_amount = this.get_selected_company.late_fee_amount
            this.company.late_fee_days = this.get_selected_company.late_fee_days
            this.company.late_fee_date_type = this.get_selected_company.late_fee_date_type
            this.company.created_at = this.$date(this.get_selected_company.created_at).format('MMMM DD, YYYY')
            this.company.created_by = this.get_selected_company.created_by.name

            this.company_details.company_id = this.get_selected_company.id
            if ( this.get_selected_company.company_details != null ) {
                this.company_details.id = this.get_selected_company.company_details.id
                this.company_details.description = this.get_selected_company.company_details.description
                this.company_details.sub_description = this.get_selected_company.company_details.sub_description
            } 
        },
        closeCompanyDialog() {
            this.$emit('closeCompanyDialog')
            this.file = null
        },
        async updateCompany() {
            if (this.company.company_name == null || this.company.company_name == '') {
                this.$toast.error('Please enter a company name')
                return
            }
            const formData = new FormData()
            formData.append('company_logo_file', this.file)
            formData.append('id', this.company.id)
            formData.append('company_name', this.company.company_name)
            formData.append('email', this.company.email)
            formData.append('number', this.company.number)
            formData.append('address', this.company.address)
            formData.append('website', this.company.website)
            formData.append('password', this.company.password)
            formData.append('sagepay_vendor', this.company.sagepay_vendor)
            formData.append('sagepay_key_test', this.company.sagepay_key_test)
            formData.append('sagepay_key_live', this.company.sagepay_key_live)
            formData.append('sagepay_is_live', this.company.sagepay_is_live)
            formData.append('cutoff_duration', this.company.cutoff_duration)
            formData.append('admin_fee', this.company.admin_fee)
            formData.append('late_fee_amount', this.company.late_fee_amount)
            formData.append('late_fee_days', this.company.late_fee_days)
            formData.append('late_fee_date_type', this.company.late_fee_date_type)
            const result = await this.classes.settings.updateCompany(this.company.id, formData)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.$store.dispatch('auth/set_selected_company', result.data)
                this.setCompany()
                this.closeCompanyDialog()
            }

            const results = await this.classes.company_setting.updateCompanyDetails(this.company_details)
            if ( results.response == true ) {
                this.$toast.success(results.message)
                this.$store.dispatch('auth/set_selected_company', results.data)
                this.setCompany()
            }

        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row)) {
    border-bottom: none !important;
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}
</style>