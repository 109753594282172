<template>
    <v-row justify="center">
        <v-dialog v-model="show_profit_margins_dialog" max-width="1200" persistent scrollable>
            <v-card>
                <v-card-title>Profit Margins</v-card-title>
                <v-card-text style="padding-bottom:100px;">
                    <v-row>
                        <v-col cols="3" class="pr-15">
                            <div class="mb-5">Create new profit margin</div>
                            <v-select v-model="new_profit_margin.event_type_id" label="Event Type *"
                                :items="event_types" item-text="name" item-value="id" outlined dense></v-select>
                            <v-select v-model="new_profit_margin.location_id" label="Location *" :items="locations"
                                item-text="name" item-value="id" outlined dense></v-select>
                            <v-text-field v-model="new_profit_margin.accommodation_margin" type="number"
                                label="Accommodation Margin *" outlined dense></v-text-field>
                            <v-text-field v-model="new_profit_margin.activity_only_margin" type="number"
                                label="Activity Only Margin *" outlined dense></v-text-field>
                            <v-btn dark class="custom-buttons mt-5" @click="createProfitMargin()">Create</v-btn>
                        </v-col>
                        <v-col cols="9" class="pl-15">
                            <v-data-table :headers="profit_margin_headers" :items="profit_margins" disable-pagination
                                hide-default-footer style="height:500px;overflow-y:scroll;">
                                <template v-slot:item="{ item }">
                                    <tr style="cursor:inherit;background-color:transparent;">
                                        <td>{{ item.id }}</td>
                                        <td class="pt-5">
                                            <v-select v-model="item.event_type_id" :items="event_types" item-text="name"
                                                item-value="id" dense @change="updateProfitMargin(item)"></v-select>
                                        </td>
                                        <td class="pt-5">
                                            <v-select v-model="item.location_id" :items="locations" item-text="name"
                                                item-value="id" dense @change="updateProfitMargin(item)"></v-select>
                                        </td>
                                        <td class="pt-5">
                                            <v-text-field v-model="item.accommodation_margin" type="number" dense
                                                @change="updateProfitMargin(item)"></v-text-field>
                                        </td>
                                        <td class="pt-5">
                                            <v-text-field v-model="item.activity_only_margin" type="number" dense
                                                @change="updateProfitMargin(item)"></v-text-field>
                                        </td>
                                        <td><v-btn class="custom-buttons" color="red" icon
                                                @click="deleteProfitMargin(item)"><v-icon>mdi-delete</v-icon></v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-buttons" text @click="closeProfitMarginsDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Settings from '../../../modules/admin/settings/modules/settings.js'
export default {
    components: {
    },
    props: [
        'show_profit_margins_dialog'
    ],
    data: () => ({
        classes: {
            settings: null
        },
        selected_template: null,
        profit_margins: [],
        event_types: [],
        locations: [],
        profit_margin_headers: [
            { text: 'ID', sortable: false, width: '10%' },
            { text: 'Event Type', sortable: false, width: '20%' },
            { text: 'Location', sortable: false, width: '30%' },
            { text: 'Accommodation Margin', sortable: false, width: '15%' },
            { text: 'Activity Only Margin', sortable: false, width: '15%' },
            { text: '', sortable: false },
        ],
        new_profit_margin: {
            event_type_id: null,
            location_id: null,
            accommodation_margin: null,
            activity_only_margin: null,
        },
    }),
    created() {
        this.classes.settings = new Settings()
    },
    async mounted() {
        this.getProfitMargins()
        this.getEventTypes()
        this.getLocations()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_user: 'auth/get_user',
        })
    },
    methods: {
        async getProfitMargins() {
            const result = await this.classes.settings.getProfitMargins({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.profit_margins = result.data
            }
        },
        async getEventTypes() {
            const result = await this.classes.settings.getEventTypes({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.event_types = result.data
            }
        },
        async getLocations() {
            const result = await this.classes.settings.getLocations({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.locations = result.data
            }
        },
        closeProfitMarginsDialog() {
            this.$emit('closeProfitMarginsDialog')
        },
        async createProfitMargin() {
            if (this.new_profit_margin.event_type_id == null || this.new_profit_margin.location_id == null || this.new_profit_margin.accommodation_margin == null || this.new_profit_margin.activity_only_margin == null) {
                this.$toast.error('Please fill out all fields')
                return
            }
            let payload = {
                company_id: this.get_selected_company.id,
                event_type_id: this.new_profit_margin.event_type_id,
                location_id: this.new_profit_margin.location_id,
                accommodation_margin: this.new_profit_margin.accommodation_margin,
                activity_only_margin: this.new_profit_margin.activity_only_margin,
            }
            const result = await this.classes.settings.createProfitMargin(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.new_profit_margin.event_type_id = null
                this.new_profit_margin.location_id = null
                this.new_profit_margin.accommodation_margin = null
                this.new_profit_margin.activity_only_margin = null
                this.getProfitMargins()
            }
        },
        async updateProfitMargin(item) {
            let payload = {
                id: item.id,
                event_type_id: item.event_type_id,
                location_id: item.location_id,
                accommodation_margin: item.accommodation_margin,
                activity_only_margin: item.activity_only_margin,
            }
            const result = await this.classes.settings.updateProfitMargin(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.getProfitMargins()
            }
        },
        async deleteProfitMargin(item) {
            const result = await this.classes.settings.deleteProfitMargin({ profit_margin_id: item.id })
            if (result.response == true) {
                this.$toast.success(result.message)
                this.getProfitMargins()
            }
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row)) {
    border-bottom: none !important;
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}
</style>