<template>
    <v-card outlined class="card-format">
        <v-card-title class="mb-5 custom-title">
            PACKAGE
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-data-table :items="package_list" :headers="package_headers" hide-default-footer disable-pagination
                        fixed-header height="500">
                        <template v-slot:item="{ item }">
                            <tr @click="selected_package = item">
                                <td><input type="radio" :checked="selected_package == item"></td>
                                <td>{{ item.id }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.location.name }}</td>
                                <td>{{ item.nights }}</td>
                                <td>{{ item.type }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="custom-buttons">
            <v-spacer></v-spacer>
            <v-btn color="#252525" :dark="package_list.length > 0 && selected_package != null"
                :disabled="(package_list.length > 0 && selected_package != null) ? false : true"
                @click="save()">SAVE</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import Packages from '../../../modules/admin/packages/modules/packages.js'
export default {
    components: {
    },
    props: [
        'lead', 'date_range', 'suppliers', 'edit_build', 'package_list'
    ],
    data: () => ({
        classes: {
            package: null,
        },
        package_headers: [
            { text: '', sortable: false },
            { text: 'Package ID', value: 'id', width: '10%' },
            { text: 'Name', value: 'name', width: '20%' },
            { text: 'Location', value: 'location.name', width: '20%' },
            { text: 'Nights', value: 'nights', width: '20%' },
            { text: 'Type', value: 'type', width: '20%' },
        ],
        selected_package: null,
    }),
    async mounted() {
    },
    created() {
        this.classes.package = new Packages()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        }),
    },
    methods: {
        skip() {
            this.$emit('skip')
        },
        back() {
            this.$emit('back')
        },
        save() {
            this.$emit('save', this.selected_package, 0)
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

:deep(.v-input__slot) {
    background: white !important;
}

:deep(.v-stepper__header) {
    box-shadow: none !important;
}

.accom-price {
    color: #FAAF3A;
    font-size: 36px;
    font-weight: 600;
}

.custom-title {
    font-family: 'Manrope';
    font-size: 14px !important;
    color: #3988AA;
    font-weight: 600;
}

.sublabel {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description {
    color: #7D7D7D;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.summary {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

:deep(.custom-buttons span) {
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
    width: 58px;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}
</style>