<template>
  <div>
    <div class="card">
      <v-row no-gutters>
        <v-col cols="11">
          <h3>Itinerary setup</h3>
        </v-col>
        <v-col cols="1">
          <v-btn rounded color="error" @click="dialog_delete_itinerary = true" v-if="action == 'edit'"><v-icon>mdi-delete</v-icon></v-btn>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="payload.itinerary_name"
            label="Itinerary name *"
            class="mt-5 pr-2 pad-all"
            outlined
            @keyup="
              ValItineraryName();
              UpdateItinerary('itinerary_name', payload.itinerary_name);
            "
          ></v-text-field>
          <ErrMessage
            class="margin-top-zero"
            :message="validation.itinerary_name.message"
            :show="validation.itinerary_name.show"
            :success="validation.itinerary_name.success"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-if="action == 'edit'"
            v-model="payload.internal_reference"
            label="Internal reference"
            class="pr-2 pad-all"
            outlined
            @keyup="
              UpdateItinerary(
                'internal_reference',
                payload.internal_reference,
                false
              )
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="form-group pad-all">
            <v-autocomplete
              v-if="action == 'edit'"
              v-model="payload.itinerary_template_id"
              :items="get_itinerary_template"
              class="form-control"
              item-value="id"
              item-text="name"
              label="Itinerary template *"
              dense
              outlined
              @change="
                UpdateItinerary(
                  'itinerary_template_id',
                  payload.itinerary_template_id
                )
              "
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="5"
            :nudge-bottom="61"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="payload.start_date"
                label="Start date *"
                readonly
                v-bind="attrs"
                v-on="on"
                class="pad-all"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="payload.start_date"
              @input="menu1 = false"
              @change="
                ValStartDate();
                UpdateItinerary('start_date', payload.start_date);
              "
            ></v-date-picker>
          </v-menu>
          <ErrMessage
            class="margin-top-zero"
            :message="validation.start_date.message"
            :show="validation.start_date.show"
            :success="validation.start_date.success"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="5"
            :nudge-bottom="61"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="payload.end_date"
                label="End date *"
                readonly
                v-bind="attrs"
                v-on="on"
                class="pad-all"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="payload.end_date"
              @input="menu2 = false"
              @change="
                ValEndDate();
                UpdateItinerary('end_date', payload.end_date);
              "
            ></v-date-picker>
          </v-menu>
          <ErrMessage
            class="margin-top-zero"
            :message="validation.end_date.message"
            :show="validation.end_date.show"
            :success="validation.end_date.success"
          />
        </v-col>
        <v-col cols="12">
          <div class="form-group pad-all">
            <h3>Map type *</h3>
            <v-autocomplete
              v-model="payload.itinerary_map_type_id"
              :items="get_map_type"
              class="form-control"
              item-value="id"
              item-text="name"
              dense
              outlined
              @change="
                UpdateItinerary(
                  'itinerary_map_type',
                  payload.itinerary_map_type_id
                )
              "
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" v-if="action == 'edit'">
          <div class="form-group pad-all">
            <h3>Tags</h3>
            <v-text-field
              v-model="tag_name"
              label="Tags"
              dense
              outlined
              v-on:keyup.enter="AddTag(tag_name)"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" v-if="action == 'edit'">
          <div class="tags-wrapper">
            <div
              class="tags"
              v-for="(tag, index) in payload.tags"
              :key="tag.id"
            >
              <div class="tag-name">
                {{ tag }}
              </div>
              <div class="tag-action">
                <v-btn color="error" icon @click="RemoveTag(index)">
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" v-if="action == 'edit'">
          <br />
          <div class="form-group pad-all">
            <h3>Location</h3>
            <v-text-field
              v-model="location_name"
              label="Location"
              dense
              outlined
              v-on:keyup.enter="AddLocation(location_name)"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" v-if="action == 'edit'">
          <div class="tags-wrapper">
            <div
              class="tags"
              v-for="(tag, index) in payload.location"
              :key="tag.id"
            >
              <div class="tag-name">
                {{ tag }}
              </div>
              <div class="tag-action">
                <v-btn color="error" icon @click="RemoveLocation(index)">
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" v-if="action == 'new'">
          <div class="text-right">
            <v-btn rounded color="#68a74f" dark @click="SaveItinerary()">
              Save
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="card" v-if="action == 'edit'">
      <v-row no-gutters>
        <v-col cols="12">
          <h3>Contributors</h3>
        </v-col>
        <v-col cols="8">
          <p>
            Collaborate on your itinerary with colleagues
          </p>
        </v-col>
        <v-col cols="3">
          <div class="text-right">
            <v-btn rounded dark @click="AddContributor()"> Add contributor </v-btn>
          </div>
          <br>
        </v-col>
        <v-col cols="12">
          <hr />
          <div class="traveller-wrapper" v-for="(contributor, index) in payload.contributor_info" :key="contributor.id">
            <v-col cols="12" class="traveller-content">
              <div class="traveller">
                <p v-if="contributor.user">
                  {{ contributor.user.name }}
                  <br>
                  {{ contributor.user.email }}
                </p>
              </div>
              <div class="traveller-action">
                <v-btn
                  color="error"
                  class="btnnote"
                  icon
                  @click="RemoveContributor(index, contributor.id)"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </div>
            </v-col>
            <hr />
          </div>
        </v-col>
        <v-col cols="12" v-if="payload.contributor_info && payload.contributor_info.length == 0">
          <br>
          <p>
            Please add a contributor
          </p>
        </v-col>
      </v-row>
    </div>
    <div class="card" v-if="action == 'edit'">
      <v-row no-gutters>
        <v-col cols="12">
          <h3>Agency</h3>
        </v-col>
        <v-col cols="8">
          <p>
            Building this itinerary for an agent? Add your agent contact and company details here
          </p>
        </v-col>
        <v-col cols="3">
          <div class="text-right">
            <v-btn rounded dark @click="AddAgency()" v-if="!payload.agency_info"> Add agency </v-btn>
          </div>
          <br>
        </v-col>
        <v-col cols="12">
          <hr>
          <br>
          <p v-if="!payload.agency_info">
            Please add an agency
          </p>
          <div class="agency-wrapper" v-if="payload.agency_info">
            <div class="agency-info">
              <div class="agency-content">
                <div class="assignees-wrapper">
                  <div
                    class="assignees"
                  >
                    <v-avatar class="assignees-avatar" size="56">
                      {{ this.payload.agency_info.agency.business_name.charAt(0).toUpperCase() }}
                    </v-avatar>
                    <div class="assignees-initial">
                      <p style="margin-top: 10px;">{{ this.payload.agency_info.agency.business_name }}</p>
                    </div>
                    <div class="assignees-action">
                      <v-btn icon fab @click="RemoveAssignee(index)">
                        <v-icon dark> mdi-file </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div class="agency-connection">
                <div class="assignees-wrapper">
                  <div
                    class="assignees"
                  >
                    <v-avatar class="assignees-avatar" size="56">
                      {{ this.payload.agency_info.contact.first_name.charAt(0).toUpperCase() }}
                    </v-avatar>
                    <div class="assignees-initial">
                      <p>{{ this.payload.agency_info.contact.first_name }} {{ this.payload.agency_info.contact.last_name }}</p>
                      <span>{{ this.payload.agency_info.contact.email }}</span>
                    </div>
                    <div class="assignees-action">
                      <v-btn icon fab @click="RemoveAssignee(index)">
                        <v-icon dark> mdi-file </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="agency-action">
              <v-btn class="agency-action-btn" icon @click="RemoveAgency(payload.agency_info.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="card" v-if="action == 'edit'">
      <v-row no-gutters>
        <v-col cols="12">
          <h3>Travellers</h3>
        </v-col>
        <v-col cols="9">
          <p>
            Add traveller details here; if you don’t know them yet, leave this
            section blank
          </p>
        </v-col>
        <v-col cols="3">
          <v-menu transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on, attrs }" class="text-right">
              <v-btn rounded dark v-bind="attrs" v-on="on" style="margin-bottom: 20px;">
                Add traveller
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="AddExisitingTraveller('Existing')">
                <v-list-item-title>Add existing contact</v-list-item-title>
              </v-list-item>
              <v-list-item @click="TravellerDialog('New')">
                <v-list-item-title>Create new</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <hr />
          <div class="traveller-wrapper" v-for="(traveller, index) in payload.traveller_info" :key="traveller.id">
            <v-col cols="12" class="traveller-content">
              <div class="traveller">
                <p v-if="traveller.contact">
                  {{ traveller.contact.first_name }} {{ traveller.contact.last_name }}
                  <br>
                  {{ traveller.contact.email }}
                </p>
                <p v-else>
                  {{ JSON.parse(traveller.travellers_info).firstname }} {{ JSON.parse(traveller.travellers_info).lastname }}
                  <br>
                  {{ JSON.parse(traveller.travellers_info).email }}
                </p>
              </div>
              <div class="traveller-action">
                <v-btn
                  v-if="traveller.contact"
                  color="error"
                  class="btnnote"
                  icon
                  @click="ContactInfo(index, traveller)"
                >
                  <v-icon dark> mdi-file </v-icon>
                </v-btn>
                <v-btn
                  v-if="!traveller.contact"
                  color="error"
                  class="btnnote"
                  icon
                  @click="EditTraveller('Edit', traveller)"
                >
                  <v-icon dark> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  class="btnnote"
                  icon
                  @click="RemoveTraveller(index, traveller.id)"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </div>
            </v-col>
            <hr />
          </div>
        </v-col>
        <v-col cols="12">
          <br />
          <v-text-field
            v-model="payload.travellers_number"
            label="Number of travellers"
            class="pr-2 pad-all"
            outlined
            @keyup="
              UpdateItinerary('travellers_number', payload.travellers_number)
            "
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="card" v-if="action == 'edit'">
      <v-row no-gutters>
        <v-col cols="12">
          <h3>Notes</h3>
        </v-col>
        <v-col cols="8">
          <p>
            Notes will not appear in your itinerary; they can only be viewed by
            you, your team and any contributors you invite
          </p>
        </v-col>
        <v-col cols="3">
          <div class="text-right">
            <v-btn rounded dark @click="AddNote()"> Add note </v-btn>
          </div>
        </v-col>
        <v-col cols="12">
          <hr />
          <div
            class="note-wrapper"
            v-for="(notes, index) in payload.notes"
            :key="notes.id"
          >
            <v-col cols="12" class="note-content">
              <div class="note">
                <p>{{ notes.note }}</p>
                <p>
                  {{ notes.created_info.name }} •
                  {{ notes.created_info.created_at }}
                </p>
              </div>
              <div class="note-action">
                <v-btn
                  color="error"
                  class="btnnote"
                  icon
                  @click="EditNote(index, notes)"
                >
                  <v-icon dark> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  class="btnnote"
                  icon
                  @click="RemoveNote(index, notes.id)"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </div>
            </v-col>
            <hr />
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- Dialogs -->
    <v-dialog
      v-model="dialog_note"
      persistent
      max-width="570px"
      overlay-color="#f4f4f5"
      overlay-opacity="1"
    >
      <v-btn fab @click="dialog_note = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          <span v-if="dialog_note_action == 'New'">Add note</span>
          <span v-if="dialog_note_action == 'Edit'">Edit note</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="note_payload.note"
            label="Start typing *"
            class="mt-5 pr-2 pad-all"
            outlined
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
                  color="green darken-1"
                  text
                  @click="dialog_existing_contact = false"
              >
                  Cancel
              </v-btn> -->
          <v-btn
            v-if="dialog_note_action == 'New'"
            color="green darken-1"
            text
            @click="SaveNote()"
          >
            Save note
          </v-btn>
          <v-btn
            v-if="dialog_note_action == 'Edit'"
            color="green darken-1"
            text
            @click="UpdateNote()"
          >
            Update note
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_agency"
      persistent
      max-width="570px"
      overlay-color="#f4f4f5"
      overlay-opacity="1"
    >
      <v-btn fab @click="dialog_agency = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="agency_payload.agency_info"
            :items="get_agencies_itinerary_info"
            dense
            outlined
            label="Search agency or agent *"
            item-value="data.item"
          >
          <template v-slot:selection="data">
            {{ data.item.business_name }}
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <v-chip
                  class="data-chip"
                >{{ data.item.business_name.charAt(0).toUpperCase() }}</v-chip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.business_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ data.item.contact_info.first_name }} {{ data.item.contact_info.last_name }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="SaveAgency()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_contributor"
      persistent
      max-width="570px"
      overlay-color="#f4f4f5"
      overlay-opacity="1"
    >
      <v-btn fab @click="dialog_contributor = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="contributor_payload.user_id"
            :items="get_itinerary_contributors"
            class="form-control"
            item-value="data.item"
            label="Search *"
            dense
            outlined
          >
            <template v-slot:selection="data">
              {{ data.item.name }}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <v-chip
                    class="data-chip"
                  >{{ data.item.name.charAt(0).toUpperCase() }}</v-chip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="SaveContributor()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_traveller"
      persistent
      max-width="570px"
      overlay-color="#f4f4f5"
      overlay-opacity="1"
    >
      <v-btn fab @click="dialog_traveller = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          <span v-if="dialog_traveller_action == 'New'">Add traveller</span>
        </v-card-title>
        <v-card-text v-if="dialog_traveller_action == 'Existing'">
          <v-autocomplete
            v-model="traveller_payload.contact_id"
            :items="get_contact_info"
            class="form-control"
            item-value="data.item"
            label="Search *"
            dense
            outlined
          >
            <template v-slot:selection="data">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <v-chip
                    class="data-chip"
                  >{{ data.item.first_name.charAt(0).toUpperCase() }}</v-chip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.first_name }} {{ data.item.last_name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-text v-if="dialog_traveller_action == 'New' || dialog_traveller_action == 'Edit'">
          <v-text-field
            v-model="traveller_payload.firstname"
            label="Firstname*"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="traveller_payload.lastname"
            label="Lastname*"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="traveller_payload.email"
            label="Email"
            outlined
          ></v-text-field>
          <v-switch
            v-if="dialog_traveller_action == 'New'"
            v-model="concent"
            label="Save to contacts library"
            style="margin-top: -10px;"
            @click="CheckConcent()"
          ></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="dialog_traveller_action == 'New' || dialog_traveller_action == 'Edit'"
            color="green darken-1"
            text
            @click="SaveTraveller()"
          >
            Save
          </v-btn>
          <v-btn
            v-if="dialog_traveller_action == 'Existing'"
            color="green darken-1"
            text
            @click="AddExistingContact()"
          >
            Add contact
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
            v-model="dialog_add_contact_concent"
            max-width="600px"
        >
        <v-card>
          <v-card-title>
            <span>You're about to save personal data</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            In order to save you must confirm that you have a legitimate interest, contractual basis or consent to store this personal data.
            <v-checkbox
                v-model="traveller_payload.concent"
                label="I have a legitimate interest and/or consent to store this information"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="CancelAddContact()"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    :disabled="!traveller_payload.concent"
                    @click="dialog_add_contact_concent = false"
                >
                    Accept
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-dialog
        v-model="dialog_delete_itinerary"
        persistent
        max-width="570px"
        overlay-color="#f4f4f5"
        overlay-opacity="1"
      >
        <v-btn fab @click="dialog_delete_itinerary = false" class="dialog-close">
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
        <v-card>
          <v-card-title>
            Are you sure you want to delete this itinerary?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialog_delete_itinerary = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="error"
              @click="deleteItinerary()"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ErrMessage from "../../../errmessage/ErrMessage.vue";
import Validation from "../../../../common/validation";

export default {
  name: "Setup",
  components: {
    ErrMessage,
  },
  props: {
    action: String,
    id: Number,
  },
  data: () => ({
    err_counter: 0,
    menu1: false,
    menu2: false,
    dialog_note: false,
    dialog_note_action: "New",
    dialog_agency: false,
    dialog_contributor: false,
    dialog_traveller: false,
    dialog_traveller_action: "New",
    dialog_add_contact_concent: false,
    dialog_delete_itinerary: false,
    concent: false,
    tag_name: "",
    location_name: "",
    payload: {
      id: 0,
      company_id: 0,
      itinerary_name: "",
      internal_reference: "",
      itinerary_status_id: 1,
      itinerary_template_id: 1,
      start_date: "",
      end_date: "",
      itinerary_map_type_id: 1,
      agency_info: null,
      traveller_info: null,
      contributor_info: null,
      tags: [],
      location: [],
      notes: [],
      created_by: 0,
      travellers_number: "",
      is_confirm: false,
    },
    agency_filter_search: null,
    agency_payload: {
      itinerary_id: 0,
      agency_info: null
    },
    note_payload: {
      index: 0,
      id: 0,
      itinerary_id: 0,
      note: "",
      created_by: 0,
    },
    contributor_payload: {
      id: 0,
      itinerary_id: 0,
      user_id: 0,
    },
    traveller_payload: {
      id: 0,
      company_id: 0,
      itinerary_id: 0,
      contact_id: 0,
      firstname: '',
      lastname: '',
      email: '',
      concent: false
    },
    validation: {
      itinerary_name: {
        message: "",
        show: false,
        success: false,
      },
      start_date: {
        message: "",
        show: false,
        success: false,
      },
      end_date: {
        message: "",
        show: false,
        success: false,
      },
    },
  }),
  async mounted() {
    await this.$store.dispatch("itinerary/fetch_map_type");
    await this.$store.dispatch('agencies/fetch_agencies_info', this.get_selected_company.id);
  },
  created() {
    this.FetchItineraryInformation();
  },
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
      get_map_type: "itinerary/get_map_type",
      get_user_login: "auth/get_user",
      get_itinerary_template: "itinerary/get_itinerary_template",
      get_contact_info: "itinerary/get_contact_info",
      get_itinerary_contributors: "itinerary/get_itinerary_contributors"
    }),
    get_agencies_itinerary_info(){
      return this.$store.getters['agencies/get_agencies_itinerary_info']()
    },
  },
  methods: {
    async FetchItineraryInformation() {
      if (this.action == "edit") {
        await this.$axios
          .get(`api/itinerary/fetch_itinerary_info_id/${this.id}`)
          .then(({ data }) => {
            if (data.response) {
              // console.log(data.data);
              this.payload.id = data.data.id;
              this.payload.itinerary_name = data.data.itinerary_name;
              this.$store.dispatch('itinerary/set_group_name', data.data.itinerary_name)
              this.$store.dispatch('itinerary/set_itinerary_number', data.data.itinerary_number)
              this.$store.dispatch('itinerary/set_created_by_info', data.data.created_info)
              this.$store.dispatch('itinerary/set_reference_number', data.data.internal_reference)
              this.payload.internal_reference = data.data.internal_reference;
              this.payload.itinerary_template_id = data.data.itinerary_template_id;
              this.payload.itinerary_map_type_id = data.data.itinerary_map_type;
              this.payload.tags = data.data.tags == null ? [] : data.data.tags;
              this.payload.location = data.data.location == null ? [] : data.data.location;
              this.payload.start_date = this.$date(data.data.start_date).format('YYYY-MM-DD');
              this.payload.end_date = this.$date(data.data.end_date).format('YYYY-MM-DD');
              this.payload.created_by = data.data.created_by;
              this.payload.notes = data.data.note_info;
              this.payload.travellers_number = data.data.travellers_number;
              this.payload.agency_info = data.data.agency_info;
              this.payload.contributor_info = data.data.contributor_info;
              this.payload.traveller_info = data.data.traveller_info;
              this.$store.dispatch('itinerary/set_itinerary', this.payload);
              // console.log(this.payload);
            } else {
              console.log("Something went wrong!");
            }
          });
      }
    },
    ValItineraryName() {
      if (Validation.valBlank(this.payload.itinerary_name)) {
        this.validation.itinerary_name.message =
          "Please input your itinerary name.";
        this.validation.itinerary_name.show = true;
        this.validation.itinerary_name.success = false;
        return true;
      } else {
        this.validation.itinerary_name.show = false;
        return false;
      }
    },
    ValStartDate() {
      if (Validation.valBlank(this.payload.start_date)) {
        this.validation.start_date.message = "Please select your start date.";
        this.validation.start_date.show = true;
        this.validation.start_date.success = false;
        return true;
      } else {
        this.validation.start_date.show = false;
        return false;
      }
    },
    ValEndDate() {
      if (Validation.valBlank(this.payload.end_date)) {
        this.validation.end_date.message = "Please select your end date.";
        this.validation.end_date.show = true;
        this.validation.end_date.success = false;
        return true;
      } else {
        this.validation.end_date.show = false;
        return false;
      }
    },
    FormValidation() {
      this.err_counter = 0;
      if (this.ValItineraryName()) {
        this.err_counter += 1;
      }
      if (this.ValStartDate()) {
        this.err_counter += 1;
      }
      if (this.ValEndDate()) {
        this.err_counter += 1;
      }
      return this.err_counter;
    },
    async SaveItinerary() {
      if (this.FormValidation() == 0) {
        this.payload.company_id = this.get_selected_company.id;
        this.payload.created_by = this.get_user_login.id;
        await this.$axios
          .post("api/itinerary/add_itinerary", this.payload)
          .then(({ data }) => {
            if (data.response) {
              this.$router.push({ name: "/itinerary" });
            } else {
              console.log("Something went wrong!");
            }
          });
      }
    },
    AddTag(tag_name) {
      this.payload.tags.push(tag_name);
      this.UpdateItinerary("tags", this.payload.tags, false);
      this.tag_name = "";
    },
    RemoveTag(index) {
      this.payload.tags.splice(index, 1);
      this.UpdateItinerary("tags", this.payload.tags, false);
    },
    AddLocation(location_name) {
      this.payload.location.push(location_name);
      this.UpdateItinerary("location", this.payload.location, false);
      this.location_name = "";
    },
    RemoveLocation(index) {
      this.payload.location.splice(index, 1);
      this.UpdateItinerary("location", this.payload.location, false);
    },
    AddNote() {
      this.ClearNotePayload();
      this.dialog_note_action = "New";
      this.dialog_note = true;
      this.note_payload.itinerary_id = this.payload.id;
      this.note_payload.note = "";
      this.note_payload.created_by = this.get_user_login.id;
    },
    SaveNote() {
      if (this.note_payload.note == "") {
        console.log("Please input your note.");
      } else {
        this.$axios
          .post("api/itinerary/add_itinerary_note", this.note_payload)
          .then(({ data }) => {
            if (data.response) {
              this.payload.notes.push(data.data);
              this.dialog_note = false;
            } else {
              console.log("Something went wrong!");
            }
          });
      }
    },
    RemoveNote(index, id) {
      let payload = {
        id: id,
      };
      this.$axios
        .post("api/itinerary/remove_itinerary_note", payload)
        .then(({ data }) => {
          if (data.response) {
            this.payload.notes.splice(index, 1);
          } else {
            console.log("Something went wrong!");
          }
        });
    },
    EditNote(index, data) {
      this.ClearNotePayload();
      this.dialog_note_action = "Edit";
      this.dialog_note = true;
      this.note_payload.index = index;
      this.note_payload.id = data.id;
      this.note_payload.note = data.note;
    },
    UpdateNote() {
      this.$axios
        .post("api/itinerary/update_itinerary_note", this.note_payload)
        .then(({ data }) => {
          if (data.response) {
            let info = this.payload.notes.find(
              (element) => element.id == this.note_payload.id
            );
            info.note = this.note_payload.note;
            this.dialog_note = false;
          } else {
            console.log("Something went wrong!");
          }
        });
    },
    ClearNotePayload() {
      this.note_payload.index = 0;
      this.note_payload.id = 0;
      this.note_payload.itinerary_id = 0;
      this.note_payload.note = "";
      this.note_payload.created_by = 0;
    },
    UpdateItinerary(field, value, required = true) {
      if (this.action != "edit") return;
      if (required) {
        if (value == "") {
          return;
        }
      }
      let info = {
        id: this.payload.id,
        field: field,
        value: value,
      };
      this.$axios.post("api/itinerary/update_itinerary", info).then(({ data }) => {
        if (data.response) {
          console.log(data.message);
        } else {
          console.log("Something went wrong!");
        }
      });
    },
    deleteItinerary() {
      this.$axios.post("api/itinerary/delete_itinerary", { id: this.payload.id })
        .then(({ data }) => {
          if (data.response) {
            this.dialog_delete_itinerary = false
            this.$router.push('/itinerary')
          }
        })
    },
    AddAgency(){
      this.dialog_agency = true;
      this.agency_payload.itinerary_id = this.payload.id;
      this.agency_payload.agency_id = 0;
    },
    SaveAgency(){
      if(!this.agency_payload.agency_info) return console.log("Please select a agency.");
      let info = {
        itinerary_id: this.agency_payload.itinerary_id,
        agency_id: this.agency_payload.agency_info.id,
        contact_id: this.agency_payload.agency_info.contact_id
      };
      this.$axios.post("api/itinerary/add_itinerary_agency", info).then(({ data }) => {
        if (data.response) {
          console.log(data.message);
          this.payload.agency_info = data.data;
          this.dialog_agency = false;
        } else {
          console.log("Something went wrong!");
        }
      });
    },
    RemoveAgency(id){
      let info = {
        id: id
      };
      this.$axios.post("api/itinerary/remove_itinerary_agency", info).then(({ data }) => {
        if (data.response) {
          console.log(data.message);
          this.payload.agency_info = null;
        } else {
          console.log("Something went wrong!");
        }
      });
    },
    AddContributor() {
      this.dialog_contributor = true
      this.contributor_payload.itinerary_id = this.payload.id;
      this.$store.dispatch('itinerary/fetch_itinerary_contributors', this.contributor_payload);
    },
    SaveContributor() {
      if (this.contributor_payload.user_id == 0) return console.log("Please select a contributor.");
      let info = {
        itinerary_id: this.contributor_payload.itinerary_id,
        user_id: this.contributor_payload.user_id.id
      };
      this.$axios.post("api/itinerary/add_itinerary_contributor", info)
        .then(({ data }) => {
          if (data.response) {
            this.payload.contributor_info.push(data.data)
            this.contributor_payload.id = 0
            this.contributor_payload.itinerary_id = 0
            this.contributor_payload.user_id = 0
            this.dialog_contributor = false
          } else {
            console.log("Something went wrong!")
          }
        });
    },
    RemoveContributor(index, id) {
      this.$axios.post("api/itinerary/remove_itinerary_contributor", { id: id })
        .then(({ data }) => {
          if (data.response) {
            console.log(data.message);
            this.payload.contributor_info.splice(index, 1);
          } else {
            console.log("Something went wrong!");
          }
        });
    },
    TravellerDialog(action){
      this.dialog_traveller = true;
      this.traveller_payload.company_id = this.get_selected_company.id;
      this.traveller_payload.itinerary_id = this.payload.id;
      this.traveller_payload.contact_id = 0;
      this.dialog_traveller_action = action;
    },
    CancelAddContact(){
      this.dialog_add_contact_concent = false;
      this.traveller_payload.concent = false;
      this.concent = false;
    },
    CheckConcent(){
      if(this.concent) this.dialog_add_contact_concent = true
      this.traveller_payload.concent = false;
    },
    SaveTraveller(){
      if(this.dialog_traveller_action == 'New'){
        this.$axios.post("api/itinerary/add_itinerary_traveller", this.traveller_payload).then(({ data }) => {
          if (data.response) {
            console.log(data.message);
            this.traveller_payload.contact_id = 0;
            this.traveller_payload.firstname = '';
            this.traveller_payload.lastname = '';
            this.traveller_payload.email = '';
            this.payload.traveller_info.push(data.data);
            this.dialog_traveller = false;
          } else {
            console.log("Something went wrong!");
          }
        });
      }
      if(this.dialog_traveller_action == 'Edit'){
        this.$axios.post("api/itinerary/update_itinerary_traveller", this.traveller_payload).then(({ data }) => {
          if (data.response) {
            console.log(data.message);
            this.traveller_payload.id = 0;
            this.traveller_payload.firstname = '';
            this.traveller_payload.lastname = '';
            this.traveller_payload.email = '';
            let info = this.payload.traveller_info.find((element) => element.id = data.data.id);
            info.travellers_info = data.data.travellers_info;
            this.dialog_traveller = false;
          } else {
            console.log("Something went wrong!");
          }
        });
      }
    },
    RemoveTraveller(index, id){
      this.$axios.post("api/itinerary/remove_itinerary_traveller", { id: id }).then(({ data }) => {
        if (data.response) {
          console.log(data.message);
          this.payload.traveller_info.splice(index, 1);
        } else {
          console.log("Something went wrong!");
        }
      });
    },
    AddExisitingTraveller(action){
      this.dialog_traveller = true;
      this.traveller_payload.company_id = this.get_selected_company.id;
      this.traveller_payload.itinerary_id = this.payload.id;
      this.traveller_payload.contact_id = 0;
      this.dialog_traveller_action = action;
      console.log(this.traveller_payload)
      this.$store.dispatch('itinerary/fetch_contact_info', this.traveller_payload);
    },
    AddExistingContact(){
      let info = {
        company_id: this.traveller_payload.company_id,
        itinerary_id: this.traveller_payload.itinerary_id,
        contact_id: this.traveller_payload.contact_id.id
      };
      this.$axios.post("api/itinerary/add_itinerary_extraveller", info).then(({ data }) => {
        if (data.response) {
          console.log(data.message);
          this.payload.traveller_info.push(data.data);
          this.dialog_traveller = false;
        } else {
          console.log("Something went wrong!");
        }
      });
    },
    EditTraveller(action, data){
      console.log(data);
      this.dialog_traveller = true;
      this.traveller_payload.id = data.id;
      this.traveller_payload.firstname = JSON.parse(data.travellers_info).firstname;
      this.traveller_payload.lastname = JSON.parse(data.travellers_info).lastname;
      this.traveller_payload.email = JSON.parse(data.travellers_info).email;
      this.dialog_traveller_action = action;
    }
  },
  watch: {},
};
</script>

<style scoped>
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.pad-all {
  padding: 5px;
}
.form-group {
  margin-top: -10px;
}
.form-group h3 {
  padding-bottom: 10px;
  font-size: 12px;
}
.form-group .form-control {
  margin-top: 5px;
}
.margin-top-zero {
  margin-top: -25px;
  margin-left: 5px;
  margin-bottom: 10px;
}
.tags-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 4px;
  gap: 1rem;
}
.tags-wrapper .tags {
  max-height: 50px;
  display: flex;
  background-color: #dcdee0;
}
.tags-wrapper .tags .tag-name {
  margin: auto 0;
  padding: 12px;
  color: #343642;
  font-weight: bold;
  font-size: 16px;
  background: rgb(237, 238, 239);
}
.tags-wrapper .tags .tag-action {
  margin: auto 0;
}

.note-wrapper {
  margin: 15px auto;
}

.dialog-close {
  margin-left: -75px;
  position: absolute;
}

.note-wrapper .note-content {
  display: flex;
}
.note-wrapper .note-content .note {
  width: 100%;
}
.note-wrapper .note-content .note-action {
  margin: auto 0px;
  width: 20%;
}
.agency-wrapper {
  display: flex;
  width: 100%;
}
.agency-wrapper .agency-info {
  display: flex;
  width: 100%;
  border: 1px solid #c0c7cd;
  border-radius: 5px;
  justify-content: space-between;
}
.agency-wrapper .agency-info .agency-content {
  width: 50%;
  border-right: 1px solid #c0c7cd;
  padding: 20px;
}
.agency-wrapper .agency-info .agency-connection {
  width: 50%;
  padding: 20px;
}
.agency-wrapper .agency-action {
  width: 20%;
  padding: 20px;
}
.agency-wrapper .agency-action .agency-action-btn {
  margin-top: 9px;
}
.assignees-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.assignees-wrapper .assignees {
  width: 100%;
  border-radius: 10px;
  display: flex;
}
.assignees-wrapper .assignees .assignees-avatar {
  background-color: #343642;
  color: white;
  font-size: 25px;
}
.assignees-wrapper .assignees .assignees-initial {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 10px;
  margin-right: 12px;
}
.assignees-wrapper .assignees .assignees-initial p {
  margin: 0px;
  font-size: 18px;
}
.assignees-wrapper .assignees .assignees-initial p::first-letter {
  font-weight: bolder;
}
.assignees-wrapper .assignees .assignees-initial span {
  font-size: 10px;
}
.assignees-wrapper .assignees .assignees-action {
  margin: 0 auto;
}
.data-chip{
    background-color: rgb(56, 154, 116) !important;
    font-weight: bolder;
    font-size: 14px;
    color: white;
    margin-right: 5px;
}
.traveller-wrapper .traveller-content {
  display: flex;
}
.traveller-wrapper .traveller-content .traveller {
  width: 100%;
}
.traveller-wrapper .traveller-content .traveller p {
  margin: auto 0px;
}
.traveller-wrapper .traveller-content .traveller-action {
  margin: auto 0px;
  width: 20%;
}
@media only screen and (max-width: 959px) {
}
</style>
