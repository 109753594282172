export default {
    namespaced: true,
    state: {
        page_name: String("")
    },
    mutations: {
        setPageName(state, payload) {
            state.page_name = payload
        }
    },
    getters: {
        getPageName: (state) => state.page_name
    },
    actions: {
        setPageNameAction( {commit}, payload ) {
            commit('setPageName', payload)
        }
    }
}