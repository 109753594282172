<template>
    <v-row justify="center">
        <v-dialog
            v-model="edit_net_price_model"
            max-width="500"
            persistent>
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-cash</v-icon>
                    Edit Net Price
                </v-card-title>
                <v-card-text class="mt-3">
                    <v-text-field
                        v-model="net_price"
                        label="Enter the new Net Price"
                        placehold="Enter your desired Net Price..."
                        :hint="`Current Net Price: ${getSupplierCurrency()} ${current_net_price.toFixed(2)}.`"
                        persistent-hint
                        outlined
                        dense>
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text class="px-6" @click="closeEditNetPriceDialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn color="success" class="px-6" @click="updateNetPrice()">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import Pricing from '../modules/Pricing.js'
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        edit_net_price_model: false,
        net_price: 0, 
        current_net_price: 0,       
        pricing: null
    }),
    props: {
        edit_net_price_dialog: Boolean,
        item: Object,
    },
    computed: {
        ...mapGetters({
            get_itinerary_action: "itinerary/get_itinerary_action",
        })
    },
    methods: {
        async updateNetPrice() {
            await this.pricing.updateNetPrice(
                this.item,
                this.net_price == 0 ? this.current_net_price : Number(this.net_price),
                this.get_itinerary_action.id
            )
            this.closeEditNetPriceDialog()
        },
        closeEditNetPriceDialog() {
            this.$emit('closeEditNetPriceDialog')
        },
        getSupplierCurrency() {
            if ( this.item != null ) {
                return this.item.get_supplier.currency != null ? this.item.get_supplier.currency : ''
            }
        }
    },
    created() {
        this.pricing = new Pricing(this.$store)
    },
    watch: {
        edit_net_price_dialog() {
            this.edit_net_price_model = this.edit_net_price_dialog
            if ( this.edit_net_price_dialog == true ) {
               const number_of_days = this.pricing.get_range(this.item.check_in_date, this.item.check_out_date)
               this.current_net_price = (number_of_days == 0 ? 1 : number_of_days) * this.item.rates[0].net
               this.net_price = 0
            }
        }
    }
}
</script>