import EventsComponent from "../../../../components/events/EventsComponent.vue"
import ManageEvent from "../../../../components/events/ManageEvent.vue"

export default [
    {
        path: '/events',
        name: 'Events',
        meta: { layout: 'mystagit' },
        component: EventsComponent,
    },
    {
        path: '/events/:event_id/manage_event',
        name: 'ManageEvent',
        meta: { layout: 'mystagit' },
        component: ManageEvent,
    },
]