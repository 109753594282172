import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        task_type_info: [],
        priority_type_info: [],
        user_info: [],
        task_info: [],
    },
    mutations: {
        set_task_type(state, payload){
            state.task_type_info = [...payload]
        },
        set_priority_type(state, payload){
            state.priority_type_info = [...payload]
        },
        set_user_info(state, payload){
            state.user_info = [...payload]
        },
        set_task_info(state, payload){
            state.task_info = [...payload]
        },
        set_task(state, payload){
            state.task_info.push(payload)
        }
    },
    getters: {
        get_task_type : state => state.task_type_info,
        get_priority_type : state => state.priority_type_info,
        get_task_info : state => (filter, payload, search) => {
            if(filter=='All'){
                if(search){
                    return state.task_info.filter(element => {
                        return element.task.toLowerCase().startsWith(search) || element.task.toUpperCase().startsWith(search)
                    })
                }
                else{
                    return state.task_info
                }
            }
            else if(filter=='My Task'){
                if(search){
                    return state.task_info.filter((element) => {
                        if(element.task.toLowerCase().startsWith(search) || element.task.toUpperCase().startsWith(search)){
                            return element.assignee_info.some((assignee) => {
                                if(assignee.user_id==payload.user_id){
                                    return element
                                }
                            });
                        }
                    })  
                }
                else{
                    return state.task_info.filter((element) => {
                        return element.assignee_info.some((assignee) => {
                            if(assignee.user_id==payload.user_id){
                                return element
                            }
                        });
                    })
                }
            }
            else if(filter=='Done'){
                if(search){
                    return state.task_info.filter((element) => {
                        if(element.task.toLowerCase().startsWith(search) || element.task.toUpperCase().startsWith(search)){
                            if(element.status == 'Done'){
                                return element
                            }
                        }
                    })
                }
                else{
                    return state.task_info.filter((element) => {
                        if(element.status == 'Done'){
                            return element
                        }
                    })
                }
            }
            else if(filter=='To do today'){
                let Today = new Date();
                if(search){
                    return state.task_info.filter((element) => {
                        if(element.task.toLowerCase().startsWith(search) || element.task.toUpperCase().startsWith(search)){
                            if(new Date(element.due_date).getTime() <= Today.getTime() && (element.status == 'To do' || element.status == 'In progress')){
                                return element
                            }
                        }
                    })
                }
                else{
                    return state.task_info.filter((element) => {
                        if(new Date(element.due_date).getTime() <= Today.getTime() && (element.status == 'To do' || element.status == 'In progress')){
                            return element
                        }
                    })
                }
            }
            else if(filter=='To do this week'){
                let today = new Date();
                // let first = new Date(today.setDate(today.getDate() - today.getDay() + 1));
                let last = new Date(today.setDate((today.getDate() - today.getDay()) + 7));
                if(search){
                    return state.task_info.filter((element) => {
                        if(element.task.toLowerCase().startsWith(search) || element.task.toUpperCase().startsWith(search)){
                            if(new Date(element.due_date).getTime() <= last.getTime() && (element.status == 'To do' || element.status == 'In progress')){
                                return element
                            }
                        }
                    })
                }
                else{
                    return state.task_info.filter((element) => {
                        if(new Date(element.due_date).getTime() <= last.getTime() && (element.status == 'To do' || element.status == 'In progress')){
                            return element
                        }
                    })
                }
            }
            else if(filter=='Comming up'){
                let today = new Date();
                if(search){
                    return state.task_info.filter((element) => {
                        if(element.task.toLowerCase().startsWith(search) || element.task.toUpperCase().startsWith(search)){
                            if(new Date(element.due_date).getTime() >= today.getTime() && (element.status == 'To do' || element.status == 'In progress')){
                                return element
                            }
                        }
                    })
                }
                else{
                    return state.task_info.filter((element) => {
                        if(new Date(element.due_date).getTime() >= today.getTime() && (element.status == 'To do' || element.status == 'In progress')){
                            return element
                        }
                    })
                }
            }
        },
        get_user_info : state => (filter) => {
            if(filter.length!=0){
                return state.user_info.filter(el => {
                    if(!filter.includes(el.id)){
                        return el
                    }
                })
            }
            else{
                return state.user_info
            }
        },
    },
    actions: {
        async fetch_task_type({commit}){
            await Vue.axios.get('api/task/fetch_task_type')
            .then(({data}) => {
                commit('set_task_type', data.data)
            })
        },
        async fetch_priority_type({commit}){
            await Vue.axios.get('api/task/fetch_priority_type')
            .then(({data}) => {
                commit('set_priority_type', data.data)
            })
        },
        async fetch_user_info({commit}){
            await Vue.axios.get('api/task/fetch_user_info')
            .then(({data}) => {
                commit('set_user_info', data.data)
            })
        },
        async fetch_task_info({commit}, payload){
            let url = `api/task/fetch_task_info/${payload.company_id}`;
            if(payload.itinerary_id) url += `/${payload.itinerary_id}`;
            await Vue.axios.get(url)
            .then(({data}) => {
                commit('set_task_info', data.data)
            })
        },
        async set_task({commit},  payload){
            commit('set_task', payload)
        }
    }
}