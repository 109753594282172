<template>
    <v-row justify="center">
        <v-dialog v-model="show_event_types_dialog" max-width="1800" persistent scrollable>
            <v-card>
                <v-card-title>Event Types</v-card-title>
                <v-card-text style="padding-bottom:100px;">
                    <v-row>
                        <v-col cols="3" class="pr-15">
                            <div class="mb-5">Create new event type</div>
                            <v-text-field v-model="new_event_type.name" label="Name *" outlined dense></v-text-field>
                            <v-btn dark class="custom-buttons mt-5" @click="createEventType()">Create</v-btn>
                        </v-col>
                        <v-col cols="9" class="pl-15">
                            <v-data-table :headers="event_type_headers" :items="event_types" disable-pagination
                                hide-default-footer style="height:500px;overflow-y:scroll;">
                                <template v-slot:item="{ item }">
                                    <tr style="cursor:inherit;background-color:transparent;">
                                        <td>{{ item.id }}</td>
                                        <td style="align-content:end;">
                                            <div>
                                                <v-text-field dense v-model="item.name"
                                                    @keyup.enter="updateEventType(item)"></v-text-field>
                                            </div>
                                        </td>
                                        <td>
                                            <div style="height:50px;padding-top:10px;">
                                                <v-img contain max-width="160px" :src="api_url + item.logo"></v-img>
                                            </div>
                                            <br>
                                            <v-file-input v-model="file['logo'][item.id]" label="Upload New" dense
                                                @change="updateLogo('logo', item)"></v-file-input>
                                        </td>
                                        <td>
                                            <div style="height:50px;padding-top:10px;">
                                                <v-img contain max-width="160px"
                                                    :src="api_url + item.alternate_logo"></v-img>
                                            </div>
                                            <br>
                                            <v-file-input v-model="file['alternate_logo'][item.id]" label="Upload New"
                                                dense @change="updateLogo('alternate_logo', item)"></v-file-input>
                                        </td>
                                        <td>
                                            <div style="height:50px;padding-top:10px;">
                                                <v-img contain max-width="160px"
                                                    :src="api_url + item.small_logo"></v-img>
                                            </div>
                                            <br>
                                            <v-file-input v-model="file['small_logo'][item.id]" label="Upload New" dense
                                                @change="updateLogo('small_logo', item)"></v-file-input>
                                        </td>
                                        <td>
                                            <div style="height:50px;padding-top:10px;">
                                                <v-img contain max-width="160px"
                                                    :src="api_url + 'media/' + item.itinerary_header"></v-img>
                                            </div>
                                            <br>
                                            <v-file-input v-model="file['itinerary_header'][item.id]" label="Upload New"
                                                dense @change="updateLogo('itinerary_header', item)"></v-file-input>
                                        </td>
                                        <td><v-btn class="custom-buttons" color="red" icon
                                                @click="deleteEventType(item)"><v-icon>mdi-delete</v-icon></v-btn></td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-buttons" text @click="closeEventTypesDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Settings from '../../../modules/admin/settings/modules/settings.js'
export default {
    components: {
    },
    props: [
        'show_event_types_dialog'
    ],
    data: () => ({
        classes: {
            settings: null
        },
        selected_template: null,
        event_types: [],
        event_type_headers: [
            { text: 'ID', sortable: false, width: '5%' },
            { text: 'Name', sortable: false, width: '25%' },
            { text: 'Logo', sortable: false, width: '15%' },
            { text: 'Alternate Logo', sortable: false, width: '15%' },
            { text: 'Small Logo', sortable: false, width: '15%' },
            { text: 'Itinerary Header', sortable: false, width: '15%' },
            { text: '', sortable: false },
        ],
        new_event_type: {
            name: null,
        },
        file: {
            logo: [],
            alternate_logo: [],
            small_logo: [],
            itinerary_header: [],
        },
        api_url: null,
    }),
    created() {
        this.classes.settings = new Settings()
    },
    async mounted() {
        this.getEventTypes()
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_user: 'auth/get_user',
        })
    },
    methods: {
        async getEventTypes() {
            const result = await this.classes.settings.getEventTypes({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.event_types = result.data
            }
        },
        closeEventTypesDialog() {
            this.$emit('closeEventTypesDialog')
        },
        async createEventType() {
            if (this.new_event_type.name == null) {
                this.$toast.error('Please enter an event type name')
                return
            }
            let payload = {
                company_id: this.get_selected_company.id,
                name: this.new_event_type.name,
            }
            const result = await this.classes.settings.createEventType(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.new_event_type.name = null
                this.new_event_type.type = null
                this.getEventTypes()
            }
        },
        async updateEventType(item) {
            let payload = {
                id: item.id,
                name: item.name,
            }
            const result = await this.classes.settings.updateEventType(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.getEventTypes()
            }
        },
        async deleteEventType(item) {
            const result = await this.classes.settings.deleteEventType({ event_type_id: item.id })
            if (result.response == true) {
                this.$toast.success(result.message)
                this.getEventTypes()
            }
        },
        async updateLogo(type, item) {
            if (this.file[type][item.id] == null || this.file[type][item.id] == 'null') {
                return
            }
            const formData = new FormData()
            formData.append(type, this.file[type][item.id])
            const result = await this.classes.settings.updateEventTypeLogo(item.id, formData)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.getEventTypes()
                this.file[type][item.id] = null
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row)) {
    border-bottom: none !important;
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}
</style>