<template>
    <v-row justify="center">
        <v-dialog v-model="create_event_dialog" max-width="1000" persistent scrollable>
            <v-card v-if="lead && quote" class="px-5 py-5">
                <v-card-title>
                    <h2>Book Quote</h2>
                </v-card-title>
                <v-card-text>
                    <v-form ref="event_form_data">
                        <v-row class="mt-3">
                            <v-col cols="4" v-if="lead.event == null">
                                <div>
                                    <v-text-field v-model="event.name" placeholder="Enter Event Name" dense type="text"
                                        tabindex="7" required
                                        :rules="[v => !!v || 'You are required to enter the name of this event.']"></v-text-field>
                                </div>
                                <div>
                                    <v-text-field v-model="event.deposit_amount" placeholder="Enter Deposit Amount"
                                        dense type="number" tabindex="7" required
                                        :rules="[v => !!v || 'You are required to enter the deposit amount of this event.']"></v-text-field>
                                </div>
                            </v-col>
                            <v-col cols="4" v-else>
                                <h2>
                                    {{ event.name }}
                                </h2>
                                <div>
                                    Deposit Amount: {{ get_selected_company.currency }}{{ event.deposit_amount }}
                                </div>
                            </v-col>
                            <v-col cols="2">
                                <small>ORGANISER NAME</small>
                                <br>
                                {{ lead.first_name + ' ' + lead.last_name }}
                            </v-col>
                            <v-col cols="2">
                                <small>EVENT DATE</small>
                                <br>
                                {{ $date(lead.start_date).format('MMMM DD, YYYY') }}
                            </v-col>
                            <v-col cols="2">
                                <small>LOCATION</small>
                                <br>
                                {{ lead.location.name }}
                            </v-col>
                            <v-col cols="2">
                                <small>GROUP SIZE</small>
                                <br>
                                {{ lead.people }}
                            </v-col>
                        </v-row>
                        <v-row style="border-bottom:solid 1px grey;" class="mb-5">
                            <v-col cols="4">
                                <h3 class="mt-5">ACTIVITIES</h3>
                            </v-col>
                            <v-col cols="2">
                                <small>QUOTE NAME</small>
                                <br>
                                <b style="font-weight:800;">{{ quote.name }}</b>
                            </v-col>
                            <v-col cols="2">
                                <small>QUOTE PRICE</small>
                                <br>
                                <b style="font-weight:800;">{{ quote.total }}</b>
                            </v-col>
                            <v-col cols="4">
                                <small>ACCOMMODATION</small>
                                <br>
                                <div v-for="(build, accom_index) in quote.builds" :key="'accom' + accom_index">
                                    <b style="font-weight:800;" v-if="build.type_id == 1">
                                        {{ build.supplier.supplier_name }}
                                    </b>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-for="(build, index) in quote.builds.filter((x) => x.type_id != 1)" :key="index">
                            <v-col cols="6">
                                <b style="font-weight:800;">{{ build.supplier.supplier_name }}</b>
                                <br>
                                {{ build.option.name }} ({{ $date(build.start_date).format('dddd') }})
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="build.start_time" label="TIME TO BE THERE" dense
                                    outlined></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" dense @click="closeCreateEventDialog()" class="btn-font-format px-6">
                        <label class="text-proper-case" tabindex="14">Close</label>
                    </v-btn>
                    <v-btn elevation="0" dense class="btn-font-format px-6" color="primary" @click="createEvent()">
                        <label class="text-proper-case" tabindex="13">Book</label>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Events from '@/modules/admin/events/modules/events.js'
import Quotes from '@/modules/admin/quotes/modules/quotes.js'

export default {
    props: [
        'create_event_dialog', 'quote', 'lead'
    ],
    data: () => ({
        event: {
            name: null,
            deposit_amount: 0,
        },
        classes: {
            events: null,
            quotes: null,
        },
        quote_data: [],
        menu: [],
    }),
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            getLocation: 'locationStore/getLocationsGetter',
        }),
    },
    async mounted() {
        this.classes.events = new Events()
        this.classes.quotes = new Quotes()
    },
    methods: {
        ...mapActions({

        }),
        closeCreateEventDialog() {
            this.$emit('closeCreateEventDialog')
        },
        createEvent() {
            if (this.lead.event == null) {
                const valid = this.$refs.event_form_data.validate()
                if (valid) {
                    this.$emit('createEvent', this.event, this.quote)
                }
            }
            else {
                this.$emit('bookQuote', this.quote)
            }
        }
    },
}

</script>

<style lang="scss" scoped>
.btn-font-format {
    font-family: 'Manrope';
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
}

.form-label {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 600;
}

.required-field {
    color: white;
}

.text-proper-case {
    cursor: pointer;
}

.text-field-style>>>.v-text-field__slot input {
    color: white;
}
</style>