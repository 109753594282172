<template>
    <div>
        <h3 class="mb-5 custom-title">
            SELECT ACCOMMODATION
        </h3>
        <v-card elevation="0" class="custom-title">
            <v-card-actions class="mt-5" style="justify-content:center;">
                <v-spacer></v-spacer>
                <v-btn @click="save()" style="background-color:green;color:white;font-weight:800;">SAVE AND
                    NEXT</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-for="(accommodation, index) in accommodations" :key="index" outlined class="card-format mt-5">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <div class="mb-3 sublabel">START DATE</div>
                        <v-select v-model="accommodation.start_date" :items="date_range" filled outlined dense>
                            <template v-slot:selection="{ item }">
                                <b>{{ $date(item).format('dddd, Do MMMM YYYY') }}</b>
                            </template>
                            <template v-slot:item="{ item }">
                                <b>{{ $date(item).format('dddd, Do MMMM YYYY') }}</b>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="6">
                        <div class="mb-3 sublabel">END DATE</div>
                        <v-select v-model="accommodation.end_date" :items="date_range" filled outlined dense>
                            <template v-slot:selection="{ item }">
                                <b>{{ $date(item).format('dddd, Do MMMM YYYY') }}</b>
                            </template>
                            <template v-slot:item="{ item }">
                                <b>{{ $date(item).format('dddd, Do MMMM YYYY') }}</b>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="mb-3 sublabel">SELECT SUPPLIER</div>
                        <v-autocomplete v-model="accommodation.supplier" :items="getSuppliersAccommodation"
                            @change="getSupplier(index)" item-text="supplier_name" return-object filled outlined dense
                            :disabled="accommodation.start_date == null || accommodation.end_date == null">
                            <template v-slot:selection="{ item }">
                                <b>{{ item.supplier_name }}</b>
                            </template>
                            <template v-slot:item="{ item }">
                                <b>{{ item.supplier_name }}</b>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row v-if="accommodation.supplier">
                    <v-col cols="4">
                        <v-img :src="api_url + 'media/suppliers/images/' + accommodation.supplier.image"></v-img>
                    </v-col>
                    <v-col cols="8">
                        <div v-html="accommodation.supplier.description" class="description">
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="accommodation.supplier">
                    <v-col cols="12" v-if="accommodation.options[0] == null">
                        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" v-else>
                        <div class="mb-3 sublabel">ACCOMMODATION PRICE</div>
                        <div class="py-5">
                            <div class="accom-price">{{ get_selected_company.currency }}{{
                                accommodation.options[0].customer_price }}</div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="custom-buttons mb-5">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="removeAcommodation(index)"><small>Remove</small></v-btn>
            </v-card-actions>
        </v-card>
        <v-card elevation="0">
            <v-card-actions class="mt-5" style="justify-content:center;">
                <v-btn class="custom-buttons" text @click="openAddDialog()">
                    <v-icon>mdi-plus</v-icon>
                    Add Accommodation
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card elevation="0" class="custom-title">
            <v-card-actions class="mt-5" style="justify-content:center;">
                <v-spacer></v-spacer>
                <v-btn @click="save()" style="background-color:green;color:white;font-weight:800;">SAVE AND
                    NEXT</v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="add_dialog" max-width="600px" persistent>
            <v-card>
                <v-card-title>Do you want to proceed in adding a new supplier?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-buttons" text @click="closeAddDialog()">Cancel</v-btn>
                    <v-btn class="custom-buttons" dark @click="addAccommodation()">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    components: {
    },
    props: [
        'lead', 'date_range', 'suppliers', 'edit_build'
    ],
    data: () => ({
        accommodations: [],
        api_url: null,
        add_dialog: false,
    }),
    async mounted() {
        this.api_url = process.env.VUE_APP_API_URL
        this.getEditData()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        }),
        getSuppliersAccommodation() {
            return this.suppliers.filter((item) => {
                return item.service_type_id === 1
            })
        },
    },
    methods: {
        getEditData() {
            if (this.edit_build) {
                this.edit_build.forEach((build, index) => {
                    this.addAccommodation()
                    this.accommodations[index].start_date = build.start_date
                    this.accommodations[index].end_date = build.end_date
                    this.accommodations[index].supplier = build.supplier
                    this.accommodations[index].options = build.options
                    this.getSupplier(index)
                })
            }
        },
        openAddDialog() {
            this.add_dialog = true
        },
        closeAddDialog() {
            this.add_dialog = false
        },
        addAccommodation() {
            this.accommodations.push({
                id: null,
                start_date: null,
                end_date: null,
                supplier: null,
                options: [],
            })
            this.closeAddDialog()
        },
        removeAcommodation(index) {
            this.accommodations.splice(index, 1)
        },
        skip() {
            this.$emit('skip')
        },
        back() {
            this.$emit('back')
        },
        save() {
            this.$emit('save', this.accommodations, 0)
        },
        // matchedRate(index) {
        //     let matched_rate = null
        //     if (this.rate[index] && this.accommodations[index].start_date && this.$date(this.accommodations[index].start_date).isBetween(this.rate[index].start_date, this.rate[index].end_date, null, '[]')) {
        //         this.rate[index].days.forEach(day => {
        //             if (this.$date(this.accommodations[index].start_date).format('dddd') == day.day)
        //                 matched_rate = day.gross
        //         })
        //     }
        //     if (matched_rate) {
        //         return parseFloat(matched_rate)
        //     }
        //     else {
        //         return 0
        //     }
        // },
        async getSupplier(index) {
            await this.$axios.get(`admin/suppliers/get_supplier/${this.accommodations[index].supplier.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.accommodations[index].supplier = data.data
                        this.accommodations[index].options[0] = this.accommodations[index].supplier.products[0]
                        console.log(data.data)
                        // this.rate[index] = this.accommodations[index].options[0].rates[0]
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

:deep(.v-input__slot) {
    background: white !important;
}

:deep(.v-stepper__header) {
    box-shadow: none !important;
}

.accom-price {
    color: #FAAF3A;
    font-size: 36px;
    font-weight: 600;
}

.custom-title {
    font-family: 'Manrope';
    font-size: 14px !important;
    color: #3988AA;
    font-weight: 600;
}

.sublabel {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description {
    color: #7D7D7D;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.summary {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

:deep(.custom-buttons span) {
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}
</style>