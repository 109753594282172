<template>
    <v-container fluid class="mt-1">
        <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="6" style="text-align:right;display:flex;align-items:center;">
                <v-select :items="event.lead.quotes.filter((x) => x.event_id != event.id)" v-model="selected_quote_to_add"
                    item-text="name" item-value="id" return-object label="Select a quote"></v-select>
                <v-btn class="custom-buttons" @click="addQuoteToEvent()" dark>Add to event</v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table :headers="headers" :items="event.quotes" disable-pagination hide-default-footer
                    :sort-by.sync="sort_by" :sort-desc.sync="sort_desc" style="font-family: Manrope;">
                    <template v-slot:item.is_booked="{ item }">
                        <label><v-icon color="success" small
                                v-if="item.is_booked == 1">mdi-star-outline</v-icon></label>
                    </template>
                    <template v-slot:item.total="{ item }">
                        <label v-if="item.total">{{ get_selected_company.currency }}{{ item.total.toFixed(2) }}</label>
                    </template>
                    <template v-slot:item.groupsize="{ item }">
                        <label v-if="item.groupsize">{{ item.groupsize }}</label>
                    </template>
                    <template v-slot:item.date_sent="{ item }">
                        <v-chip color="success" v-if="item.date_sent">{{ $date(item.date_sent).format('DD-MMM-YYYY')
                            }}</v-chip>
                        <v-chip color="error" v-else>Never</v-chip>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        <label>{{ $date(item.created_at).format('DD-MMM-YYYY') }}</label>
                    </template>
                    <template v-slot:item.is_activated="{ item }">
                        <!-- <label v-if="item.is_activated">Yes</label>
                        <label v-else>No</label> -->
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="50" v-bind="attrs" v-on="on" v-ripple class="mr-1" color="primary"
                                    @click="toggleActivateQuote(item, 0)"
                                    v-if="item.is_activated">mdi-toggle-switch</v-icon>
                                <v-icon size="50" v-bind="attrs" v-on="on" v-ripple class="mr-1" color="primary"
                                    @click="toggleActivateQuote(item, 1)" v-else>mdi-toggle-switch-off-outline</v-icon>
                            </template>
                            <span v-if="item.is_activated">Deactivate</span>
                            <span v-else>Activate</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" v-ripple class="mr-1" color="primary"
                                    @click="openUpdateQuoteDialog(item)">mdi-magnify</v-icon>
                            </template>
                            <span>View/Update Quote</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-dialog v-model="update_quote_dialog" persistent scrollable max-width="1900">
                <v-card v-if="event && selected_quote">
                    <v-card-text>
                        <CustomQuote :lead_id="event.lead.id" :quote_id="selected_quote.id" />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" text @click="closeUpdateQuoteDialog()">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Events from '../../modules/admin/events/modules/events.js'
import Invitees from '../../modules/admin/invitees/modules/invitees.js'
import Settings from '../../modules/admin/settings/modules/settings.js'
import Quotes from '../../modules/admin/quotes/modules/quotes.js'

import CustomQuote from '../quotes/CustomQuote.vue'

export default {
    components: {
        CustomQuote
    },
    data: () => ({
        page_name: "Quotes",
        classes: {
            events: null,
            invitees: null,
            settings: null,
            quotes: null,
        },
        headers: [
            { text: 'ID', value: 'id', width: '5%', sortable: false, align: 'center' },
            { text: 'Quote Name', value: 'name' },
            { text: 'Type', value: 'type' },
            { text: 'Total', value: 'total' },
            { text: 'Groupsize', value: 'groupsize' },
            { text: 'Date Sent', value: 'date_sent' },
            { text: 'Created', value: 'created_at' },
            { text: 'Activated', value: 'is_activated', align: 'center' },
            { text: 'Action', value: 'action', width: '140', align: 'center' },
        ],
        sort_by: 'id',
        sort_desc: true,
        update_quote_dialog: false,
        selected_quote: null,
        selected_quote_to_add: null,
    }),
    created() {
        this.setPageName(this.page_name)
        this.classes.events = new Events()
        this.classes.invitees = new Invitees()
        this.classes.settings = new Settings()
        this.classes.quotes = new Quotes()
    },
    async mounted() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            event: 'eventsStore/getSelectedEventGetter',
        }),
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
        }),
        async addQuoteToEvent() {
            let payload = {
                id: this.selected_quote_to_add.id,
                company_id: this.get_selected_company.id,
                event_id: this.event.id,
                is_booked: true,
            }
            const result = await this.classes.quotes.updateQuote(payload)
            if (result.response == true) {
                this.$emit('refresh')
            }
        },
        async toggleActivateQuote(item, status) {
            let payload = {
                id: item.id,
                company_id: this.get_selected_company.id,
                is_activated: status,
            }
            const result = await this.classes.quotes.updateQuote(payload)
            if (result.response == true) {
                this.$emit('refresh')
            }
        },
        openUpdateQuoteDialog(item) {
            this.selected_quote = item
            this.update_quote_dialog = true
        },
        closeUpdateQuoteDialog() {
            this.selected_quote = null
            this.update_quote_dialog = false
            this.$emit('refresh')
        }
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    border-radius: 15px !important;

    .v-data-footer {
        background-color: #E9E9E9;
        color: #7C7C7C;
        font-family: 'Work Sans';
    }

    .v-data-footer__select .v-text-field {
        background-color: white;

        .v-select__slot {
            padding-left: 10px;
        }
    }
}

.custom-small {
    color: #7C7C7C;
    font-size: 12px;
    font-weight: 600;
}

:deep(.theme--light.v-data-table tbody td) {
    height: 40px !important;
}

.label-text-color {
    color: #7C7C7C;
}

.text-proper-case {
    text-transform: capitalize;
}

.search-text-field {
    margin-top: 15px;

    .v-input__slot {
        min-height: 0 !important;
        margin-top: 8px;
    }

    .v-input__prepend-inner {
        margin-top: 5px !important;
    }
}

:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.paid_row {
    background-color: #FFF1F1 !important;
}


:deep(.custom-text-field) {
    legend {
        display: none;
    }

    fieldset {
        border-color: #D3D3D3 !important;
    }

    input {
        color: #101010;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}

.label-1 {
    color: #7C7C7C;
    background-color: #F7F7F7;
    font-size: 20px;
    border: solid 1px #7C7C7C;
    border-radius: 4px;
    padding: 3px 5px;
}

.label-2 {
    color: #EE9E25;
    background-color: #FFF3E2;
    font-size: 20px;
    border: solid 1px #EE9E25;
    border-radius: 4px;
    padding: 3px 5px;
}

.label-3 {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 20px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
}

.label-4 {
    color: #AA3939;
    background-color: #FFCFCF;
    font-size: 20px;
    border: solid 1px #AA3939;
    border-radius: 4px;
    padding: 3px 5px;
}

.custom-expansion-panels {
    max-height: 450px;
    overflow-y: scroll;
    padding-right: 9px;
    padding-bottom: 50px;
}

.custom-expansion-panel::before {
    box-shadow: none !important;
}

.custom-expansion-panel::after {
    border-top: none !important;
}

.v-expansion-panel--active,
.v-expansion-panel--active+.v-expansion-panel {
    margin-top: 0px !important;
}

.v-expansion-panel-header--active {
    border-radius: 10px 10px 0px 0px !important;
    min-height: 0px !important;
}

:deep(.custom-expansion-header) {
    border-radius: 10px;
    background: var(--Blue-Linear, #3988AA);
    color: var(--icon-color, #FFF);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    .v-icon {
        color: white !important;
    }
}

.custom-expansion-panel-content {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid var(--Border, #E9E9E9);
    background: var(--icon-color, #FFF);
    box-shadow: 5px 5px 13px 0px rgba(240, 240, 240, 0.90), -5px -5px 10px 0px rgba(255, 255, 255, 0.90), 5px -5px 10px 0px rgba(240, 240, 240, 0.20), -5px 5px 10px 0px rgba(240, 240, 240, 0.20);
}
</style>