<template>
    <v-container fluid style="background-color: #F4F4F5 !important;">
        <v-row class="mt-5" justify="center" align="center">
            <v-card width="820" elevation="2">
                <v-card-title class="text-small">
                    <small><strong>Description</strong></small>
                </v-card-title>
                <v-card-subtitle>
                    Tell your customers a little about where they are staying; the information you provide here will
                    appear in their itinerary
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <vue-editor v-model="get_supplier_setup.description"></vue-editor>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="#588BAD" dark class="pl-10 pr-10" small rounded @click="save_description"
                        style="text-transform: none !important; color: white;">
                        Save Description
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapGetters } from 'vuex'
export default {
    components: {
        VueEditor
    },
    props: [
    ],
    data: () => ({
        content: ""
    }),
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_supplier_setup: 'supplier/get_supplier_setup'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async save_description() {
            await this.$axios.patch('api/suppliers/update_description', {
                supplier_id: this.get_supplier_setup.id,
                company_id: this.get_selected_company.id,
                description: this.get_supplier_setup.description
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.showSnackBar(data.message)
                        this.$store.dispatch('supplier/fetch_supplier', this.get_selected_company.id)
                        // this.$store.dispatch('supplier/set_supplier_setup_company', data.data)
                    }
                })
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>