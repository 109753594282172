<template>
    <div>
        <v-navigation-drawer app :value="drawer"
            v-click-outside="{ handler: closeDrawer, closeConditional: onCloseConditional }"
            :permanent="!$vuetify.breakpoint.mdAndDown" clipped style="background-color: #E9E9E9;">
            <v-list>
                <v-list-item class="px-5 pt-4 pb-1">
                    <v-img v-if="get_selected_company.company_logo" :src="api_url + get_selected_company.company_logo"
                        contain></v-img>
                    <div v-else style="font-family:VintageGoods;font-size:26px;color:#3988AA;">GoGroupBooking</div>
                </v-list-item>
                <v-divider class="mx-6"></v-divider>
                <v-menu v-model="menu_select_company" :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="mx-4 mt-3 mb-3 mystagit-logo" link two-line v-bind="attrs" v-on="on"
                            v-if="get_selected_company && get_selected_company.id">
                            <!-- <v-list-item-avatar>
                                <v-img :src="mystagit_logo"></v-img>
                            </v-list-item-avatar> -->
                            <v-list-item-content>
                                <v-list-item-title>{{ get_selected_company.company_name }}</v-list-item-title>
                                <!-- <v-list-item-subtitle>Mullingar, WH</v-list-item-subtitle> -->
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small color="grey lighten-1">mdi-unfold-more-horizontal</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="mx-4 mt-3 mb-3 mystagit-logo" link two-line v-bind="attrs" v-on="on" v-else>
                            <v-list-item-content>
                                <v-list-item-title>Select a Company</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small color="grey lighten-1">mdi-unfold-more-horizontal</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                    <CompanySelection @eventCloseCompanySelectionMenu="eventCloseCompanySelectionMenu()"
                        @openAddCompanyDialog="openAddCompanyDialog()">
                    </CompanySelection>
                </v-menu>
                <!-- <v-divider class="mx-6"></v-divider> -->
                <!-- <v-list-item>
                    <v-list-item-content>
                        <v-text-field outlined dense class="mx-2" placeholder="Search" prepend-inner-icon="mdi-magnify"
                            hide-details></v-text-field>
                    </v-list-item-content>
                </v-list-item> -->
                <v-divider class="mx-6 mb-3"></v-divider>
                <label v-if="get_selected_company && get_selected_company.id" class="mx-6"
                    style="font-family: 'Work Sans';font-weight: bold;color:#7C7C7C">Company
                    Menu</label>
            </v-list>

            <v-list dense nav v-if="get_selected_company && get_selected_company.id">
                <v-list-item v-for="(item) in sidebars" :key="item.id" link class="mx-2" :to="item.route">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link class="mx-2" to="/supplier"
                    v-if="get_user.user_role_id < 3 || selectedAccess.allow_suppliers">
                    <v-list-item-icon>
                        <v-icon>mdi-account-box-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Suppliers</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link class="mx-2" to="/packages"
                    v-if="get_user.user_role_id < 3 || selectedAccess.allow_packages">
                    <v-list-item-icon>
                        <v-icon>mdi-list-box-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Packages</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link class="mx-2" to="/reports"
                    v-if="get_user.user_role_id < 3 || selectedAccess.allow_reports">
                    <v-list-item-icon>
                        <v-icon>mdi-chart-box-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Reports</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link class="mx-2" to="/settings"
                    v-if="get_selected_company && get_selected_company.id && get_user.user_role_id < 3">
                    <v-list-item-icon>
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Company Settings</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <template v-slot:append>
                <v-list dense nav v-if="get_user.user_role_id < 3">
                    <v-divider class="mb-2"></v-divider>
                    <div class="mb-4">
                        <label class="mx-6" style="font-family: 'Work Sans';font-weight: bold;color:#7C7C7C">Agency
                            Menu</label>
                    </div>
                    <v-list-item link class="mx-2" to="/users">
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Users</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="mx-2" to="/user-activity">
                        <v-list-item-icon>
                            <v-icon>mdi-gesture-tap</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>User Activity</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="mx-2" to="/agency-settings">
                        <v-list-item-icon>
                            <v-icon>mdi-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Agency Settings</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>
        <AddCompany :add_company_dialog="add_company_dialog" @closeAddCompanyDialog="closeAddCompanyDialog">
        </AddCompany>
    </div>
</template>

<script>
import Sidebar from "@/modules/sidebar/sidebar.js"
import { mapGetters } from "vuex"

import CompanySelection from "./components/CompanySelection.vue"
import AddCompany from "./components/AddCompany.vue"

export default {
    data: () => ({
        class: {
            sidebar: null
        },
        sidebars: [],
        choose_company_dialog: false,
        company_selected: null,
        menu_select_company: false,
        add_company_dialog: false,
        api_url: null,
    }),
    components: {
        CompanySelection, AddCompany
    },
    props: [
        'drawer',
    ],
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'auth/get_selected_company',
            get_companies: 'auth/get_companies',
        }),
        selectedAccess() {
            return this.get_user.access.filter((access) => access.company_id == this.get_selected_company.id)[0]
        }
    },
    mounted() {
        this.class.sidebar = new Sidebar()
        this.sidebars = this.class.sidebar.getSidebars()
        this.fetchCompanies()
        this.api_url = process.env.VUE_APP_API_URL
    },
    methods: {
        showChangeCompany() {
            this.choose_company_dialog = true
        },
        closeDrawer() {
            if (this.drawer) {
                this.$emit('closeDrawer')
            }
        },
        onCloseConditional() {
            return this.drawer && this.$vuetify.breakpoint.mdAndDown
        },
        async fetchCompanies() {
            if (this.get_user.user_role_id == 1) {
                this.$axios.get('admin/companies/get_companies')
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch('auth/set_companies', data.data)
                        }
                    })
            }
            else {
                this.$axios.get('admin/companies/get_companies_by_access')
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch('auth/set_companies', data.data)
                        }
                    })
            }
        },
        choose_c() {
            this.$store.dispatch('auth/set_selected_company', this.company_selected)
            this.choose_company_dialog = false
        },
        close_company_dialog() {
            this.choose_company_dialog = false
        },
        eventCloseCompanySelectionMenu() {
            this.menu_select_company = false
            if (this.$route.name == '/dashboard') {
                this.$router.go(0)
            }
            else{
                this.$router.push({ name: '/dashboard' }).then(() => { this.$router.go(0) })
            }
        },
        openAddCompanyDialog() {
            this.add_company_dialog = true
        },
        closeAddCompanyDialog() {
            this.add_company_dialog = false
        }
    },
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Work+Sans|Manrope');

.mystagit-logo {
    border: 1px solid #CCC;
    border-radius: 10px;
    cursor: pointer;
}

.mystagit-logo-title {
    font-family: 'Manrope';
}

.mystagit-logo-subtitle {
    font-size: 12px;
    font-family: 'Manrope';
}

.v-data-table__wrapper {
    tr {
        cursor: pointer;
    }
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}
</style>