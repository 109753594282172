<template>
    <v-row justify="center">
        <v-dialog v-model="confirm_delete_lead_model" max-width="35%" persistent>
            <v-card>
                <v-card-title>Confirm Deletion of Lead</v-card-title>
                <v-card-text>
                    <p>Are you sure you want to delete this lead? Any data attached to this lead will be deleted.</p>
                    <v-text-field v-model="confirmation" placeholder="Please type 'DELETE' to confirm"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" dense @click="eventCloseConfirmDeleteLeadDialog()" class="btn-font-format"
                        style="width: 120px;font-weight: bold;">
                        <label class="text-proper-case">No</label>
                    </v-btn>
                    <v-btn elevation="0" dense class="btn-font-format" style="width: 120px;font-weight: bold;"
                        color="primary" @click="eventDeleteLead()" :disabled="confirmation != 'DELETE'">
                        <label class="text-proper-case">Yes</label>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import Leads from '@/modules/admin/leads/modules/leads.js'

export default {
    props: {
        show_confirm_delete_lead_dialog: Boolean,
        lead_id: Number
    },
    data: () => ({
        confirm_delete_lead_model: false,
        class: {
            lead: null
        },
        confirmation: null,
    }),
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        })
    },
    mounted() {
        this.class.lead = new Leads()
    },
    methods: {
        eventCloseConfirmDeleteLeadDialog() {
            this.$emit('eventCloseConfirmDeleteLeadDialog')
            this.confirmation = null
        },
        async eventDeleteLead() {
            const result = await this.class.lead.deleteLead({ lead_id: this.lead_id, company_id: this.get_selected_company.id }).then((data) => data)

            if (result.response == true) {
                this.$toast.success(result.message, { timeout: 2000, icon: true })
                this.eventCloseConfirmDeleteLeadDialog()
            } else {
                this.$toast.error(`${result.data.status}: ${result.data.statusText}`)
            }
        }
    },
    watch: {
        show_confirm_delete_lead_dialog() {
            this.confirm_delete_lead_model = this.show_confirm_delete_lead_dialog
        }
    }
}

</script>

<style lang="scss" scoped>
.btn-font-format {
    font-family: 'Manrope';
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
}

.form-label {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 600;
    color: #7C7C7C;
}

.v-card__title {
    font-family: 'Manrope';
    font-weight: 600 !important;
    line-height: 43.71px;
}

.v-card__text {
    font-family: 'Manrope';
}
</style>