<template>
    <div class="card">
        <v-skeleton-loader type="table" v-if="get_abs_date_range[0].itinerary_id != id"></v-skeleton-loader>
        <v-row
            v-else
            no-gutters
        >
            <v-col cols="12" v-for="(item, index) in get_abs_date_range" :key="index">
                <div class="build-wrapper mb-5 pa-2">
                    <div class="build-header">
                        <strong>{{ $date(item.date).format('dddd MMM DD, YYYY') }}</strong>
                    </div>
                    <v-divider 
                        class="mb-5 mt-2"
                        style="background-color: black;"
                    ></v-divider>
                    <div class="build-content">
                        <div class="build-content-main">
                            <v-row>
                                <v-btn class="mx-2" fab dark large>
                                    <div class="build-content-day-wrapper">
                                        <div class="build-content-day">Day</div>
                                        <div class="build-content-number">
                                            {{ index + 1 }}
                                        </div>
                                    </div>
                                </v-btn>
                                <!-- Preview for multiple day service -->
                                <div 
                                  v-for="(item_days, itemdaysindex) in item.get_child_days"
                                  :key="itemdaysindex"
                                >
                                  <v-btn class="mx-2"
                                    fab
                                    large
                                    disabled
                                  >
                                      <div class="build-content-day-wrapper">
                                        <div class="build-content-number">
                                          <v-icon>
                                            {{ item_days[0].supplier_product_type.icon }}
                                          </v-icon>
                                        </div>
                                      </div>
                                  </v-btn>
                                  <strong style="display: block;color:grey;" class="ml-3 mt-2">{{ item_days[0].supplier_product.name }}<br/><small>{{ item_days[0].supplier_info.supplier_name }}</small><br/><small>{{ item_days[0].check_in_time }}</small><br/><small v-if="Object.keys(item.get_child_days[itemdaysindex]).length > 1">{{ Object.keys(item.get_child_days[itemdaysindex]).length }} items</small></strong>
                                </div>
                                <!-- Actual service -->
                                <div 
                                  v-for="(iitem, iitemindex) in item.get_child"
                                  :key="iitemindex"
                                >
                                  <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn class="mx-2" 
                                        fab 
                                        large
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                          <div class="build-content-day-wrapper">
                                            <div class="build-content-number">
                                              <v-icon>
                                                {{ iitem[0].supplier_product_type.icon }}
                                              </v-icon>
                                            </div>
                                          </div>
                                      </v-btn>
                                    </template>
                                    <v-list>
                                      <v-list-item
                                        v-for="(action, index) in items"
                                        :key="index"
                                        @click="act(action, iitem, item)"
                                      >
                                        <v-list-item-title>
                                          <v-icon
                                            class="mr-2"
                                          >
                                            {{ action.icon }}
                                          </v-icon>
                                          {{ action.title }}
                                        </v-list-item-title>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                  <strong style="display: block;" class="ml-3 mt-2">{{ iitem[0].supplier_product.name }}<br/><small>{{ iitem[0].supplier_info.supplier_name }}</small><br/><small>{{ iitem[0].check_in_time }}</small><br/><small v-if="Object.keys(item.get_child[iitemindex]).length > 1">{{ Object.keys(item.get_child[iitemindex]).length }} items</small></strong>
                                </div>
                                <template>
                                    <v-menu right bottom>

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="mx-2 action-button"
                                                fab
                                                large
                                                color="primary"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item @click="open_media(item, 'service')">
                                              <v-list-item-title>
                                                <v-icon
                                                  class="mr-2"
                                                >
                                                  mdi-semantic-web
                                                </v-icon>
                                                Service 
                                              </v-list-item-title>
                                            </v-list-item>
                                            <!-- <v-list-item @click="open_media(item, 'resource')">
                                              <v-list-item-title> 
                                                <v-icon
                                                  class="mr-2"
                                                >
                                                  mdi-semantic-web
                                                </v-icon>
                                                Resource 
                                              </v-list-item-title>
                                            </v-list-item> -->
                                            <v-list-item @click="clear_build(item)">
                                              <v-list-item-title> 
                                                <v-icon
                                                  class="mr-2"
                                                >
                                                  mdi-trash-can
                                                </v-icon>
                                                Clear day 
                                              </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        
                                    </v-menu>
                                </template>
                            </v-row>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <!-- service dialog -->
        <ServiceDialog 
          :value="open_service_dialog"
          @close_dialog_and_clear="close_dialog_and_clear"
          @close_service_dialog="close_service_dialog"
        />
        <ServiceSetupDialog
          v-if="service_setup"
          :value="service_setup"
          @close_dialog="close_dialog_and_clear"
          @save_data="save_data"
        />
        <DialogDeleteRow
          v-if="delete_row"
          :value="delete_row"
          @cancel="close_dialog_and_clear"
          @delete_row="delete_row_fn"
        />
        <DialogEditITem
          :value="edit_itinerary_item_model"
          :e="e"
          @close_dialog_edit="close_dialog_edit"
          @add_itinerary_item="add_itinerary_item"
        />
        <DialogDeleteItem
          :value="delete_itinerary_item_model"
          @close_dialog_delete="close_dialog_delete"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceDialog from './child_component/Service_Dialog.vue'
import ServiceSetupDialog from './child_component/Service_Setup_Dialog.vue'
import DialogDeleteRow from './child_component/Dialog_Delete.vue'
import DialogEditITem from './child_component/Edit_Item.vue'
import DialogDeleteItem from './child_component/Delete_Item.vue'
export default {
  components: {
    ServiceDialog,
    ServiceSetupDialog,
    DialogDeleteRow,
    DialogEditITem,
    DialogDeleteItem
  },
  props: [
    'id'
  ],
  data () {
    return {
      open_service_dialog: false,
      service_setup: false,
      delete_row: false,
      to_delete: {},
      items: [
        { title: 'Edit', icon: 'mdi-pen', action: 'edit' },
        { title: 'Delete', icon: 'mdi-delete', action: 'delete' },
      ],
      edit_itinerary_item_model: false,
      delete_itinerary_item_model: false,
      e: {
        check_in_date: [],
        check_in_time: [],
        check_out_date: [],
        check_out_time: [],
        instructions: []
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('itinerary/fetch_itinerary_build_config', this.id)
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_abs_date_range:               'itinerary/get_abs_date_range',
        get_chosen_service_supplier:      'itinerary/get_chosen_service_supplier',
        get_supplier_amenities:           'itinerary/get_supplier_amenities',
        get_to_push_amenities:            'itinerary/get_to_push_amenities',
        get_day_selected:                 'itinerary/get_day_selected'
    })
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    // render_build(date) {
    //   const selectif = this.get_abs_date_range.date_range.filter((item) => {
    //     return (
    //       new Date(item.date_selected).toLocaleDateString() ===
    //       new Date(date).toLocaleDateString()
    //     );
    //   });
    //   return selectif;
    // },
    async refreh_list(){
      await this.$store.dispatch('itinerary/fetch_itinerary_build_config', this.id)
    },
    open_media(item, option){
      this.$store.dispatch('itinerary/set_day_selected', item)
      switch(option){
        case "service":
          this.open_service_dialog = true
          break;
        case "resource":

          break;
        default:
          break;
      }
    },
    close_dialog_and_clear(){
      this.open_service_dialog = false
      this.service_setup = false
      this.delete_row = false
      this.to_delete = {}
      this.$store.commit('itinerary/set_to_push_amenities')
    },
    close_service_dialog(){
      this.open_service_dialog = false
      this.service_setup = true
    },
    async save_data(){
      console.log(this.get_to_push_amenities)
      let tp = {
        ib_id:            this.get_day_selected.id,
        supplier_id:      this.get_chosen_service_supplier.supplier_id,
        amenities:        this.get_to_push_amenities,
        service_type_id:  this.get_chosen_service_supplier.supplier_info.service_type_id,
      }
      if(tp.amenities[0].check_in_date == null || tp.amenities[0].check_out_date == null){
        this.showSnackBar('Date and Time should not be empty!')
        return
      }
      this.$store.dispatch('itinerary/save_itinerary_item', tp)
      this.$store.dispatch('itinerary/fetch_itinerary_build_config', this.id)
      this.close_dialog_and_clear()
    },
    clear_build(item){
      this.delete_row = true
      this.to_delete = item
    },
    async delete_row_fn(){
      // console.log(this.to_delete)
      await this.$axios.delete(`api/itinerary/build/delete_children/${this.to_delete.id}`)
      .then(({data}) => {
        if(data.response){
          this.refreh_list()
          this.close_dialog_and_clear()
        }
      })
    },
    act(item, actual, day) {
      this.$store.dispatch('itinerary/set_day_selected', day)
      if(item.action == 'edit'){
        this.edit_item(actual)
        actual.forEach((item, index) => {
          this.e.check_in_date[index] = this.$date(item.check_in_date).format('YYYY-MM-DD')
          this.e.check_in_time[index] = item.check_in_time
          this.e.check_out_date[index] = this.$date(item.check_out_date).format('YYYY-MM-DD')
          this.e.check_out_time[index] = item.check_out_time
          this.e.instructions[index] = item.instructions
        })
        return
      }
      if(item.action == 'delete'){
        this.delete_item(actual)
        return
      }
    },
    edit_item(actual){
      this.$store.dispatch('itinerary/set_itinerary_edit_item', actual)
      this.edit_itinerary_item_model = true
    },
    close_dialog_edit(){
      this.edit_itinerary_item_model = false
      this.$store.dispatch('itinerary/fetch_itinerary_build_config', this.id)
    },
    add_itinerary_item(item) {
      console.log(item)
      this.$store.dispatch('itinerary/set_chosen_service_supplier', item)
      this.edit_itinerary_item_model = false
      this.service_setup = true
    },
    delete_item(actual){
      this.$store.dispatch('itinerary/set_itinerary_edit_item', actual)
      this.delete_itinerary_item_model = true
    },
    close_dialog_delete(){
      this.delete_itinerary_item_model = false
      this.$store.dispatch('itinerary/fetch_itinerary_build_config', this.id)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
}
.btnheader {
  margin: 0px 10px 15px 10px;
}
.btnfooter {
  margin: 15px 10px 0px 10px;
}
.build-header {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 5px;
}
.build-content .build-content-main {
  margin: 12px;
}
.build-content-day {
  margin: 3px;
}
.action-button {
  display: none;
}

.build-content-main:hover .action-button {
  display: inline-block;
}
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}
.card .card-header-items {
  text-align: center;
}
.card .card-header-items .header-items {
  margin: 0px 0px;
}
.media-image:hover {
  cursor: pointer;
  box-shadow: 0px 1px 5px 5px rgba(169, 169, 169, 0.6);
}
.image-show-wrapper {
  width: 100%;
}
.image-show-wrapper .image-show {
  max-width: 50%;
  margin: 0 auto;
}
.image-show-wrapper .image-show-name {
  font-size: 10px;
  text-align: center;
}
.tags-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.tags-wrapper .tags {
  max-height: 50px;
  display: flex;
  background-color: #dcdee0;
}
.tags-wrapper .tags .tag-name {
  margin: auto 0;
  padding: 12px;
  color: #343642;
  font-weight: bold;
  font-size: 16px;
  background: rgb(237, 238, 239);
}
.tags-wrapper .tags .tag-action {
  margin: auto 0;
}
.media-button {
  text-align: center;
}
.media-button .input-media {
  /* color: rgb(82, 90, 104); */
  display: none;
}
.dialog-close {
  margin-left: -75px;
  position: absolute;
}
@media only screen and (max-width: 959px) {
  .card .card-header {
    text-align: center;
  }
  .card .card-header-items .header-items {
    margin: 5px 5px 10px 5px;
  }
}
</style>