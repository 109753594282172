<template>
    <v-row justify="center">
        <v-dialog v-model="show_locations_dialog" max-width="1800" persistent scrollable>
            <v-card>
                <v-card-title>Locations</v-card-title>
                <v-card-text style="padding-bottom:100px;">
                    <v-row>
                        <v-col cols="3" class="pr-15">
                            <div class="mb-5">Create new location</div>
                            <v-text-field v-model="new_location.name" label="Name *" outlined dense></v-text-field>
                            <v-select v-model="new_location.type" label="Type" :items="['ie', 'uk', 'world']" outlined
                                dense></v-select>
                            <v-btn dark class="custom-buttons mt-5" @click="createLocation()">Create</v-btn>
                        </v-col>
                        <v-col cols="9" class="pl-15">
                            <v-data-table :headers="location_headers" :items="locations" disable-pagination
                                hide-default-footer style="height:500px;overflow-y:scroll;">
                                <template v-slot:item="{ item }">
                                    <tr style="cursor:inherit;background-color:transparent;">
                                        <td>{{ item.id }}</td>
                                        <td><v-text-field dense v-model="item.name"
                                                @keyup.enter="updateLocation(item)"></v-text-field></td>
                                        <td><v-select dense v-model="item.type" :items="['ie', 'uk', 'world']"
                                                @change="updateLocation(item)"></v-select>
                                        </td>
                                        <td><v-btn class="custom-buttons" color="red" icon
                                                @click="deleteLocation(item)"><v-icon>mdi-delete</v-icon></v-btn></td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-buttons" text @click="closeLocationsDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Settings from '../../../modules/admin/settings/modules/settings.js'
export default {
    components: {
    },
    props: [
        'show_locations_dialog'
    ],
    data: () => ({
        classes: {
            settings: null
        },
        selected_template: null,
        locations: [],
        location_headers: [
            { text: 'ID', sortable: false, width: '5%' },
            { text: 'Name', sortable: false, width: '50%' },
            { text: 'Type', sortable: false, width: '40%' },
            { text: '', sortable: false },
        ],
        new_location: {
            name: null,
            type: null,
        }
    }),
    created() {
        this.classes.settings = new Settings()
    },
    async mounted() {
        this.getLocations()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_user: 'auth/get_user',
        })
    },
    methods: {
        async getLocations() {
            const result = await this.classes.settings.getLocations({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.locations = result.data
            }
        },
        closeLocationsDialog() {
            this.$emit('closeLocationsDialog')
        },
        async createLocation() {
            if (this.new_location.name == null) {
                this.$toast.error('Please enter a location name')
                return
            }
            let payload = {
                company_id: this.get_selected_company.id,
                name: this.new_location.name,
                type: this.new_location.type,
            }
            const result = await this.classes.settings.createLocation(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.new_location.name = null
                this.new_location.type = null
                this.getLocations()
            }
        },
        async updateLocation(item) {
            let payload = {
                id: item.id,
                name: item.name,
                type: item.type,
            }
            const result = await this.classes.settings.updateLocation(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.getLocations()
            }
        },
        async deleteLocation(item) {
            const result = await this.classes.settings.deleteLocation({location_id: item.id})
            if (result.response == true) {
                this.$toast.success(result.message)
                this.getLocations()
            }
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row)) {
    border-bottom: none !important;
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}
</style>