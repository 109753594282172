<template>
  <v-dialog
      persistent
      width="50%"
      :value="value"
  >
    <v-card v-if="get_itinerary_edit_item[0]">
      <v-card-title>
        <v-icon
          class="mr-2"
        >
          {{ get_itinerary_edit_item[0].supplier_product_type.icon }}
        </v-icon>
        {{ get_itinerary_edit_item[0].supplier_product.name }}
        <v-spacer/>
        <v-icon
          @click="close_dialog_edit()"
        >mdi-close</v-icon>
      </v-card-title>
      
      <v-divider/>
      <v-card-actions>
          <v-spacer/>
          <v-btn
              color="primary"
              class="pt-5 pb-5 pl-10 pr-10"
              rounded
              @click="add_itinerary_item()"
          >
              Add
          </v-btn>
      </v-card-actions>
      
      <v-divider/>
      
      <v-card-subtitle class="mt-5">
        <v-alert
          outlined
          type="warning"
          prominent
          border="left"
          dense
        >
          Changing dates may alter the price of these items on your itinerary.
        </v-alert>
      </v-card-subtitle>
      <v-card-text v-for="(item, index) in get_itinerary_edit_item" :key="index">
        <v-card
          class="mt-5"
          elevation="0"
          style="border: 1px solid grey; border-radius: 8px;"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="9"
              >
                {{ item.supplier_product_option.name }}
              </v-col>
              <v-col
                cols="3"
              >
                <v-btn
                  icon
                  small
                  @click="count_fn_decrease(index)"
                >
                  <v-icon
                    small
                  >
                    mdi-minus
                  </v-icon>
                </v-btn>
                {{ item.count }}
                <v-btn
                  icon
                  small
                  @click="count_fn_increment(index)"
                >
                  <v-icon
                    small
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="6"
              >
                <v-menu
                    v-model="menu[index]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="e.check_in_date[index]"
                        label="check in date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="e.check_in_date[index]"
                        :min="get_itinerary.start_date"
                        :max="get_itinerary.end_date"
                        label="check in date"
                        @input="menu[index] = false"
                    ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  type="time"
                  label="Check-in time"
                  v-model="e.check_in_time[index]"
                >
                </v-text-field>
              </v-col>

              <!-- end -->
              <v-col
                cols="6"
              >
                <v-menu
                    v-model="menu[index]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="e.check_out_date[index]"
                        label="check in date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="e.check_out_date[index]"
                        :min="get_itinerary.start_date"
                        :max="get_itinerary.end_date"
                        label="check in date"
                        @input="menu[index] = false"
                    ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  type="time"
                  label="Check-out time"
                  v-model="e.check_out_time[index]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-text-field
              type="text"
              outlined
              :value="item.description"
              dense
              label="Check-in instructions"
              v-model="e.instructions[index]"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="delete_option(item)">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          outlined
          rounded
          class="pl-8 pr-8"
          @click="close_dialog_edit()"
        >
          <v-icon
            class="mr-2"
          >
            mdi-close
          </v-icon>
          cancel
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="pl-10 pr-10"
          @click="save_edited()"
        >
          <v-icon
            class="mr-2"
          >
            mdi-content-save
          </v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
    'value', 'e'
  ],
  data () {
    return {
      menu: []
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
      get_itinerary_edit_item:        'itinerary/get_itinerary_edit_item',
      get_itinerary:                  'itinerary/get_itinerary'
    })
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    close_dialog_edit(){
      this.$emit('close_dialog_edit')
    },
    format_date(date){
      return new Date(date).toISOString().slice(0, 10)
    },
    convert_time(time){
      const timeParts = time.split(" ")
      const timeOnly = timeParts[1]
      return timeOnly
    },
    count_fn_increment(index){
      this.get_itinerary_edit_item[index].count++
    },
    count_fn_decrease(index){
      if(this.get_itinerary_edit_item[index].count > 0){
        this.get_itinerary_edit_item[index].count--
        return
      }
    },
    async delete_option(item) {
      this.$store.dispatch('itinerary/delete_itinerary_item', item.id)
      this.close_dialog_edit()
    },
    async add_itinerary_item() {
      await this.$axios.get('api/itinerary/build/fetch_supplier_product_by_id', {supplier_product_id: this.get_itinerary_edit_item[0].supplier_product.id })
        .then(({ data }) => {
          this.$emit('add_itinerary_item', data.data)
        })
    },
    async save_edited() {
      this.get_itinerary_edit_item.forEach((item, index) => {
        if (this.e.check_in_date[index] == null || this.e.check_out_date[index] == null) {
          this.showSnackBar('date and time should not be empty!')
          return
        }
        let pass = {
          id: item.id,
          count: item.count,
          check_in_date: this.e.check_in_date[index],
          check_in_time: this.e.check_in_time[index],
          check_out_date: this.e.check_out_date[index],
          check_out_time: this.e.check_out_time[index],
          instructions: this.e.instructions[index]
        }
        this.$store.dispatch('itinerary/edit_itinerary_item', pass)
      })
      this.close_dialog_edit()
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>