<template>
    <v-row justify="center">
        <v-dialog v-model="delete_package_dialog" max-width="35%" persistent>
            <v-card>
                <v-card-title>Confirm Deletion of Package</v-card-title>
                <v-card-text>
                    <p>Are you sure you want to delete this package? Any data attached to this package will be deleted.</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" dense @click="closeDeletePackageDialog()" class="btn-font-format"
                        style="width: 120px;font-weight: bold;">
                        <label class="text-proper-case">No</label>
                    </v-btn>
                    <v-btn elevation="0" dense class="btn-font-format" style="width: 120px;font-weight: bold;"
                        color="primary" @click="deletePackage()">
                        <label class="text-proper-case">Yes</label>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    data: () => ({
    }),
    props: [
        'delete_package_dialog'
    ],
    methods: {
        closeDeletePackageDialog() {
            this.$emit('closeDeletePackageDialog')
        },
        deletePackage() {
            this.$emit('deletePackage')
        }
    },
}

</script>

<style lang="scss" scoped>
.btn-font-format {
    font-family: 'Manrope';
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
}

.form-label {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 600;
    color: #7C7C7C;
}

.v-card__title {
    font-family: 'Manrope';
    font-weight: 600 !important;
    line-height: 43.71px;
}

.v-card__text {
    font-family: 'Manrope';
}
</style>