<template>
    <v-container
        fluid
    >
        <v-row
            justify="center"
        >
            <v-col
                cols="12"
                class="text-center"
            >
                <strong
                    style="font-weight: 100; font-size: 24px;"
                >
                    {{ get_group_name }}
                </strong>
            </v-col>
            <v-col
                cols="12"
                class="text-center"
            >
                <!-- <img
                    class="d-flex mx-auto my-5"
                    :src="get_selected_company.company_logo" 
                    alt="Company Image"
                /> -->
                <v-img
                    eager
                    class="mx-auto my-5"
                    aspect-ratio="3"
                    cover
                    v-if="company_logo"
                    :src="company_logo"
                ></v-img>
                <strong
                    class="mt-2"
                    style="font-weight: 700; font-size: 30px;"
                >
                    ITINERARY SUMMARY
                </strong>
            </v-col>
        </v-row>
        <v-divider class="mt-15" inset></v-divider>
        <v-row
            v-for="(item, itemindex) in data"
            :key="itemindex"
            class="mt-5"
        >
            <v-col
                cols="3"
            >
                <label
                    style="font-weight: 600; font-size: larger;"
                >
                    <label
                        v-if="itemindex == 0"
                    >
                        Arrive -
                    </label>Day {{ itemindex + 1 }}
                </label>
                <label
                    style="display: block; font-weight: 800; font-size: small;"
                    class="ml-15 pa-2"
                    v-for="(iitem, iitemindex) in item.get_child"
                    :key="iitemindex"
                >
                    {{ iitem.supplier_product_type.name }}
                </label>
            </v-col>
            <v-col
                cols="7"
            >
                <label
                    style="font-weight: 600; font-size: larger;"
                >
                    {{ $date(item.date).format('MMM DD') }}
                </label>
                <label
                    style="display: block; font-weight: lighter; font-size: small;"
                    class="ml-15 pa-2"
                    v-for="(iitem, iitemindex) in item.get_child"
                    :key="iitemindex"
                >
                {{ iitem.info.address }}
                </label>
            </v-col>
        </v-row>
        <v-row
            justify="center"
            class="mt-15"
        >
            <v-col
                cols="12"
                class="text-center"
            >
                <strong
                    style="font-weight: 700; font-size: 30px;"
                >
                    ITINERARY DETAIL
                </strong>
            </v-col>
        </v-row>
        <v-divider
            class="mt-15"
        />
        <v-row
            v-for="(item, itemindex) in data"
            :key="itemindex"
        >
            <v-card
                elevation="0"
                width="100%"
            >
                <v-card-title>
                    <label
                        v-if="itemindex == 0"
                        style="font-weight: 700; font-size: medium;"
                    >
                        Arrive -
                    </label> <label style="font-weight: 700; font-size: medium;"> Day {{ itemindex + 1 }} : {{ $date(item.date).format('MMM DD') }}</label>
                </v-card-title>
                <v-divider/>
                <div
                    v-for="(iitem, iitemindex) in item.get_child"
                    :key="iitemindex"
                >
                    <v-card-title>
                        <label
                            style="font-weight: 800; font-size: large;"
                        >{{ iitem.supplier_product_type.name }}</label>
                        <v-spacer/>
                        <label
                            style="font-weight: 300; font-size: medium;"
                        >{{ iitem.info.supplier_name }}</label>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="8"
                            >
                                <v-row>
                                    <v-col
                                        cols="3"
                                    >
                                        <label
                                            style="font-weight: 700; font-size: small;"
                                        >
                                            Location: 
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="9"
                                    >
                                        <label
                                            style="font-weight: lighter; font-size: small;"
                                        >
                                            {{ iitem.info.address }}
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="3"
                                    >
                                        <label
                                            style="font-weight: 700; font-size: small;"
                                        >
                                            Check in:
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="9"
                                    >
                                        <label
                                            style="font-weight: lighter; font-size: small;"
                                        >
                                            {{ iitem.check_in_date }}
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="3"
                                    >
                                        <label
                                            style="font-weight: 700; font-size: small;"
                                        >
                                            Check out:
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="9"
                                    >
                                        <label
                                            style="font-weight: lighter; font-size: small;"
                                        >
                                            {{ iitem.check_out_date }}
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="3"
                                    >
                                        <label
                                            style="font-weight: 700; font-size: small;"
                                        >
                                            Nights:
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="9"
                                    >
                                        <label
                                            style="font-weight: lighter; font-size: small;"
                                        >
                                            {{ get_range(iitem.check_in_date, iitem.check_out_date) === 0 ? 1 : get_range(iitem.check_in_date, iitem.check_out_date) }} Night(send)
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="3"
                                    >
                                        <label
                                            style="font-weight: 700; font-size: small;"
                                        >
                                            Room:
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="9"
                                    >
                                        <label
                                            style="font-weight: lighter; font-size: small;"
                                        >
                                            {{ iitem.supplier_product_option.name }}
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="3"
                                    >
                                        <label
                                            style="font-weight: 700; font-size: small;"
                                        >
                                            Rating:
                                        </label>
                                    </v-col>
                                    <v-col
                                        cols="9"
                                    >
                                        <label
                                            style="font-weight: lighter; font-size: small;"
                                        >
                                            {{ iitem.info.industry_rating }} Star
                                        </label>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col
                                cols="4"
                            >
                                <v-img
                                    style="max-height: 450px;"
                                    :src="iitem.info.base64"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="8"
                            >
                                <label
                                    v-if="iitem.info.description != null"
                                    v-html="iitem.info.description"
                                >
                                </label>
                                <label
                                    v-else
                                    v-html="iitem.supplier_product.description"
                                >

                                </label>
                            </v-col>
                            <v-col
                                cols="4"
                            >
                                <v-img
                                    style="max-height: 450px;"
                                    :src="iitem.supplier_product_option.base64"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </div>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
    'data',
    'company_logo'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_group_name:                     'itinerary/get_group_name',
        get_selected_company:               'auth/get_selected_company'
    })
  },
  methods: {
    get_range(start, end){
        const date1 = new Date(start)
        const date2 = new Date(end)

        const diff_in_time = date2.getTime() - date1.getTime()
        const diff_in_days = diff_in_time / (1000 * 3600 * 24)

        return diff_in_days
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>