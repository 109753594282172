import Vue from "vue";

export default class Packages {
    constructor() {
        this.vue = Vue
    }
    getSuppliers({ company_id }) {
        return this.vue.axios.get(`admin/suppliers/get_suppliers/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result.data
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    getSuppliersByLocation({ company_id, location_id }) {
        return this.vue.axios.get(`admin/suppliers/get_suppliers_by_location/${company_id}/${location_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result.data
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    getLocations({ company_id }) {
        return this.vue.axios.get(`admin/locations/get_locations/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result.data
                }
            } else {
                return data
            }
        })
    }
    getPackages({ company_id }) {
        return this.vue.axios.get(`admin/packages/get_packages/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    getPackagesByLocation({ company_id, location_id, type, nights }) {
        return this.vue.axios.get(`admin/packages/get_packages_by_location/${company_id}/${location_id}`, { type: type, nights: nights }).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    getPackage({ package_id }) {
        return this.vue.axios.get(`admin/packages/get_package/${package_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    createPackage(payload) {
        return this.vue.axios.post('admin/packages/create_package', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    updatePackage(payload) {
        return this.vue.axios.post(`admin/packages/update_package/${payload.id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    deletePackage({ package_id }) {
        return this.vue.axios.delete(`admin/packages/delete_package/${package_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
}