<template>
    <v-container
        fluid
        class="ma-0 pa-0"
    >
        <v-row class="mt-5" justify="center" align="center">
            <v-card
                elevation="0"
            >
                <v-card-title>
                    <label
                        style="font-weight: 100; font-size: medium;"
                    >
                        Thanks for your response. You can close this window now.
                    </label>
                </v-card-title>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
    }
  },
  mounted () {
    this.verifyToken()
  },
  created () {
  },
  computed: {
  },
  methods: {
    async verifyToken(){
        let tp = {
            action:     this.$router.currentRoute.name == "/accept" ? 3 : 4,
            token:      this.$router.currentRoute.params.token
        }
        await this.$axios.get('api/email/email_response', tp)
        .then(({data}) => {
            console.log(data)
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>