<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
                <v-tab v-for="(item, index) in items" :key="index" :href="`#tab-` + index">
                    {{ item }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-0">
                    <v-card>
                        <v-card-title>
                            <v-spacer />
                            <v-btn class="mt-2 mr-5 pt-3 pb-3 pl-5 pr-5" style="text-transform: unset !important;"
                                color="primary" rounded @click="generatePDF()">
                                <v-icon class="mr-2">mdi-file</v-icon>
                                Export Invoices 
                            </v-btn>
                            <v-btn class="mt-2 mr-5 pt-3 pb-3 pl-5 pr-5" style="text-transform: unset !important;"
                                color="primary" rounded @click="getInvoices()">
                                <v-icon class="mr-2">mdi-refresh</v-icon>
                                Refresh
                            </v-btn>
                            <v-btn class="mt-2 mr-5 pt-3 pb-3 pl-5 pr-5" style="text-transform: unset !important;"
                                color="primary" rounded @click="openInvoice()">
                                Create incoming payment
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <PTable ref="general_payments" @openEditInvoice="openEditInvoice" />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-1">
                    <v-card>
                        <v-card-title>
                            <v-spacer />
                            <v-btn rounded style="text-transform: unset !important;"
                                class="mt-2 mr-2 pt-3 pb-3 pl-5 pr-5 white--text" color="red" disabled>
                                Export payments
                            </v-btn>
                            <v-btn rounded style="text-transform: unset !important;"
                                class="mt-2 pt-3 pb-3 pr-5 pl-5 white--text" color="primary">
                                Refresh payments
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <SupplierPTable />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <v-dialog 
            v-model="show_payment_dialog" 
            persistent 
            max-width="50%" 
            overlay-color="#f4f4f5"
            overlay-opacity="1"
        >
            <v-btn fab @click="close_dialog()" class="dialog-close">
                <v-icon dark> mdi-arrow-left </v-icon>
            </v-btn>
            <v-card>
                <v-card-title v-if="!edit_invoice">
                    Create incoming payment
                </v-card-title>
                <v-card-title v-else>
                    Edit incoming payment
                </v-card-title>
                <v-card-subtitle class="my-3">
                    <h2>Invoice details</h2>
                    Update the information in this section according to the invoice you have raised for your client.
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="invoice.payment_name" label="Payment name*"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="invoice.payment_type" label="Payment type*"
                                :items="['Pre quotation', 'Deposit', 'Instalment', 'Final', 'Credit']"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="invoice.amount_due"  @change="totalAmountDue()" type="number" label="Amount due*" persistent-hint :hint="total_amount_due"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="invoice.service_charge" @change="totalAmountDue()" type="number" label="Service charge %*"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="invoice.due_date" type="date" label="Due date*"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="invoice.method" label="Method"
                                :items="['Bank transfer', 'Credit card', 'Cash', 'Cheque', 'Voucher', 'Stripe', 'PayPal', 'Other']"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="invoice.invoice_number" type="number"
                                label="Invoice number"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="invoice.bill_to" label="Bill to"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="invoice.billing_address" label="Billing address"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="invoice.notes" label="Notes"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-subtitle class="my-3">
                    <h2>Amount paid</h2>
                    Update the information in this section according to the actual funds that you have received from your
                    client.
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="invoice.payer" label="Payer"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="invoice.amount_paid" label="Amount paid"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="date" v-model="invoice.date_paid" label="Date paid"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="invoice.payment_note" label="Add a payment note"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn outlined rounded class="pl-5 pr-5" @click="closeInvoice()">
                        cancel
                    </v-btn>
                    <v-btn v-if="!edit_invoice" color="primary" rounded class="pl-5 pr-5" @click="saveInvoice()">
                        Create
                    </v-btn>
                    <v-btn v-else color="primary" rounded class="pl-5 pr-5" @click="editInvoice()">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PTable from './components/PaymentTable.vue'
import SupplierPTable from './components/SupplierPaymentTable.vue'
import html2pdf from "html2pdf.js";
export default {
    components: {
        PTable,
        SupplierPTable
    },
    props: [
        'id'
    ],
    async mounted() {
        this.invoice.itinerary_id = this.get_itinerary.id
        this.invoice.created_by = this.get_user_login.id
        this.getInvoices()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_itinerary: "itinerary/get_itinerary_action",
            get_user_login: "auth/get_user",
        }),
    },
    data() {
        return {
            tab: null,
            items: [
                'GENERAL PAYMENTS',
                'SUPPLIER PAYMENTS'
            ],
            show_payment_dialog: false,
            invoice: {
                id: null,
                itinerary_id: null,
                created_by: null,
                payment_name: null,
                payment_type: null,
                amount_due: 0,
                service_charge: 0,
                due_date: null,
                method: null,
                invoice_number: null,
                bill_to: null,
                billing_address: null,
                notes: null,
                payer: null,
                amount_paid: null,
                date_paid: null,
                payment_note: null,
            },
            edit_invoice: false,
            total_amount_due: 0
        }
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        generatePDF() {
            html2pdf(document.getElementById("payment-table"), {
                margin: 1,
                filename: this.$date(new Date()).format('YYYY-MM-DD') + "-invoices.pdf",
                image: { type: 'jpeg', quality: 1.0 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
            });
        },
        async getInvoices() {
            this.$store.dispatch("itinerary/fetch_invoices", { itinerary_id: this.get_itinerary.id })
            this.$refs.general_payments != null ? this.$refs.general_payments.setTotals() : ''
        },
        async openInvoice() {
            this.show_payment_dialog = true
            
        },
        openEditInvoice(item) {
            this.edit_invoice = true
            this.show_payment_dialog = true
            this.invoice.id = item.id
            this.invoice.itinerary_id = item.itinerary_id
            this.invoice.created_by = item.created_by
            this.invoice.payment_name = item.payment_name
            this.invoice.payment_type = item.payment_type
            this.invoice.amount_due = item.amount_due
            this.invoice.due_date = item.due_date
            this.invoice.method = item.method
            this.invoice.invoice_number = item.invoice_number
            this.invoice.bill_to = item.bill_to
            this.invoice.billing_address = item.billing_address
            this.invoice.notes = item.notes
            this.invoice.payer = item.payer
            this.invoice.amount_paid = item.amount_paid
            this.invoice.date_paid = item.date_paid
            this.invoice.payment_note = item.payment_note
            this.invoice.service_charge = item.service_charge
            this.totalAmountDue()
        },
        async saveInvoice() {
            /*let amount = this.invoice.amount_due
            amount = this.invoice.service_charge * amount / 100
            this.invoice.amount_due = parseFloat(this.invoice.amount_due) + parseFloat(amount)*/
            await this.$axios.post('api/itinerary/payments/create_invoice', this.invoice)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeInvoice()
                        this.$store.commit("itinerary/set_invoices", data.data)
                        this.$store.commit("itinerary/set_total_due", data.total_due)
                        this.$store.commit("itinerary/set_total_paid", data.total_paid)
                        this.$refs.general_payments.setTotals()
                    }
                })            
        },
        async editInvoice() {
            await this.$axios.patch('api/itinerary/payments/edit_invoice', this.invoice)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeInvoice()
                        this.$store.commit("itinerary/set_invoices", data.data)
                        this.$store.commit("itinerary/set_total_due", data.total_due)
                        this.$store.commit("itinerary/set_total_paid", data.total_paid)
                        this.$refs.general_payments.setTotals()
                    }
                })
        },
        closeInvoice() {
            this.show_payment_dialog = false
            this.edit_invoice = false
            this.invoice.payment_name = null
            this.invoice.payment_type = null
            this.invoice.amount_due = null
            this.invoice.due_date = null
            this.invoice.method = null
            this.invoice.invoice_number = null
            this.invoice.bill_to = null
            this.invoice.billing_address = null
            this.invoice.notes = null
            this.invoice.payer = null
            this.invoice.amount_paid = null
            this.invoice.date_paid = null
            this.invoice.payment_note = null
        },
        close_dialog(){
            this.show_payment_dialog = false
        },
        totalAmountDue() {
            this.total_amount_due = `Total Amount Due with Service Charge: ${(this.invoice.amount_due * ((this.invoice.service_charge / 100) + 1)).toFixed(2)} EUR`
        }
    },
    watch: {
        'invoice.payment_type'() {
            this.invoice.service_charge = this.invoice.payment_type == 'Deposit' ? 4 : 0
        }
    }
}
</script>

<style scoped lang="scss">
    .dialog-close {
        margin-left: -75px;
        position: absolute;
    }
</style>