<template>
    <v-row justify="center">
        <v-dialog v-model="show_payment_settings_dialog" max-width="1800" persistent scrollable>
            <v-card>
                <v-card-title>Payment Settings</v-card-title>
                <v-card-text style="padding-bottom:100px;">
                    <v-row>
                        <v-col cols="3" class="pr-15 mt-3">
                            <v-combobox v-model="selected_currency"
                                :items="currencies"
                                label="Default Currency"
                                outlined dense>
                                <template v-slot:item="{item}">
                                    {{ item.code }}: {{ item.symbol }}
                                </template>
                                <template v-slot:selection="{ item }">
                                    {{ item.code }}: {{ item.symbol }}
                                </template>
                            </v-combobox>
                        </v-col>
                        <v-col cols="9" class="pl-15 mt-3">
                            <v-combobox v-model="payment_setting_data.gateway" label="Gateway" outlined dense
                                :items="['Sagepay', 'Stripe']"></v-combobox>
                            <v-text-field dense outlined label="Gatewat URL" v-model="payment_setting_data.gateway_url"></v-text-field>
                            <v-text-field dense outlined label="Key" v-model="payment_setting_data.key"></v-text-field>
                            <v-text-field dense outlined label="Success URL" v-model="payment_setting_data.success_url"></v-text-field>
                            <v-text-field dense outlined label="Failure URL" v-model="payment_setting_data.failure_url"></v-text-field>
                            <v-text-field dense outlined label="Frontend URL" v-model="payment_setting_data.frontend_url"></v-text-field>
                            <v-divider></v-divider>
                            <v-text-field dense outlined label="Stripe Published Key" v-model="payment_setting_data.published_key" v-if="payment_setting_data.gateway == 'Stripe'"></v-text-field>
                            <v-text-field dense outlined label="Stripe Secret Key" v-model="payment_setting_data.secret_key" v-if="payment_setting_data.gateway == 'Stripe'"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-buttons" text @click="closePaymentSettingDialog()">Close</v-btn>
                    <v-btn class="custom-buttons" text @click="savePaymentSettings()">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import PaymentSettings from '../../../modules/admin/settings/modules/payment_settings.class';
import { mapGetters } from 'vuex';

export default {
    components: {
    },
    props: [
        'show_payment_settings_dialog'
    ],
    data: () => ({
        classes: {
            payment_settings: new PaymentSettings()
        },
        selected_currency: null,
        currencies: [],
        payment_setting_data: {
            gateway: null,
            gateway_url: null,
            company_id: null,
            currency_setting_id: null,
            key: null,
            success_url: null,
            failure_url: null,
            frontend_url: null,
            published_key: null,
            secret_key: null,
            id: null,
        }
    }),
    computed:{
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        })
    },
    methods: {
        closePaymentSettingDialog() {
            this.$emit('closePaymentSettingsDialog')
        },
        async savePaymentSettings() {
            this.closePaymentSettingDialog()
            this.payment_setting_data.currency_setting_id = this.selected_currency.id
            const result = await this.classes.payment_settings.public_methods.savePaymentSettings({
                id: this.payment_setting_data.id, 
                payload: this.payment_setting_data
            })

            if ( result.response == true ) {
                this.payment_setting_data = result.data
            }
        }
    },
    async mounted() {
        const result = await this.classes.payment_settings.public_methods.getCurrencies()
        if ( result.response == true ) {
            this.currencies = [...result.data]
        }
    },
    watch: {
        show_payment_settings_dialog: async function() {
            const result = await this.classes.payment_settings.public_methods.getPaymentSettings(this.get_selected_company.id)
            if ( result.response == true ) {
                if ( result.data != null ) {
                    this.payment_setting_data.id = result.data.id
                    this.payment_setting_data.gateway = result.data.gateway
                    this.payment_setting_data.gateway_url = result.data.gateway_url
                    this.payment_setting_data.key = result.data.key
                    this.payment_setting_data.success_url = result.data.success_url
                    this.payment_setting_data.failure_url = result.data.failure_url
                    this.payment_setting_data.frontend_url = result.data.frontend_url

                    // Search Default Currency
                    this.selected_currency = this.currencies.find( (item) => item.id == result.data.currency_setting_id)
                }
            }
            this.payment_setting_data.company_id = this.get_selected_company.id
        }
    }
}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row)) {
    border-bottom: none !important;
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}
</style>