<template>
    <v-row>
        <v-col cols="12">
            <v-card elevation="0">
                <v-card-title class="justify-center">
                    <div class="mr-4"><v-img src="../../../assets/check_circle_green.png" height="40" width="40"></v-img>
                    </div>
                    <div class="payment-confirmed">
                        Package Created Successfully
                    </div>
                </v-card-title>
            </v-card>
        </v-col>
        <v-col cols="12">
            <div class="thank-you mb-10">Thank you, your package has been successfully created.</div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

.v-input__slot {
    background: white !important;
}

.v-stepper__header {
    box-shadow: none !important;
}

.payment-confirmed {
    color: #679F54;
    font-family: Manrope;
    font-size: 32px;
    font-weight: 800;
}

.thank-you {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    color: #7C7C7C;
}

.quote-summary {
    font-family: Manrope;
    font-size: 20px !important;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #7C7C7C;
}

.summary-text-left {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
    color: #7C7C7C;

}

.summary-text-right {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #101010;
}

.summary-button {
    width: 220px;
    height: 38px;
    border-radius: 5px;
    border: 0.5px;
    font-family: Manrope;
    font-size: 16px !important;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000 !important;
    box-shadow: none;
    border: 0.5px solid #D3D3D3 !important;
}
</style>