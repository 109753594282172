<template>
    <v-row justify="center">
        <v-dialog
            :value="value"
            persistent
            max-width="75%"
        >
        <v-card>
            <v-card-title class="text-h5">
                Search Service
                <v-spacer/>
                <v-icon
                    style="cursor:pointer;"
                    @click="close_dialog_and_clear"
                >
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-subtitle>
                {{ get_day_selected.date }}
            </v-card-subtitle>
            <v-divider class="mb-5"></v-divider>
            <v-card-text>
                <v-row>
                    <v-col
                        cols="6"
                    >
                        <v-autocomplete
                            label="Country"
                            prepend-inner-icon="mdi-view-list-outline"
                            :items="get_countries"
                            v-model="country"
                            @change="search_city()"
                        />
                    </v-col>
                    <v-col
                        cols="6"
                    >
                        <v-autocomplete
                            label="City"
                            prepend-inner-icon="mdi-view-list-outline"
                            :items="get_cities"
                            v-model="city"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <v-text-field
                    label="Supplier Name"
                    prepend-inner-icon="mdi-account"
                    v-model="s.supplier_name"
                    v-on:keyup.enter="search_supplier"
                >
                </v-text-field>
            </v-card-text>
            <v-card-text
                v-if="get_isloading"
                class="text-center"
            >
                <strong>Searching...</strong>
                <br/>
                <v-progress-circular
                    :size="120"
                    :width="10"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    class="pa-5"
                    rounded
                    @click="clear_search"
                >
                    Clear Search
                </v-btn>
                <v-btn
                    color="primary"
                    class="pa-5"
                    rounded
                    @click="search_supplier"
                >
                    Search
                </v-btn>
            </v-card-actions>
            <v-divider/>
            <v-card-text
                class="mt-5"
                v-if="get_supplier_lists.length > 0"
            >
                <v-data-table
                    :headers="headers"
                    :items="get_supplier_lists"
                    hide-default-footer
                    disable-pagination
                    :expanded.sync="expanded"
                    single-expand
                >
                    <template v-slot:item="{ item, expand, isExpanded }">
                        <tr>
                            <td>
                                <v-card-actions>
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon>mdi-account</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ item.supplier_info.supplier_name }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card-actions>
                            </td>
                            <td>
                                <v-btn
                                    @click="expand(!isExpanded)"
                                    rounded
                                    color="info"
                                    class="pl-5 pr-5"
                                >
                                    <v-icon
                                        class="mr-2"
                                    >
                                        mdi-eye
                                    </v-icon>
                                    Show rates
                                </v-btn>
                            </td>
                            <td>
                                <label>{{ item.supplierproducttype.name }}</label>
                            </td>
                            <td>
                                <v-btn
                                    fab
                                    elevation="0"
                                    color="primary"
                                    small
                                    @click="selected_service(item)"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <v-list>
                                <v-list-group
                                    v-for="list in item.product_options"
                                    :key="list.name"
                                    no-action
                                    @click="get_rates(list)"
                                >
                                    <template v-slot:activator>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ list.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <v-data-table
                                        :headers="header_rates"
                                        :items="get_rates_vuex"
                                        :expanded.sync="expandedchild"
                                        single-expand
                                    >
                                        <template v-slot:item="{ item, expand, isExpanded }">
                                            <tr>
                                                <td>
                                                    {{ item.name }}
                                                </td>
                                                <td>
                                                    {{ item.start_date }}
                                                </td>
                                                <td>
                                                    {{ item.end_date }}
                                                </td>
                                                <td>
                                                    <v-btn
                                                        @click="expand(!isExpanded)"
                                                        rounded
                                                        color="info"
                                                        class="pl-5 pr-5"
                                                        small
                                                    >
                                                        <v-icon
                                                            class="mr-2"
                                                        >
                                                            mdi-eye
                                                        </v-icon>
                                                        Show rate
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-slot:expanded-item="{ headers, item }">
                                            <td :colspan="headers.length">
                                                <v-data-table
                                                    :headers="header_rates_child"
                                                    :items="item.supplierrates"
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <tr>
                                                            <td>
                                                                {{ item.day }}
                                                            </td>
                                                            <td>
                                                                {{ item.net }}
                                                            </td>
                                                            <td>
                                                                {{ item.gross }}
                                                            </td>
                                                            <td>
                                                                {{ item.markup }} %
                                                            </td>
                                                            <td>
                                                                {{ item.commission }} %
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </v-data-table>
                                            </td>
                                        </template>
                                    </v-data-table>
                                </v-list-group>
                            </v-list>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {
  },
  props: [
    'value'
  ],
  data () {
    return {
        s: {
            supplier_name: null
        },
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: '', sortable: false, },
            { text: 'Type' },
            { text: '', sortable: false, },
        ],
        country: null,
        city: null,
        expanded: [],
        header_rates: [
            {
                text: 'Name'
            },
            {
                text: 'Start Date'
            },
            {
                text: 'End Date'
            },
            {
                text: ''
            }
        ],
        expandedchild: [],
        header_rates_child: [
            {
                text: 'Day'
            },
            {
                text: 'Net'
            },
            {
                text: 'Gross'
            },
            {
                text: 'Markup'
            },
            {
                text: 'Commission'
            }
        ]
    }
  },
  mounted () {
  },
  async created () {
    this.$store.dispatch('itinerary/fetch_countries')
  },
  computed: {
    ...mapGetters({
        get_day_selected:       'itinerary/get_day_selected',
        get_isloading:          'itinerary/get_isloading',
        get_supplier_lists:     'itinerary/get_supplier_lists',
        get_countries:          'itinerary/get_countries',
        get_cities:             'itinerary/get_cities',
        get_rates_vuex:         'itinerary/get_rates'
    })
  },
  methods: {
    clear_search(){
        this.s = {}
        this.$store.commit('itinerary/set_supplier_lists', [])
    },
    async search_supplier(){
        let tp = {
            name:       this.s.supplier_name,
            country:    this.country,
            city:       this.city
        }
        this.$store.dispatch('itinerary/set_isloading', true)
        this.$store.dispatch('itinerary/fetch_supplier_lists', tp)
        
    },
    close_dialog_and_clear(){
        this.clear_search()
        this.$emit('close_dialog_and_clear')
    },
    selected_service(item){
        this.$store.dispatch('itinerary/set_chosen_service_supplier', item)
        this.clear_search()
        this.$emit('close_service_dialog')
    },
    search_city(){
        let data = {
            "country": this.country
        }
        this.$store.dispatch('itinerary/fetch_cities', data)
    },
    async get_rates(data){
        await this.$store.dispatch('itinerary/fetch_rates', data)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>