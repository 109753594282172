<template>
    <v-container fluid>
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-row v-if="!loading" class="mb-10">
            <v-col cols="10">
                <v-card class="card-format" style="background-color:#FAFAFA;height:180px">
                    <v-card-text>
                        <v-row style="height:180px;">
                            <v-col cols="2" class="pl-7 pt-8">
                                <label style="font-size:16px;color:black;font-weight:600;">Total Amount</label>
                                <br><br>
                                <label style="color:black;font-size:36px;">{{ get_selected_company.currency }}500,000</label>
                            </v-col>
                            <v-col cols="4">
                                <apexchart type="area" :options="options" :series="options.series" height="160"
                                    width="100%"></apexchart>
                            </v-col>
                            <v-col cols="2" class="pl-7 pt-8">
                                <label style="font-size:16px;color:black;font-weight:600;">Sub Total Paid</label>
                                <br><br>
                                <label style="color:black;font-size:36px;">{{ get_selected_company.currency }}500,000</label>
                            </v-col>
                            <v-col cols="4">
                                <apexchart type="area" :options="options2" :series="options2.series" height="160"
                                    width="100%"></apexchart>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card class="card-format" style="background-color:#FAFAFA;height:180px;">
                    <v-card-text class="px-10 py-8">
                        <div style="text-align:center;font-size:16px;color:black;font-weight:600;" class="mb-10">No. of
                            Events</div>
                        <div style="text-align:center;color:#3988AA;font-size:44px;font-weight:600;">{{ events.length }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="!loading" class="mb-2">
            <v-col cols="3">
                <div style="display:flex;height:54px;border:solid 1px lightgrey;border-radius:5px;">
                    <div class="pt-3 px-3" style="background-color:#F0F0F0;"><v-icon size="30">mdi-calendar</v-icon>
                    </div>
                    <v-dialog v-model="menu1" max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="filter_start_date" label="Start Date" readonly v-bind="attrs"
                                v-on="on" dense filled style="border-radius:0px;"></v-text-field>
                        </template>
                        <v-date-picker v-if="menu1" v-model="filter_start_date" full-width @change="changeStartDate()">
                        </v-date-picker>
                    </v-dialog>
                    <v-dialog v-model="menu2" max-width="290px" min-width="290px">

                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="filter_end_date" label="End Date" readonly v-bind="attrs" v-on="on"
                                dense filled style="border-radius:0px;"></v-text-field>
                        </template>
                        <v-date-picker v-if="menu2" v-model="filter_end_date" full-width @change="changeEndDate()">
                        </v-date-picker>
                    </v-dialog>
                </div>
            </v-col>
            <v-col cols="3">
                <div style="display:flex;">
                    <v-select @change="filter()" :items="['Stag Party', 'Hen Party']" label="Select Type"
                        v-model="filter_type" dense filled
                        style="height:54px;border:solid 1px lightgrey;border-radius:5px;max-width:150px;"
                        class="mr-5"></v-select>
                    <v-select @change="filter()" :items="getLocation" label="Select Location" v-model="filter_location"
                        item-text="name" item-value="id" dense filled
                        style="height:54px;border:solid 1px lightgrey;border-radius:5px;max-width:180px;"></v-select>
                </div>
            </v-col>
            <v-col cols="3">
                <v-text-field @change="filter()" label="Search" v-model="search" dense filled
                    style="height:54px;border:solid 1px lightgrey;border-radius:5px;max-width:230px;"></v-text-field>
            </v-col>
            <v-col cols="3" style="text-align:right;">
                <!-- <v-btn class="custom-buttons py-6 px-5 mr-5" @click="filter()">
                    <v-icon>mdi-filter-outline</v-icon>Filter
                </v-btn> -->
                <v-btn class="custom-buttons py-6 px-5 mr-5" @click="reset()"><v-icon>mdi-restart</v-icon>Reset</v-btn>
                <v-btn class="custom-buttons py-6 px-5" dark><v-icon>mdi-download</v-icon>Export</v-btn> <!-- TODO -->
            </v-col>
        </v-row>
        <v-row v-if="!loading" class="mt-2">
            <v-col cols="12" class="pt-0">
                <v-card outlined class="card-format px-5">
                    <v-data-table :headers="headers" :items="events" :single-expand="singleExpand"
                        :expanded.sync="expanded" item-key="id" :sort-by.sync="sort_by" :sort-desc.sync="sort_desc">
                        <template v-slot:item="{ item, expand, isExpanded }">
                            <tr style="cursor:initial;font-weight:600;">
                                <td>
                                    <!-- <input type="checkbox"> -->
                                    <v-avatar color="#4374F3" size="42">
                                        <label style="color:white;font-size:16px;">
                                            {{ item.lead.first_name[0] + item.lead.last_name[0] }}
                                        </label>
                                    </v-avatar>
                                </td>
                                <td>
                                    <div style="display:flex;align-items:center;">
                                        <!-- <div style="width:30px;">
                                            <v-tooltip bottom color="primary" v-if="item.free_stag_hen">
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn small dense icon v-bind="attrs" v-on="on">
                                                        <v-icon color="orange">mdi-star</v-icon>
                                                    </v-btn>
                                                </template>
                <span>Free Stag/Hen</span>
                </v-tooltip>
                <v-tooltip bottom color="primary" v-if="item.bar_crawl">
                    <template v-slot:activator="{ attrs, on }">
                                                    <v-btn small dense icon v-bind="attrs" v-on="on">
                                                        <v-icon color="blue">mdi-star</v-icon>
                                                    </v-btn>
                                                </template>
                    <span>Bar Crawl</span>
                </v-tooltip>
                </div> -->
                                        <div>
                                            {{ item.lead.first_name + ' ' + item.lead.last_name }}
                                            <br>
                                            <small style="color:#4374F3">{{ item.reference_code }}</small>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ item.lead.start_date }}</td>
                                <td
                                    :set="percentage = (item.invitees.filter((x) => x.payments.filter((y) => y.payment_details.filter((z) => z.payment_type_id == 1).length > 0).length > 0).length) / (item.invitees.length) * 100">
                                    <v-progress-linear v-if="item.invitees.length > 0" height="15" rounded
                                        :value="(percentage).toFixed(0) " color="green" background-color="#D7D7D7"
                                        style="border-radius:20px;">
                                        <strong style="color:white;font-size:10px;">
                                            {{ (percentage).toFixed(0) }}%
                                        </strong>
                                    </v-progress-linear>
                                    <!-- <td :set_org="org_paid = item.lead.payments.filter((item) => item.status == 'Organizer Payment')[0] ? 1 : 0"
                                    :set="percentage = (item.invitees.filter((val) => val.payments.filter((item) => item.status == 'Full Payment')[0]).length + org_paid) / (item.invitees.length + 1) * 100"> -->
                                    <!-- <v-progress-linear v-if="item.invitees.length > 0" height="15" rounded
                                        :value="(percentage).toFixed(0)" color="green" background-color="#D7D7D7"
                                        style="border-radius:20px;">
                                        <strong style="color:white;font-size:10px;">
                                            {{ (percentage).toFixed(0) }}%</strong>
                                    </v-progress-linear>
                                    <small v-else>No invitees yet</small> -->
                                </td>
                                <td>{{ item.lead.duration }}</td>
                                <td>
                                    <div v-if="item.quotes[0]" style="display:flex;align-items:center;">
                                        <div style="margin-right:10px;">
                                            <label :class="'label-' + item.quotes[0].builds[0].booking_status_id">
                                                B
                                            </label>
                                        </div>
                                        <div>{{ item.quotes[0].builds[0].supplier.supplier_name ?? null }}</div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="item.quotes[0]">
                                        <div :set="option = item.quotes[0].builds[item.quotes[0].builds.findIndex((x) => x.type_id == 2)]"
                                            style="display:flex;align-items:center;">
                                            <div style="margin-right:10px;" v-if="option">
                                                <label :class="'label-' + option.booking_status_id">B</label>
                                            </div>
                                            <div v-if="option">{{ option.supplier.supplier_name }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="item.quotes[0]">
                                        <div :set="option = item.quotes[0].builds[item.quotes[0].builds.findIndex((x) => x.type_id == 6)]"
                                            style="display:flex;align-items:center;">
                                            <div style="margin-right:10px;" v-if="option">
                                                <label :class="'label-' + option.booking_status_id">B</label>
                                            </div>
                                            <div v-if="option">{{ option.supplier.supplier_name }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="item.quotes[0]">
                                        <div :set="option = item.quotes[0].builds[item.quotes[0].builds.findIndex((x) => x.type_id == 5)]"
                                            style="display:flex;align-items:center;">
                                            <div style="margin-right:10px;" v-if="option">
                                                <label :class="'label-' + option.booking_status_id">B</label>
                                            </div>
                                            <div v-if="option">{{ option.supplier.supplier_name }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="item.quotes[0]">
                                        <div :set="option = item.quotes[0].builds[item.quotes[0].builds.findIndex((x) => x.type_id == 4)]"
                                            style="display:flex;align-items:center;">
                                            <div style="margin-right:10px;" v-if="option">
                                                <label :class="'label-' + option.booking_status_id">B</label>
                                            </div>
                                            <div v-if="option">{{ option.supplier.supplier_name }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div
                                        style="overflow-x:scroll;max-width:150px;display:flex;align-items:center;justify-items:center;">
                                        <label v-if="item.disable_payment" class="custom-label">Disabled</label>
                                        <label v-if="item.late_fee == 0" class="custom-label">Late Fee Disabled</label>
                                        <label v-if="item.is_fully_paid" class="custom-label">Fully Paid</label>
                                    </div>
                                </td>
                                <td>
                                    <v-btn @click="manageEvent(item)" class="custom-buttons" dark small>Update</v-btn>
                                    <!-- <v-tooltip bottom color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on" @click="manageEvent(item)">
                                                <v-icon color="black">mdi-pencil-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Manage Event</span>
                                    </v-tooltip> -->
                                    <!-- <v-tooltip bottom color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on" @click="manageEvent(item)">
                                                <v-icon color="black">mdi-account-multiple-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Manage Invitees</span>
                                    </v-tooltip> -->
                                    <!-- <v-tooltip bottom color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on">
                                                <v-icon color="black">mdi-cog-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Event Settings</span>
                                    </v-tooltip> -->
                                    <v-menu right bottom>

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on">
                                                <v-icon color="black">mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item style="cursor:pointer;" @click="sendOrganiserEmail(item)">
                                                <v-list-item-title>
                                                    Send Organiser Email
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item style="cursor:pointer;" @click="openNotesDialog(item)">
                                                <v-list-item-title>
                                                    Notes
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-tooltip bottom color="primary">

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on">
                                                <v-icon v-if="isExpanded"
                                                    @click="expand(!isExpanded)">mdi-chevron-down</v-icon>
                                                <v-icon v-else @click="expand(!isExpanded)">mdi-chevron-up</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Expand</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-card class="card-format" style="background-color:#FAFAFA;margin-bottom:20px;">
                                    <v-card-text>
                                        <v-row style="color:#101010;font-size:12px;">
                                            <v-col>
                                                <v-icon small color="#3988AA">mdi-phone-outline</v-icon>
                                                {{ item.lead.phone }}
                                                <br>
                                                <v-icon small color="#3988AA">mdi-email-outline</v-icon>
                                                {{ item.lead.email }}
                                                <br>
                                                <v-icon small color="#3988AA">mdi-map-marker-outline</v-icon>
                                                {{ item.lead.location.name }}
                                            </v-col>
                                            <v-col>
                                                <b style="color:#3988AA!important;">NOTES:</b>
                                                <div>{{ item.notes ?? 'None' }}</div>
                                            </v-col>
                                            <v-col>
                                                <b style="color:#3988AA!important;">GROUP SIZE:</b>
                                                {{ item.lead.people }}
                                                <br>
                                                <b style="color:#3988AA!important;">BOOKING COMPLETED:</b>
                                                {{ $date(item.created_at).format('DD/MM/YYYY') }}
                                                <br>
                                                <b style="color:#3988AA!important;">REFERENCE:</b>
                                                {{ item.reference_code }}
                                                <br>
                                                <!-- <b style="color:#3988AA!important;">CONFIRMATION EMAIL:</b> -->
                                                <!-- {{ $date().format('DD/MM/YYYY') }} -->
                                            </v-col>
                                            <v-col>
                                                <b style="color:#3988AA!important;">TOTAL PAYMENT:</b>
                                                {{ get_selected_company.currency }}{{ item.quotes[0].total }}
                                                <br>
                                                <b style="color:#3988AA!important;">DEPOSIT:</b>
                                                {{ get_selected_company.currency }}{{ item.deposit_amount }}
                                                <!-- <br>
                                                <b style="color:#3988AA!important;">VENUE:</b> None
                                                <br>
                                                <b style="color:#3988AA!important;">RATES:</b> None -->
                                            </v-col>
                                            <v-col>
                                                <b style="color:#3988AA!important;">PASSWORD:</b>
                                                {{ item.password }}
                                                <br>
                                                <label v-if="item.free_stag_hen" class="label-2 mr-2">FREE
                                                    STAG/HEN</label>
                                                <label v-if="item.bar_crawl" class="label-2">BAR CRAWL</label>
                                                <!-- <br>
                                                <b style="color:#3988AA!important;">PENDING:</b>
                                                {{ get_selected_company.currency }}{{ item.deposit_amount }} -->
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="!loading">
            <v-dialog v-model="notes_dialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        Add Notes
                    </v-card-title>
                    <v-card-text>
                        <v-textarea v-model="notes" outlined></v-textarea>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeNotesDialog()">Cancel</v-btn>
                        <v-btn dark @click="addNotes()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Events from '../../modules/admin/events/modules/events.js'

export default {
    components: {
    },
    data: () => ({
        loading: true,
        headers: [
            { text: '', width: '1%' },
            { text: 'Organiser', width: '10%', value: 'lead.first_name', sortable: false },
            { text: 'Event Date', width: '5%', value: 'lead.start_date', sortable: false },
            { text: 'Paid', width: '7%', sortable: false },
            { text: 'Nights', width: '1%', sortable: false },
            { text: 'Accommodation', width: '10%', sortable: false },
            { text: 'Day Activity', width: '10%', sortable: false },
            { text: 'Night Activity', width: '10%', sortable: false },
            { text: 'Dinner', width: '10%', sortable: false },
            { text: 'Finger Food', width: '10%', sortable: false },
            { text: 'Tags', sortable: false },
            { text: 'Action', width: '13%', sortable: false },
        ],
        expanded: [],
        singleExpand: false,
        sort_by: 'lead.start_date',
        sort_desc: false,
        classes: {
            events: null
        },
        events: [],
        orig_events: [],
        notes_dialog: false,
        selected_event: null,
        notes: null,
        filter_start_date: null,
        filter_end_date: null,
        menu1: false,
        menu2: false,
        filter_type: null,
        filter_location: null,
        search: null,
        options: {
            chart: {
                type: "area",
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            series: [
                {
                    name: "Amount",
                    data: [45, 52, 48, 45, 49, 43, 50],
                }
            ],
            stroke: {
                colors: ['#3988AA'],
                width: 1,
            },
            fill: {
                colors: ['#6ED4F4'],
                type: "gradient",
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100],
                }
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul"
                ]
            }
        },
        options2: {
            chart: {
                type: "area",
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            series: [
                {
                    name: "Amount",
                    data: [45, 52, 48, 45, 49, 43, 50],
                }
            ],
            stroke: {
                colors: ['#FAAF3A'],
                width: 1,
            },
            fill: {
                colors: ['#FFE1B1'],
                type: "gradient",
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100],
                }
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul"
                ]
            }
        },
    }),
    created() {
        this.setPageName("Manage Events")
        this.classes.events = new Events()
    },
    async mounted() {
        this.getEvents()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            getLocation: 'locationStore/getLocationsGetter',
        })
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
        }),
        async getEvents() {
            const result = await this.classes.events.getEvents({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.events = result.data
                this.orig_events = result.data
                this.loading = false
            }
        },
        manageEvent(item) {
            this.$router.push({ name: 'ManageEvent', params: { event_id: item.id } })
        },
        changeStartDate() {
            this.menu1 = false
            this.filter()
        },
        changeEndDate() {
            this.menu2 = false
            this.filter()
        },
        filter() {
            let filtered_events = this.orig_events
            if (this.filter_start_date != null) {
                filtered_events = filtered_events.filter((event) => event.lead.start_date >= this.filter_start_date)
            }
            if (this.filter_end_date != null) {
                filtered_events = filtered_events.filter((event) => event.lead.end_date <= this.filter_end_date)
            }
            if (this.filter_type != null) {
                filtered_events = filtered_events.filter((event) => event.lead.activity_type == this.filter_type)
            }
            if (this.filter_location != null) {
                filtered_events = filtered_events.filter((event) => event.lead.location_id == this.filter_location)
            }
            if (this.search != null && this.search != '') {
                filtered_events = filtered_events.filter((event) => event.reference_code == this.search || event.lead.email == this.search)
            }
            this.events = filtered_events
        },
        reset() {
            this.filter_start_date = null
            this.filter_end_date = null
            this.filter_type = null
            this.filter_location = null
            this.events = this.orig_events
        },
        async sendOrganiserEmail(item) {
            let payload = {
                company_id: this.get_selected_company.id,
                lead: item.lead,
                event: item,
            }
            const result = await this.classes.events.sendOrganiserEmail(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
            }
        },
        openNotesDialog(item) {
            this.notes_dialog = true
            this.selected_event = item
            this.notes = item.notes
        },
        closeNotesDialog() {
            this.notes_dialog = false
        },
        async addNotes() {
            let payload = {
                id: this.selected_event.id,
                notes: this.notes,
            }
            const result = await this.classes.events.updateEvent(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closeNotesDialog()
                this.getEvents()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    border-radius: 15px !important;

    .v-data-footer {
        background-color: #E9E9E9;
        color: #7C7C7C;
        font-family: 'Work Sans';
    }

    .v-data-footer__select .v-text-field {
        background-color: white;

        .v-select__slot {
            padding-left: 10px;
        }
    }
}

:deep(tbody tr:hover) {
    background-color: transparent !important;
}

:deep(.theme--light.v-data-table tbody td) {
    height: 80px;
    border: none;
}

.label-text-color {
    color: #7C7C7C;
}

.text-proper-case {
    text-transform: capitalize;
}

.search-text-field {
    margin-top: 15px;

    .v-input__slot {
        min-height: 0 !important;
        margin-top: 8px;
    }

    .v-input__prepend-inner {
        margin-top: 5px !important;
    }
}

:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

:deep(.v-input__slot::before) {
    border-style: none !important;
}

.label-1 {
    color: #7C7C7C;
    background-color: #F7F7F7;
    font-size: 11px;
    border: solid 1px #7C7C7C;
    border-radius: 4px;
    padding: 3px 5px;
}

.label-2 {
    color: #EE9E25;
    background-color: #FFF3E2;
    font-size: 11px;
    border: solid 1px #EE9E25;
    border-radius: 4px;
    padding: 3px 5px;
}

.label-3 {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 11px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
}

.label-4 {
    color: #AA3939;
    background-color: #FFCFCF;
    font-size: 11px;
    border: solid 1px #AA3939;
    border-radius: 4px;
    padding: 3px 5px;
}

.custom-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 11px;
    border-radius: 20px;
    padding: 5px 10px;
    margin-right:10px;
}
</style>