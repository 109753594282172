<template>
    <v-container fluid>
        <v-card class="mt-12 mx-4">
            <v-card-title style="font-size: 16px;">
                <v-spacer></v-spacer>
                <v-btn color="#525A68" class="pl-10 pr-10" dark rounded @click="open_add_company_dialog()"
                    :disabled="get_user.user_role_id === 1 ? false : true">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    add company
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="get_companies">
                    <template v-slot:item="{ item }">
                        <tr style="cursor:inherit;background-color:transparent;">
                            <td>
                                {{ item.company_name }}
                            </td>
                            <td>
                                {{ item.created_at }}
                            </td>
                            <!-- <td>
                                <v-icon small @click="open_edit_company_dialog(item)">
                                    mdi-pencil
                                </v-icon>
                            </td> -->
                        </tr>
                    </template>
                    <template v-slot:item.action></template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-row justify="center">
            <v-dialog max-width="620" v-model="add_company_dialog" persistent>
                <v-card>
                    <v-card-title style="font-size: 16px;">
                        <small>Add Company</small>
                        <v-spacer></v-spacer>
                        <v-icon @click="close_add_company_dialog()">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field dense label="Name" outlined prepend-inner-icon="mdi-tooltip-text-outline"
                            v-model="new_company_name" @keyup.enter="add_company()">
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block color="#588BAD" dark small rounded @click="add_company()">
                            Create
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="edit_company_dialog" width="500" persistent>
                <v-card v-if="selected_company">
                    <v-card-title>
                        {{ selected_company.company_name }}
                        <v-spacer></v-spacer>
                        <v-icon @click="close_edit_company_dialog()">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-form class="mt-2" ref="form" lazy-validation>
                            <v-file-input outlined hide-details label="Logo" dense v-model="file"></v-file-input>
                        </v-form>
                        <small class="ml-8">File only accepts .png, .jpg formats.</small>
                        <div v-if="selected_company.company_logo" class="mt-5">
                            <v-divider></v-divider>
                            <div class="mt-2">
                                <v-img class="mx-auto" contain max-height="150" max-width="250"
                                    :src="selected_company.company_logo"></v-img>
                                <v-icon class="d-flex mx-auto mt-5" @click="to_delete = !to_delete">
                                    mdi-delete
                                </v-icon>
                                <div v-if="to_delete == true" class="mt-5 d-inline-flex flex-row justify-center ml-15">
                                    <p>Are you sure you want to delete this logo?</p>
                                    <v-btn class="ml-2" x-small color="error" @click="remove_logo(selected_company)">
                                        Delete
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                    <v-btn class="rounded-0" block color="primary" @click="submit">
                        submit
                    </v-btn>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        headers: [
            { text: 'Company Name' },
            { text: 'Created At' },
        ],
        add_company_dialog: false,
        new_company_name: null,
        edit_company_dialog: false,
        selected_company: null,
        file: null,
        to_delete: false,
    }),
    mounted() {
    },
    created() {
        this.setPageName("Companies")
    },
    computed: {
        ...mapGetters({
            get_companies: 'auth/get_companies',
            get_user: 'auth/get_user',
        })
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction'
        }),
        showSnackBar(message) {
            this.$store.commit("auth/setMessage", { show: true, message: message }, { root: 1 })
        },
        open_add_company_dialog() {
            this.add_company_dialog = true
        },
        close_add_company_dialog() {
            this.add_company_dialog = false
            this.new_company_name = null
        },
        async add_company() {
            let payload = {
                company_name: this.new_company_name
            }
            await this.$axios.post('api/auth/add_company', payload)
                .then(({ data }) => {
                    if (data.message) {
                        this.showSnackBar(data.message)
                    }
                    this.$store.dispatch('auth/set_companies', data.data)
                    this.close_add_company_dialog()
                })
                .catch(err => {
                    this.showSnackBar(err.message)
                })
        },
        open_edit_company_dialog(item) {
            this.edit_company_dialog = true
            this.selected_company = item
        },
        close_edit_company_dialog() {
            this.edit_company_dialog = false
            this.selected_company = null
        },
        extension(item) {
            const parts = item.name.split('.')
            const file_type = parts[parts.length - 1].toLowerCase()
            return file_type
        },
        submit() {
            const ext = this.extension(this.file)
            if (ext != 'png' && ext != 'jpeg' && ext != 'jpg') {
                alert('Please select a valid file!')
                return
            }
            const formData = new FormData()
            formData.append('file', this.file)
            formData.append('company_id', this.selected_company.id)
            formData.append('user_id', this.get_user.id)
            this.$store.dispatch('auth/save_company_information', formData)
            this.file = null
            this.close_edit_company_dialog()
        },

        remove_logo(obj) {
            this.$store.dispatch('auth/delete_company_logo', obj)
            this.to_delete = false
            this.close_edit_company_dialog()
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>