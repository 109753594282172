import Vue from "vue";

export default class Settings {
    constructor() {
        this.vue = Vue
    }

    updateCompany(id, payload) {
        return this.vue.axios.post(`admin/companies/update_company/${id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }

    getLocations({ company_id }) {
        return this.vue.axios.get(`admin/locations/get_locations/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    createLocation(payload) {
        return this.vue.axios.post('admin/locations/create_location', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    updateLocation(payload) {
        return this.vue.axios.post(`admin/locations/update_location/${payload.id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    deleteLocation({ location_id }) {
        return this.vue.axios.delete(`admin/locations/delete_location/${location_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }

    getEventTypes({ company_id }) {
        return this.vue.axios.get(`admin/event_types/get_event_types/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    createEventType(payload) {
        return this.vue.axios.post('admin/event_types/create_event_type', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    updateEventType(payload) {
        return this.vue.axios.post(`admin/event_types/update_event_type/${payload.id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    updateEventTypeLogo(id, payload) {
        return this.vue.axios.post(`admin/event_types/update_event_type/${id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    deleteEventType({ event_type_id }) {
        return this.vue.axios.delete(`admin/event_types/delete_event_type/${event_type_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }

    getProfitMargins({ company_id }) {
        return this.vue.axios.get(`admin/profit_margins/get_profit_margins/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    createProfitMargin(payload) {
        return this.vue.axios.post('admin/profit_margins/create_profit_margin', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    updateProfitMargin(payload) {
        return this.vue.axios.post(`admin/profit_margins/update_profit_margin/${payload.id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    deleteProfitMargin({ profit_margin_id }) {
        return this.vue.axios.delete(`admin/profit_margins/delete_profit_margin/${profit_margin_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }

    getEmailTemplates({ company_id }) {
        return this.vue.axios.get(`admin/email_templates/get_email_templates/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    createEmailTemplate(payload) {
        return this.vue.axios.post('admin/email_templates/create_email_template', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    updateEmailTemplate(payload) {
        return this.vue.axios.post(`admin/email_templates/update_email_template/${payload.id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }

    getReminders({ company_id }) {
        return this.vue.axios.get(`admin/reminders/get_reminders/${company_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    createReminder(payload) {
        return this.vue.axios.post('admin/reminders/create_reminder', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    updateReminder(payload) {
        return this.vue.axios.post(`admin/reminders/update_reminder/${payload.id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
    deleteReminder({ reminder_id }) {
        return this.vue.axios.delete(`admin/reminders/delete_reminder/${reminder_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            } else {
                return data
            }
        })
    }
}