<template>
    <div class="login-bg">
        <!--<v-app-bar color="#343642" dark>
            <v-toolbar-title>GoGroupBooking</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>-->
        <slot></slot>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user'
        })
    }
}
</script>

<style scoped>
.login-bg {
    height: 100vh;
    background: linear-gradient(180deg, #F7F7F7 50%, #FFF 50%);
}
</style>