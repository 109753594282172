<template>
    <v-container fluid class="mt-6">
        <v-row>
            <v-col>
                <v-btn text @click="backToPackages()">
                    <div class="back-to-leads d-flex">
                        <v-img src="../../assets/back_arrow.svg" width="20" height="20" class="mr-2"></v-img>
                        Back to Packages
                    </div>
                </v-btn>
                <!-- <v-btn text color="red" @click="deletePackageDialog()" v-if="edit_package_id">
                    Delete Package
                </v-btn> -->
            </v-col>
        </v-row>
        <v-skeleton-loader type="table" v-if="!loaded"></v-skeleton-loader>
        <v-row v-else class="mt-5" style="padding-left:24px;padding-right:24px;">
            <v-col>
                <v-text-field class="custom-text-field" v-model="new_package.name" outlined round
                    label="PACKAGE NAME *"></v-text-field>
            </v-col>
            <v-col>
                <v-autocomplete class="custom-text-field" v-model="new_package.location_id" :items="getLocation"
                    item-text="name" item-value="id" outlined placeholder="LOCATION *" required></v-autocomplete>
            </v-col>
            <v-col>
                <v-text-field class="custom-text-field" v-model="new_package.nights" outlined round
                    label="NIGHTS *"></v-text-field>
            </v-col>
            <v-col>
                <v-select class="custom-text-field" v-model="new_package.type" :items="['stag', 'hen']" outlined round
                    label="PACKAGE TYPE *"></v-select>
            </v-col>
            <v-col v-if="!confirmed">
                <v-btn class="custom-buttons" @click="confirm()" dark>Confirm</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="confirmed && loaded">
            <v-col cols="12">
                <v-stepper v-model="selected_step" alt-labels elevation="0">
                    <v-stepper-header>
                        <div v-for="(step, index) in steps" :key="index" class="custom-stepper-header">
                            <v-stepper-step :complete="selected_step > index + 1" :step="index + 1"
                                @click="changeStep(index)" style="cursor:pointer;"
                                :color="(selected_step == index + 1) ? '#3988AA' : '#AFCFDC'">
                                <div
                                    :class="(selected_step == index + 1) ? 'custom-step-active' : 'custom-step-inactive'">
                                    {{ step }}
                                </div>
                            </v-stepper-step>
                            <v-divider v-if="index < steps.length - 1" color="#AFCFDC"></v-divider>
                        </div>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-row>
                            <v-col :cols="(selected_step < 8) ? 6 : 12" class="pr-9">
                                <v-stepper-content v-for="(step, index) in steps" :key="index" :step="index + 1">
                                    <PackageBuild v-if="index < 6" :suppliers="all_suppliers" :service_name="step"
                                        :build_index="index" :edit_build="builds[index]" @skip="skip()" @back="back()"
                                        @save="save" />
                                </v-stepper-content>
                                <PackageReview v-if="selected_step == 7" :all_builds="builds"
                                    :edit_package="package_to_edit" @back="back()" @finish="finish" @update="update" />

                                <PackageComplete v-if="selected_step == 8" />
                            </v-col>
                            <v-col v-if="selected_step < 8" cols="6" class="pl-9"
                                style="background-color:white;z-index:1;">
                                <div class="summary">SUMMARY</div>
                                <v-expansion-panels v-model="open_panels" multiple class="custom-expansion-panels">
                                    <v-expansion-panel v-for="(step, index) in sidebar_steps" :key="index"
                                        class="custom-expansion-panel mb-7">
                                        <v-expansion-panel-header class="custom-expansion-header" :id="'panel' + index">
                                            {{ step }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="custom-expansion-panel-content">
                                            <PackageSummaryContent :builds="builds[index]" :step="index"
                                                @changeStep="changeStep" />
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isBetween from 'dayjs/plugin/isBetween'

import Packages from '@/modules/admin/packages/modules/packages.js'

import PackageSummaryContent from './modules/PackageSummaryContent.vue'
// import PackageAccommodation from './modules/PackageAccommodation.vue'
import PackageBuild from './modules/PackageBuild.vue'
import PackageReview from './modules/PackageReview.vue'
import PackageComplete from './modules/PackageComplete.vue'
export default {
    components: {
        PackageSummaryContent, PackageBuild, PackageReview, PackageComplete
    },
    props: [
    ],
    data: () => ({
        loaded: false,
        edit_package_id: null,
        package_to_edit: null,
        delete_package_dialog: false,
        new_package: {
            name: null,
            location_id: null,
            nights: null,
            type: null,
        },
        classes: {
            packages: null
        },
        steps: [
            'Day Activity', 'Night Activity', 'Dinner', 'Fingerfood', 'Transport', 'Addons', 'Review', 'Complete'
        ],
        sidebar_steps: [
            'Day Activity', 'Night Activity', 'Dinner', 'Fingerfood', 'Transport', 'Addons'
        ],
        selected_step: 1,
        all_suppliers: [],
        open_panels: [0],
        builds: [],
        created_package: null,
        confirmed: false,
    }),
    async mounted() {
        this.classes.packages = new Packages()
        this.getEditData()
    },
    created() {
        this.$date.extend(advancedFormat)
        this.$date.extend(isBetween)
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            getLocation: 'locationStore/getLocationsGetter',
        }),
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction'
        }),
        backToPackages() {
            this.$router.push({ name: "Packages" })
        },
        getEditData() {
            this.edit_package_id = this.$route.params.package_id
            if (this.edit_package_id) {
                this.confirmed = true
                this.open_panels = [0, 1, 2, 3, 4, 5, 6]
                this.setPageName('Edit Package')
                this.classes.packages.getPackage({ package_id: this.edit_package_id }).then((data) => {
                    this.package_to_edit = data.data
                    this.new_package = this.package_to_edit
                    this.getSuppliers()
                    let grouped_builds = this.groupBy(this.package_to_edit.builds, 'type_id')
                    grouped_builds.forEach((type, index) => {
                        // let supplier_id = null
                        let day = null
                        let setBuildIndex = null

                        let supplier_type = 0
                        switch (index) {
                            case 1:
                                supplier_type = 1
                                break
                            case 2:
                                supplier_type = 2
                                break
                            case 3:
                                supplier_type = 6
                                break
                            case 4:
                                supplier_type = 5
                                break
                            case 5:
                                supplier_type = 4
                                break
                            case 6:
                                supplier_type = 3
                                break
                            case 7:
                                supplier_type = 7
                                break
                        }
                        type.forEach((build, buildIndex) => {
                            if (build.day != day) {
                                let edit_build = {
                                    day: build.day,
                                    supplier: build.supplier,
                                    options: [build.option],
                                }
                                if (this.builds[supplier_type - 2] == null) {
                                    this.builds[supplier_type - 2] = []
                                }
                                this.builds[supplier_type - 2].push(edit_build)
                                day = build.day
                                setBuildIndex = buildIndex
                            }
                            else {
                                this.builds[supplier_type - 2][setBuildIndex].options.push(build.option)
                            }
                        })
                    })

                    this.loaded = true
                })
            }
            else {
                this.setPageName('Create Package')
                this.loaded = true
            }
        },
        groupBy(array, key) {
            return array.reduce((result, currentValue) => {
                (result[currentValue[key]] = result[currentValue[key]] || []).push(
                    currentValue,
                )
                return result
            }, [])
        },
        changeStep(index) {
            this.selected_step = index + 1
            if (index == 7)
                return
            if (index > 5) {
                this.scroll(0)
            }
            else {
                this.scroll(index)
                this.open_panels.indexOf(index) === -1 ? this.open_panels.push(index) : null
            }
        },
        scroll(index) {
            setTimeout(() => {
                this.$vuetify.goTo('#panel' + index, { offset: 0, container: '.custom-expansion-panels' });
            }, 500)
        },
        skip() {
            this.changeStep(this.selected_step)
        },
        back() {
            this.changeStep(this.selected_step - 2)
        },
        save(data, step) {
            this.builds[step] = data
            this.changeStep(this.selected_step)
        },
        async finish() {
            let payload = {
                company_id: this.get_selected_company.id,
                name: this.new_package.name,
                location_id: this.new_package.location_id,
                type: this.new_package.type,
                nights: this.new_package.nights,
                builds: this.builds,
            }
            const result = await this.classes.packages.createPackage(payload).then((data) => data)
            if (result.response == true) {
                this.created_package = result.data
                this.$toast.success(result.message, { timeout: 2000, icon: true })
                this.changeStep(this.selected_step)
            }
            else {
                this.$toast.error(`${result.status}: ${result.data.message}`)
            }
        },
        async update() {
            let payload = {
                id: this.edit_package_id,
                company_id: this.get_selected_company.id,
                name: this.new_package.name,
                location_id: this.new_package.location_id,
                type: this.new_package.type,
                nights: this.new_package.nights,
                builds: this.builds,
            }
            const result = await this.classes.packages.updatePackage(payload).then((data) => data)
            if (result.response == true) {
                this.created_package = result.data
                this.$toast.success(result.message, { timeout: 2000, icon: true })
                this.changeStep(this.selected_step)
            }
            else {
                this.$toast.error(`${result.status}: ${result.data.message}`)
            }
        },
        confirm() {
            if (this.new_package.name != null && this.new_package.location_id != null && this.new_package.type != null && this.new_package.nights != null) {
                this.getSuppliers()
                this.confirmed = true
            }
        },
        async getSuppliers() {
            let payload = {
                company_id: this.get_selected_company.id,
                location_id: this.new_package.location_id
            }
            await this.classes.packages.getSuppliersByLocation(payload).then((data) => {
                this.all_suppliers = data
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    padding: 24px;
    border-radius: 20px !important;

    .card-footer-format {
        border-top: 1px solid #E9E9E9;
        background-color: #E9E9E9;
    }
}

.card-footer-format {
    .pagination-font-format {
        font-family: 'Work Sans';
        font-size: 14px;
    }

    .bg-color-white {
        background-color: white;
    }
}

.v-input__slot {
    background: white !important;
}

.v-stepper__header {
    box-shadow: none !important;
}

.accom-price {
    color: #FAAF3A;
    font-size: 36px;
    font-weight: 600;
}

.custom-title {
    font-family: Manrope;
    font-size: 14px !important;
    color: #3988AA;
    font-weight: 600;
}

.supplier-title {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.supplier-dates {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.sublabel {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
}

.details {
    font-family: Manrope;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px !important;
}

.description {
    color: #7D7D7D;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.summary {
    color: #7C7C7C;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

.back-to-leads {
    color: var(--Blue-Linear, #3988AA);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
}

:deep(.custom-text-field) {
    label {
        top: 10px !important;
    }

    .v-text-field__slot {
        top: 6px;
    }

    .v-label--active {
        transform: translateY(-10px) scale(0.75) !important;
    }

    legend {
        display: none;
    }

    fieldset {
        border-color: #D3D3D3 !important;
    }

    input {
        color: #727272;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
}

:deep(.custom-text-field-active) {
    input {
        color: #101010;
    }
}

:deep(.custom-stepper-header) {
    display: contents;
    flex-wrap: inherit;

    .v-stepper__step--inactive span {
        background: #AFCFDC !important;
    }
}

.custom-step-active {
    color: var(--Blue-Linear, #3988AA);
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-shadow: none !important;
}

.custom-step-inactive {
    color: #AFCFDC;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-shadow: none !important;
}

.custom-expansion-panels {
    max-height: 500px;
    overflow-y: scroll;
    padding-right: 9px;
    padding-bottom: 300px;
}

.custom-expansion-panel::before {
    box-shadow: none !important;
}

.custom-expansion-panel::after {
    border-top: none !important;
}

.v-expansion-panel--active,
.v-expansion-panel--active+.v-expansion-panel {
    margin-top: 0px !important;
}

.v-expansion-panel-header--active {
    border-radius: 10px 10px 0px 0px !important;
    min-height: 0px !important;
}

:deep(.custom-expansion-header) {
    border-radius: 10px;
    background: var(--Blue-Linear, #3988AA);
    color: var(--icon-color, #FFF);
    font-family: Manrope;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    .v-icon {
        color: white !important;
    }
}

.custom-expansion-panel-content {
    font-family: Manrope;
    padding: 30px;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid var(--Border, #E9E9E9);
    background: var(--icon-color, #FFF);
    box-shadow: 5px 5px 13px 0px rgba(240, 240, 240, 0.90), -5px -5px 10px 0px rgba(255, 255, 255, 0.90), 5px -5px 10px 0px rgba(240, 240, 240, 0.20), -5px 5px 10px 0px rgba(240, 240, 240, 0.20);
}

.v-expansion-panel-content__wrap {
    padding: 0px !important;
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 226px;
}
:deep(.custom-buttons span) {
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
    width: 58px;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}
</style>