import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import AuthenticationState from '../components/login/states/index.js'
import SupplierState from '../components/suppliers/states/index.js'
import ResourcesState from '../components/resources/states/index'
import ContactState from '../components/contacts/states/index.js'
import TaskState from '../components/task/states/index.js'
import AgenciesState from '../components/agencies/states/index.js'
import ItineraryState from '../components/itinerary/states/index.js'
import UsersState from '../components/users/states/index.js'
import SupplierPricing from '../components/suppliers/states/supplierpricing.js'
import SupplierRates from '../components/suppliers/states/supplierrates.js'
import BookingState from '../components/itinerary/components/booking/states/bookinstate.js'
import PageNameStore from '@/common/PageNameStore'

import LeadsStore from '@/modules/admin/leads/store'
import locationStore from '@/modules/admin/locations/store'
import QuotesStore from '@/modules/admin/quotes/store'
import PackagesStore from '@/modules/admin/packages/store'
import EventsStore from '@/modules/admin/events/store'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth:                   AuthenticationState,
        supplier:               SupplierState,
        resources:              ResourcesState,
        contact:                ContactState,
        task:                   TaskState,
        agencies:               AgenciesState,
        itinerary:              ItineraryState,
        users:                  UsersState,
        supplier_pricing:       SupplierPricing,
        supplier_rates:         SupplierRates,
        booking:                BookingState,
        pageNameStore:          PageNameStore,
        leadsStore:             LeadsStore,
        locationStore:          locationStore,
        quotesStore:            QuotesStore,
        packagesStore:          PackagesStore,
        eventsStore:            EventsStore,
    },
    plugins: [createPersistedState()]
});