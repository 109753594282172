<template>
    <v-container fluid class="mt-12">
        <v-row class="mt-2">
            <v-col cols="12" class="pt-0">
                <v-card outlined class="card-format">
                    <v-data-table :headers="headers" :items="reports" :sort-by.sync="sort_by"
                        :sort-desc.sync="sort_desc">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.id }}</td>
                                <td>{{ $date(item.payment_date).format('MMM DD, YYYY h:mm A') }}</td>
                                <td>{{ item.total_paid_amount }}</td>
                                <td>{{ item.event.name }}</td>
                                <td>{{ item.remarks }}</td>
                                <td>{{ item.paid_by }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
    data: () => ({
        reports: [],
        headers: [
            { text: 'Report ID', value: 'id', width: '10%' },
            { text: 'Payment Date', value: 'payment_date', width: '20%' },
            { text: 'Total Amount', value: 'total_paid_amount', width: '20%' },
            { text: 'Event', value: 'event_id', width: '10%' },
            { text: 'Remarks', value: 'remarks', width: '20%' },
            { text: 'Paid By', value: 'paid_by', width: '20%' },
        ],
        sort_by: 'id',
        sort_desc: true,
    }),
    components: {
    },
    created() {
        this.setPageName("Reports")
    },
    async mounted() {
        this.fetchReports()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        })
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
        }),
        async fetchReports() {
            await this.$axios.get(`admin/payments/get_payments/${this.get_selected_company.id}`)
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.reports = data.data
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    border-radius: 15px !important;

    .v-data-footer {
        background-color: #E9E9E9;
        color: #7C7C7C;
        font-family: 'Work Sans';
    }

    .v-data-footer__select .v-text-field {
        background-color: white;

        .v-select__slot {
            padding-left: 10px;
        }
    }
}

.label-text-color {
    color: #7C7C7C;
}

.text-proper-case {
    text-transform: capitalize;
}

.search-text-field {
    margin-top: 15px;

    .v-input__slot {
        min-height: 0 !important;
        margin-top: 8px;
    }

    .v-input__prepend-inner {
        margin-top: 5px !important;
    }
}
</style>