import Vue from 'vue'

export default class CompanySettings 
{
    constructor()
    {
        this.vue = Vue
    }

    updateCompanyDetails(payload)
    {
        return this.vue.axios.post(`admin/company_settings/update_company_details`, payload).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                return result
            }
        }).catch( (err) => {
            console.log(err)
        })
    }
}