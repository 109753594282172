import Packages from "../../../../components/packages/Packages.vue"
import CreatePackage from "../../../../components/packages/CreatePackage.vue"

export default [
    {
        path: '/packages',
        name: 'Packages',
        meta: { layout: 'mystagit' },
        component: Packages,
    },
    {
        path: '/package/create',
        name: 'CreatePackage',
        meta: { layout: 'mystagit' },
        component: CreatePackage,
    },
    {
        path: '/package/edit/:package_id',
        name: 'EditPackage',
        meta: { layout: 'mystagit' },
        component: CreatePackage,
    },
]