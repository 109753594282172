
export default {
    namespaced: true,
    state: {
        selected_event: {},
    },
    mutations: {
        setSelectedEventMutation(state, payload) {
            state.selected_event = payload
        },
    },
    getters: {
        getSelectedEventGetter: (state) => state.selected_event,
    },
    actions: {
        setSelectedEventAction({ commit }, payload) {
            commit('setSelectedEventMutation', payload)
        },
    }
}