<template>
    <v-container fluid>
        <v-row justify="center">
            <v-dialog max-width="620" v-model="add_company_dialog" persistent>
                <v-card>
                    <v-card-title style="font-size: 16px;">
                        <small>Add Company</small>
                        <v-spacer></v-spacer>
                        <v-icon @click="closeAddCompanyDialog()">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field dense label="Name *" outlined v-model="new_company_name">
                        </v-text-field>
                        <v-text-field dense label="Currency *" outlined v-model="new_company_currency">
                        </v-text-field>
                        <v-text-field dense label="Timezone *" outlined v-model="new_company_timezone">
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block color="#588BAD" dark small rounded @click="addCompany()">
                            Create
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'add_company_dialog'
    ],
    data: () => ({
        headers: [
            { text: 'Company Name' },
            { text: 'Created At' },
        ],
        new_company_name: null,
        new_company_currency: null,
        new_company_timezone: null,
        edit_company_dialog: false,
        selected_company: null,
        file: null,
        to_delete: false,
    }),
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_companies: 'auth/get_companies',
            get_user: 'auth/get_user',
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage", { show: true, message: message }, { root: 1 })
        },
        closeAddCompanyDialog() {
            this.$emit('closeAddCompanyDialog')
            this.new_company_name = null
        },
        async addCompany() {
            let payload = {
                user_id: this.get_user.id,
                company_name: this.new_company_name,
                currency: this.new_company_currency,
                timezone: this.new_company_timezone,
            }
            await this.$axios.post('admin/companies/create_company', payload)
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.getCompanies()
                        this.$toast.success(data.message)
                        this.closeAddCompanyDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async getCompanies() {
            this.$axios.get('admin/companies/get_companies')
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('auth/set_companies', data.data)
                    }
                })
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>