<template>
  <v-container fluid>
      <v-card class="mt-12 mx-4" elevation="2">
        <v-card-title class="text-small">
          <small>Create contact</small>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="First Name*"
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="c.first_name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="Email"
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="c.email"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="last Name*"
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="c.last_name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="get_contact_list"
                item-value="id"
                item-text="name"
                return-object
                v-model="c.contact_type_id"
                outlined
                dense
                label="Contact type"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :items="get_gender"
                item-value="id"
                item-text="name"
                return-object
                v-model="c.gender_id"
                outlined
                dense
                label="Gender"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                type="date"
                dense
                outlined
                label="Birthday Date"
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="c.date_of_birth"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                type="number"
                dense
                outlined
                label="Age"
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="c.age"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                type="number"
                dense
                outlined
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="c.mobile_number"
                label="mobile"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                type="number"
                dense
                outlined
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="c.landline_number"
                label="phone"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                type="number"
                dense
                outlined
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="c.other"
                label="other"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                type="text"
                dense
                outlined
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="c.address"
                label="Address"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="get_source"
                item-value="id"
                item-text="name"
                return-object
                v-model="c.source_id"
                outlined
                dense
                label="Source"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <strong style="fontsize: 20px">Tags</strong>
              <v-text-field
                dense
                outlined
                v-model="for_tags"
                label="Add a tag"
                prepend-inner-icon="mdi-tooltip-text-outline"
                class="mt-3"
                @keyup.enter="add_tags()"
              >
                <template slot="append">
                  <small class="mt-2">Press ENTER to add</small>
                </template>
              </v-text-field>
            </v-col>
            <v-row>
              <v-col cols="12">
                <div class="tags-wrapper">
                  <div class="tags" v-for="(tag, index) in c.tags" :key="index">
                    <div class="tag-name">
                      {{ tag }}
                    </div>
                    <div class="tag-action">
                      <v-btn color="error" icon @click="remove_element(index)">
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-col cols="12">
              <strong style="fontsize: 20px">Notes</strong>
              <v-text-field
                dense
                outlined
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="c.notes"
                label="Add a contact note"
                class="mt-3"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-5 mx-4" elevation="2">
        <v-card-title class="text-small">
          <small>Additional contact information</small>
        </v-card-title>
        <v-card-subtitle>
          Include more in-depth contact details here
        </v-card-subtitle>
        <v-card-text>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Passport Details</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Full name as on passport"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.passport_full_name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Other name"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.passport_other_names"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      :items="get_country"
                      item-value="id"
                      item-text="name"
                      return-object
                      v-model="c.passport_country_origin_id"
                      outlined
                      dense
                      label="Passport country of origin"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Passport number"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.passport_number"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Passport issue details"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.passport_issue_details"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      type="date"
                      dense
                      outlined
                      label="Expiry Date"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.passport_expiry_date"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Visa information"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.passport_visa_info"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Vaccination Details"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.passport_vaccination_details"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Passport notes"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.passport_note"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Insurance Details</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Travel Insurance provider"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.travel_insurance_provider"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Policy number"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.insurance_policy_number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Insurance notes"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.insurance_notes"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Emergency Contact</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Full name"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.emergency_contact_name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      :items="get_relationship"
                      item-value="id"
                      item-text="name"
                      return-object
                      v-model="c.emergency_relationships_id"
                      outlined
                      dense
                      label="Relationship with traveller"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Email"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.emergency_email"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Phone"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.emergency_contact_number"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Licence Details</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Drivers licence details"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.drivers_licence_details"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Diving licence details"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.diving_licence_details"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Other licence details"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.other_licence_details"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Physical Details</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      type="number"
                      dense
                      outlined
                      label="Height"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.height"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      type="number"
                      dense
                      outlined
                      label="Weight"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.weight"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Clothing size"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.clothing_size"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Fitness level"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.fitness_level"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Cycling ability"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.cycling_ability"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Swimming ability"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.swimming_ability"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Health and mobility level"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.health_and_mobility"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Language Spoken</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      :items="get_language_spoken"
                      item-value="id"
                      item-text="name"
                      return-object
                      v-model="for_language_spoken"
                      outlined
                      dense
                      label="Add Language"
                      @keyup.enter="add_language_spoken"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <div class="tags-wrapper">
                      <div
                        class="tags"
                        v-for="(lang, index) in c.language_spoken"
                        :key="index"
                      >
                        <div class="tag-name">
                          {{ lang.name }}
                        </div>
                        <div class="tag-action">
                          <v-btn
                            color="error"
                            icon
                            @click="remove_spoken(index)"
                          >
                            <v-icon dark> mdi-delete </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Special Requirements</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Allergies"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.allergies"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Dietary requirements"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.dietary_requirements"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Religious or cultural requirements"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.religious_cultural_requirements"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Special assistance needed"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.special_assistance"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Social Media</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Facebook profile"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.facebook_profile"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Instagram profile"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.instagram_profile"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Wechat profile"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.wechat_profile"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="LinkedIn profile"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.linkedin_profile"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Other social profile"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="c.other_profile"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#588BAD"
            dark
            class="pl-10 pr-10"
            small
            rounded
            style="texttransform: none !important; color: white"
            @click="save_contact()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: [],
  data: () => ({
    activePicker: null,
    date: null,
    menu: false,
    c: {
      first_name: null,
      email: null,
      last_name: null,
      contact_type_id: null,
      gender_id: null,
      date_of_birth: null,
      age: null,
      mobile_number: null,
      landline_number: null,
      other: null,
      address: null,
      source_id: null,
      notes: null,
      passport_full_name: null,
      passport_other_names: null,
      passport_country_origin_id: null,
      passport_number: null,
      passport_issue_details: null,
      passport_expiry_date: null,
      passport_visa_info: null,
      passport_vaccination_details: null,
      passport_note: null,
      travel_insurance_provider: null,
      insurance_policy_number: null,
      insurance_notes: null,
      emergency_contact_name: null,
      emergency_relationships_id: null,
      emergency_contact_number: null,
      emergency_email: null,
      drivers_licence_details: null,
      diving_licence_details: null,
      other_licence_details: null,
      height: null,
      weight: null,
      clothing_size: null,
      fitness_level: null,
      cycling_ability: null,
      swimming_ability: null,
      health_and_mobility: null,
      allergies: null,
      dietary_requirements: null,
      religious_cultural_requirements: null,
      special_assistance: null,
      facebook_profile: null,
      instagram_profile: null,
      wechat_profile: null,
      linkedin_profile: null,
      other_profile: null,
      tags: [],
      language_spoken: [],
    },
    for_tags: null,
    for_language_spoken: null,
    panel: [0, 1, 2, 3, 4, 5, 6, 7],
  }),
  async mounted() {
    await this.$store.dispatch("contact/fetch_gender");
    await this.$store.dispatch("contact/fetch_country");
    await this.$store.dispatch("contact/fetch_language_spoken");
    await this.$store.dispatch("contact/fetch_relationship");
    await this.$store.dispatch("contact/fetch_source");
  },
  created() {
    this.setPageName("Contact")
  },
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
      get_contact_list: "contact/get_contact_list",
      get_gender: "contact/get_gender",
      get_country: "contact/get_country",
      get_language_spoken: "contact/get_language_spoken",
      get_relationship: "contact/get_relationship",
      get_source: "contact/get_source",
    }),
  },
  methods: {
    ...mapActions({
        setPageName:    'pageNameStore/setPageNameAction'
    }),
    add_language_spoken() {
      const selectif = this.c["language_spoken"].filter((item) => {
        return item.id === this.for_language_spoken.id;
      });
      if (!selectif[0]) {
        this.c["language_spoken"].push(this.for_language_spoken);
      }
      this.for_language_spoken = null;
    },
    add_tags() {
      const selectif = this.c["tags"].filter((item) => {
        return item === this.for_tags;
      });
      if (!selectif[0]) {
        this.c["tags"].push(this.for_tags);
      }
      this.for_tags = null;
    },

    remove_spoken(tagindex) {
      this.c.language_spoken.splice(tagindex, 1);
    },

    remove_element(tagindex) {
      this.c.tags.splice(tagindex, 1);
    },
    async save_contact() {
      await this.$axios
        .post("api/contacts/create", {
          company_id: this.get_selected_company.id,
          first_name: this.c.first_name,
          email: this.c.email,
          last_name: this.c.last_name,
          contact_type_id: this.c.contact_type_id
            ? this.c.contact_type_id.id
            : null,
          gender_id: this.c.gender_id ? this.c.gender_id.id : null,
          date_of_birth: this.c.date_of_birth,
          age: this.c.age,
          mobile_number: this.c.mobile_number,
          landline_number: this.c.landline_number,
          other: this.c.other,
          address: this.c.address,
          source_id: this.c.source_id,
          notes: this.c.notes,
          passport_full_name: this.c.passport_full_name,
          passport_other_names: this.c.passport_other_names,
          passport_country_origin_id: this.c.passport_country_origin_id
            ? this.c.passport_country_origin_id.id
            : null,
          passport_number: this.c.passport_number,
          passport_issue_details: this.c.passport_issue_details,
          passport_expiry_date: this.c.passport_expiry_date,
          passport_visa_info: this.c.passport_visa_info,
          passport_vaccination_details: this.c.passport_vaccination_details,
          passport_note: this.c.passport_note,
          travel_insurance_provider: this.c.travel_insurance_provider,
          insurance_policy_number: this.c.insurance_policy_number,
          insurance_notes: this.c.insurance_notes,
          emergency_contact_name: this.c.emergency_contact_name,
          emergency_relationships_id: this.c.emergency_relationships_id
            ? this.c.emergency_relationships_id.id
            : null,
          emergency_contact_number: this.c.emergency_contact_number,
          emergency_email: this.c.emergency_email,
          drivers_licence_details: this.c.drivers_licence_details,
          diving_licence_details: this.c.diving_licence_details,
          other_licence_details: this.c.other_licence_details,
          height: this.c.height,
          weight: this.c.weight,
          clothing_size: this.c.clothing_size,
          fitness_level: this.c.fitness_level,
          cycling_ability: this.c.cycling_ability,
          swimming_ability: this.c.swimming_ability,
          health_and_mobility: this.c.health_and_mobility,
          allergies: this.c.allergies,
          dietary_requirements: this.c.dietary_requirements,
          religious_cultural_requirements:
            this.c.religious_cultural_requirements,
          special_assistance: this.c.special_assistance,
          facebook_profile: this.c.facebook_profile,
          instagram_profile: this.c.instagram_profile,
          wechat_profile: this.c.wechat_profile,
          linkedin_profile: this.c.linkedin_profile,
          other_profile: this.c.other_profile,
          tags: this.c.tags,
          language_spoken: this.c.language_spoken,
        })
        .then(({ data }) => {
          console.log(data);
          if (data.response) {
            this.c = {};
            this.$router.push({ name: "/contact" });
          }
        });
    },
  },
  watch: {},
};
</script>

<style scoped>
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}
.card .card-header-items {
  text-align: center;
}
.card .card-header-items .header-items {
  margin: 0px 0px;
}
.media-image:hover {
  cursor: pointer;
  box-shadow: 0px 1px 5px 5px rgba(169, 169, 169, 0.6);
}
.image-show-wrapper {
  width: 100%;
}
.image-show-wrapper .image-show {
  max-width: 50%;
  margin: 0 auto;
}
.image-show-wrapper .image-show-name {
  font-size: 10px;
  text-align: center;
}
.tags-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.tags-wrapper .tags {
  max-height: 50px;
  display: flex;
  background-color: #dcdee0;
}
.tags-wrapper .tags .tag-name {
  margin: auto 0;
  padding: 12px;
  color: #343642;
  font-weight: bold;
  font-size: 16px;
  background: rgb(237, 238, 239);
}
.tags-wrapper .tags .tag-action {
  margin: auto 0;
}
.media-button {
  text-align: center;
}
.media-button .input-media {
  /* color: rgb(82, 90, 104); */
  display: none;
}
.dialog-close {
  margin-left: -75px;
  position: absolute;
}
@media only screen and (max-width: 959px) {
  .card .card-header {
    text-align: center;
  }
  .card .card-header-items .header-items {
    margin: 5px 5px 10px 5px;
  }
}
</style>
