<template>
    <v-container
        fluid
    >
        <v-data-table
            dense
            :headers="headers"
            :items="payments"
            class="elevation-1"
            hide-default-header
        >
            <template v-slot:header="{ props }">
                <th
                    v-for="(head, index) in props.headers"
                    :key="index"
                >
                    <label
                        class="font-weight-light"
                    >
                        {{ head.text.toUpperCase() }}
                    </label>
                </th>
            </template>
            <template
                v-slot:no-data
            >
                <label
                    class="float-left ma-5"
                    style="font-weight: 700; font-size: large;"
                >No record found!</label>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
        headers: [
            {
                text: 'Days'
            },
            {
                text: 'Item'
            },
            {
                text: 'Due Date'
            },
            {
                text: 'Amount Due'
            },
            {
                text: 'Date Paid'
            },
            {
                text: 'Amount Paid'
            }
        ],
        payments: [],
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>