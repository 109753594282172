<template>
    <v-row justify="center">
        <v-dialog v-model="create_new_lead_model" max-width="50%" persistent scrollable>
            <v-card>
                <v-card-title>
                    Create New Lead
                </v-card-title>
                <v-card-text>
                    <v-form ref="lead_form_data">
                        <v-row>
                            <v-col cols="6">
                                <div>
                                    <label class="form-label">Advisor <span class="required-field">*</span></label>
                                    <v-autocomplete v-model="lead.advisor_id" outlined dense
                                        placeholder="Select Advisor" item-text="name" item-value="id"
                                        :items="getAdvisors" tabindex="1" autofocus requried
                                        :rules="[v => !!v || 'You are required to select an Advisor for this lead.']"></v-autocomplete>
                                </div>
                                <div>
                                    <label class="form-label">Nights <span class="required-field">*</span></label>
                                    <v-text-field v-model="lead.duration" outlined dense placeholder="Enter No. of Nights"
                                        type="number" tabindex="2" required :rules="[
                                            v => !!v || 'You are required to enter the number of nights for this lead.',
                                            v => (v > 0) || 'The number of nights must be at least one (1) nights for this lead.' 
                                        ]"></v-text-field>
                                </div>
                                <div>
                                    <label class="form-label">Group Size <span class="required-field">*</span></label>
                                    <v-text-field v-model="lead.people" outlined dense placeholder="Enter Group Size"
                                        type="number" tabindex="3" required :rules="[
                                            v => !!v || 'You are required to enter the number of poeople for this lead.',
                                            v => (v > 0) || 'The number of poeople must be at least one (1) for this lead.'
                                        ]"></v-text-field>
                                </div>
                                <div>
                                    <label class="form-label">Event Type <span class="required-field">*</span></label>
                                    <v-select v-model="lead.event_type_id" :items="event_types" item-text="name"
                                        item-value="id" outlined dense placeholder="Select Event Type" tabindex="4"
                                        required :rules="[
                                            v => !!v || 'You are required to select an event type for this lead.'
                                        ]">
                                    </v-select>
                                </div>
                                <div>
                                    <label class="format-label">Party Location <span
                                            class="required-field">*</span></label>
                                    <v-autocomplete v-model="lead.location_id" :items="getLocation" item-text="name"
                                        item-value="id" outlined dense placeholder="Select Party Location" tabindex="5"
                                        required :rules="[
                                            v => !!v || 'You are required to select a party location for this lead.'
                                        ]"></v-autocomplete>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <div>
                                    <label class="form-label">First Name <span class="required-field">*</span></label>
                                    <v-text-field v-model="lead.first_name" outlined dense
                                        placeholder="Enter First Name" type="text" tabindex="7" required :rules="[
                                            v => !!v || 'You are required to enter the first name of the organizer for this lead.'
                                        ]"></v-text-field>
                                </div>
                                <div>
                                    <label class="form-label">Last Name <span class="required-field">*</span></label>
                                    <v-text-field v-model="lead.last_name" outlined dense placeholder="Enter Last Name"
                                        type="text" tabindex="8" required :rules="[
                                            v => !!v || 'You are required to enter the last name of the organizer for this lead.'
                                        ]"></v-text-field>
                                </div>
                                <div>
                                    <label class="form-label">Mobile Number</label>
                                    <v-text-field v-model="lead.phone" outlined dense placeholder="Enter Mobile Number"
                                        type="text" tabindex="9"></v-text-field>
                                </div>
                                <div>
                                    <label class="form-label">Email <span class="required-field">*</span></label>
                                    <v-text-field v-model="lead.email" outlined dense placeholder="Enter Email"
                                        type="text" tabindex="10" required :rules="[
                                            v => !!v || 'You are required to enter the email address of the organizer for this lead.'
                                        ]"></v-text-field>
                                </div>
                                <div>
                                    <label class="form-label">Party Date <span class="required-field">*</span></label>
                                    <v-menu ref="menu" v-model="party_date.menu" :close-on-content-click="false"
                                        :return-value.sync="party_date.date" min-width="auto">
                                        <template v-slot:activator="{on, attrs}">
                                            <v-text-field v-model="party_date.formatted_date" outlined dense
                                                placeholder="Select a Party Date to start." type="text" v-bind="attrs"
                                                v-on="on" tabindex="6" required :rules="[
                                                    v => !!v || 'You are required to select a party date for this lead.'
                                                ]">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="party_date.date" @change="eventSave"
                                            :min="new Date().toISOString()" :events="addPartyActivities"
                                            event-color="green"></v-date-picker>
                                    </v-menu>
                                </div>
                                <!-- <div>
                                    <label class="form-label">Password <span class="required-field">*</span></label>
                                    <v-text-field
                                        v-model="lead.password"
                                        outlined
                                        dense
                                        placeholder="Enter Password"
                                        :type="show_password ? 'text' : 'password'"
                                        :append-icon="show_password ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="show_password = !show_password"
                                        tabindex="11"
                                        autocomplete="new-password"
                                        required
                                        :rules="[
                                            v => !!v || 'You are required to enter the password of the organizer for this lead.'
                                        ]"></v-text-field>
                                </div>
                                <div>
                                    <label class="form-label">Confirm Password</label>
                                    <v-text-field
                                        v-model="confirm_password"
                                        outlined
                                        dense
                                        placeholder="Enter Confirm Password"
                                        :type="show_confirm_password ? 'text' : 'password'"
                                        :append-icon="show_confirm_password ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="show_confirm_password = !show_confirm_password"
                                        tabindex="12"
                                        required
                                        :rules="[
                                            v => (v === this.lead.password) || 'The password you enter does not match with the confirmed password.'
                                        ]"></v-text-field>
                                </div> -->
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" dense @click="eventCloseCreateNewLead()" class="btn-font-format px-6">
                        <label class="text-proper-case" tabindex="14">Close</label>
                    </v-btn>
                    <v-btn elevation="0" dense class="btn-font-format px-6" color="primary"
                        @click="eventSaveNewlyCreatedLead()">
                        <label class="text-proper-case" tabindex="13">Create</label>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Leads from '@/modules/admin/leads/modules/leads.js'
import Settings from '@/modules/admin/settings/modules/settings.js'

export default {
    props: {
        show_create_new_lead_dialog: Boolean
    },
    data: () => ({
        create_new_lead_model: false,
        lead: {
            advisor_id: 0,
            duration: 1,
            event_type_id: null,
            start_date: null,
            location_id: null,
            first_name: null,
            last_name: null,
            phone: null,
            email: null,
            password: null,
            people: 1,
            company_id: null
        },
        event_types: [],
        party_date: {
            menu: false,
            date: new Date().toISOString().split("T")[0],
            formatted_date: new Date().toLocaleDateString(),
        },
        show_password: false,
        show_confirm_password: false,
        confirm_password: null,
        classes: {
            leads: null,
            settings: null,
        }
    }),
    async mounted() {
        this.classes.leads = new Leads()
        this.classes.settings = new Settings()
        this.getEventTypes()
    },
    computed: {
        ...mapGetters({
            getAdvisors:            'leadsStore/getAdvisorsGetter',
            get_selected_company:   'auth/get_selected_company',
            getLocation:            'locationStore/getLocationsGetter',
        }),
        addPartyActivities() {
            let number_of_days = 0;
            return [...Array(parseInt(this.lead.duration))].map( () => {
                const event_date = new Date(this.party_date.date)
                number_of_days = number_of_days + 1
                event_date.setDate(event_date.getDate() + number_of_days)
                return event_date.toISOString().substr(0, 10)
            })
        }
    },
    methods: {
        ...mapActions({
            
        }),
        async getEventTypes() {
            const result = await this.classes.settings.getEventTypes({ company_id: this.get_selected_company.id })
            if (result.response == true) {
                this.event_types = result.data
            }
        },
        eventCloseCreateNewLead() {
            this.$emit('eventCloseCreateNewLead')
        },
        eventSave(party_date) {
            this.$refs.menu.save(party_date)
            this.party_date.formatted_date = new Date(party_date).toLocaleDateString()
        },
        async eventSaveNewlyCreatedLead() {
            const valid = this.$refs.lead_form_data.validate()
            if ( valid ) {
                // TODO:
                this.lead.start_date = this.party_date.date
                this.lead.company_id = this.get_selected_company.id
                const lead_data = await this.classes.leads.createLead(this.lead).then( (data) => data)
                if ( typeof lead_data.status === "undefined" ) {
                    this.eventCloseCreateNewLead()
                    this.$toast.success('You have successfully created a new lead.', {timeout: 2000, icon: true})
                } else {
                    this.$toast.error(`${lead_data.status}: ${lead_data.statusText}`)
                }
                
            }
        }
    },
    watch: {
        show_create_new_lead_dialog() {
            this.create_new_lead_model = this.show_create_new_lead_dialog
        }
    }
}

</script>

<style lang="scss" scoped>
.btn-font-format {
    font-family: 'Manrope';
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
}

.form-label {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 600;
    color: #7C7C7C;
}

.required-field {
    color: red
}

.text-proper-case {
    cursor: pointer;
}
</style>