<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn class="custom-buttons" dark @click="getUserActivity()">
                    <v-icon class="mr-2">mdi-restart</v-icon>Refresh
                </v-btn>
            </v-card-title>
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-card-text v-else>
                <v-data-table :headers="headers" :items="user_activities" :sort-by.sync="sort_by"
                    :sort-desc.sync="sort_desc">
                    <template v-slot:item="{ item }">
                        <tr style="cursor:inherit;background-color:transparent;height:80px;">
                            <td>
                                {{ $date(item.created_at).format('MMMM DD, YYYY') }}
                                <br>
                                {{ $date(item.created_at).format('h:mm A') }}
                            </td>
                            <td>
                                <small><b>User: {{ item.user_id }}</b></small>
                                <br>
                                {{ item.user.name }}
                            </td>
                            <td>
                                <small><b>Company: {{ item.company_id }}</b></small>
                                <br>
                                <label class="access-label">
                                    {{ item.company.company_name }}
                                </label>
                            </td>
                            <td>{{ item.message }}</td>
                            <td>
                                <div style="display:flex;">
                                    <div v-if="item.lead_id" class="mx-2" style="width:100px">
                                        <small><b>Lead: {{ item.lead_id }}</b></small>
                                        <br>
                                        {{ item.lead.first_name + ' ' + item.lead.last_name }}
                                    </div>
                                    <div v-if="item.quote_id" class="mx-2" style="width:100px">
                                        <small><b>Quote: {{ item.quote_id }}</b></small>
                                        <br>
                                        {{ item.quote.name }}
                                    </div>
                                    <div v-if="item.event_id" class="mx-2" style="width:100px">
                                        <small><b>Event: {{ item.event_id }}</b></small>
                                        <br>
                                        {{ item.event.name }}
                                    </div>
                                    <div v-if="item.invitee_id" class="mx-2" style="width:100px">
                                        <small><b>Invitee: {{ item.invitee_id }}</b></small>
                                        <br>
                                        {{ item.invitee.name }}
                                    </div>
                                    <div v-if="item.supplier_id" class="mx-2" style="width:100px">
                                        <small><b>Supplier: {{ item.supplier_id }}</b></small>
                                        <br>
                                        {{ item.supplier.supplier_name }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data() {
        return {
            loading: false,
            headers: [
                { text: 'Timestamp', width: '130px', sortable: false },
                { text: 'User', width: '130px', sortable: false },
                { text: 'Company', width: '120px', sortable: false },
                { text: 'Description', width: '200px', sortable: false },
                { text: 'Details', sortable: false },
            ],
            user_activities: [],
            sort_by: 'id',
            sort_desc: true,
        }
    },
    async mounted() {
        this.getUserActivity()
    },
    created() {
        this.setPageName('User Activity')
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        })
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction'
        }),
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getUserActivity() {
            this.loading = true
            this.$axios.get('admin/user_activity/get_user_activities')
                .then(({ data }) => {
                    if (data.response) {
                        this.user_activities = data.data
                        this.loading = false
                    }
                })
        },
    },
    watch: {

    }

}
</script>

<style scoped lang="scss">
:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
}
</style>